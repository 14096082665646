import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import { GET } from '../../helpers/APIServices';
import { ServiceConfirmEmailNewUser } from '../../helpers/getURL';

import '../../css/CheckIn/VerifyUser.css';

export const VerifyUser = () => {
  const history = useHistory();
  const { search } = useLocation();
  if (!search) {
    history.push('/?home=true');
  }

  useEffect(() => {
    let URLConfirm = ServiceConfirmEmailNewUser();

    if (URLConfirm) {
      URLConfirm += search;
      GET(URLConfirm).then((data) => {
        if (data !== true) {
          history.push('/expire');
        }
        const element = document.getElementsByClassName('container__VerifyUser');
        if (element && element.length === 1) {
          element[0].classList.remove('hidden');
          setTimeout(() => history.push('/'), 10000);
        }
      });
    }
  }, [search, history]);

  return (
    <div className="row container-fluid container__VerifyUser justify-content-center hidden">
      <div className="col-10 col-sm-8 col-md-6 col-lg-5 text-center container__VerifyUser-content">
        <i className="fas fa-check"></i>
        <label>Usuario verificado</label>
      </div>
    </div>
  );
};
