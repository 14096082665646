import React, { useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
//import { useContext } from 'react/cjs/react.development';
import { DataContext } from '../../../context/DataContext';
import { CloseModalIcon } from '../../Home/CloseModalIcon';
import { CloseModal, setSession } from '../../Utilities/General';

export const ClosingModal = () => {
  const { setValidate } = useContext(DataContext);
  const history = useHistory();
  const { search } = useLocation();
  const url = new URLSearchParams(search);
  const exitStreaming = () => {
    const isGoAccount = url.has('profile');
    const isLogOut = url.has('logout');
    if (isGoAccount) {
      CloseModal('modalCloseStreaming');
      history.push('/perfil?profile=true');
    } else if (isLogOut) {
      setValidate({
        valid: false,
        registeredAuctions: [],
        idAuction: '',
        nameAuction: '',
        dateAuction: '',
        palletAuction: '',
        idPallet: '',
        onPaymentValidate: false,
      });
      setSession(false, '');
      history.push('/?home=true');
    } else {
      history.push('?home=true');
    }
  };
  const cancelExit = () => {
    history.push(`/streaming?id=${url.get('id')}`);
    CloseModal('modalCloseStreaming');
  };
  return (
    <div
      className="modal fade "
      id="modalCloseStreaming"
      aria-labelledby="closeStreamingModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header justify-content-end border-0">
            <CloseModalIcon onHandleClick={cancelExit} />
          </div>
          <div className="modal-body text-center mb-3">
            <div className="closing-accept">
              <img src="./resources/logo.svg" alt="logo MLS" className="img-fluid" width="50%" />
            </div>
            <h3 className="text-uppercase px-5 pb-5">¿Está seguro que desea salir del streaming?</h3>
            <button
              type="button"
              className="btn btn-outline-danger w- btn-lg me-2"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={(e) => exitStreaming()}
            >
              Sí
            </button>
            <button
              type="button"
              className="btn btn-outline-secondary w-auto btn-lg ms-2"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => cancelExit()}
            >
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
