import React, { useLayoutEffect, useState, useEffect, useContext } from 'react';
//componentes
import { Carousel } from './Carousel';
import Auctions from './Auctions';
import { AuctionsCarousel } from './AuctionsCarousel';
import { AuctionsHighlightSection } from './AuctionsHighlightSection';
import { AuctionsHighlightCarousel } from './AuctionsHighlightCarousel';
import Video from './Video';

//Servicios
import { GET } from '../../helpers/APIServices';
import { ServiceLastCatalog, ServiceCatalog, ServiceAuctionCustomerStatus } from '../../helpers/getURL';

//Animaciones
import { setSlideIn } from '../Utilities/Animation';

//CSS Index
import '../../css/index.css';
import { DataContext } from '../../context/DataContext';

export const Home = () => {
  const [mobile, setMobile] = useState([]);
  const [lastCatalog, setLastCatalog] = useState({});
  const [articles, setArticles] = useState([]);
  const [auctions, setAuctions] = useState([]);
  const [userStatusLastCatalog, setUserStatusLastCatalog] = useState('');
  const {
    validate: { valid, idPallet },
  } = useContext(DataContext);
  const getArticles = (idAuction) => {
    if (!idAuction) {
      return;
    }
    const URLCatalog = ServiceCatalog();
    let url = new URL(URLCatalog);
    let params = {
      idAuction,
      PageNumber: 1,
      Destacado: true,
      Order: '03',
    };

    Object.keys(params).forEach((key) => {
      if (!params[key]) {
        delete params[key];
      }
    });
    Object.keys(params).forEach((key) => {
      url.searchParams.append(key, params[key]);
    });

    GET(url).then(({ lots = [] }) => {
      if (lots.length === 0) {
        setArticles([]);
      } else {
        setArticles(lots);
      }
    });
  };

  useLayoutEffect(() => {
    const handleResize = () => {
      setMobile(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {window.removeEventListener('resize',handleResize)};
  }, []);

  useLayoutEffect(() => {
    const handleScroll = () => {
      setSlideIn('.container__auctions--types');
      setSlideIn('.container__auctionsHighlight--types');
    }
    window.addEventListener('scroll', handleScroll);
    return () => {window.removeEventListener("scroll", handleScroll)};
  }, []);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if(idPallet){
      getData();
    }
  }, [valid]);

  const getData = () => {
    const id = localStorage.getItem('id');
    const URLLastCatalog = ServiceLastCatalog();
    if (id) {
      const URLAuctionCustomerStatus = ServiceAuctionCustomerStatus(id);
      GET(URLAuctionCustomerStatus).then((response) => {
        if (response) {
          setAuctions(response);
          GET(URLLastCatalog).then((data) => {
            if (data) {
              const users = response.find((auction) => auction.idAuction === data.id);
              if (users) {
                setUserStatusLastCatalog(users.idAuctionCustomerStatus);
              }
              setLastCatalog(data);
              getArticles(data.id);
            }
          });
        }
      });
    } else {
      GET(URLLastCatalog).then((data) => {
        if (data) {
          setLastCatalog(data);
          getArticles(data.id);
        }
      });
    }
  }

  if (mobile <= 576) {
    return (
      <div>
        <Carousel auctions={auctions} />
        <AuctionsCarousel data={lastCatalog} userStatusLastCatalog={userStatusLastCatalog} />
        <AuctionsHighlightCarousel data={lastCatalog} articles={articles} />
        <Video />
      </div>
    );
  } else {
    return (
      <div>
        <Carousel auctions={auctions} />
        <Auctions title="Próxima" title2="subasta" data={lastCatalog} userStatusLastCatalog={userStatusLastCatalog} />
        <AuctionsHighlightSection data={lastCatalog} articles={articles} />
        <Video />
      </div>
    );
  }
};
