import React, { useEffect, useRef, useState } from 'react';
import { OpenOfferSuccess } from '../Utilities/General';

export const CountDownCatalog = ({
  startAuctionDate,
  endAuctionDate,
  classAuction,
  onCourseCountDown,
  onEndCountDown,
  textStatusAuction,
  titleColor,
  scheduled,
  statusAuction
}) => {
  const [timerHours, setTimerHours] = useState('00');
  const [timerMinutes, setTimerMinutes] = useState('00');
  const [timerSeconds, setTimerSeconds] = useState('00');

  let interval = useRef();

  const startTimer = () => {
    let countDownDate = startAuctionDate.getTime();

    interval = setInterval(() => {
      const now = new Date().getTime();
      const status = Math.sign(countDownDate - now);
      if (status < 0) {
        countDownDate = endAuctionDate;
        onCourseCountDown();
      }
      const distance = countDownDate - now;
      let days = Math.floor(distance / (1000 * 60 * 60 * 24));
      let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)) + days * 24;
      let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = Math.floor((distance % (1000 * 60)) / 1000);
      if (distance < 0) {
        clearInterval(interval);
        onEndCountDown();
      } else {
        if (hours === 0 && minutes === 20 && seconds === 0 && statusAuction === scheduled) {
          const message = '<h2>LA SUBASTA ESTÁ A PUNTO</h2><h2>DE COMENZAR</h2>';
          OpenOfferSuccess(message);
        }
        if (hours < 10) {
          hours = '0' + hours;
        }
        if (minutes < 10) {
          minutes = '0' + minutes;
        }
        if (seconds < 10) {
          seconds = '0' + seconds;
        }
        setTimerHours(hours);
        setTimerMinutes(minutes);
        setTimerSeconds(seconds);
      }
    }, 1000);
  };

  useEffect(() => {
    startTimer();
    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <>
      <h4 className={`text-center ${titleColor}`}>{textStatusAuction}</h4>
      <div className={classAuction}>
        <section>
          <p>
            <img src="./resources/Timer@2x.png" alt="" width="30px" />
          </p>
        </section>
        <section>
          <p>{timerHours}</p>
          <p>
            <small>HORAS</small>
          </p>
        </section>
        <span className="separator">:</span>
        <section>
          <p>{timerMinutes}</p>
          <p>
            <small>MINUTOS</small>
          </p>
        </section>
        <span className="separator">:</span>
        <section>
          <p>{timerSeconds}</p>
          <p>
            <small>SEGUNDOS</small>
          </p>
        </section>
      </div>
    </>
  );
};
