import React from 'react';
import { useHistory } from 'react-router-dom';
import { CloseModal } from '../Utilities/General';

export const WatchCatalog = ({ idAuction, modal, goCatalog, clasStyle = '' }) => {
  const history = useHistory();
  const onCloseModal = () => {
    CloseModal('modalStreamingReisterMesage');
    history.push('/catalogo?id=' + idAuction);
    goCatalog();
  };
  const openCatalog = () => {
    history.push('/catalogo?id=' + idAuction);
  };
  return (
    <button className={`btn btn-outline-danger btn-lg ${clasStyle}`} onClick={(e) => (modal ? onCloseModal() : openCatalog())}>
      Ver catálogo
    </button>
  );
};
