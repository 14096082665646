import React, { useState, useEffect } from 'react';
import { capitalize } from '../Utilities/General';
import { ServiceGetCity } from '../../helpers/getURL';
import { GET } from '../../helpers/APIServices';
import Select, { components } from 'react-select';

export const SelectCity = (props) => {
  const [style, setStyle] = useState('');
  useEffect(() => {
    if (props.errors.city && props.address.idCol === '') {
      const styleError = {
        control: (base, state) => ({
          ...base,
          borderColor: 'var(--color-error)',
        }),
        singleValue: (provided) => ({
          ...provided,
          color: 'var(--color-error)',
        }),
      };
      setStyle(styleError);
    } else {
      const styleDefault = {
        control: (base, state) => ({
          ...base,
          borderColor: 'hsl(0, 0%, 80%)',
        }),
        singleValue: (provided) => ({
          ...provided,
          color: 'var(--color-text)',
        }),
      };
      setStyle(styleDefault);
    }
  }, [props.errors.city, props.address.idCol]);

  const [cities, setCities] = useState([]);
  useEffect(() => {
    if (props.address.idPais !== 'MX' || props.address.idEdo === '' || props.address.idMun === '') {
      setCities([]);
      return;
    }
    let URL = ServiceGetCity(props.address.idEdo, props.address.idMun);
    if (URL) {
      const getData = () => {
        GET(URL).then((data) => {
          if (data === '401') {
            getData();
            return '';
          }
          if (data) {
            setCities(data);
          } else {
            setCities([]);
          }
        });
      };
      getData();
    }
  }, [props.address.idPais, props.address.idEdo, props.address.idMun]);

  const address = (e) => {
    props.setAddress({
      ...props.address,
      idCol: e.value,
      col: e.label
    });
  };

  let value;
  let options = [];

  options.push({ value: '', label: 'Colonia:' });
  if (props.listCities.length > 0) {
    props.listCities.map((item) => {
      options.push({ value: item.idCity, label: capitalize(item.sCity) });
      return '';
    });
  } else {
    cities.map((item) => {
      options.push({ value: item.idCity, label: capitalize(item.sCity) });
      return '';
    });
  }

  value = options.length === 0 ? { value: '', label: 'Colonia:' } : options.find((obj) => obj.value === props.address.idCol);

  const DropdownIndicator = (props) => {
    let icon = 'fas fa-angle-down';
    if (props.selectProps.menuIsOpen) {
      icon = 'fas fa-search';
    }
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <i className={icon}></i>
        </components.DropdownIndicator>
      )
    );
  };

  return (
    <span className="dropdown shadow-0 col-12">
      <Select
        id={props.id}
        options={options}
        isSearchable={true}
        onChange={(e) => address(e)}
        placeholder="Colonia:"
        value={value}
        isDisabled={props.disabled}
        styles={style}
        components={{ DropdownIndicator }}
      />
    </span>
  );
};
