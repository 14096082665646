import React, { useContext, useEffect, useState } from 'react';
import { DataContext } from '../../context/DataContext';

import { ButtonRegister } from '../Register/ButtonRegister';
import { ButtonToStreaming } from '../Register/ButtonToStreaming';
import { WatchCatalog } from '../Register/WatchCatalog';
import { convertToDate } from '../Utilities/General';
import AuctionsItem from './AuctionsItem';
import { getIdStreaming, getExecution } from '../../helpers/getConstants';

function Auctions(props) {
  const [state, setState] = useState({
    name: '',
    idAuction: '',
    auctionDate: new Date(),
    pallet: 0,
    isStreaming: false,
    idPallet: '',
    idType: '',
    idStatus: '',
    title: '',
  });
  const { userStatusLastCatalog = '' } = props;
  const idStreaming = getIdStreaming();
  const execution = getExecution();
  const {
    validate: { registeredAuctions },
  } = useContext(DataContext);

  const getIsStreaming = (idAuction) => {
    const auctionValue = registeredAuctions.find((auction) => auction.idAuction === idAuction);
    if (auctionValue) {
      setState((prevState) => ({
        ...prevState,
        pallet: parseInt(auctionValue.pallet, 10),
        isStreaming: auctionValue.streaming,
        idPallet: auctionValue.idPallet,
      }));
    }
  };

  useEffect(() => {
    const data = props.data;
    if (data) {
      getIsStreaming(data.id);
      setState((prevState) => ({
        ...prevState,
        name: data.id ? data.name : '',
        idAuction: data.id,
        auctionDate: convertToDate(data.auctionDate),
        idType: data.idType,
        idStatus: data.idStatus,
      }));
    }
  }, [props]);

  if (state.idAuction) {
    return (
      <div className="container-fluid container-auctions mb-4 pb-4">
        <div className="row">
          <div className="col-11 offset-1 ">
            <h2>
              {' '}
              <span className="container-auctions--title">{props.title}</span> {props.title2}
            </h2>
          </div>
        </div>
        <div className="row container-auctions-row">
          <div className="col-12 col-sm-5 offset-sm-1 text-center-mobile">
            <span className="container-auctions--subtitle">{state.name}</span>
          </div>
          <div className="col-12 col-sm-5 text-center-mobile container-auctions-col">
            {state.pallet > 0 && state.idType === idStreaming && state.idStatus === execution && (
              <ButtonToStreaming streaming={state.isStreaming} idAuction={state.idAuction} userStatusLastCatalog={userStatusLastCatalog} />
            )}
            {state.pallet <= 0 && <ButtonRegister idAuction={state.idAuction} nameAuction={state.name} dateAuction={state.auctionDate} />}
          </div>
        </div>
        <div className="row">
          <div className="col-11 offset-1 text-center-mobile">
            <span className="container-auctions--subtitle2">{state.auctionDate}</span>
          </div>
        </div>
        <div className="row container-auctions-row">
          <div className="col-11 container-auctions-col container-auctions-btn2">
            <WatchCatalog idAuction={state.idAuction} modal={false} clasStyle={'watch-catalog'} />
          </div>
        </div>
        <div className="row container-auctions-row">
          <div className="col-11 offset-1 mt-5 mb-5">
            <span className="container-auctions--description">
              Contaremos con una gran variedad de piezas únicas en distintas categorías:{' '}
            </span>
          </div>
        </div>
        <div className="row justify-content-center container-auctions-row">{props.children}</div>

        <div className="row justify-content-sm-center container__auctions--types">
          <AuctionsItem productName="Relojes" img="resources/SV-343.jpg" idAuction={state.idAuction} category={'02'} class={''} />
          <AuctionsItem
            productName="Alhajas"
            img="resources/SV-010.jpg"
            idAuction={state.idAuction}
            category={'01'}
            class={'container-auctions-items--ml offset-xxl-2'}
          />
          <AuctionsItem
            productName="Artículos personales"
            img="resources/SV-231.jpg"
            idAuction={state.idAuction}
            category={'03'}
            class={'container-auctions-items--ml offset-xxl-2'}
          />
        </div>
      </div>
    );
  }
  return (
    <div className="container-fluid container-auctions mb-4 pb-4">
      <div className="row">
        <div className="col-12 text-center">
          <h2>
            <span className="container-auctions--title"> Espera nuestras próximas Subastas.</span>
          </h2>
        </div>
      </div>
    </div>
  );
}

export default Auctions;
