import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getConfig } from '../../helpers/getConfig';

function Video() {
  const [data, setData] = useState([]);

  useEffect(() => {
    getConfig('video.json').then((data) => {
      setData(data);
    });
    const video = document.getElementById('videoAuction');
    if (video) {
      video.controls = true;
      video.preload = 'auto';
      video.autoPlay = true;
      video.muted = true;
      video.loop = true;
      video.playsInline = true;
    }
  }, []);

  const { title = '', text1 = '', url = '', text2 = '' } = data;

  return (
    <div className="container-fluid container-video">
      <div className="row">
        <div className="col">
          <div dangerouslySetInnerHTML={{ __html: title }} />
        </div>
      </div>
      <div className="row justify-content-center container-video--description">
        <div className="col-10">
          <div dangerouslySetInnerHTML={{ __html: text1 }} />
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-10">
          <div className="embed-responsive embed-responsive-16by9">
            <video
              id="videoAuction"
              src={url}
              width="960"
              height="480"
              controls
              preload="auto"
              autoPlay
              muted
              loop
              playsInline
              type="video/mp4"
            >
              <source src={url} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
      <div className="row justify-content-center container-video--description">
        <div className="col">
          <div dangerouslySetInnerHTML={{ __html: text2 }} />
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col">
          <Link to="/contacto">
            <button className="btn btn-outline-secondary btn-lg btn-video--footer">Contáctanos</button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Video;
