import React, { useState, useRef, useEffect } from 'react';
import NumberFormat from 'react-number-format';
import ReCAPTCHA from 'react-google-recaptcha';

import { POST } from '../../helpers/APIServices';
import {
  CloseModal,
  OpenMessage,
  verifyFieldsRequerid,
  validEmail,
  validMinMaxDate,
  restoreInputs,
  validPassword,
  setMinMaxDate,
  onlyText,
  onlyMail,
  changePasswordText,
} from '../Utilities/General';
import { ServiceClients } from '../../helpers/getURL';

import '../../css/CheckIn/CheckIn.css';
import { CloseModalIcon } from '../Home/CloseModalIcon';

function CheckIn() {
  const [inputName, setinputName] = useState('');
  const [inputLastName, setinputLastName] = useState('');
  const [inputSecondLastName, setinputSecondLastName] = useState('');
  const [inputBirthday, setinputBirthday] = useState('');
  const [inputMail, setinputMail] = useState('');
  const [inputPhone, setinputPhone] = useState('');
  const [inputPassword, setinputPassword] = useState('');
  const [inputVerifyPassword, setinputVerifyPassword] = useState('');
  const [disabledBtn, setdisabledBtn] = useState(true);
  const [checked, setChecked] = useState(false);
  const URL = ServiceClients();
  const [captcha, setCaptcha] = useState(false);
  const captchaContact = useRef(null);

  useEffect(() => {
    setMinMaxDate('input-birthday');
  }, []);

  const inputs = [
    'input-nameCheckIn',
    'input-firtsName',
    'input-lastName',
    'input-birthday',
    'input-emailCheckIn',
    'input-phoneCheckIn',
    'input-passwordCheck',
    'input-passwordCheck2',
  ];

  const enableBtn = () => {
    setdisabledBtn(true);
    const chk = document.getElementById('CheckCondition').checked;

    if (
      inputName &&
      inputLastName &&
      inputSecondLastName &&
      inputBirthday &&
      inputMail &&
      inputPhone &&
      inputPassword &&
      inputVerifyPassword &&
      chk
    ) {
      setdisabledBtn(false);
    }
  };

  const clearState = () => {
    setMinMaxDate('input-birthday');
    setinputBirthday('');
    setinputName('');
    setinputLastName('');
    setinputSecondLastName('');
    setinputMail('');
    setinputPhone('');
    setinputPassword('');
    setinputVerifyPassword('');
    setChecked(false);
    restoreInputs(inputs);
    CloseModal('modalCheckIn');
    setCaptcha(false);
    setdisabledBtn(true);
    document.getElementById('input-birthday').classList.add('active');
  };

  const verifyFields = () => {
    const validFields = verifyFieldsRequerid(inputs);
    if (!validFields) {
      OpenMessage('error', 'Campos obligatorios');
      return false;
    } else {
      if (!validEmail(inputMail)) {
        document.getElementById('input-emailCheckIn').classList.add('is-invalid');
        OpenMessage('error', 'El formato del correo eléctronico no es válido');
        return false;
      }
      if (!validMinMaxDate('input-birthday')) {
        document.getElementById('input-birthday').classList.add('is-invalid');
        OpenMessage('error', 'La fecha de nacimiento no es válida');
        return false;
      }
      if (!verifyPassword()) {
        return false;
      }
      return true;
    }
  };

  const verifyPassword = () => {
    const password = ['input-passwordCheck', 'input-passwordCheck2'];
    const validFields = verifyFieldsRequerid(password);
    if (!validFields) {
      return false;
    }
    const msg = validPassword(inputPassword);

    if (msg) {
      OpenMessage('error', msg);
      document.getElementById('input-passwordCheck').classList.add('is-invalid');
      return false;
    }
    if (inputPassword !== inputVerifyPassword) {
      password.forEach((id) => {
        let element = document.getElementById(id);
        element.classList.add('is-invalid');
      });
      OpenMessage('error', 'Las contraseñas no coinciden');
      return false;
    }

    return true;
  };

  const onChange = () => {
    setCaptcha(true);
    enableBtn();
  };

  const createUser = () => {
    const validFields = verifyFields();
    if (!validFields) {
      return false;
    }

    if (!captcha) {
      OpenMessage('error', 'Debe aceptar el captcha');
      return false;
    }

    if (!checked) {
      OpenMessage('error', 'Debe aceptar los términos y condiciones');
      return false;
    }

    const params = {
      Name: inputName,
      LastName: inputLastName,
      SecondLastName: inputSecondLastName,
      Birthdate: inputBirthday,
      Phone: inputPhone,
      Email: inputMail,
      Password: inputPassword,
    };

    POST(URL, params).then((data) => {
      setdisabledBtn(true);
      if (data === true) {
        clearState();
        captchaContact.current.props.grecaptcha.reset();
        setCaptcha(false);
        OpenMessage('success', 'Operación exitosa. Favor de validar su correo para continuar con su registro');
      } else {
        if (data && data.errors && data.errors.Password.length > 0) {
          OpenMessage('error', data.errors.Password[0]);
        } else {
          OpenMessage('error', data);
        }
        captchaContact.current.props.grecaptcha.reset();
        setCaptcha(false);
      }
      setdisabledBtn(false);
    });
  };

  return (
    <div className="modal" id="modalCheckIn" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div className="modal-dialog modal-sm modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header modal-header-login mt-2 justify-content-end">
            <CloseModalIcon onHandleClick={clearState} />
          </div>
          <div className="modal-body">
            <div className="text-center mb-5">
              <img src="./resources/logo.svg" alt="logo MLS" className="img-fluid" width="65%" />
            </div>
            <div className="text-center mb-4 mt-4">
              <h4 className="modal-title fw-bold text-uppercase">Registrarse</h4>
            </div>
            <div className="row mb-3">
              <div className="col-10 form-outline container-input-checkin">
                <input
                  type="text"
                  className="form-control"
                  id="input-nameCheckIn"
                  value={inputName}
                  onKeyUp={enableBtn}
                  onInput={(e) => onlyText(e)}
                  onChange={(e) => setinputName(e.target.value)}
                  maxLength="50"
                  autoComplete="off"
                />
                <label htmlFor="input-nameCheckIn" className="col-form-label form-label">
                  Nombre(s):
                </label>
                <div className="form-notch">
                  <div className="form-notch-leading"></div>
                  <div className="form-notch-middle"></div>
                  <div className="form-notch-trailing"></div>
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-10 form-outline container-input-checkin">
                <input
                  type="text"
                  className="form-control"
                  id="input-firtsName"
                  value={inputLastName}
                  onKeyUp={enableBtn}
                  onInput={(e) => onlyText(e)}
                  onChange={(e) => setinputLastName(e.target.value)}
                  maxLength="50"
                  autoComplete="off"
                />
                <label htmlFor="input-firtsName" className="col-form-label form-label">
                  Apellido Paterno:
                </label>
                <div className="form-notch">
                  <div className="form-notch-leading"></div>
                  <div className="form-notch-middle"></div>
                  <div className="form-notch-trailing"></div>
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-10 form-outline container-input-checkin">
                <input
                  type="text"
                  className="form-control"
                  id="input-lastName"
                  value={inputSecondLastName}
                  onChange={(e) => setinputSecondLastName(e.target.value)}
                  onInput={(e) => onlyText(e)}
                  onKeyUp={enableBtn}
                  maxLength="50"
                  autoComplete="off"
                />
                <label htmlFor="input-lastName" className="col-form-label form-label">
                  Apellido Materno:
                </label>
                <div className="form-notch">
                  <div className="form-notch-leading"></div>
                  <div className="form-notch-middle"></div>
                  <div className="form-notch-trailing"></div>
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-10 form-outline container-input-checkin">
                <input
                  type="date"
                  className="form-control active"
                  id="input-birthday"
                  value={inputBirthday}
                  onKeyUp={enableBtn}
                  onChange={(e) => setinputBirthday(e.target.value)}
                  onBlur={(e) => e.target.classList.add('active')}
                />
                <label htmlFor="input-birthday" className="col-form-label form-label">
                  Fecha de Nacimiento:
                </label>
                <div className="form-notch">
                  <div className="form-notch-leading"></div>
                  <div className="form-notch-middle" style={{ width: '112.8px' }}>
                    {/* Se deja fijo el estilo porque tiene valor por defecto */}
                  </div>
                  <div className="form-notch-trailing"></div>
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-10 form-outline container-input-checkin">
                <input
                  type="text"
                  className="form-control"
                  id="input-emailCheckIn"
                  value={inputMail}
                  onKeyUp={enableBtn}
                  onChange={(e) => setinputMail(e.target.value)}
                  maxLength="80"
                  onInput={(e) => onlyMail(e)}
                  autoComplete="off"
                />
                <label htmlFor="input-emailCheckIn" className="col-form-label form-label">
                  Correo electrónico:
                </label>
                <div className="form-notch">
                  <div className="form-notch-leading"></div>
                  <div className="form-notch-middle"></div>
                  <div className="form-notch-trailing"></div>
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-10 form-outline container-input-checkin">
                <NumberFormat
                  format="(##) ####-####"
                  mask=""
                  className="form-control form-contact"
                  id="input-phoneCheckIn"
                  onKeyUp={enableBtn}
                  onValueChange={(e) => setinputPhone(e.value)}
                  value={inputPhone}
                  autoComplete="off"
                />
                <label htmlFor="input-phoneCheckIn" className="col-form-label form-label">
                  Teléfono:
                </label>
                <div className="form-notch">
                  <div className="form-notch-leading"></div>
                  <div className="form-notch-middle"></div>
                  <div className="form-notch-trailing"></div>
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-10 form-outline input-group input-password container-input-checkin">
                <input
                  type="password"
                  className="form-control"
                  id="input-passwordCheck"
                  value={inputPassword}
                  onKeyUp={enableBtn}
                  onChange={(e) => setinputPassword(e.target.value)}
                  maxLength="50"
                  aria-describedby="icon-eyes-checkin"
                />
                <i className="fas fa-eye-slash" id="icon-eyes-checkin" onClick={(e) => changePasswordText('input-passwordCheck', e)}></i>
                <label htmlFor="input-passwordCheck" className="col-form-label form-label">
                  Contraseña:
                </label>
                <div className="form-notch">
                  <div className="form-notch-leading"></div>
                  <div className="form-notch-middle"></div>
                  <div className="form-notch-trailing"></div>
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-10 form-outline container-input-checkin input-group input-password">
                <input
                  type="password"
                  className="form-control"
                  id="input-passwordCheck2"
                  value={inputVerifyPassword}
                  onKeyUp={enableBtn}
                  onChange={(e) => setinputVerifyPassword(e.target.value)}
                  maxLength="50"
                  aria-describedby="icon-eyes-checkin2"
                />
                <i className="fas fa-eye-slash" id="icon-eyes-checkin2" onClick={(e) => changePasswordText('input-passwordCheck2', e)}></i>
                <label htmlFor="input-passwordCheck2" className="col-form-label form-label">
                  Verificar Contraseña:
                </label>
                <div className="form-notch">
                  <div className="form-notch-leading"></div>
                  <div className="form-notch-middle"></div>
                  <div className="form-notch-trailing"></div>
                </div>
              </div>
            </div>
            <div className="mb-3"></div>
          </div>
          <div className="modal-footer modal-header-login">
            <div className="container-fluid">
              <div className="row">
                <div className="col-9 container-input-checkin">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="CheckCondition"
                      onClick={(e) => {
                        setChecked(e.target.checked);
                        enableBtn();
                      }}
                      checked={checked}
                      onChange={(e) => {}}
                      onKeyUp={enableBtn}
                    />
                    <label className="form-check-label" htmlFor="CheckCondition">
                      Acepto todos los &nbsp;
                      <a href="/resources/terminos-y-condiciones-subasta-2021.pdf" target="_blank" rel="noreferrer">
                        Términos y condiciones
                      </a>
                    </label>
                  </div>
                </div>
              </div>
              <div className="row mb-3 mt-4 justify-content-center">
                <div className="col-10 container-captcha">
                  <ReCAPTCHA ref={captchaContact} sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI" onChange={onChange} />
                </div>
              </div>

              <div className="row">
                <div className="col text-center mb-3 mt-4">
                  <button type="button" className="btn btn-outline-danger btn-lg" onClick={createUser} disabled={disabledBtn}>
                    Crear Cuenta
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CheckIn;
