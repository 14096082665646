import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';

//import MiniPlayer from './MiniPlayer/MiniPlayer';
//import { POSITION } from './MiniPlayer/config';

import { convertToTime, formatCurrency, OpenMessage, OpenOfferSuccess, OpenWaitNextAuction, OpenDeclineClient  } from '../Utilities/General';
import { ServiceConnectionSignalr, ServiceGetCurrentBid, ServiceMakeBids, ServiceBidsStreaming } from '../../helpers/getURL';
import { ConnectionSignalr, GET } from '../../helpers/APIServices';
import {
  getAvailableLot,
  //getAwardLot,
  //getRetiredLot,
  getStreamPlayBackUrlQA,
  //getStreamPlayBackUrlUAT,
  getUserRejectedStatus,
} from '../../helpers/getConstants';

const STREAM_PLAYBACK_URL = getStreamPlayBackUrlQA();
// const STREAM_PLAYBACK_URL = getStreamPlayBackUrlUAT();

export const Video = (props) => {
  const [mobile, setMobile] = useState([]);
  const dataContent = JSON.parse(localStorage.getItem('dataContent'));
  const IdAuction = props.IdAuction;
  const IdLot = props.IdLot;
  const IdCustomer = props.IdCustomer;
  const {
    updateCarousel,
    update,
    setUpdate,
    updateNotice,
    streaming,
    streamingText,
    annoucement,
    dataCarousel,
    statusCustomer = '',
    streamingFinish,
  } = props;
  let pallet = 0;
  const [connection, setConnection] = useState(null);
  const [offer, setOffer] = useState(false);
  const [adviceOffer, setAdviceOffer] = useState('');
  const [bid, setBid] = useState([]);
  const [confirm, setConfirm] = useState(false);
  const [nextLot, setNextLot] = useState(false);
  const [waitingStreaming, setWaitingStreaming] = useState(false);
  const [waitingText, setWaitingText] = useState('');
  const [lastLot, setLastLot] = useState(false);
  const [currentBid, setCurrentBid] = useState({
    oldPrice: 0.0,
    newPrice: 0.0,
    increment: 0.0,
  });
  const statusRejected = getUserRejectedStatus();
  const SIGNALR = ServiceConnectionSignalr();
  const URLMakeBids = ServiceMakeBids();
  const URLGetCurrentBid = ServiceGetCurrentBid();
  let URLBids = ServiceBidsStreaming();
  const latestBid = useRef(null);
  const token = localStorage.getItem('token');
  const available = getAvailableLot();
  latestBid.current = bid;
  if (dataContent && dataContent.registeredAuctions.length > 0) {
    dataContent.registeredAuctions.map((item) => {
      if (item.idAuction === IdAuction) {
        pallet = item.pallet;
      }
      return 0;
    });
  }

  useLayoutEffect(() => {
    const handleResize = () => {
      setMobile(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
  }, []);

  useEffect(() => {
    if (streamingFinish) {
      setWaitingStreaming(true);
      setWaitingText('Espera nuestra próxima subasta');
      setTimeout(() => {
        OpenWaitNextAuction();
      }, 800);
    }
    setWaitingStreaming(streaming);
    setWaitingText(streamingText);
  }, []);

  useEffect(() => {
    startConnection();
  }, []);

  useEffect(() => {
    if (currentBid.newPrice === 0 && IdLot) {
      getCurrentBid();
    }
    if (IdLot && bid.length === 0) {
      getBids();
    }
    if (update) {
      getCurrentBid();
      setUpdate(false);
      setNextLot(false);
    }
    const lastLotStatus = dataCarousel.length > 0 ? dataCarousel[dataCarousel.length - 1].idStatus : '';
    const lastLotStatusSubasta = dataCarousel.length > 0 ? dataCarousel[dataCarousel.length - 1].statusSubasta : '';
    if (lastLotStatus !== available && lastLotStatusSubasta === 'Actual') {
      setLastLot(true);
      setWaitingText('Espera nuestra próxima subasta');
    }
    setNextLot(false);
  }, [props.IdLot]);

  useEffect(() => {
    getCurrentBid();
  }, [update]);

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then((result) => {
          registerToAuctionLot(IdAuction); 
          connection.on('ReceiveMessage', (message) => {
            onReceiveNotification(message);
          });
        })
        .catch(function (err) {
          return console.error(err.toString());
        });
      connection.onclose(() => {
        onClose();
      });
      connection.onreconnecting((error) => {
        openToastNotification('toastStartConnect');
      });
      connection.onreconnected((connectionId) => {
        openToastNotification('toastConnectionStablished');
        onReload();
      });
    }
  }, [connection]);

  const openToastNotification = (id) => {
    var x = document.getElementById(id);
    x.className = 'show';
    setTimeout(() => {
      x.className = x.className.replace('show', '');
    }, 6000);
  };

  const startConnection = () => {
    const connect = ConnectionSignalr(SIGNALR);
    if (connect) {
      setConnection(connect);
    }
  };

  const onClose = () => {
    openToastNotification('toastTryingConnect');
    startConnection();
  };

  const getBids = () => {
    const params = '?IdAuction=' + IdAuction + '&IdLot=' + IdLot;
    URLBids += params;
    if (IdAuction && IdLot) {
      GET(URLBids).then((data) => {
        if (data && Array.isArray(data)) {
          setBid(data);
        }
      });
    }
  };

  const adviceWin = (idUser, message) => {
    if (idUser === IdCustomer) {
      if (message) {
        const text = `<i class="fas fa-trophy win-trophy"></i> <h3>¡${message}!</h3>`;
        OpenOfferSuccess(text, false);
        const backdrops = document.getElementsByClassName('modal-backdrop fade').length - 1;
        for (let i = 0; i < backdrops; i++) {
          document.getElementsByClassName('modal-backdrop fade')[i].remove();
        }
      }
    }
  };

  const onReload = () => {
    setOffer(false);
    updateCarousel();
  };

  const onReceiveNotification = ({
    actionCode,
    timestamp,
    bidAmount,
    userPallette,
    idUser,
    message,
    onBidCancelled = {},
    counterOfferMessage = null,
    increment,
    newAmount,
    idBid,
  }) => {
    switch (actionCode) {
      case 0:
        updateNotice(message);
        break;
      case 1:
        getCurrentBid();
        break;
      case 2:
        setNextLot(true);
        setBid([]);
        updateCarousel();
        adviceWin(idUser, message);
        break;
      case 3:
        insertBid(userPallette, timestamp, bidAmount, counterOfferMessage, increment, bidAmount, newAmount, idUser, idBid);
        break;
      case 4:
        if (onBidCancelled && onBidCancelled.bidId) {
          let bids = latestBid.current;
          const newBids = bids.filter((bid) => bid.idBid !== onBidCancelled.bidId);
          setBid(newBids);
        }
        if (onBidCancelled && onBidCancelled.clientId === IdCustomer) {
          const text = `<h3>¡SU OFERTA SE HA CANCELADO!</h3>`;
          OpenOfferSuccess(text, false);
        }
        onReload(actionCode);
        break;
      case 7:
        getCurrentBid();
        setWaitingStreaming(false);
        break;
      case 8:
        setWaitingStreaming(true);
        setWaitingText('Por favor espere, la transmisión continuará en breve');
        break;
      case 9:
        setWaitingStreaming(true);
        setWaitingText('Espera nuestra próxima subasta');
        setTimeout(() => {
          OpenWaitNextAuction();
        }, 800);
        break;
      case 13:
        setLastLot(true);
        setWaitingText('Espera nuestra próxima subasta');
        break;

      case 14:
        //agregar validacion para el id usuario
        if (idUser === IdCustomer)
        {
          OpenDeclineClient();
        }
        break;
      default:
        getCurrentBid();
        setWaitingStreaming(false);
        break;
    }
  };

  const insertBid = (paleta, hora, oferta, counterOfferMessage, incremento, actual, ofertar, idUser, idPuja) => {
    let bids = latestBid.current;
    if (paleta) {
      if (counterOfferMessage) {
        const { timestamp, bidAmount, userPallette, newAmount, increment, idBid } = counterOfferMessage;
        const lastBid = {
          hora: timestamp,
          oferta: bidAmount,
          paleta: userPallette,
          idBid,
        };
        bids = [lastBid, ...bids];
        setCurrentBid({
          idUser: idUser,
          oldPrice: bidAmount,
          newPrice: newAmount,
          increment: increment,
        });
      }
      const newBid = {
        hora,
        oferta,
        paleta,
        idBid: idPuja,
      };
      bids = [newBid, ...bids];
      setBid(bids);
      setAdviceOffer('');
      if (idUser === IdCustomer) {
        setAdviceOffer('Usted está ganando');
      }
      setCurrentBid({
        idUser: idUser,
        oldPrice: actual,
        newPrice: ofertar,
        increment: incremento,
      });
    } else {
      updateCarousel();
    }
  };

  const registerToAuctionLot = async (IdAuction) => {
    if (connection && connection.connectionStarted) {
      connection.send('RegisterToAuctionLot', IdAuction);
    }
  };

  const getCurrentBid = async () => {
    if (IdLot) {
      const json = {
        IdAuction,
        IdLot: IdLot,
      };

      let jsonContent = JSON.stringify(json);
      try {
        await fetch(URLGetCurrentBid, {
          method: 'POST',
          body: jsonContent,
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            Authorization: 'Bearer ' + token,
          },
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.idUser === IdCustomer) {
              setAdviceOffer('Usted está ganando');
            } else {
              setAdviceOffer('');
            }
            setCurrentBid(data);
          });
      } catch (e) {
        console.log('Failed to get current bid.', e);
      }
    }
  };

  const confirmOffert = async (user, amount, auction) => {
    setConfirm(true);
    const objMessage = {
      IdAuction: auction,
      IdCustomer: user,
      Amount: amount,
      IdLot,
    };

    let jsonMessage = JSON.stringify(objMessage);
    try {
      await fetch(URLMakeBids, {
        method: 'POST',
        body: jsonMessage,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + token,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (!data.bidSuccess) {
            setAdviceOffer('HAY UNA PUJA MÁS ALTA QUE LA DE USTED');
          }
          setOffer(false);
          setConfirm(false);
        });
    } catch (e) {
      console.log('Sending message failed.', e);
    }
  };

  const sendBid = (e) => {
    if (statusCustomer === statusRejected) {
      OpenMessage('warning', 'Su cuenta se encuentra bloqueada, favor de contactarnos.');
    } else {
      e.preventDefault();
      const amount = currentBid.newPrice;
      const isUserProvided = IdCustomer;
      const isLoteProvided = IdLot;
      const isAmountProvided = amount;

      if (isUserProvided && isAmountProvided && isLoteProvided && connection && connection.connectionStarted) {
        confirmOffert(IdCustomer, amount, IdAuction);
      }
    }
  };

  return (
    <div className="container__video">
      {waitingStreaming || lastLot ? (
        <div>
          <h1 className="text-center fw-5 pt-5 text-uppercase fw-bold">{waitingText}</h1>
        </div>
      ) : (
        <div className="row justify-content-center">
          {nextLot || annoucement ? (
            <div className="col">
              <h1 className="text-center fw-5 pt-5 text-uppercase fw-bold">Preparando siguiente lote...</h1>
            </div>
          ) : (
            <div>
              <div className="grid-video">
                <div className="col-video1">
                  <div className="grid-video">
                    <div className="col-offer1">
                      <h3 className="fw-bold">Oferta actual:</h3>
                    </div>
                    <div className="col-offer2">
                      <label className="container__video--offer fw-bold">{formatCurrency(currentBid.oldPrice)}</label>
                    </div>
                    <div className="col-offer3">
                      <h5>Incremento de oferta:</h5>
                    </div>
                    <div className="col-offer4">
                      <label className="container__video--rise">{formatCurrency(currentBid.increment)}</label>
                    </div>
                  </div>
                </div>
                <div className="col-video2">
                  <div className="row">
                    <div className="col-12 text-center">
                      {confirm ? (
                        <button className="btn btn-outline-danger btn-lg btn-lot" disabled>
                          <span className="spinner-border spinner-border-smn fs-2" role="status" aria-hidden="true"></span>
                          <span> Ofertando...</span>
                        </button>
                      ) : (
                        <button
                          id="btnOffer"
                          className="btn btn-outline-danger btn-lg btn-confirm"
                          disabled={currentBid.idUser === IdCustomer ? true : false}
                          onClick={(e) =>
                            offer ? sendBid(e) : [setOffer(true), setAdviceOffer('Al ofertar usted acepta pagar el precio indicado')]
                          }
                        >
                          {offer ? (
                            <span>
                              Confirmar <span>oferta:</span>
                            </span>
                          ) : (
                            'Ofertar: '
                          )}
                          <span> {formatCurrency(currentBid.newPrice)}</span>
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 text-center">
                      <label className="container__video--warning">{adviceOffer}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      <div className="row justify-content-center">
        <div className="col-12 text-center">
          <div className="videoStreaming">
            {waitingStreaming && !nextLot && mobile > 992 ? (
              <i className="fab fa-youtube icon-youtube"></i>
            ) : (
              <div>
                <iframe
                  id="videoStreaming"
                  name="videoStreaming"
                  title="videoStreaming"
                  width="100%"
                  height="315"
                  src={STREAM_PLAYBACK_URL}
                  frameBorder="0"
                  allowFullScreen
                  allow="autoplay"
                />
                <h4>
                  Para iniciar la transmisión de nuestra subasta,
                  <br /> favor de darle play <i className="far fa-hand-point-right"></i> <i className="far fa-play-circle"></i>{' '}
                  <b>...¡Bienvenido!</b>
                </h4>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="row container">
        <div className="col-1 text-center border__Palet--title">
          <i className="fas fa-circle"></i>
        </div>
        <div className="col-11 border__Palet--title">
          <span>Paleta:</span>
          <span className="numberPalet">{pallet}</span>
        </div>
      </div>
      <div className="row container">
        {bid.length > 0 && (
          <div className="col-12 bids-scroll">
            <table className="table table-striped">
              <tbody>
                {bid.map((item, i) => {
                  return (
                    <tr key={i}>
                      <td>
                        Paleta <strong className="fw-bold">{item.paleta}</strong> ofertó
                      </td>
                      <td>
                        <strong className="fw-bold">{formatCurrency(item.oferta)}</strong>
                      </td>
                      <td>{convertToTime(item.hora)}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <div className="tryingConnect" id="toastTryingConnect">
        <div className="progress bar-width">
          <div
            className="progress-bar progress-bar-striped bg-danger toast-error"
            role="progressbar"
            aria-valuenow="100"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
        <h2>La conexión se perdió</h2>
        <br />
        <h2>Intentando Reconectar...</h2>
      </div>
      <div className="connectionStablished toast-error" id="toastConnectionStablished">
        <div className="progress bar-width">
          <div
            className="progress-bar progress-bar-striped bg-success toast-error"
            role="progressbar"
            aria-valuenow="100"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
        <h2>¡Conexión restablecida!.</h2>
        <br />
        <h2>El sistema se actualizará automáticamente.</h2>
      </div>
      <div className="startConnect tryingConnect toast-error" id="toastStartConnect">
        <div className="progress bar-width">
          <div
            className="progress-bar progress-bar-striped bg-danger toast-error"
            role="progressbar"
            aria-valuenow="100"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
        <h2>La conexión se perdió.</h2>
        <br />
        <h2>Inicia proceso de reconexión.</h2>
      </div>
    </div>
  );
};
