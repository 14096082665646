import React, { useState, useEffect } from 'react';
import { capitalize } from '../Utilities/General';
import { ServiceGetTown } from '../../helpers/getURL';
import { GET } from '../../helpers/APIServices';
import Select, { components } from 'react-select';

export const SelectTown = (props) => {
  const [style, setStyle] = useState('');
  useEffect(() => {
    if (props.errors.town && props.address.idMun === '') {
      const styleError = {
        control: (base, state) => ({
          ...base,
          borderColor: 'var(--color-error)',
        }),
        singleValue: (provided) => ({
          ...provided,
          color: 'var(--color-error)',
        }),
      };
      setStyle(styleError);
    } else {
      const styleDefault = {
        control: (base, state) => ({
          ...base,
          borderColor: 'hsl(0, 0%, 80%)',
        }),
        singleValue: (provided) => ({
          ...provided,
          color: 'var(--color-text)',
        }),
      };
      setStyle(styleDefault);
    }
  }, [props.errors.town, props.address.idMun]);

  const [towns, setTowns] = useState([]);
  useEffect(() => {
    if (props.address.idPais !== 'MX' || props.address.idEdo === '') {
      setTowns([]);
      return;
    }

    const getData = () => {
      let URL = ServiceGetTown(props.address.idEdo);
      if (URL) {
        GET(URL).then((data) => {
          if (data === '401') {
            getData();
            return '';
          }
          if (data.length > 0) {
            setTowns(data);
          }
        });
      }
    };
    getData();
  }, [props.address.idPais, props.address.idEdo]);

  const address = (e) => {
    props.setAddress({
      ...props.address,
      idMun: e.value,
      colonia: e.label,
      idCol: '',
    });
    props.setListCities([]);
  };

  let options = [];
  options.push({ value: '', label: 'Municipio:' });
  towns.map((item) => {
    options.push({ value: item.idTown, label: capitalize(item.sTown) });
    return '';
  });
  const value = options.length === 0 ? { value: '', label: 'Municipio:' } : options.find((obj) => obj.value === props.address.idMun);

  const DropdownIndicator = (props) => {
    let icon = 'fas fa-angle-down';
    if (props.selectProps.menuIsOpen) {
      icon = 'fas fa-search';
    }
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <i className={icon}></i>
        </components.DropdownIndicator>
      )
    );
  };

  return (
    <span className="dropdown shadow-0 col-12">
      <Select
        options={options}
        isSearchable={true}
        onChange={(e) => address(e)}
        placeholder="Municipio:"
        value={value}
        isDisabled={props.disabled}
        styles={style}
        components={{ DropdownIndicator }}
      />
    </span>
  );
};
