import React, { Fragment, useState, useEffect } from 'react';
import NumberFormat from 'react-number-format';
import {
  onlyMail,
  OpenMessage,
  validEmail,
  onlyNumber,
  onlyTextNumber,
  verifyFieldsRequerid,
  validRFC,
  validCURP,
  onlyTextNumber2,
  calcRFC,
} from '../../Utilities/General';
import { getGuidEmpty } from '../../../helpers/getConstants';
import { ServiceClientData, ServiceUpdateClientData } from '../../../helpers/getURL';
import { GET, PUT } from '../../../helpers/APIServices';
import { SelectNationality } from '../SelectNationality';
import { SelectCountry } from '../SelectCountry';
import { SelectState } from '../SelectState';
import { SelectTown } from '../SelectTown';
import { SelectCity } from '../SelectCity';
import { SelectEconomicActivity } from '../SelectEconomicActivity';
import { SelectJustCountry } from '../SelectJustCountry';
import { ServiceGetByZipCode } from '../../../helpers/getURL';

export const CustomerComplementaryData = (props) => {
  //const [clientData, setClientData] = useState();
  const [mountNationality, setMountNationality] = useState(false);
  const [dataNationality, setDataNationality] = useState('');
  const [dataCURP, setDataCURP] = useState('');
  const [dataRFC, setDataRFC] = useState('');
  const [dataCalle, setDataCalle] = useState('');
  const [dataNumero, setDataNumero] = useState('');
  const [dataNumeroInt, setDataNumeroInt] = useState('');

  const [dataTelefonoSec, setDataTelefonoSec] = useState('');
  const [dataCorreoSec, setDataCorreoSec] = useState('');

  const [mountEconomicActivity, setMountEconomicActivity] = useState(false);
  const [dataActEconomica, setDataActEconomica] = useState('');
  const [dataPaisNac, setDataPaisNac] = useState('');

  const [classInput, setClassInput] = useState('form-control');
  const [disableInput, setDisableInput] = useState(true);
  const [disableCancel, setDisableCancel] = useState(true);
  const [errors, setErrors] = useState({});

  const [listCities, setListCities] = useState([]);
  const idCustomer = localStorage.getItem('id');

  const [address, setAddress] = useState({
    id: '00000000-0000-0000-0000-000000000000',
    idCustomer: idCustomer,
    idPais: 'MX',
    idEdo: '',
    idMun: '',
    idCol: '',
    scp: '',
    numero: '',
    numeroInt: '',
    razonSocial: '',
    colonia: '',
    calle: '',
    rfc: '',
    alias: '',
    referencia: '',
    esFisicoMoral: false,
  });
  const changeCP = (e) => {
    setAddress({
      ...address,
      scp: e.target.value,
    });
  };

  const verifyCurpRfc = () => {
    const inputs = ['input-nameAccount', 'input-lastnameAccount', 'input-secondlastnameAccount', 'input-birthdayAccount'];
    let error = false;
    inputs.forEach((id) => {
      let element = document.getElementById(id).hasAttribute('disabled');
      if (!element) {
        error = true;
      }
    });
    if (error) {
      return;
    }
    const name = document.getElementById('input-nameAccount').value;
    const lastname = document.getElementById('input-lastnameAccount').value;
    const secondlastname = document.getElementById('input-secondlastnameAccount').value;
    const birthday = document.getElementById('input-birthdayAccount').value;
    const value = calcRFC(name, lastname, secondlastname, birthday);
    if (value !== dataCURP.substr(0, 10)) {
      setDataCURP(value);
    }
    if (value !== dataRFC.substr(0, 10)) {
      setDataRFC(value);
    }
  };

  const getData = () => {
    const URL = ServiceClientData();
    GET(URL).then((data) => {
      if (data === '401') {
        getData();
        return '';
      }
      //setClientData(data);
      const { customerContactData } = data;
      if (customerContactData) {
        setDataNationality(customerContactData.idNationality ? customerContactData.idNationality : '');
        setDataRFC(customerContactData.rfc ? customerContactData.rfc : '');
        setDataCURP(customerContactData.curp ? customerContactData.curp : '');
        setDataTelefonoSec(customerContactData.secondaryPhoneNumber ? customerContactData.secondaryPhoneNumber : '');
        setDataCorreoSec(customerContactData.secondaryEmail ? customerContactData.secondaryEmail : '');
        setDataCalle(customerContactData.street ? customerContactData.street : '');
        setDataNumero(customerContactData.number ? customerContactData.number : '');
        setDataNumeroInt(customerContactData.numberInt ? customerContactData.numberInt : '');

        setAddress({
          ...address,
          id: customerContactData.id ? customerContactData.id : '',
          idPais: customerContactData.country ? customerContactData.country : '',
          idMun: customerContactData.idTown ? customerContactData.idTown : '',
          idEdo: customerContactData.idState ? customerContactData.idState : '',
          idCol: customerContactData.idCity ? customerContactData.idCity : '',
          colonia: customerContactData.city ? customerContactData.city : '',
          scp: customerContactData.zipCode ? customerContactData.zipCode : '',
          numero: customerContactData.number ? customerContactData.number : '',
          numeroInt: customerContactData.numberInt ? customerContactData.numberInt : '',
          calle: customerContactData.street ? customerContactData.street : '',
        });
        setDataActEconomica(customerContactData.idEconomicActivity !== getGuidEmpty() ? customerContactData.idEconomicActivity : '');
        setDataPaisNac(customerContactData.idCountryOfBirth ? customerContactData.idCountryOfBirth : '');
      }
    });
  };

  useEffect(() => {
    if (props.visible === 'show' && props.load) {
      getData();
      setMountEconomicActivity(true);
      setMountNationality(true);
      verifyCurpRfc();
    }
  }, [props.visible, props.load]);

  const verifySelect = () => {
    let valid = true;
    let errorSelect = {
      country: false,
      state: false,
      town: false,
      city: false,
      economicActivity: false,
      countryBirth: false,
      nationality: false,
    };

    if (!address.idPais) {
      errorSelect.country = true;
      valid = false;
    }

    if (!address.idEdo) {
      errorSelect.state = true;
      valid = false;
    }

    if (!address.idMun) {
      errorSelect.town = true;
      valid = false;
    }

    if (!address.idCol) {
      errorSelect.city = true;
      valid = false;
    }

    if (!dataActEconomica) {
      errorSelect.economicActivity = true;
      valid = false;
    }

    if (!dataPaisNac) {
      errorSelect.countryBirth = true;
      valid = false;
    }

    if (!dataNationality) {
      errorSelect.nationality = true;
      valid = false;
    }

    setErrors(errorSelect);
    return valid;
  };

  const verifyFields = () => {
    const inputs = ['input-cp', 'input-calle', 'input-numero', 'input-curp', 'input-rfc'];
    const validSelect = verifySelect();
    const validFields = verifyFieldsRequerid(inputs);

    if (!validFields || !validSelect) {
      OpenMessage('error', 'Campos obligatorios');
      return false;
    } else {
      if (dataCorreoSec && !validEmail(dataCorreoSec)) {
        let emailAccountSec = document.getElementById('input-emailAccountSec');
        emailAccountSec.classList.add('is-invalid');
        OpenMessage('error', 'El formato del correo eléctronico no es válido');
        return false;
      }
      const curp = document.getElementById('input-curp');
      if (!validCURP(curp)) {
        return false;
      }

      const rfc = document.getElementById('input-rfc');
      if (!validRFC(rfc, false)) {
        return false;
      }
    }
    return true;
  };

  const save = () => {
    const params = {
      idCustomer: idCustomer,
      customerContactData: {
        zipCode: address.scp,
        country: address.idPais,
        idState: address.idEdo,
        idTown: address.idMun,
        idCity: address.idCol,
        street: dataCalle,
        number: dataNumero,
        numberInt: dataNumeroInt,
        secondaryEmail: dataCorreoSec,
        secondaryPhoneNumber: dataTelefonoSec,
        idEconomicActivity: dataActEconomica,
        idCountryOfBirth: dataPaisNac,
        curp: dataCURP,
        rfc: dataRFC,
        idNationality: dataNationality,
      },
    };
    if (!verifyFields()) {
      return false;
    }

    const URLUpdate = ServiceUpdateClientData();
    PUT(URLUpdate, params).then((data) => {
      if (data === true) {
        if (props.parent === 'Modal') {
          OpenMessage('success');
          cancel();
          props.close();
        } else {
          OpenMessage('success');
          cancel();
        }
      } else {
        OpenMessage('error', data.title);
      }
    });
  };

  const edit = (event) => {
    setDisableCancel(false);
    let btnEdit = document.getElementById('btnEditComplementary');
    if (btnEdit.innerText === 'GUARDAR') {
      save();
    } else {
      btnEdit.innerHTML = '<i className="fas fa-edit"></i>Guardar';
      setDisableInput(false);
      setClassInput('form-control enable');
    }
  };

  const cancel = () => {
    const errorSelect = {
      country: false,
      state: false,
      town: false,
      city: false,
      economicActivity: false,
      countryBirth: false,
      nationality: false,
    };
    setErrors(errorSelect);
    setDisableCancel(true);
    setDisableInput(true);
    setClassInput('form-control');
    let btnEdit = document.getElementById('btnEditComplementary');
    btnEdit.innerHTML = '<i className="fas fa-edit"></i>Editar';
    getData();
  };

  const getZipCode = () => {
    if (address.scp === '') {
      return;
    }
    const URL = ServiceGetByZipCode(address.scp);
    GET(URL, address).then((data) => {
      if (data === '401') {
        getZipCode();
        return '';
      }
      if (data) {
        setAddress({
          ...address,
          idPais: data.state.idCountry,
          idMun: data.town.idTown,
          idEdo: data.state.idState,
          idCol: '',
        });
        setListCities(data.cities);
      } else {
        OpenMessage('error', 'Código postal no encontrado');
      }
    });
  };

  return (
    <Fragment>
      <div className="row inputs-form">
        <label className="col-sm-3 col-form-label configuration-profile-labels">Nacionalidad*</label>
        <div className="col-sm-8">
          <SelectNationality
            setNationality={setDataNationality}
            nacionalidad={dataNationality}
            id={'select-nationality'}
            errors={errors}
            disabled={disableInput}
            visible={props.visible}
            setMountNationality={setMountNationality}
            mountNationality={mountNationality}
          />
        </div>
      </div>
      <br />
      <div className="row inputs-form">
        <label className="col-sm-3 col-form-label configuration-profile-labels">CURP*</label>
        <div className="col-sm-8">
          <input
            id="input-curp"
            type="text"
            disabled={disableInput}
            value={dataCURP}
            onChange={(e) => setDataCURP(e.target.value.toUpperCase())}
            className={'text-uppercase ' + classInput}
            onInput={(e) => onlyTextNumber2(e)}
            maxLength="18"
          />
        </div>
      </div>
      <br />
      <div className="row inputs-form">
        <label className="col-sm-3 col-form-label configuration-profile-labels">RFC*</label>
        <div className="col-sm-8">
          <input
            id="input-rfc"
            type="text"
            disabled={disableInput}
            value={dataRFC}
            onChange={(e) => setDataRFC(e.target.value.toUpperCase())}
            className={'text-uppercase ' + classInput}
            onInput={(e) => onlyTextNumber2(e)}
            maxLength="15"
          />
        </div>
      </div>
      <br />
      <div className="mb-5 row inputs-form">
        <label className="col-sm-3 col-form-label configuration-profile-labels">País*</label>
        <div className="col-sm-8">
          <SelectCountry setAddress={setAddress} address={address} id={'select-country'} errors={errors} disabled={disableInput} />
        </div>
      </div>
      <div className="mb-5 row inputs-form ">
        <label className="col-sm-3 col-form-label configuration-profile-labels">C.P.*</label>
        <div className="col-sm-7">
          <input
            id="input-cp"
            type="text"
            onInput={(e) => onlyNumber(e)}
            disabled={disableInput}
            value={address.scp}
            onChange={(e) => changeCP(e)}
            className={classInput}
            maxLength="10"
          />
        </div>
        <div className="col-sm-1">
          <div className="form-outline">
            <button className="btn btn-outline-secondary btn-lg" type="button" onClick={() => getZipCode()} disabled={disableInput}>
              <i className="fas fa-search"></i>
            </button>
          </div>
        </div>
      </div>
      <div className="mb-5 row inputs-form">
        <label className="col-sm-3 col-form-label configuration-profile-labels">Estado*</label>
        <div className="col-sm-8">
          <SelectState setAddress={setAddress} address={address} id={'select-state'} errors={errors} disabled={disableInput} />
        </div>
      </div>
      <div className="mb-5 row inputs-form">
        <label className="col-sm-3 col-form-label configuration-profile-labels">Municipio*</label>
        <div className="col-sm-8">
          <SelectTown
            setAddress={setAddress}
            address={address}
            id={'select-town'}
            setListCities={setListCities}
            errors={errors}
            disabled={disableInput}
          />
        </div>
      </div>
      <div className="mb-5 row inputs-form">
        <label className="col-sm-3 col-form-label configuration-profile-labels">Colonia*</label>
        <div className="col-sm-8">
          <SelectCity
            setAddress={setAddress}
            address={address}
            id={'select-city'}
            listCities={listCities}
            errors={errors}
            disabled={disableInput}
          />
        </div>
      </div>
      <div className="row inputs-form">
        <label className="col-sm-3 col-form-label configuration-profile-labels">Calle*</label>
        <div className="col-sm-8">
          <input
            id="input-calle"
            type="text"
            disabled={disableInput}
            value={dataCalle}
            onChange={(e) => setDataCalle(e.target.value)}
            className={classInput}
            onInput={(e) => onlyTextNumber(e)}
            maxLength="50"
          />
        </div>
      </div>
      <br />
      <div className="row inputs-form">
        <label className="col-sm-3 col-form-label configuration-profile-labels">Numero Ext.*</label>
        <div className="col-sm-3">
          <input
            id="input-numero"
            type="text"
            disabled={disableInput}
            value={dataNumero}
            onChange={(e) => setDataNumero(e.target.value)}
            className={classInput}
            onInput={(e) => onlyTextNumber(e)}
            maxLength="50"
          />
        </div>
        <label className="col-sm-2 col-form-label configuration-profile-labels">Numero Int.</label>
        <div className="col-sm-3">
          <input
            id="input-numero-int"
            type="text"
            disabled={disableInput}
            value={dataNumeroInt}
            onChange={(e) => setDataNumeroInt(e.target.value)}
            className={classInput}
            onInput={(e) => onlyTextNumber(e)}
            maxLength="50"
          />
        </div>
      </div>
      <br />
      <div className="row inputs-form">
        <label className="col-sm-3 col-form-label configuration-profile-labels">Teléfono Secundario</label>
        <div className="col-sm-8">
          <NumberFormat
            id="input-phoneAccountSec"
            format="(##) ####-####"
            mask=""
            disabled={disableInput}
            value={dataTelefonoSec}
            onValueChange={(e) => setDataTelefonoSec(e.value)}
            className={classInput}
          />
        </div>
      </div>
      <br />
      <div className="mb-5 row inputs-form">
        <label className="col-sm-3 col-form-label configuration-profile-labels">Correo electrónico Secundario</label>
        <div className="col-sm-8">
          <input
            id="input-emailAccountSec"
            name="input-emailAccountSec"
            type="text"
            disabled={disableInput}
            value={dataCorreoSec}
            onChange={(e) => setDataCorreoSec(e.target.value)}
            className={classInput}
            maxLength="80"
            onInput={(e) => onlyMail(e)}
          />
        </div>
      </div>
      <div className="mb-5 row inputs-form">
        <label className="col-sm-3 col-form-label configuration-profile-labels">Actividad Economica*</label>
        <div className="col-sm-8">
          <SelectEconomicActivity
            setActEconomica={setDataActEconomica}
            actEconomica={dataActEconomica}
            id={'select-economic-activity'}
            errors={errors}
            disabled={disableInput}
            visible={props.visible}
            mountEconomicActivity={mountEconomicActivity}
            setMountEconomicActivity={setMountEconomicActivity}
          />
        </div>
      </div>
      <div className="mb-5 row inputs-form">
        <label className="col-sm-3 col-form-label configuration-profile-labels">Pais de Nacimiento*</label>
        <div className="col-sm-8">
          <SelectJustCountry
            setCountry={setDataPaisNac}
            country={dataPaisNac}
            id={'select-birth-coutry'}
            errors={errors}
            disabled={disableInput}
          />
        </div>
      </div>

      <div className="mb-5 row inputs-form">
        <div className="col-sm-12 text-end">
          <button className="btn btn-outline-danger" disabled={disableCancel} onClick={cancel}>
            <i className="fas fa-times"></i>
            Cancelar
          </button>
          &nbsp;&nbsp;&nbsp;
          <button id="btnEditComplementary" className="btn btn-outline-secondary" onClick={(e) => edit(e)}>
            <i className="fas fa-edit"></i>
            Editar
          </button>
        </div>
      </div>
    </Fragment>
  );
};
