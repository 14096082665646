import React from "react";

export const PaginationCatalog = (props) => {
  let pageLinks = [];
  for (let i = 1; i <= props.pages; i++) {
    let active = props.currentPage === i ? "page-active" : "";
    pageLinks.push(
      <li
        className={`page-item`}
        key={i}
        onClick={(e) => props.nextPage(i)}
      >
        <button className={`page-link num-pages ${active}`}>{i}</button>
      </li>
    );
  }
  if(props.pages > 5 && window.innerWidth < 500){
    pageLinks = pageLinks.filter(
      (page) =>
        parseInt(page.key) >= props.currentPage - 1 &&
        parseInt(page.key) <= props.currentPage + 1
    );
  }
  else if (props.pages > 7) {
    pageLinks = pageLinks.filter(
      (page) =>
        parseInt(page.key) >= props.currentPage - 3 &&
        parseInt(page.key) <= props.currentPage + 3
    );
  }
  return (
    <nav aria-label="...">
      <ul className="pagination">
        {props.currentPage > 1 ? (
          <li
            className={`page-item prev-pag`}
            onClick={(e) => props.nextPage(props.currentPage - 1)}
          >
            <button className={`page-link num-pages`} aria-label="Previous">
              <i className="fas fa-caret-left"></i>
            </button>
          </li>
        ) : (
          ""
        )}
        {props.currentPage > 4 && props.pages > 7&& window.innerWidth > 500 &&  <li><i className="fas fa-ellipsis-h  ellipsis"></i></li>}
        {props.currentPage > 2 && props.pages > 5 && window.innerWidth < 500 && <li><i className="fas fa-ellipsis-h  ellipsis"></i></li>}
        {pageLinks}
        {props.currentPage < props.pages - 3 && props.pages > 7 && window.innerWidth > 500 && (
          <li><i className="fas fa-ellipsis-h ellipsis"></i></li>
        )}
        {props.currentPage < props.pages - 1 && props.pages > 5 && window.innerWidth < 500 && (
          <li><i className="fas fa-ellipsis-h  ellipsis"></i></li>
        )}
        {props.currentPage < props.pages ? (
          <li
            className={`page-item next-pag`}
            onClick={(e) => props.nextPage(props.currentPage + 1)}
          >
            <button className={`page-link num-pages`} aria-label="Next">
              <i className="fas fa-caret-right"></i>
            </button>
          </li>
        ) : (
          ""
        )}
      </ul>
    </nav>
  );
};
