import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

//Servicios
import { ServiceCarouselStreaming, ServiceAuctionById, ServiceAuctionCustomerStatus } from '../../helpers/getURL';
import { GET } from '../../helpers/APIServices';

//Componentes
import { Carousel } from './Carousel';
import { Video } from './Video';
import { Information } from './Information';

//Utiles
import { convertToDate, OpenCloseStreaming } from '../Utilities/General';

//CSS
import '../../css/streaming/streaming.css';
import { getStreamingEnded, getStreamingInPause, getStreamingInProcess, getStreamingToStart } from '../../helpers/getConstants';
import { MenuStreaming } from './MenuStreaming';

export const Streaming = () => {
  const history = useHistory();
  const [dataCarousel, setDataCarousel] = useState([]);
  const [allCarousel, setAllCarousel] = useState([]);
  const [dataAuction, setDataAuction] = useState({});
  const [dataCurrentLot, setDataCurrentLot] = useState('');
  const [lotToShow, setLotToShow] = useState('');
  const [notice, setNotice] = useState('');
  const [carouselMovil, setCarouselMovil] = useState(false);
  const [waitingStreaming, setWaitingStreaming] = useState(false);
  const [waitingText, setWaitingText] = useState('');
  const { search } = useLocation();
  const IdAuction = new URLSearchParams(search).get('id');
  const IdCustomer = localStorage.getItem('id');
  const URLAuction = ServiceAuctionById(IdAuction);
  let URL = ServiceCarouselStreaming();
  const [update, setUpdate] = useState(false);
  const [updateOffer, setUpdateOffer] = useState(false);
  const [displayVideo, setDisplayVideo] = useState(true);
  const [displayActualLot, setDisplayActualLot] = useState(true);
  const [displayAwards, setDisplayAwards] = useState(true);
  const [statusCustomer, setStatusCustomer] = useState('');
  const streamingToStart = getStreamingToStart();
  const streamingInPause = getStreamingInPause();
  const streamingInProcess = getStreamingInProcess();
  const streamingEnded = getStreamingEnded();
  const [streamingFinish, setStreamingFinish] = useState(false);
  const params = '?IdAuction=' + IdAuction + '&IdCustomer=' + IdCustomer + '&Before=2&After=5';
  URL += params;

  if (!IdCustomer) {
    history.push('/?home=true');
  }

  useEffect(() => {
    const URLAuctionCustomerStatus = ServiceAuctionCustomerStatus(IdCustomer);
    if (IdCustomer) {
      GET(URLAuctionCustomerStatus).then((response) => {
        const users = response.find((auction) => auction.idAuction === IdAuction);
        if (users) {
          setStatusCustomer(users.idAuctionCustomerStatus);
        }
        if (response) {
          GET(URL).then((data) => {
            if (data) {
              const lots = data.filter((lot) => !lot.anuncio);
              setDataCarousel(lots);
              setAllCarousel(data);
              getCurrentLot(data);
            }
          });
          GET(URLAuction).then((data) => {
            if (data) {
              checkStatusStreaming(data.statusStreaming);
              setDataAuction(data);
            }
          });
        }
      });
    } else {
      GET(URL).then((data) => {
        if (data) {
          const lots = data.filter((lot) => !lot.anuncio);
          setDataCarousel(lots);
          setAllCarousel(data);
          getCurrentLot(data);
        }
      });
      GET(URLAuction).then((data) => {
        if (data) {
          checkStatusStreaming(data.statusStreaming);
          setDataAuction(data);
        }
      });
    }
  }, [URL, URLAuction]);
  useEffect(() => {
    const intFrameWidth = window.innerWidth;
    if (intFrameWidth < 573) {
      setCarouselMovil(true);
      setDisplayVideo(true);
      setDisplayActualLot(true);
      setDisplayAwards(false);
    } else {
      setCarouselMovil(false);
      setDisplayVideo(true);
      setDisplayActualLot(true);
      setDisplayAwards(false);
    }
  }, []);
  const checkStatusStreaming = (status) => {
    switch (status) {
      case streamingToStart:
        setWaitingStreaming(true);
        setWaitingText('Por favor espere, la transmisión iniciará en breve');
        break;
      case streamingInPause:
        setWaitingStreaming(true);
        setWaitingText('Por favor espere, la transmisión continuará en breve');
        break;
      case streamingInProcess:
        setWaitingStreaming(false);
        break;
      case streamingEnded:
        setWaitingStreaming(true);
        setStreamingFinish(true);
        setWaitingText('Espera nuestra próxima subasta');
        break;
      default:
        setWaitingStreaming(false);
        break;
    }
  };
  const updateCarousel = () => {
    GET(URL).then((data) => {
      if (data) {
        const lots = data.filter((lot) => !lot.anuncio);
        setDataCarousel(lots);
        setAllCarousel(data);
        setDataCurrentLot('');
        getCurrentLot(data, true);
        setUpdateOffer(true);
      }
    });
  };
  useEffect(() => {
    if (updateOffer) {
      setUpdateOffer(true);
    }
  }, [dataCurrentLot]);

  const getCurrentLot = (data, update) => {
    let haveCurrentLot = false;
    if (data.length > 0) {
      const actual = data.find((item) => item.statusSubasta.toUpperCase() === 'ACTUAL');
      if (actual) {
        setLotToShow(actual);
        setNotice(actual.notice);
        setDataCurrentLot(actual);
        haveCurrentLot = true;
        if (update) {
          setUpdate(true);
        }
      }
      if (!haveCurrentLot && dataCarousel.length > 0) {
        dataCurrentLot = dataCarousel[0];
      }
    }
  };

  const updateNotice = (message) => {
    if (message) {
      setNotice(message);
    }
  };

  const openCatalog = () => {
    window.open('/catalogo?id=' + IdAuction, '_blank');
  };

  const watchLot = (idClicked) => {
    const lot = allCarousel.find((lot) => lot.idLot === idClicked);
    setLotToShow(lot);
  };
  const exitStreaming = () => {
    OpenCloseStreaming();
  };

  const resizing = () => {
    const intFrameWidth = window.innerWidth;

    if (intFrameWidth < 576 && !carouselMovil) {
      setCarouselMovil(true);
      setDisplayVideo(true);
      setDisplayActualLot(true);
      setDisplayAwards(false);
    } else if (intFrameWidth >= 576) {
      setCarouselMovil(false);
      setDisplayVideo(true);
      setDisplayActualLot(true);
      setDisplayAwards(false);
    }
  };

  window.onresize = resizing;

  return (
    <>
      <div className="container container__Streaming">
        <MenuStreaming
          displayVideo={displayVideo}
          displayActualLot={displayActualLot}
          displayAwards={displayAwards}
          setDisplayActualLot={setDisplayActualLot}
          setDisplayAwards={setDisplayAwards}
          position={'top'}
        />
        <div className="row">
          <div className="col-9 container__Streaming--title fw-bold">
            Subasta de <span>{dataAuction.name}</span> - {dataAuction.numberOfLots + ' Lotes'}
          </div>
          <div className="col-3 exit-lotes">
            <button className="btn btn-outline-danger btn-lg mb-3 btn-lot" onClick={exitStreaming}>
              <img src="/resources/exit.png" alt="exit" /> Salir
            </button>
            <button className="btn btn-outline-secondary btn-lg btn-lot" onClick={openCatalog}>
              <i className="fas fa-eye"></i> <span> Todos los</span> lotes
            </button>
          </div>
        </div>
        <div className="row">
          {dataAuction.eventDate && (
            <div className="col-10 container__Streaming--subtitle fw-bold">{convertToDate(dataAuction.eventDate)}</div>
          )}
        </div>
        {notice && (
          <div className="row advice-streaming my-3">
            <div className="col-md-1 col-sm-2 text-center m-auto my-3">
              <img src="/resources/speaker.png" alt="speaker" />
            </div>
            <div className="col-md-11 col-sm-10 text-justify p-3">
              <span>{notice}</span>
            </div>
          </div>
        )}

        <Carousel dataCarousel={dataCarousel} announcement={dataCurrentLot.anuncio} watchLot={watchLot} carouselMovil={carouselMovil} />
        <div className="row">
          <div className="col-md-6 col-sm-12">
            {Object.keys(dataAuction).length > 0 && displayVideo && (
              <Video
                IdLot={dataCurrentLot.idLot}
                IdAuction={IdAuction}
                IdCustomer={IdCustomer}
                updateCarousel={updateCarousel}
                update={updateOffer}
                setUpdate={setUpdateOffer}
                updateNotice={updateNotice}
                auctionDate={dataAuction.eventDate}
                streaming={waitingStreaming}
                streamingText={waitingText}
                annoucement={dataCurrentLot.anuncio}
                statusCustomer={statusCustomer}
                dataCarousel={dataCarousel}
                streamingFinish={streamingFinish}
              />
            )}
          </div>

          <div className="col-md-6 col-sm-12 margin_information">
            <Information
              carouselMovil={carouselMovil}
              displayActualLot={displayActualLot}
              displayAwards={displayAwards}
              idCurrentLot={dataCurrentLot.idLot}
              announcement={dataCurrentLot.anuncio}
              IdAuction={IdAuction}
              IdCustomer={IdCustomer}
              CurrentLot={lotToShow}
              update={update}
              setUpdate={setUpdate}
              watchLot={watchLot}
            />
          </div>
        </div>
      </div>
      <MenuStreaming
        displayVideo={displayVideo}
        displayActualLot={displayActualLot}
        displayAwards={displayAwards}
        setDisplayActualLot={setDisplayActualLot}
        setDisplayAwards={setDisplayAwards}
        position={'bottom'}
      />
    </>
  );
};
