import React, { useState, useEffect } from 'react';

import { OpenMessage, validPassword, changePasswordText, setAttributePassword } from '../../Utilities/General';
import { ServiceChangePassword } from '../../../helpers/getURL';
import { PUT } from '../../../helpers/APIServices';

export const CustomerChangePassword = () => {
  const [dataPassword, setDataPassword] = useState('');
  const [dataPassword2, setDataPassword2] = useState('');
  const [dataShow, setDataShow] = useState('hide');

  const [classInput, setClassInput] = useState('form-control');
  const [classPassword, setClassPassword] = useState('fas fa-eye-slash hide');
  const [disableInput, setDisableInput] = useState(true);
  const [disableCancel, setDisableCancel] = useState(true);
  const URLChangePassword = ServiceChangePassword();

  const getData = () => {
    setDataPassword('');
    setDataPassword2('');
    setAttributePassword('input-passwordAccount');
    setAttributePassword('input-passwordAccount2');
    setDataShow('hide');
  };

  useEffect(() => {
    getData();
  }, []);

  const verifyFieldsRequerid = (elements) => {
    let error = false;
    elements.forEach((id) => {
      let element = document.getElementById(id);
      if (!element.value.trim()) {
        error = true;
        element.classList.add('is-invalid');
      } else {
        element.classList.remove('is-invalid');
      }
    });
    if (error) {
      return false;
    }
    return true;
  };

  const verifyPassword = () => {
    const password = ['input-passwordAccount', 'input-passwordAccount2'];
    const validFields = verifyFieldsRequerid(password);
    if (!validFields) {
      OpenMessage('error', 'Campos obligatorios');
      return false;
    }
    const msg = validPassword(dataPassword);

    if (msg) {
      OpenMessage('error', msg);
      document.getElementById('input-passwordAccount').classList.add('is-invalid');
      return false;
    }
    if (dataPassword !== dataPassword2) {
      password.forEach((id) => {
        let element = document.getElementById(id);
        element.classList.add('is-invalid');
      });
      OpenMessage('error', 'Las contraseñas no coinciden');
      return false;
    }

    return true;
  };

  const save = () => {
    if (!verifyPassword()) {
      return false;
    }
    const password = {
      NewPassword: dataPassword,
    };
    PUT(URLChangePassword, password).then((response) => {
      if (response === true) {
        OpenMessage('success');
        cancel();
      } else {
        OpenMessage('error', response);
      }
    });
  };

  const edit = (event) => {
    setDataShow('show');
    setDisableCancel(false);
    let btnEdit = document.getElementById('btnEditPass');
    if (btnEdit.innerText === 'GUARDAR') {
      save();
    } else {
      btnEdit.innerHTML = '<i class="fas fa-edit"></i>Guardar';
      setDisableInput(false);
      setClassInput('form-control enable');
      setClassPassword('fas fa-eye-slash');
    }
  };

  const cancel = () => {
    setDisableCancel(true);
    setDisableInput(true);
    setClassInput('form-control');
    let btnEdit = document.getElementById('btnEditPass');
    btnEdit.innerHTML = '<i class="fas fa-edit"></i>Cambiar';
    setClassPassword('fas fa-eye-slash hide');
    getData();
  };

  return (
    <div>
      <br />
      <div className="mb-3 row inputs-form">
        <label className="col-sm-3 col-form-label configuration-profile-labels">Contraseña</label>
        <div className="col-sm-6">
          <div className="input-group input-password">
            <input
              id="input-passwordAccount"
              type="password"
              disabled={disableInput}
              value={dataPassword}
              onChange={(e) => setDataPassword(e.target.value)}
              className={classInput}
              maxLength="50"
              aria-describedby="icon-eyes-account"
            />
            <i className={classPassword} id="icon-eyes-account" onClick={(e) => changePasswordText('input-passwordAccount', e)}></i>
          </div>
        </div>
        <div className="col-sm-3">
          <button id="btnEditPass" className="btn btn-outline-secondary" onClick={(e) => edit(e)}>
            <i className="fas fa-edit"></i>
            Cambiar
          </button>
        </div>
      </div>
      <br />
      <div className={dataShow}>
        <div className="mb-3 row inputs-form">
          <label className="col-sm-3 col-form-label configuration-profile-labels">Verificar Contraseña</label>
          <div className="col-sm-6">
            <div className="input-group input-password">
              <input
                id="input-passwordAccount2"
                type="password"
                disabled={disableInput}
                value={dataPassword2}
                onChange={(e) => setDataPassword2(e.target.value)}
                className={classInput}
                maxLength="50"
                aria-describedby="icon-eyes-account2"
              />
              <i className={classPassword} id="icon-eyes-account2" onClick={(e) => changePasswordText('input-passwordAccount2', e)}></i>
            </div>
          </div>
          <div className="col-sm-3">
            <button className="btn btn-outline-danger" disabled={disableCancel} onClick={cancel}>
              <i className="fas fa-times"></i>
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
