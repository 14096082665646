import React from 'react';

export const SearchDescription = (props) => {
  return (
    <form className='example' action='' onSubmit={props.handleSubmit}>
      <input
        className='input-element'
        type='text'
        placeholder='BÚSQUEDA RÁPIDA'
        onChange={props.handleChange}
        value={props.searchTerm}
      />
      <button type='submit'>
        <i className='fa fa-search'></i>
      </button>
    </form>
  );
};
