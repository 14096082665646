import React, { useContext } from 'react';
import { DataContext } from '../../context/DataContext';
import { CloseModalIcon } from '../Home/CloseModalIcon';
import { CloseModal } from '../Utilities/General';
import { WatchCatalog } from './WatchCatalog';

export const StreamingRegisterMessage = () => {
  const {
    validate: {
      idAuction,
      registeredAuctions,
      nameAuction,
      dateAuction,
      palletAuction,
    },
    setValidate,
  } = useContext(DataContext);

  const onClear = () => {
    setValidate({
      valid: false,
      registeredAuctions: [...registeredAuctions],
      idAuction: '',
      nameAuction: '',
      dateAuction: '',
      palletAuction: '',
      idPallet: '',
      onPaymentValidate: false,
    });
    CloseModal('modalStreamingReisterMesage');
  };
  const convertToDate = (i) => {
    const strings = dateAuction.split('|');
    return strings[i];
  };
  return (
    <div>
      <div
        className='modal fade '
        id='modalStreamingReisterMesage'
        aria-labelledby='streamingReisterModalLabel'
        aria-hidden='true'
        data-bs-backdrop='static'
        data-bs-keyboard='false'
      >
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-header header-streaming-mesagge'>
              <h4
                className='modal-title auction-streaming'
                id='streamingReisterModalLabel'
              >
                REGISTRO A LA SUBASTA
              </h4>
              <CloseModalIcon onHandleClick={onClear} />
            </div>
            <div className='modal-body text-center body-streaming-message'>
              <p>
                El registro a la subasta{' '}
                <span className='auction-streaming'>{nameAuction}</span> ha sido
                realizado con éxito
              </p>
              <i className='far fa-check-circle check-streaming'></i>
              <br />
              <br />
              <span className='date-register'>
                Fecha: {convertToDate(0)}
                <p> Hora: {convertToDate(1)}</p>
              </span>
              <h4 className='auction-streaming'>
                PALETA ASIGNADA:{' '}
                <span className='pallet-streaming'>{palletAuction}</span>
              </h4>
              <p className='text-center'>
                <WatchCatalog
                  idAuction={idAuction}
                  modal={true}
                  goCatalog={onClear}
                  clasStyle={'watch-catalog'}
                />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
