import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { GET } from "../../helpers/APIServices";
import {
  ServiceGetAwardsActive,
  ServiceClientData,
  ServiceGenericParameters,
} from "../../helpers/getURL";
import {
  convertToDate,
  formatCurrency,
  convertToDatePenaltyDay,
  OpenMessage,
} from "../Utilities/General";
import {
  getImgDefault,
  getPenaltyDays,
  getPenaltyDaysDefault,
  getDisableCheckoutMessageID,
} from "../../helpers/getConstants";

import { ModalCustomerData } from "./customerData/ModalCustomerData";

export const AwardActive = (props) => {
  const [awardActive, setAwardActive] = useState([]);
  const [clientData, setClientData] = useState({});
  const [visibleModal, setVisibleModal] = useState("hide");
  const history = useHistory();
  const idCustomer = localStorage.getItem("id");
  const URL = ServiceClientData();
  const [disabelCheckoutMessage, setMessage] = useState("");

  const getData = () => {
    GET(URL).then((data) => {
      if (data === "401") {
        getData();
        return "";
      }
      setClientData(data);
    });
  };

  useEffect(() => {
    const deliveryLimitID = getDisableCheckoutMessageID();
    const URLDeliveryLimit = ServiceGenericParameters(deliveryLimitID);
    const getData = () => {
      GET(URLDeliveryLimit).then((data) => {
        if (data === "401") {
          getData();
          return "";
        }
        if (data.value) {
          setMessage(data.value);
        }
      });
    };
    getData();
  }, []);

  useEffect(() => {
    if (props.visible === "show") {
      setVisibleModal("hide");
      const getAwardActive = () => {
        GET(URL).then((data) => {
          if (data === "401") {
            getAwardActive();
            return "";
          }
          setClientData(data);

          const URLGetAwardsActive = ServiceGetAwardsActive(idCustomer);
          GET(URLGetAwardsActive).then((data) => {
            if (data === "401") {
              getAwardActive();
              return "";
            }
            if (data) {
              const URLPenaltyDays = ServiceGenericParameters(getPenaltyDays());
              GET(URLPenaltyDays).then((response) => {
                if (response === "401") {
                  getAwardActive();
                  return "";
                }
                let days;
                if (response && response.value) {
                  days = parseInt(response.value);
                  if (isNaN(days)) {
                    days = getPenaltyDaysDefault();
                  }
                } else {
                  days = getPenaltyDaysDefault();
                }

                let arrayGrouped;
                let index = 0;

                const groupBy = (
                  array,
                  key = "idSubasta",
                  kay = "nombreSubasta",
                  koy = "fechaSubasta"
                ) => {
                  const grouped = {};

                  array.forEach((el) => {
                    grouped[el[key]] = {
                      idSubasta: el[key],
                      nombreSubasta: el[kay],
                      fechaSubasta: el[koy],
                      index,
                      awards: [],
                      datePenalty: convertToDatePenaltyDay(el[koy], days),
                    };
                    index++;
                  });

                  array.forEach((el) => {
                    if (grouped[el[key]]) {
                      grouped[el[key]].awards.push(el);
                    }
                  });
                  arrayGrouped = Object.values(grouped);
                };
                groupBy(data);
                setAwardActive(arrayGrouped);
              });
            }
          });
        });
      };
      getAwardActive();
    }
  }, [props.visible, idCustomer, URL]);

  const validaDatos = () => {
    let GeneralData = [
      "customerName",
      "customerLastname",
      "customerSecondLastname",
      "customerBirthday",
      "customerCURP",
      "customerRFC",
      "customerNationalityId",
    ];
    let ContactData = [
      "country",
      "idState",
      "idTown",
      "idCity",
      "street",
      "zipCode",
      "number",
      "phoneNumber",
      "email",
    ];
    let EconomicActivityData = ["idActEconomica", "idPais"];
    let contador = 0;
    let key;
    for (key in clientData) {
      if (key === "customerGeneralData") {
        for (let customerGeneralData in clientData[key]) {
          let campo = GeneralData.find((item) => {
            return item === customerGeneralData;
          });
          if (campo) {
            if (!clientData[key][customerGeneralData]) {
              contador++;
            }
          }
        }
      }
      if (key === "customerContactData") {
        for (let customerContactData in clientData[key]) {
          let campo = ContactData.find((item) => {
            return item === customerContactData;
          });
          if (campo) {
            if (
              !clientData[key][customerContactData] &&
              !clientData[key][customerContactData] !== ""
            ) {
              contador++;
            }
          }
        }
      }

      if (key === "customerEconomicActivity") {
        for (let customerEconomicActivity in clientData[key]) {
          let campo = EconomicActivityData.find((item) => {
            return item === customerEconomicActivity;
          });
          if (campo) {
            if (!clientData[key][customerEconomicActivity]) {
              contador++;
            }
          }
        }
      }
    }
    return contador;
  };

  const shoppingCartError = () => {
    OpenMessage("warning", disabelCheckoutMessage);
  };

  const shoppingCart = (data) => {
    let contador = validaDatos();
    if (contador === 0)
      history.push({
        pathname: "/carrito-compras",
        state: { detail: data, enableInvoice: props.enableInvoice },
      });
    else {
      const collapseOne = document.getElementById("collapseOne");
      const collapseTwo = document.getElementById("collapseTwo");
      const headingOne = document.querySelector("#headingOne > button");
      const headingTwo = document.querySelector("#headingTwo > button");

      collapseOne.classList.add("show");
      headingOne.classList.remove("collapsed");
      collapseTwo.classList.remove("show");
      headingTwo.classList.add("collapsed");
      setVisibleModal("show");
      let btnFinalizarCompra = document.getElementById("btnFinalizarCompra");
      btnFinalizarCompra.click();
    }
  };

  if (awardActive.length === 0) {
    return (
      <div className={props.visible}>
        <div className='border-title'>
          <div>Mis adjudicaciones activas</div>
        </div>
        <div className='mb-3 row inputs-form'>
          <div className='col-12 text-center'>No existen registros</div>
        </div>
      </div>
    );
  } else {
    return (
      <div className={"container__awardActive " + props.visible}>
        <div className='border-title'>
          <div>Mis adjudicaciones activas</div>
        </div>
        {awardActive.map((item, x) => {
          return (
            <div key={x}>
              {" "}
              <div className='mb-3 row inputs-form '>
                <label className='col-12 col-form-label configuration-profile-labels'>
                  {item.awards.length} adjudicaciones
                </label>
              </div>
              <div className='mb-3 row inputs-form border-bottom'>
                <div className='col-12 col-sm-6 col-lg-7 col-xl-8 text-center-mobile'>
                  <h5>
                    {item.nombreSubasta} {convertToDate(item.fechaSubasta)}
                    <br />
                    Fecha máxima de pago {item.datePenalty}
                  </h5>
                </div>
                <div className='col-12 col-sm-6 col-lg-5 col-xl-4 text-end text-center-mobile'>
                  {/* 
                  ToDo: Se debe cambiar el metodo shoppingCartError() por  shoppingCart(item.awards) 
                    Cuando se habilite de nuevo actnet
                  */}
                  <button
                    className='btn btn-outline-secondary btn-profile'
                    onClick={(e) => shoppingCart(item.awards)}
                  >
                    Finalizar compra
                  </button>
                  <button
                    id='btnFinalizarCompra'
                    className='hide'
                    data-bs-toggle='modal'
                    data-bs-target='#modalCustomerData'
                  >
                    Finalizar compra
                  </button>
                </div>
                <br />
                <br />
              </div>
              {item.awards.map((award, i) => {
                return (
                  <div className='mb-3 row inputs-form border-title' key={i}>
                    <div className='col-12'>
                      <div className='card mb-3'>
                        <div className='row g-0 align-items-center'>
                          <div className='col-4'>
                            <img
                              src={
                                award.images[0]
                                  ? award.images[0]
                                  : getImgDefault()
                              }
                              className='img-fluid rounded-start'
                              alt={award.descripcionLote}
                            />
                          </div>
                          <div className='col-8'>
                            <div className='card-body'>
                              <h5 className='card-title'>Lote: {award.lot}</h5>
                              <p className='card-text'>
                                {award.nombreLote} <br />{" "}
                                {award.descripcionLote}
                              </p>

                              <div className='row'>
                                <div className='col-12 col-sm-6 col-lg-7 col-xl-8 text-center-mobile'>
                                  <small className='text-muted'>
                                    Precio final:{" "}
                                    {formatCurrency(award.precioSalida)}
                                  </small>
                                </div>
                                <div className='col-12 col-sm-6 col-lg-5 col-xl-4 text-center'>
                                  <button
                                    className='btn btn-outline-secondary button-eye float-right'
                                    data-mdb-toggle='modal'
                                    data-mdb-target={`#modal${award.idLote}`}
                                  >
                                    Ver detalle
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className='modal fade modal-top'
                      id={`modal${award.idLote}`}
                      aria-labelledby='exampleModalLabel'
                      aria-hidden='true'
                    >
                      <div className='modal-dialog modal-dialog-centered'>
                        <div className='modal-content'>
                          <div className='modal-body'>
                            <img
                              src={
                                award.images[0]
                                  ? award.images[0]
                                  : getImgDefault()
                              }
                              alt=''
                              className='img-fluid w-100'
                            />
                          </div>
                          <h4 className='text-desc'>{award.descripcionLote}</h4>
                          <div className='modal-footer'>
                            <button
                              type='button'
                              className='btn btn-outline-danger'
                              data-mdb-dismiss='modal'
                            >
                              Cerrar
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
        <ModalCustomerData updateData={getData} visible={visibleModal} />
      </div>
    );
  }
};
