import React, { useState, useEffect } from 'react';
import NumberFormat from 'react-number-format';

import {
  convertToDateFormatEnglish,
  setMinMaxDate,
  onlyText,
  OpenMessage,
  validMinMaxDate,
  verifyFieldsRequerid,
  onlyMail,
  validEmail,
} from '../Utilities/General';
import { ServiceClientData, ServiceUpdateClientData } from '../../helpers/getURL';
import { GET, PUT } from '../../helpers/APIServices';

import { CustomerChangePassword } from './customerData/CustomerChangePass';

export const ConfigurationAccount = (props) => {
  const [dataName, setDataName] = useState('');
  const [dataLastName, setDataLastName] = useState('');
  const [dataSecondLastName, setDataSecondLastName] = useState('');
  const [dataBirthday, setDataBirthday] = useState('');
  const [dataTelefono, setDataTelefono] = useState('');
  const [dataCorreo, setDataCorreo] = useState('');

  const [classInput, setClassInput] = useState('form-control');
  const [disableInput, setDisableInput] = useState(true);
  const [disableCancel, setDisableCancel] = useState(true);

  const URL = ServiceClientData();
  const URLClients = ServiceUpdateClientData();

  const getData = () => {
    GET(URL).then((data) => {
      if (data === '401') {
        getData();
        return '';
      }

      if (data && data.customerGeneralData) {
        const generalData = data.customerGeneralData;
        const birthday = convertToDateFormatEnglish(generalData.birthday);
        setDataName(generalData.name ? generalData.name : '');
        setDataLastName(generalData.lastName ? generalData.lastName : '');
        setDataSecondLastName(generalData.secondLastName ? generalData.secondLastName : '');
        setDataBirthday(birthday ? birthday : '');
        setDataCorreo(generalData.email ? generalData.email : '');
        setDataTelefono(generalData.phoneNumber ? generalData.phoneNumber : '');
      }
    });
  };

  useEffect(() => {
    if (props.visible === 'show') {
      getData();
      setMinMaxDate('input-birthdayAccount');
    }
  }, [props.visible]);

  const verifyFields = () => {
    const inputs = [
      'input-nameAccount',
      'input-lastnameAccount',
      'input-secondlastnameAccount',
      'input-birthdayAccount',
      'input-phoneAccount',
      'input-emailAccount',
    ];

    const validFields = verifyFieldsRequerid(inputs);
    if (!validFields) {
      OpenMessage('error', 'Campos obligatorios');
      return false;
    } else {
      if (!validMinMaxDate('input-birthdayAccount')) {
        document.getElementById('input-birthdayAccount').classList.add('is-invalid');
        OpenMessage('error', 'La fecha de nacimiento no es válida');
        return false;
      }

      if (!validEmail(dataCorreo)) {
        document.getElementById('input-emailAccount').classList.add('is-invalid');
        OpenMessage('error', 'El formato del correo eléctronico no es válido');
        return false;
      }
    }
    return true;
  };

  const updateProfile = () => {
    const name = dataName + ' ' + dataLastName;
    document.getElementById('userName').innerHTML = name;
    localStorage.setItem('userName', name);
  };

  const save = () => {
    const params = {
      idCustomer: localStorage.getItem('id'),
      customerGeneralData: {
        name: dataName,
        lastName: dataLastName,
        secondLastName: dataSecondLastName,
        birthday: dataBirthday,
        phoneNumber: dataTelefono,
        email: dataCorreo,
      },
    };

    if (!verifyFields()) {
      return false;
    }

    PUT(URLClients, params).then((data) => {
      if (data === true) {
        OpenMessage('success');
        updateProfile();
        cancel();
        if (props.parent === 'Modal') {
          props.close();
        }
      } else {
        OpenMessage('error', data);
      }
    });
  };

  const edit = (event) => {
    setDisableCancel(false);
    let btnEdit = document.getElementById('btnEdit');
    if (btnEdit.innerText === 'GUARDAR') {
      save();
    } else {
      btnEdit.innerHTML = '<i className="fas fa-edit"></i>Guardar';
      setDisableInput(false);
      setClassInput('form-control enable');
    }
  };

  const cancel = () => {
    setDisableCancel(true);
    setDisableInput(true);
    setClassInput('form-control');
    let btnEdit = document.getElementById('btnEdit');
    btnEdit.innerHTML = '<i className="fas fa-edit"></i>Editar';
    getData();
  };

  return (
    <div>
      <br />
      <div className="row inputs-form">
        <label className="col-sm-3 col-form-label configuration-profile-labels">Nombre*</label>
        <div className="col-sm-8">
          <input
            id="input-nameAccount"
            type="text"
            onInput={(e) => onlyText(e)}
            disabled={disableInput}
            value={dataName}
            onChange={(e) => setDataName(e.target.value)}
            className={classInput}
            maxLength="50"
          />
        </div>
      </div>
      <br />
      <div className="row inputs-form">
        <label className="col-sm-3 col-form-label configuration-profile-labels">Apellido paterno*</label>
        <div className="col-sm-8">
          <input
            id="input-lastnameAccount"
            type="text"
            disabled={disableInput}
            value={dataLastName}
            onChange={(e) => setDataLastName(e.target.value)}
            className={classInput}
            onInput={(e) => onlyText(e)}
            maxLength="50"
          />
        </div>
      </div>
      <br />
      <div className="row inputs-form">
        <label className="col-sm-3 col-form-label configuration-profile-labels">Apellido materno*</label>
        <div className="col-sm-8">
          <input
            id="input-secondlastnameAccount"
            type="text"
            disabled={disableInput}
            value={dataSecondLastName}
            onChange={(e) => setDataSecondLastName(e.target.value)}
            className={classInput}
            onInput={(e) => onlyText(e)}
            maxLength="50"
          />
        </div>
      </div>
      <br />
      <div className="mb-5 row inputs-form">
        <label className="col-sm-3 col-form-label configuration-profile-labels">Fecha de nacimiento*</label>
        <div className="col-sm-8">
          <input
            id="input-birthdayAccount"
            type="date"
            disabled={disableInput}
            value={dataBirthday}
            onChange={(e) => setDataBirthday(e.target.value)}
            className={classInput}
          />
        </div>
      </div>
      <div className="mb-5 row inputs-form">
        <label className="col-sm-3 col-form-label configuration-profile-labels">Teléfono*</label>
        <div className="col-sm-8">
          <NumberFormat
            id="input-phoneAccount"
            format="(##) ####-####"
            mask=""
            disabled={disableInput}
            value={dataTelefono}
            onValueChange={(e) => setDataTelefono(e.value)}
            className={classInput}
          />
        </div>
      </div>
      <div className="mb-5 row inputs-form">
        <label className="col-sm-3 col-form-label configuration-profile-labels">Correo electrónico*</label>
        <div className="col-sm-8">
          <input
            id="input-emailAccount"
            name="input-emailAccount"
            type="text"
            disabled
            value={dataCorreo}
            onChange={(e) => setDataCorreo(e.target.value)}
            className={classInput}
            maxLength="80"
            onInput={(e) => onlyMail(e)}
          />
        </div>
      </div>
      <div className={props.parent === 'Modal' ? 'hide' : 'show'}>
        <CustomerChangePassword />
      </div>
      <div className="mb-5 row inputs-form">
        <div className="col-sm-12 text-end">
          <button className="btn btn-outline-danger" disabled={disableCancel} onClick={cancel}>
            <i className="fas fa-times"></i>
            Cancelar
          </button>
          &nbsp;&nbsp;&nbsp;
          <button id="btnEdit" className="btn btn-outline-secondary" onClick={(e) => edit(e)}>
            <i className="fas fa-edit"></i>
            Editar
          </button>
        </div>
      </div>
    </div>
  );
};
