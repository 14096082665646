import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getConfig } from '../../helpers/getConfig';
import '../../css/footer.css';

const FooterMain = () => {
  const [data, setData] = useState([]);
  const LOCATION = useLocation();
  let email = '',
    phone1 = '',
    phone2 = '',
    phone3 = '',
    phone4 = '',
    desc1 = '',
    desc2 = '',
    desc3 = '',
    desc4 = '',
    days = '',
    schedule = '',
    address = '',
    socialMedia = [],
    socialKey = 0,
    hideFooter = '';

  useEffect(() => {
    getConfig('footer2.json').then((data) => setData(data));
  }, []);

  if (data && data.footer) {
    email = data.footer.email;
    phone1 = data.footer.phone1;
    phone2 = data.footer.phone2;
    phone3 = data.footer.phone3;
    phone4 = data.footer.phone4;
    desc1 = data.footer.desc1;
    desc2 = data.footer.desc2;
    desc3 = data.footer.desc3;
    desc4 = data.footer.desc4;
    days = data.footer.days;
    schedule = data.footer.schedule;
    address = data.footer.address;
    socialMedia = data.footer.socialMedia;
  }

  const viewFooter = () => {
    if (LOCATION.pathname.toLowerCase() === '/streaming') {
      hideFooter = 'hide';
    }
  };

  viewFooter();

  return (
    <footer>
      <div className="row">
        <section className={hideFooter}>
          <div className="container text-center text-md-start">
            <div className="row container-footer">
              <div className="col-12 col-md-4 col-footer">
                <div className="row">
                  <div className="col mb-9">
                    <img src="./resources/LogoBlanco.svg" alt="logo MLS" className="img-logo-footer img-fluid" width="220px" />
                  </div>
                </div>
                <div className="row mb-5">
                  <div className="col">
                    <img src="./resources/MLSLogoBlanco.svg" alt="logo MLS" className="img-logo-footer img-fluid" width="220px" />
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-4 col-footer">
                <div className="row mb-4 container-footer__align">
                  <div className="col-xl-4 col-12">
                    <h2 className="container-footer-tel">
                      <a href={'tel:' + phone1} rel="noreferrer">
                        <b>{phone1}</b>
                      </a>
                    </h2>
                  </div>
                  <div className="col-xl-8 col-12">
                    <h2 className="container-footer-tel">{desc1}</h2>
                  </div>
                </div>

                <div className="row mb-4 container-footer__align">
                  <div className="col-xl-4 col-12">
                    <h2 className="container-footer-tel">
                      <a href={'tel:' + phone2} rel="noreferrer">
                        <b>{phone2}</b>
                      </a>
                    </h2>
                  </div>
                  <div className="col-xl-8 col-12">
                    <h2 className="container-footer-tel">{desc2}</h2>
                  </div>
                </div>
                <div className="row mb-4 container-footer__align">
                  <div className="col-xl-4 col-12">
                    <h2 className="container-footer-tel">
                      <a href={'tel:' + phone3} rel="noreferrer">
                        <b>{phone3}</b>
                      </a>
                    </h2>
                  </div>
                  <div className="col-xl-8 col-12">
                    <h2 className="container-footer-tel">{desc3}</h2>
                  </div>
                </div>
                <div className="row mb-4 container-footer__align">
                  <div className="col-xl-4 col-12">
                    <h2 className="container-footer-tel">
                      <a href={'tel:' + phone4} rel="noreferrer">
                        <b>{phone4}</b>
                      </a>
                    </h2>
                  </div>
                  <div className="col-xl-8 col-12">
                    <h2 className="container-footer-tel">{desc4}</h2>
                  </div>
                </div>
                <p className="text-center">
                  <a href={`mailto:${email}`} rel="noreferrer" className="hover-footer">
                    {email}
                  </a>
                </p>
                <p className="mb-4 fst-italic text-center">
                  <label className="lbl-direction">{address}</label>
                </p>

                <p className="text-center">
                  <label>{days}</label>
                </p>
                <p className="text-center">
                  <label>{schedule}</label>
                </p>
              </div>

              <div className="col-12 col-md-4 col-footer">
                <a href="/resources/terminos-y-condiciones-subasta-2021.pdf" target="_blank" rel="noreferrer" className="hover-footer">
                  <h2 className="text-center mb-4">Términos y condiciones</h2>
                </a>
                <p className="text-center">
                  <label className="mb-4 text-uppercase fw-bold">Siguenos en:</label>
                </p>
                <p className="text-center">
                  {socialMedia.map(({ url, icon }) => {
                    socialKey++;
                    return (
                      <span className="mb-4" key={socialKey}>
                        <a href={url} target="_blank" rel="noreferrer">
                          <i className={icon}></i>
                        </a>
                      </span>
                    );
                  })}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-12 text-center"></div>
            </div>
          </div>
        </section>

        <div className="text-center container-footer2">
          Todos los derechos reservados por Montepío Luz Saviñón, I.A.P. | &nbsp;
          <a className="text-reset fw-bold" href="/resources/aviso-de-privacidad-subastas-2023.pdf" target="_blank" rel="noreferrer">
            Aviso de privacidad
          </a>
        </div>
      </div>
    </footer>
  );
};

export default FooterMain;
