import React, { useEffect, useState } from 'react';

import { resetRadios } from '../../Utilities/General';
import { getactnetID, getBillPocketID, getMercadoPagoId } from '../../../helpers/getConstants'

export const Payment = ({
  visible,
  eventButton,
  externStep,
  lastStep,
  originStep2,
  hideBillPocket,
  hideActnet,
  hideMercadoPago
}) => {
  const [disabledBtn, setDisabledBtn] = useState(true);
  const [data, setData] = useState({});
  let [step, setStep] = useState(externStep + 1);
  const BILLPOCKETID = getBillPocketID();
  const ACTNETID = getactnetID();
  const MERCADOPAGOID = getMercadoPagoId();

  useEffect(() => {
    if (lastStep && visible === 'show') {
      setStep(8);
      resetRadios('PaymentRadios');
      setDisabledBtn(true);
    }
  }, [lastStep, visible]);

  const clickRadio = (value, data, paymentGateway) => {
    const selectItem = {
      payment: {
        id: value,
        name: data,
        paymentGateway: paymentGateway,
      },
    };
    setData(selectItem);
    setDisabledBtn(false);
  };

  return (
    <div className={'card ' + visible}>
      <div className="card-header text-center">
        <h3>Método de pago</h3>
      </div>
      <div className="card-body">
        <div className="row mb-6">
          <div className="col-12">
            <h5 className="card-text card-title">Seleccione un método de pago de las opciones disponibles:</h5>
          </div>
        </div>
        <div className="row mb-2">
          {!hideBillPocket && (
            <div className="col-md-3 col-sm-6">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="PaymentRadios"
                  id="Payment1Radios"
                  value="1"
                  onClick={(e) => clickRadio(e.target.value, 'Bill Pocket', BILLPOCKETID)}
                />
                <label className="form-check-label" htmlFor="Payment1Radios">
                  <img className="img-Payment" src="./resources/img/bill_pocket.jpg" alt="Bill Pocket" width="80%" />
                </label>
              </div>
            </div>
          )}
          {
            !hideActnet && (
              <div className="col-md-3 col-sm-6">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="PaymentRadios"
                    id="Payment2Radios"
                    value="2"
                    onClick={(e) => clickRadio(e.target.value, 'Actnet', ACTNETID)}
                  />
                  <label className="form-check-label" htmlFor="Payment2Radios">
                    <img className="img-Payment2" src="./resources/img/actnet.png" alt="Actnet" width="90%"/>
                  </label>
                </div>
              </div>
            )
          }
          {
            !hideMercadoPago && (
              <div className="col-md-3 col-sm-6">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="PaymentRadios"
                    id="Payment3Radios"
                    value="3"
                    onClick={(e) => clickRadio(e.target.value, 'MercadoPago', MERCADOPAGOID)}
                  />
                  <label className="form-check-label" htmlFor="Payment3Radios">
                    <img className="img-Payment3" src="/resources/img/mercado_pago.png" alt="Actnet" width="90%"/>
                  </label>
                </div>
              </div>
            )
          }

        </div>
        <div className="row mb-2">
          <div className="col-6 text-end">
            <button
              type="button"
              className="btn btn-lg btn-outline-danger"
              disabled={disabledBtn}
              onClick={(e) => eventButton(true, step, data)}
            >
              Continuar
            </button>
          </div>
          <div className="col-6 text-start">
            <button
              type="button"
              className="btn btn-lg btn-outline-secondary"
              onClick={(e) => eventButton(true, originStep2, data)}
            >
              Regresar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
