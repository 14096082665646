import React, { useEffect, useState } from 'react';
import { getConfig } from '../../helpers/getConfig';
import '../../css/Menu/AboutUs.css';

export const AboutUs = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    getConfig('aboutUs.json').then((data) => setData(data));
  }, []);

  let title1 = '',
    title2 = '',
    paragraph1 = '',
    paragraph2 = '',
    paragraph3 = '',
    paragraph4 = '';

  if (data) {
    title1 = data.title1;
    title2 = data.title2;
    paragraph1 = data.paragraph1;
    paragraph2 = data.paragraph2;
    paragraph3 = data.paragraph3;
    paragraph4 = data.paragraph4;
  }

  return (
    <div className="container-fluid container-about-us slidein">
      <div className="row ">
        <div className="col-12 col-md-6">
          <h2 className="title-section-s mb-5">
            <div dangerouslySetInnerHTML={{ __html: title1 }} />
          </h2>
          <div className="we-platform mb-5">
            <div dangerouslySetInnerHTML={{ __html: paragraph1 }} />
            <div dangerouslySetInnerHTML={{ __html: paragraph2 }} />
          </div>
          <h2 className="title-section-s mb-5">
            <div dangerouslySetInnerHTML={{ __html: title2 }} />
          </h2>
          <div className="we-platform">
            <div dangerouslySetInnerHTML={{ __html: paragraph3 }} />
            <div dangerouslySetInnerHTML={{ __html: paragraph4 }} />
          </div>
        </div>
        <div className="col-12 col-md-6 image-container slidein">
          <img src="/resources/quienes_somos.jpg" alt="" className="about-us-image" />
        </div>
      </div>
    </div>
  );
};
