import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { convertToDate, formatCurrency } from '../Utilities/General';
import { ButtonRegister } from '../Register/ButtonRegister';
import { getImgDefault } from '../../helpers/getConstants';

export const AuctionsHighlightCarousel = (props) => {
  const [state, setState] = useState({
    articles: [],
    auctionName: '',
    auctionDate: new Date(),
    idAuction: '',
  });

  useEffect(() => {
    const data = props.data;
    if (data) {
      setState((prevState) => ({
        ...prevState,
        name: data.id ? data.name : '',
        idAuction: data.id,
        auctionDate: convertToDate(data.auctionDate),
      }));
    }
    setState((prevState) => ({
      ...prevState,
      articles: [],
    }));
    if (props.articles) {
      setState((prevState) => ({
        ...prevState,
        articles: props.articles,
      }));
    }
  }, [props]);

  if (state.idAuction && state.articles.length > 0) {
    return (
      <div className="container-fluid container-auctionsHighlight container__auctionsHighlight--types">
        <div className="row">
          <div className="col-12 text-uppercase text-center-mobile">
            <h2>
              <span className="container-auctionsHighlight--title">destacados</span> de subasta
            </h2>
          </div>
        </div>

        <div className="row container-auctionsHighlight-row">
          <div className="col-12 text-center-mobile">
            <span className="container-auctionsHighlight--description">
              Contaremos con una gran variedad de piezas únicas en distintas categorías:{' '}
            </span>
          </div>
        </div>
        <div className="row container-auctionsHighlight-row mb-5">
          <div className="col-12 container-auctionsHighlight-col container-auctionsHighlight-btn2 text-center-mobile">
            <Link to="/catalogo">
              <button className="btn btn-outline-danger btn-lg">Catálogo completo</button>
            </Link>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-12">
            <div id="carouselAuctionHighlight" className="carousel carousel-dark slide" data-bs-ride="carousel">
              <div className="carousel-indicators auctionsCarousel-indicators">
                {state.articles.map((item, i) => (
                  <button
                    type="button"
                    data-bs-target="#carouselAuctionHighlight"
                    data-bs-slide-to={i}
                    className={i === 0 ? 'rounded-circle active' : 'rounded-circle'}
                    aria-current={i === 0 ? 'true' : ''}
                    key={i}
                  ></button>
                ))}
              </div>
              <div className="carousel-inner">
                {state.articles.map((item, i) => (
                  <div className={i === 0 ? 'carousel-item active' : 'carousel-item'} key={i}>
                    <div className="row justify-content-center">
                      <div className="col-8">
                        <div className="card">
                          <div className="container-products-items--img">
                            <img
                              src={
                                item.lstImageLote && item.lstImageLote.length > 0 && item.lstImageLote[0]
                                  ? item.lstImageLote[0]
                                  : getImgDefault()
                              }
                              className="card-img-top "
                              alt="..."
                            />
                          </div>

                          <div className="card-body text-center">
                            <p className="container-products-items--title">{item.name}</p>
                            <p className="product-text">
                              Lote <span>{item.order}</span>
                            </p>
                            <span className="product-text">
                              PRECIO DE SALIDA: <span className="price-text">{formatCurrency(item.startingPrice)}</span>
                            </span>
                          </div>
                          <div className="card-footer text-center">
                            <button className="btn btn-outline-secondary" data-mdb-toggle="modal" data-mdb-target={`#modal${item.idLot}`}>
                              <i className="fas fa-eye"></i>
                              <strong> Detalles</strong>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <button className="carousel-control-prev" type="button" data-bs-target="#carouselAuctionHighlight" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#carouselAuctionHighlight" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
              </button>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            {state.articles.map((item, i) => (
              <div
                className="modal"
                id={`modal${item.idLot}`}
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                aria-modal="true"
                role="dialog"
                key={i}
              >
                <div className="modal-dialog modal-sm modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-body">
                      <img src={item.lstImageLote[0]} alt="" className="img-fluid" />
                    </div>
                    <h4 className="text-desc text-center">{item.name}</h4>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-outline-danger" data-mdb-dismiss="modal">
                        Cerrar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="row">
          <div className="col text-center container-auctions-button">
            <ButtonRegister
              idAuction={state.idAuction}
              nameAuction={state.name}
              dateAuction={state.auctionDate}
              classStyle={'btn-carousel-secondary'}
            />
          </div>
        </div>
      </div>
    );
  }
  return null;
};
