import React, { useState } from 'react';
import { getactnetID, getBillPocketID, getMercadoPagoId } from '../../../helpers/getConstants'
import { CloseModalIcon } from '../../Home/CloseModalIcon';
import { CloseModal } from '../../Utilities/General';

export const PaymentGateways = ({ retryPAyment, hideBillPocket, hideActnet, hideMercadoPago }) => {
  const [data, setData] = useState({});
  const [sure, setSure] = useState(false);
  const [disabledBtn, setDisabledBtn] = useState(true);
  const BILLPOCKETID = getBillPocketID();
  const ACTNETID = getactnetID();
  const MERCADOPAGOID = getMercadoPagoId();

  const closeOfferSuccess = () => {
    CloseModal('modalPaymentGateways');
    setDisabledBtn(true);
    setData({});
  };
  const clickRadio = (value, data) => {
    const selectItem = {
      payment: {
        id: value,
        name: data,
      },
    };
    setData(selectItem);
    setDisabledBtn(false);
  };

  return (
    <div
      className="modal fade "
      id="modalPaymentGateways"
      aria-labelledby="paymentGatewaysModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body">
            <div className="card-header text-center">
              <div className="modal-header justify-content-end border-0">
                <CloseModalIcon onHandleClick={() => closeOfferSuccess()} />
              </div>
              <h3>Método de pago</h3>
            </div>
            <div className="row my-4">
              <div className={`col-12 ${sure && 'text-center'}`}>
                <h5 className="card-text card-title">
                  {sure
                    ? 'Ya se había generado un pago anteriormente. ¿Está seguro de continuar?'
                    : 'Seleccione un método de pago de las opciones disponibles:'}
                </h5>
              </div>
            </div>
            {!sure && (
              <div className="d-flex mb-2 justify-content-start">
                {!hideBillPocket && (
                  <div className="col-6">
                    <div className="form-check">
                      <input
                        className="form-check-input mt-5"
                        type="radio"
                        name="PaymentRadios"
                        id="Payment1Radios"
                        value={BILLPOCKETID}
                        checked={data.payment?.id === BILLPOCKETID ? true : false}
                        onClick={(e) => clickRadio(e.target.value, 'Bill Pocket')}
                      />
                      <label className="form-check-label" htmlFor="Payment1Radios">
                        <img className="img-Payment" src="./resources/img/bill_pocket.jpg" alt="Bill Pocket" width="80%" />
                      </label>
                    </div>
                  </div>
                )}
                {
                  !hideActnet && (
                    <div className="col-6">
                      <div className="form-check">
                        <input
                          className="form-check-input mt-5"
                          type="radio"
                          name="PaymentRadios"
                          id="Payment2Radios"
                          value={ACTNETID}
                          checked={data.payment?.id === ACTNETID ? true : false}
                          onClick={(e) => clickRadio(e.target.value, 'Actnet')}
                        />
                        <label className="form-check-label" htmlFor="Payment2Radios">
                          <img className="img-Payment" src="./resources/img/actnet.png" alt="Actnet" width="100%"/>
                        </label>
                      </div>
                    </div>
                  )
                }
                {
                  !hideMercadoPago && (
                    <div className="col-6">
                      <div className="form-check">
                        <input
                          className="form-check-input mt-5"
                          type="radio"
                          name="PaymentRadios"
                          id="Payment3Radios"
                          value={MERCADOPAGOID}
                          checked={data.payment?.id === MERCADOPAGOID ? true : false}
                          onClick={(e) => clickRadio(e.target.value, 'Mercado Pago')}
                        />
                        <label className="form-check-label" htmlFor="Payment3Radios">
                          <img className="img-Payment" src="/resources/img/mercado_pago.png" alt="MercadoPago" width="100%"/>
                        </label>
                      </div>
                    </div>
                  )
                }
              </div>
            )}
            {!sure ? (
              <div className="row justify-content-center">
                <div className="col-3">
                  <button type="button" className="btn btn-outline-secondary btn-lg" onClick={(e) => setSure(true)} disabled={disabledBtn}>
                    Continuar
                  </button>
                </div>
                <div className="col-3">
                  <button
                    type="button"
                    className="btn btn-outline-danger btn-lg closin-accept"
                    data-bs-dismiss="modalOfferSuccess"
                    aria-label="Close"
                    onClick={(e) => closeOfferSuccess()}
                  >
                    Cancelar
                  </button>
                </div>
              </div>
            ) : (
              <div className="row justify-content-center">
                <div className="col-3">
                  <button
                    type="button"
                    className="btn btn-outline-secondary btn-lg w-100"
                    data-bs-dismiss="modalOfferSuccess"
                    aria-label="Close"
                    onClick={(e) => [retryPAyment(data), setSure(false), closeOfferSuccess()]}
                  >
                    Sí
                  </button>
                </div>
                <div className="col-3">
                  <button type="button" className="btn btn-outline-danger btn-lg w-100" onClick={(e) => setSure(false)}>
                    No
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
