import React, { useEffect, useState } from "react";

import { formatCurrency } from "../../Utilities/General";
import { getImgDefault } from "../../../helpers/getConstants";

import { getMessageInsuranceID } from "../../../helpers/getConstants";
import { GET } from "../../../helpers/APIServices";
import { ServiceGenericParameters } from "../../../helpers/getURL";

export const ConfirmOrder = (props) => {
  const [mail, setMail] = useState("");
  const [addressInvoice, setAddressInvoice] = useState("");
  const [addressInvoiceTitle, setAddressInvoiceTitle] = useState("");
  const [addressMail, setAddressMail] = useState("");
  const [payment, setPayment] = useState("");
  const [items, setItems] = useState([]);
  const [hideInvoice, setHideInvoice] = useState("");
  const [hideMail, setHideMail] = useState("");
  const [stepSend, setStepSend] = useState();
  const [messageInsurance, setMessageInsurance] = useState("");

  useEffect(() => {
    if (props.visible === "show" && props.data) {
      const data = props.data;
      let value = "";

      value = data.addressInvoice ? data.addressInvoice.name : "";
      setAddressInvoice(value);
      value = data.addressInvoice ? data.addressInvoice.title : "";
      setAddressInvoiceTitle(value);

      value = data.addressMail ? data.addressMail.name : data.send.name;
      setAddressMail(value);
      value = data.mail ? data.mail.name : data.send.name;
      setMail(value);

      value = data.payment ? data.payment.name : "";
      setPayment(value);

      setItems(data.selectItem);

      if (
        !props.enableInvoice ||
        props.dataConsigna ||
        data.invoice.id === "2"
      ) {
        setHideInvoice("hide");
      }
      if (data.send.type === "1") {
        //entrega en oficina
        setHideMail("hide");
        setStepSend(2); //metodo de envio
      } else {
        setHideMail("");
        setStepSend(3); //direcciones de envio
      }
    }
  }, [props.visible, props.data, props.dataConsigna, props.enableInvoice]);

  useEffect(() => {
    const messageInsuranceID = getMessageInsuranceID();
    const URLMessageInsurance = ServiceGenericParameters(messageInsuranceID);
    const getData = () => {
      GET(URLMessageInsurance).then((data) => {
        if (data === "401") {
          getData();
          return "";
        }
        if (data.value) {
          setMessageInsurance(data.value);
        }
      });
    };
    getData();
  }, []);

  return (
    <div className={"card " + props.visible}>
      <div className='card-header text-center'>
        <h3>Confirmación de Orden</h3>
      </div>
      <div className='card-body'>
        <div className={"row mb-2 " + hideMail}>
          <div className='col-12'>
            <h5 className='card-text card-title'>Método de envío</h5>
          </div>
        </div>
        <div className={"row mb-5 " + hideMail}>
          <div className='col-8'>
            <p>{mail}</p>
          </div>
          <div className='col-4 text-end'>
            <button
              className='btn btn-link'
              onClick={(e) => props.eventButton(true, 2)}
            >
              Cambiar
            </button>
          </div>
        </div>
        <div className='row mb-2'>
          <div className='col-12'>
            <h5 className='card-text card-title'>Dirección de envío</h5>
          </div>
        </div>
        <div className='row mb-5'>
          <div className='col-8'>
            <p>{addressMail}</p>
          </div>
          <div className='col-4 text-end'>
            <button
              className='btn btn-link'
              onClick={(e) => props.eventButton(true, stepSend)}
            >
              Cambiar
            </button>
          </div>
        </div>
        <div className={"row mb-2 " + hideMail}>
          <div className='col-12'>
            <h5 className='card-text card-title'>Seguro</h5>
          </div>
        </div>
        <div className={"row mb-5 " + hideMail}>
          <div className='col-12'>
            <p>{messageInsurance}</p>
          </div>
        </div>
        <div className='row mb-2'>
          <div className='col-12'>
            <h5 className='card-text card-title'>Método de pago</h5>
          </div>
        </div>
        <div className='row mb-5'>
          <div className='col-8'>
            <p>{payment}</p>
          </div>
          <div className='col-4 text-end'>
            <button
              className='btn btn-link'
              onClick={(e) => props.eventButton(true, 7)}
            >
              Cambiar
            </button>
          </div>
        </div>
        <div className={"row mb-2 " + hideInvoice}>
          <div className='col-12'>
            <h5 className='card-text card-title'>Dirección de facturación</h5>
          </div>
        </div>
        <div className={"row mb-5 " + hideInvoice}>
          <div className='col-8'>
            <p>{addressInvoiceTitle}</p>
            <p>{addressInvoice}</p>
          </div>
          <div className='col-4 text-end'>
            <button
              className='btn btn-link'
              onClick={(e) => props.eventButton(true, 5)}
            >
              Cambiar
            </button>
          </div>
        </div>
        <div className='row mb-2'>
          <div className='col-12'>
            <h5 className='card-text card-title'>Materiales:</h5>
          </div>
        </div>
        {items.map((item, i) => {
          return (
            <div key={i}>
              <div className='mb-3 row inputs-form'>
                <div className='col-12'>
                  <div className='mb-3'>
                    <div className='row g-0 align-items-center'>
                      <div className='col-2'>
                        <img
                          src={
                            item.images[0] ? item.images[0] : getImgDefault()
                          }
                          className='img-fluid rounded-start'
                          alt={item.descripcionLote}
                        />
                      </div>
                      <div className='col-md-10'>
                        <div className='card-body'>
                          <div className='row'>
                            <div className='col-3'>
                              <p className='card-text'>{item.nombreLote}</p>
                            </div>
                            <div className='col-6'>
                              <p className='card-text'>
                                {item.descripcionLote}
                              </p>
                            </div>
                            <div className='col-3'>
                              <small className='text-muted'>
                                {formatCurrency(item.precioSalida)}
                              </small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
