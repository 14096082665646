import React, { useState, useEffect } from 'react';
import { ServiceGetCountries } from '../../helpers/getURL';
import { GET } from '../../helpers/APIServices';
import Select, { components } from 'react-select';

export const SelectJustCountry = (props) => {
  const [countries, setCountries] = useState([]);
  const [style, setStyle] = useState('');

  useEffect(() => {
    const getData = () => {
      let URLCountries = ServiceGetCountries();
      if (URLCountries) {
        GET(URLCountries).then((data) => {
          if (data === '401') {
            getData();
            return '';
          }
          if (data) {
            setCountries(data);
          } else {
            setCountries([]);
          }
        });
      }
    };
    getData();
  }, []);

  useEffect(() => {
    if (props.errors.countryBirth) {
      const styleError = {
        control: (base, state) => ({
          ...base,
          borderColor: 'var(--color-error)',
        }),
        singleValue: (provided) => ({
          ...provided,
          color: 'var(--color-error)',
        }),
      };
      setStyle(styleError);
    } else {
      const styleDefault = {
        control: (base, state) => ({
          ...base,
          borderColor: 'hsl(0, 0%, 80%)',
        }),
        singleValue: (provided) => ({
          ...provided,
          color: 'var(--color-text)',
        }),
      };
      setStyle(styleDefault);
    }
  }, [props.errors.countryBirth]);

  const country = (e) => {
    props.setCountry(e);
  };

  let options = [];
  options.push({ value: '', label: 'País:' });
  countries.map((item) => {
    options.push({ value: item.idState, label: item.name });
    return '';
  });

  const value = options.length === 0 ? { value: '', label: 'País:' } : options.find((obj) => obj.value === props.country);

  const DropdownIndicator = (props) => {
    let icon = 'fas fa-angle-down';
    if (props.selectProps.menuIsOpen) {
      icon = 'fas fa-search';
    }
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <i className={icon}></i>
        </components.DropdownIndicator>
      )
    );
  };

  return (
    <span className="dropdown shadow-0 col-12">
      <Select
        id={props.id}
        options={options}
        isSearchable={true}
        onChange={(e) => country(e.value)}
        placeholder="País:"
        value={value}
        styles={style}
        components={{ DropdownIndicator }}
        isDisabled={props.disabled ?? true}
      />
    </span>
  );
};
