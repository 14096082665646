import React, { Fragment } from 'react';
import { CartGridItem } from './CartGridItem';


export const CartGrid = (props) => {
    
  const handdleDeleteCartItem = (data) => {
    props.delCartItem(data)
  }

    return(
        <Fragment>
            <div>
                {props.items.map((item, index) => (
                    <Fragment key={index}>
                    { index > 0 && <hr/> }
                    <CartGridItem
                        item = { item }
                        delCartItem = {handdleDeleteCartItem}
                    />
                    </Fragment>
                ))}
            </div>
        </Fragment>
    );
}