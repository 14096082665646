import React from 'react';

export const AdressConfirmDelete = ({ deleteAddress, id, modalType }) => {
  return (
    <div
      className='modal fade '
      id={`modalDeleteAddress${modalType}`}
      aria-labelledby='deleteAddress'
      aria-hidden='true'
    >
      <div className='modal-dialog modal-dialog-centered'>
        <div className='modal-content'>
          <div className='modal-body text-center mb-3'>
            <h3 className='text-uppercase px-5 pb-5'>
              ¿Está seguro que desea eliminar esta dirección?
            </h3>
            <button
              type='button'
              className='btn btn-outline-danger w- btn-lg me-2'
              data-bs-dismiss='modal'
              aria-label='Close'
              onClick={(e) => deleteAddress(id)}
            >
              Sí, Eliminar
            </button>
            <button
              type='button'
              className='btn btn-outline-secondary w-auto btn-lg ms-2'
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
