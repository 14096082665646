import React, { useState, useEffect } from 'react';
import Select, { components } from 'react-select';

import { GET } from '../../helpers/APIServices';
import { ServiceNationality } from '../../helpers/getURL';

export const SelectNationality = (props) => {
  const [style, setStyle] = useState('');
  useEffect(() => {
    if (props.errors.nationality) {
      const styleError = {
        control: (base, state) => ({
          ...base,
          borderColor: 'var(--color-error)',
        }),
        singleValue: (provided) => ({
          ...provided,
          color: 'var(--color-error)',
        }),
      };
      setStyle(styleError);
    } else {
      const styleDefault = {
        control: (base, state) => ({
          ...base,
          borderColor: 'hsl(0, 0%, 80%)',
        }),
        singleValue: (provided) => ({
          ...provided,
          color: 'var(--color-text)',
        }),
      };
      setStyle(styleDefault);
    }
  }, [props.errors.nationality]);

  const [nationalities, setNationalities] = useState([]);

  useEffect(() => {
    const URL = ServiceNationality();
    if (URL && props.visible === 'show' && props.mountNationality === true) {
      const getData = () => {
        GET(URL).then((data) => {
          if (data === '401') {
            getData();
            return '';
          }
          props.setMountNationality(false);
          if (data) {
            setNationalities(data);
          } else {
            setNationalities([]);
          }
        });
      };
      getData();
    }
  }, [props.visible, props.mountNationality]);

  const nationality = (e) => {
    props.setNationality(e);
  };

  let options = [];
  options.push({ value: '', label: 'Nacionalidad:' });
  nationalities.map((item) => {
    options.push({ value: item.id, label: item.nacionalidad });
    return '';
  });
  const value = options.length === 0 ? { value: '', label: 'Nacionalidad:' } : options.find((obj) => obj.value === props.nacionalidad);

  const DropdownIndicator = (props) => {
    let icon = 'fas fa-angle-down';
    if (props.selectProps.menuIsOpen) {
      icon = 'fas fa-search';
    }
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <i className={icon}></i>
        </components.DropdownIndicator>
      )
    );
  };

  return (
    <span className="dropdown shadow-0 col-12">
      <Select
        options={options}
        isSearchable={true}
        onChange={(e) => nationality(e.value)}
        placeholder="Nacionalidad:"
        value={value}
        styles={style}
        components={{ DropdownIndicator }}
        isDisabled={props.disabled}
      />
    </span>
  );
};
