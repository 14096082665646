import React, { useEffect, useState } from 'react';
import { AddressBilling } from './AddressBilling';
import { CloseModal, validSession } from '../Utilities/General';
import { GET } from '../../helpers/APIServices';
import { ServiceGetAddressBillingByIdCustomer, ServiceGetGeneralParameterById } from '../../helpers/getURL';
import { getLimitShippingInvoice } from '../../helpers/getConstants';
import ModalAddressBilling from './ModalAddressBilling';

export const AddressBillingAccount = (props) => {
  const [titleModal, setTitleModal] = useState('');
  const [listAddress, setListAddress] = useState([]);
  const [maxAddress, setMaxAddress] = useState(false);
  const [listCities, setListCities] = useState([]);
  const [address, setAddress] = useState({
    id: '00000000-0000-0000-0000-000000000000',
    idCustomer: localStorage.getItem('id'),
    idPais: 'MX',
    idEdo: '',
    idMun: '',
    idCol: '',
    scp: '',
    numero: '',
    numeroInt: '',
    razonSocial: '',
    colonia: '',
    calle: '',
    rfc: '',
    alias: '',
    referencia: '',
    esFisicoMoral: false,
    correo: '',
  });

  const addressClick = () => {
    setTitleModal('Agregar');
    const inputs = [
      'input-Calle-i',
      'input-Numero-i',
      'input-NumeroInt-i',
      'input-Rfc-i',
      'input-RazonSocial-i',
      'input-Scp-i',
      'input-emailBilling',
    ];

    inputs.forEach((item) => {
      const element = document.getElementById(item);
      element.classList.remove('active');
      element.classList.remove('is-invalid');
    });
  };

  const AddressRefresh = (flagDelete) => {
    if (!validSession()) {
      return;
    }
    const idCustomer = localStorage.getItem('id');
    const URL = ServiceGetAddressBillingByIdCustomer(idCustomer);
    GET(URL).then((data) => {
      if (data === '401') {
        AddressRefresh();
        return '';
      }
      if (flagDelete) {
        CloseModal('modalDeleteAddressBilling');
      }
      if (data) {
        setListAddress(data);
        const id = getLimitShippingInvoice();
        const URLParameter = ServiceGetGeneralParameterById(id);

        GET(URLParameter).then((response) => {
          if (response === '401') {
            AddressRefresh();
            return '';
          }
          if (response && response.valor !== undefined && !isNaN(response.valor)) {
            setMaxAddress(parseInt(data.length) >= parseInt(response.valor));
          } else {
            setMaxAddress(false);
          }
        });
      } else {
        setListAddress([]);
      }
    });
  };

  useEffect(() => {
    if (props.visible === 'show') {
      const Address = () => {
        const idCustomer = localStorage.getItem('id');
        if (!validSession()) {
          return;
        }
        const URL = ServiceGetAddressBillingByIdCustomer(idCustomer);
        GET(URL).then((data) => {
          if (data === '401') {
            Address();
            return '';
          }
          if (data) {
            setListAddress(data);

            const id = getLimitShippingInvoice();
            const URLParameter = ServiceGetGeneralParameterById(id);
            GET(URLParameter).then((response) => {
              if (response === '401') {
                Address();
                return '';
              }
              if (response && response.valor !== undefined && !isNaN(response.valor)) {
                setMaxAddress(parseInt(data.length) >= parseInt(response.valor));
              } else {
                setMaxAddress(false);
              }
            });
          }
        });
      };

      Address();
    }
  }, [props.visible]);

  return (
    <div className={'container__addressAccount ' + props.visible}>
      <div className="border-title">
        <div>Datos fiscales</div>
      </div>
      <AddressBilling
        listAddress={listAddress}
        setAddress={setAddress}
        address={address}
        AddressRefresh={AddressRefresh}
        modal={'modalAddressInvoice'}
        setTitleModal={setTitleModal}
      />
      <div className="mb-3 row inputs-form justify-content-center">
        <div className="col-12 text-center">
          <button
            disabled={maxAddress}
            type="button"
            className="btn btn-outline-secondary btn-profile"
            data-bs-toggle="modal"
            data-bs-target="#modalAddressInvoice"
            onClick={(e) => addressClick()}
          >
            Agregar datos fiscales
          </button>
        </div>
      </div>
      <ModalAddressBilling
        setAddress={setAddress}
        address={address}
        AddressRefresh={AddressRefresh}
        setListCities={setListCities}
        listCities={listCities}
        titleModal={titleModal}
      />
    </div>
  );
};
