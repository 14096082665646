import { createContext, useState } from 'react'

export const TokenStatusContext = createContext(null);

export const TokenStatusProvider = ({ children }) => {
  const [tokenStatus, setTokenStatus] = useState(0);
  const [onTokenValidate, setOnTokenValidate] = useState(false);
  const [mercadoPagoInitialized, setMercadoPagoInitialized] = useState(false);
  const [paymentFormError, setPaymentFormError] = useState(false);
  const [renderMpPaymentForm, setRenderMpPaymentForm] = useState(false);
  const [tokenAmount, setTokenAmount] = useState(0);
  return (
    <TokenStatusContext.Provider value={{
      tokenStatus,
      setTokenStatus,
      onTokenValidate,
      setOnTokenValidate,
      mercadoPagoInitialized,
      setMercadoPagoInitialized,
      paymentFormError,
      setPaymentFormError,
      renderMpPaymentForm,
      setRenderMpPaymentForm,
      tokenAmount,
      setTokenAmount,
    }}>
      {children}
    </TokenStatusContext.Provider>
  );
};
