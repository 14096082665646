import React, { useState, useEffect } from 'react';
import { GET } from '../../../helpers/APIServices';
import ModalAddressBilling from '../ModalAddressBilling';
import { resetRadios, CloseModal, OpenMessage } from '../../Utilities/General';
import { ServiceGenericParameters, ServiceGetAddressBillingByIdCustomer } from '../../../helpers/getURL';
import { getLimitShippingInvoice } from '../../../helpers/getConstants';

export const AddressBilling = (props) => {
  let [step, setStep] = useState(props.step + 1);
  const idCustomer = localStorage.getItem('id');
  const URL = ServiceGetAddressBillingByIdCustomer(idCustomer);
  const [data, setData] = useState([]);
  const [disabledBtn, setDisabledBtn] = useState(true);
  const [listAddress, setListAddress] = useState([]);
  const [listCities, setListCities] = useState([]);
  const [address, setAddress] = useState({
    id: '00000000-0000-0000-0000-000000000000',
    idCustomer: localStorage.getItem('id'),
    idPais: 'MX',
    idEdo: '',
    idMun: '',
    idCol: '',
    scp: '',
    numero: '',
    numeroInt: '',
    razonSocial: '',
    colonia: '',
    calle: '',
    rfc: '',
    alias: '',
    referencia: '',
    esFisicoMoral: false,
  });

  const AddressRefresh = () => {
    GET(URL).then((data) => {
      if (data) {
        setListAddress(data);
      }
    });
  };

  useEffect(() => {
    if (props.lastStep && props.visible === 'show') {
      setStep(8);
      resetRadios('AddressMailInvoiceRadios');
      setDisabledBtn(true);
    }
  }, [props.lastStep, props.visible]);

  useEffect(() => {
    if (props.visible === 'show') {
      GET(URL).then((data) => {
        if (data) {
          setListAddress(data);
        }
      });
    }
  }, [URL, props.visible]);

  const clickRadio = (value, data, rfc, title) => {
    const selectItem = {
      addressInvoice: {
        id: value,
        name: data,
        rfc: rfc,
        title: title,
      },
    };
    setData(selectItem);
    setDisabledBtn(false);
  };

  const NDirectionsValidation = () => {
    const URL = ServiceGenericParameters(getLimitShippingInvoice());
    GET(URL).then((data) => {
      if (data) {
        if (listAddress.length >= data.value) {
          CloseModal('modalAddressInvoice');
          OpenMessage('warning', 'No se pueden registrar más de ' + data.value + ' direcciones de envío');
        }
      } else {
        OpenMessage('warning', 'No es posible registrar una dirección de envío');
      }
    });
  };

  return (
    <div className={'card ' + props.visible}>
      <div className="card-header text-center">
        <h3>Factura</h3>
      </div>
      <div className="card-body">
        <div className="row mb-6">
          <div className="col-12">
            <h5 className="card-text card-title">Seleccione una dirección de facturación de las opciones disponibles:</h5>
          </div>
        </div>
        <div className="row mb-2"></div>
        {listAddress.map((item, i) => {
          const title = `${item.rfc}, ${item.razonSocial}`;
          const value = `${item.calle} No. Ext. ${item.numero} ${item.numeroInt !== '' ? `No. Int. ${item.numeroInt}` : ''}, ${
            item.colonia
          }, C.P. ${item.scp}, ${item.municipio}, ${item.estado}, ${item.idPais}`;
          return (
            <div className="row mb-2" key={i}>
              <div className="col-12 title-rfc">
                <label className="form-check-label" htmlFor={item.id}>
                  <p>{title}</p>
                </label>
              </div>
              <div className="col-12">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="AddressMailInvoiceRadios"
                    id={item.id}
                    value={item.id}
                    onClick={(e) => clickRadio(e.target.value, value, item.rfc, title)}
                  />
                  <label className="form-check-label" htmlFor={item.id}>
                    <p>{value}</p>
                  </label>
                </div>
              </div>
            </div>
          );
        })}
        <div className="row mb-2">
          <div className="col-12 text-center">
            <button
              type="button"
              className="btn btn-lg btn-outline-secondary btn-profile"
              data-bs-toggle="modal"
              data-bs-target="#modalAddressInvoice"
              onClick={() => NDirectionsValidation()}
            >
              Agregar datos fiscales
            </button>
          </div>
        </div>
        <br />
        <div className="row mb-2">
          <div className="col-6 text-end">
            <button
              type="button"
              className="btn btn-lg btn-outline-danger"
              onClick={(e) => props.eventButton(true, step, data)}
              disabled={disabledBtn}
            >
              Continuar
            </button>
          </div>
          <div className="col-6 text-start">
            <button type="button" className="btn btn-lg btn-outline-secondary" onClick={(e) => props.eventButton(true, step - 2, data)}>
              Regresar
            </button>
          </div>
        </div>
      </div>
      <ModalAddressBilling
        setAddress={setAddress}
        address={address}
        setListCities={setListCities}
        listCities={listCities}
        AddressRefresh={AddressRefresh}
      />
    </div>
  );
};
