const PAGE = window.innerHeight - 100;
export const setSlideIn = (selector) => {
  const element = document.querySelector(selector);
  if (element) {
    const elementTop = element.getBoundingClientRect().top;
    if (!element.classList.contains('slidein')) {
      element.style = 'opacity: 0';
      //element.classList.remove('slidein');
      if (elementTop <= PAGE) {
        element.style = '';
        element.classList.add('slidein');
      }
    }
  }
};
