import React, {  useReducer, useEffect, createContext } from "react";
let reducer = ( validate, newValidate ) => {
  return {...validate, ...newValidate}
}
const intitialState = {
  valid: false,
  registeredAuctions: [],
  idAuction: "",
  nameAuction: "",
  dateAuction: "",
  palletAuction: "",
  idPallet: "",
}
const localState = JSON.parse(localStorage.getItem("dataContent"));
export const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [validate, setValidate] = useReducer(reducer,localState || intitialState);
  useEffect(() => {
    localStorage.setItem("dataContent", JSON.stringify(validate));
  }, [validate]);
  return (
    <DataContext.Provider
      value={{
        validate,
        setValidate,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
