import React, { useState, useEffect } from 'react';
import { getImgDefault } from '../../helpers/getConstants';

export const Carousel = (props) => {
  const [dataCarousel, setDataCarousel] = useState([]);
  const { watchLot, carouselMovil, announcement } = props;
  useEffect(() => {
    if (props.dataCarousel && props.dataCarousel.length > 0) {
      let carousel = props.dataCarousel;
      setDataCarousel(carousel);
      const index = carousel.findIndex((item) => item.statusSubasta.toUpperCase() === 'ACTUAL');
      setTimeout(() => {
        let container = document.getElementById('carousel-lots');
        container.scrollLeft -= (index - 1) * 175;
        if (carousel[1].statusSubasta.toUpperCase() === 'ACTUAL') {
          container.scrollLeft += 30;
        } else if (index > 1) {
          container.scrollLeft += (index - 1) * 170;
        }
      }, 800);
    }
  }, [props.dataCarousel]);
  const titleStatus = (status, order) => {
    let title = '';
    switch (status) {
      case 'DISPONIBLE':
      case 'ACTUAL':
        title = `LOTE ${order}`;
        break;
      case 'GANADO':
      case 'RETIRADO':
        title = status;
        break;
      case 'ADJUDICADO':
      case 'PERDIDO':
      case 'PAGADO':
        title = 'ADJUDICADO';
        break;
      default:
        title = '';
        break;
    }
    return title;
  };

  return (
    <div className="row container__streaming--carousel" id="carousel-lots">
      {dataCarousel.map((item, i) => {
        let img = item.imagesList && item.imagesList[0] ? item.imagesList[0] : getImgDefault();
        const title = titleStatus(item.statusSubasta.toUpperCase(), item.order);
        let headerClass;
        let borderClass;

        switch (item.statusSubasta.toUpperCase()) {
          case 'ADJUDICADO':
          case 'PERDIDO':
          case 'PAGADO':
            headerClass = 'header__adjudge';
            borderClass = 'orange__border';
            break;
          case 'GANADO':
            headerClass = 'header__win';
            borderClass = 'blue__border';
            break;
          case 'DISPONIBLE':
            headerClass = 'card-header-next';
            borderClass = 'gray__border';
            break;
          default:
            headerClass = '';
            borderClass = 'gray__border';
            break;
        }

        if (item.statusSubasta.toUpperCase() === 'ACTUAL') {
          return (
            <div
              className={`col-3 mt-4 container__streaming--carousel__item_now ${
                i === dataCarousel.length - 1 && carouselMovil ? 'last-lot' : ''
              }`}
              key={i}
            >
              <div className="card card__next" onClick={(e) => watchLot(item.idLot)}>
                <div className="row header-now">
                  <h3 className="card-text text-center">{announcement ? 'Preparando' : 'Ahora'}</h3>
                </div>
                <div className="row">
                  <div className={`${carouselMovil ? 'col-12' : 'col-4 ps-4'}`}>
                    <img src={img} className="card-img-top img-now" alt={item.name} width="75px" height="75px" />
                  </div>
                  <div className={`${carouselMovil ? 'col-12 container-titles' : 'col-8'}`}>
                    <div className="card-body">
                      <p className={`text-center card__next--title fw-bold ${carouselMovil ? 'fs-5' : 'fs-4'} `}>{title}</p>
                      <p
                        data-bs-toggle="tooltip"
                        data-bs-placement="bottom"
                        title={item.description}
                        className={`card-text text-center fw-bold desc-ellipsis ${carouselMovil ? 'fs-6' : 'fs-4'}`}
                      >
                        {item.description}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        } else {
          let favorite = '';
          if (item.favorite === true) {
            favorite = 'fas fa-star bookmark';
          }
          return (
            <div className="col-1 mt-4 text-center container__streaming--carousel__item" key={i}>
              <div className="card card__carousel" onClick={(e) => watchLot(item.idLot)}>
                <div className={`card-header ${headerClass}`}>{title}</div>
                <div className={`card-body ${borderClass}`}>
                  <i className={favorite}></i>
                  <img src={img} className="card-img-top" alt={item.name} width="75px" height="75px" />
                  {carouselMovil && (
                    <div>
                      <p className="card-text text-center card__next--title fw-bold fs-5">{title}</p>
                      <p
                        data-bs-toggle="tooltip"
                        data-bs-placement="bottom"
                        title={item.description}
                        className="card-text text-center fw-bold fs-6 desc-ellipsis"
                      >
                        {item.description}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        }
      })}
    </div>
  );
};
