import React, { useEffect } from 'react';
import { getAvailableLot, getRetiredLot } from '../../helpers/getConstants';
import { formatCurrency } from '../Utilities/General';

export const Offer = ({
  offerLot,
  format,
  offer,
  setOffer,
  offerSelected,
  setOfferSelected,
  confirm,
  setConfirm,
  handleScroll,
  handleOffer,
  loading,
  handleConfirm,
  idType,
  idStatus,
  auctionTypes,
  auctionStatus,
  idLoteStatus,
  addCartItem,
  enablePostSale,
  clicked,
  setClicked,
  idLot,
}) => {
  const available = getAvailableLot();
  const retired = getRetiredLot();
  useEffect(() => {
    const onClick = (e) => {
      setClicked('');
    };
    document.addEventListener('click', onClick);

    if (clicked === idLot) {
      setOffer(true);
      setConfirm(false);
    } else if (clicked !== idLot) {
      setOffer(false);
    }
  }, [clicked, idLot, setOffer, setConfirm, setClicked]);

  const hideDropdown = (e, item, idLot) => {
    const dropdown = 'dropdown_' + idLot;
    const ul = 'ul_dropdown_' + idLot;
    const elementDrop = document.getElementById(dropdown);
    const elementUl = document.getElementById(ul);
    elementDrop.classList.remove('show');
    elementUl.classList.remove('show');
    setOfferSelected(item.precio);
    setClicked(idLot);
    e.stopPropagation();
  };

  return (
    <div className="row justify-content-center ">
      {idType === auctionTypes.idGoldenMinutes &&
        idStatus === auctionStatus.directSale &&
        (idLoteStatus === available || idLoteStatus === retired) &&
        enablePostSale === true && (
          <div className="col-12">
            <button
              className={`btn button-danger-offerted ${format !== 1 ? ' button-eye-2' : ' button-eye'} `}
              onClick={(e) => addCartItem()}
              disabled={loading}
            >
              <i className="fas fa-shopping-cart"></i>
              <span> Comprar</span>
            </button>
          </div>
        )}
      {(confirm && idType === auctionTypes.idGoldenMinutes && idStatus === auctionStatus.execution) ||
      (confirm &&
        idType === auctionTypes.idStreaming &&
        idLoteStatus === available &&
        (idStatus === auctionStatus.scheduled || idStatus === auctionStatus.execution)) ? (
        <div className="col-12">
          <button
            className={`btn button-danger-offerted ${format !== 1 ? ' button-eye-2' : ' button-eye'} `}
            disabled={loading}
            onClick={(e) => [setOffer(true), setConfirm(false)]}
          >
            <i className="fas fa-clock"></i>
            <span> Ofertado</span>
          </button>
        </div>
      ) : null}

      <div className={`align-buttons col-12`}>
        {(!confirm && idType === auctionTypes.idGoldenMinutes && idStatus === auctionStatus.execution) ||
        (!confirm &&
          idType === auctionTypes.idStreaming &&
          idLoteStatus === available &&
          (idStatus === auctionStatus.scheduled || idStatus === auctionStatus.execution)) ? (
          <button
            className={`btn ${!offer ? 'btn-outline-danger' : ' button-danger-offerted'} button-eye align-buttons-items`}
            onClick={(e) => [!offer ? handleOffer(e) : handleConfirm(), setClicked(idLot)]}
            disabled={loading}
          >
            {!offer ? <img src="./resources/Ofertar@2x.png" alt="" width="20px" /> : null}
            <strong>{!offer ? ' Ofertar' : ' Confirmar'}</strong>
          </button>
        ) : null}
        {(!confirm && offer && idType === auctionTypes.idGoldenMinutes && idStatus === auctionStatus.execution) ||
        (!confirm &&
          offer &&
          idType === auctionTypes.idStreaming &&
          (idStatus === auctionStatus.scheduled || idStatus === auctionStatus.execution)) ? (
          <div className="dropdown shadow-0 align-buttons-items buttons-offer">
            <button
              className={`btn dropdown-toggle ${format !== 1 ? 'drop-element-offer-2' : 'drop-element-offer'} shadow-0`}
              data-mdb-toggle="dropdown"
              id={'dropdown_' + idLot}
              aria-expanded="false"
              onClick={(e) => e.stopPropagation()}
            >
              {formatCurrency(offerSelected)}
            </button>
            <ul
              id={'ul_dropdown_' + idLot}
              className="dropdown-menu drop-scroll"
              aria-labelledby={'dropdown_' + idLot}
              onScroll={handleScroll}
            >
              {offerLot.map((item, i) => {
                return (
                  <li key={i}>
                    <button
                      className={`dropdown-item  ${format !== 1 ? 'drop-items-2' : 'drop-items'} `}
                      onClick={(e) => hideDropdown(e, item, idLot)}
                    >
                      {formatCurrency(item.precio)}
                    </button>
                  </li>
                );
              })}
            </ul>
          </div>
        ) : null}
      </div>
    </div>
  );
};
