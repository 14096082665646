import React from 'react';
import { useHistory } from 'react-router-dom';
import { CloseModalIcon } from '../Home/CloseModalIcon';
import { CloseModal } from '../Utilities/General';

export const OfferSuccess = () => {
  const history = useHistory();

  const closeOfferSuccess = () => {
    const redirect = document.getElementById('redirectHome').value;
    CloseModal('modalOfferSuccess');
    if (redirect === 'true') {
      history.push('/?home=true');
    }
  };

  return (
    <div
      className="modal fade "
      id="modalOfferSuccess"
      aria-labelledby="offerSuccessModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header justify-content-end border-0">
            <CloseModalIcon onHandleClick={() => closeOfferSuccess()} />
          </div>
          <div className="modal-body text-center">
            <div className="text-center closing-accept">
              <img src="./resources/logo.svg" alt="logo MLS" className="img-fluid" width="50%" />
            </div>
            <div className="text-center closing-accept" id="textMessageSection"></div>
            <input type="hidden" id="redirectHome" value="false" />
            <button
              type="button"
              className="btn btn-outline-secondary btn-lg success-offer-items closin-accept"
              data-bs-dismiss="modalOfferSuccess"
              aria-label="Close"
              onClick={(e) => closeOfferSuccess()}
            >
              Aceptar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
