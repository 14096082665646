import React, { useEffect, useState } from 'react';

import { formatCurrency, OpenMessage, CloseBackdrops, ResetStyleBody } from '../../Utilities/General';
import { getImgDefault } from '../../../helpers/getConstants';

export const ShoppingCart = (props) => {
  const [items, setItems] = useState([]);
  const detail = props.data;

  useEffect(() => {
    CloseBackdrops();
    ResetStyleBody();
    const consiga = detail ? detail.filter((x) => x.consigna) : [];
    const sap = detail ? detail.filter((x) => !x.consigna && !x.consignaSAP) : [];
    const consignaSap = detail ? detail.filter((x) => !x.consigna && x.consignaSAP) : [];

    let data = [];

    if (sap.length > 0) {
      data.push({
        title: 'Lotes Gama',
        details: sap,
        consigna: false,
        consignaSAP: false,
      });
    }

    if (consiga.length > 0) {
      data.push({
        title: 'Lotes Plus',
        details: consiga,
        consigna: true,
        consignaSAP: false,
      });
    }
    
    if (consignaSap.length > 0) {
      data.push({
        title: 'Lotes Plus CS',
        details: consignaSap,
        consigna: false,
        consignaSAP: true,
      });
    }

    setItems(data);
    if (data.length > 1) { 
      OpenMessage('warning', 'No es posible seleccionar lotes Plus, lotes Gama y lotes Plus CS en una misma orden. Deben pagarse por separado.');
    }
  }, [detail]);

    useEffect(() => {
      if(items.length === 1 && items[0].details.length === 1){
        props.addAllCart(true, items[0].consigna, items[0].consignaSAP, 'checkAll_0')
      }
    }, [items])
    
  return (
    <div className={props.visible}>
      {items.map((item, i) => {
        const idCheckAll = 'checkAll_' + i;
        return (
          <div key={i}>
            <div className="row inputs-form">
              <div className="col-12 mb-3">
                <div className="card">
                  <div className="card-header text-center text-uppercase">
                    <h3>{item.title}</h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-3 inputs-form">
              <div className="col-12">
                <div className="card mb-3">
                  <div className="row g-0 align-items-center">
                    <div className="col-1 text-center">
                      <input
                        id={idCheckAll}
                        className="checkAll form-check-input"
                        type="checkbox"
                        onChange={(e) => props.addAllCart(e.target.checked, item.consigna, item.consignaSAP, idCheckAll)}
                      />
                    </div>
                    <div className="col-2">
                      <p className="card-text text-uppercase text-center">Imagen</p>
                    </div>
                    <div className="col-9">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-3">
                            <p className="card-text text-uppercase">No. Lote</p>
                          </div>
                          <div className="col-6">
                            <p className="card-text text-uppercase">Descripción</p>
                          </div>
                          <div className="col-3">
                            <p className="card-text text-uppercase">Precio</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {item.details.map((element, j) => {
              return (
                <div key={j}>
                  <div className="mb-3 row inputs-form">
                    <div className="col-12">
                      <div className="card mb-3">
                        <div className="row g-0 align-items-center">
                          <div className="col-1 text-center">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id={element.idLote}
                              onChange={(e) => props.addCart(element.idLote, e.target.checked, element.precioSalida, item.consigna, item.consignaSAP, idCheckAll)}
                            />
                          </div>
                          <div className="col-2">
                            <img
                              src={element.images[0] ? element.images[0] : getImgDefault()}
                              className="img-fluid rounded-start"
                              alt={element.descripcionLote}
                            />
                          </div>
                          <div className="col-9">
                            <div className="card-body">
                              <div className="row">
                                <div className="col-3">
                                  <p className="card-text">{element.lot}</p>
                                </div>
                                <div className="col-6">
                                  <p className="card-text">
                                    {element.nombreLote} <br /> {element.descripcionLote}
                                  </p>
                                </div>
                                <div className="col-3">
                                  <small className="text-muted">{formatCurrency(element.precioSalida)}</small>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};
