import React from 'react';
import { CloseModal } from '../../Utilities/General';

import { CustomerData } from './CustomerData';

export const ModalCustomerData = (props) => {
  const clearForm = (e) => {
    props.updateData();
    CloseModal('modalCustomerData');
  };

  return (
    <div className="modal container__modaladress" id="modalCustomerData" data-bs-backdrop="static" data-bs-keyboard="false">
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header modal-header-login ">
            <button type="button" className="btn-close" aria-label="Close" onClick={(e) => clearForm(e)}></button>
          </div>
          <div className="modal-header modal-header-login ">
            <strong>Complete su informacion personal para continuar con su proceso</strong>
          </div>
          <div className="modal-body">
            <CustomerData visible={props.visible} parent="Modal" close={clearForm} />
          </div>
          <div className="modal-footer">
            <div className="container-fluid">
              <div className="mb-3 row inputs-form">
                <div className="col-7 text-end"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
