import React, { useState, useEffect } from 'react';
import { GET } from '../../../helpers/APIServices';
import { getDeliveryLimitID, getActivateMessagingID, getIdGoldenMinutes, getIdStreaming } from '../../../helpers/getConstants';
import { ServiceGenericParameters } from '../../../helpers/getURL';
import { resetRadios } from '../../Utilities/General';

export const Send = (props) => {
  const [disabledBtn, setDisabledBtn] = useState(true);
  const [step, setStep] = useState(0);
  const [data, setData] = useState([]);
  const [message, setMessage] = useState('');
  const [officeDelivery, setOfficeDelivery] = useState(true);
  const idGoldenMinutes = getIdGoldenMinutes();
  const  { idTypeAuction = '' } = props;

  useEffect(() => {
    setStep(props.step + 1); //siempre es el siguiente paso
  }, [props.step]);

  useEffect(() => {
    const deliveryLimitID = getDeliveryLimitID();
    const URLDeliveryLimit = ServiceGenericParameters(deliveryLimitID);
    const getData = () => {
      GET(URLDeliveryLimit).then((data) => {
        if (data === '401') {
          getData();
          return '';
        }
        if(data.value){
          setMessage(data.value);
        }
      });
    };
    getData();
  }, [])

  useEffect(() => {
    const activateMessagingID = getActivateMessagingID();
    const URLActivateMessagingID = ServiceGenericParameters(activateMessagingID);
    const getActivate = () => {
      GET(URLActivateMessagingID).then((data) => {
        if (data === '401') {
          getActivate();
          return '';
        }
        if(data.value){
          const isOffice = data.value.trim().toLowerCase() === "true" ? true : false;
          if(!isOffice){
            clickRadio("2", 'Mensajería')
          }
          setOfficeDelivery(isOffice);
        }
      });
    };
    getActivate();
  }, [])
  
  useEffect(() => {
    if (props.lastStep && props.visible === 'show') {
      resetRadios('SendRadios');
      setDisabledBtn(true);
      setStep(props.step + 1);

    }
  }, [props.step, props.lastStep, props.visible]);

  const clickRadio = (value, name) => {
    const send = {
      send: {
        type: value,
        name: name,
      },
    };
    setData(send);
    if (parseInt(value) === 1) {
      if (props.lastStep) {
        setStep(8); //Ultima pantalla
      } else {
        setStep(5); //Facturacion
      }
    } else {
      setStep(props.step + 1);
    }
    props.setOrigen(2);
    props.setOrigen2(2);
    setDisabledBtn(false);
  };

  return (
    <div className={'card ' + props.visible}>
      <div className="card-header text-center">
        <h3>Método de envío</h3>
      </div>
      <div className="card-body">
        <div className="row mb-6">
          <div className="col-12">
            <h5 className="card-text card-title">Seleccione un método de envío de las opciones disponibles:</h5>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-12">
            {((officeDelivery && idTypeAuction === idGoldenMinutes) || idTypeAuction !== idGoldenMinutes) && <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="SendRadios"
                id="OfficeRadios"
                value="1"
                onClick={(e) => clickRadio(e.target.value, 'Entrega en oficina')}
                checked={data?.send?.type === "1" ? true : false}
                onChange={(e)=> {}}
              />
              <label className="form-check-label" htmlFor="OfficeRadios">
                Entrega en oficina
              </label>
            </div>}
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="SendRadios"
                id="MailRadios"
                value="2"
                onClick={(e) => clickRadio(e.target.value, 'Mensajería')}
                checked={data?.send?.type === "2" ? true : false}
                onChange={(e)=> {}}
              />
              <label className="form-check-label" htmlFor="MailRadios">
                Mensajería
              </label>
            </div>
          </div>
        </div>
        {data?.send?.type === "2" && <div className="row mb-2 px-5">
          <div className="col-12">
            <h5>{message}. </h5>
          </div>
        </div>}
        <div className="row mb-2">
          <div className="col-6 text-end">
            <button
              type="button"
              className="btn btn-lg btn-outline-danger"
              disabled={disabledBtn}
              onClick={(e) => props.eventButton(true, step, data)}
            >
              Continuar
            </button>
          </div>
          <div className="col-6 text-start">
            <button type="button" className="btn btn-lg btn-outline-secondary" onClick={(e) => props.eventButton(false, 1, data)}>
              Regresar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
