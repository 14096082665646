import React from 'react';

function CarouselIndicators(props) {
  return (
    <button
      type="button"
      data-bs-target={'#' + props.idCarousel}
      data-bs-slide-to={props.slide}
      className={'rounded-circle ' + props.nameClass}
      aria-current={props.nameClass === 'active' ? 'true' : ''}
    ></button>
  );
}

export default CarouselIndicators;
