import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';

import { DataContext } from '../../context/DataContext';
import { ButtonRegister } from '../Register/ButtonRegister';
import { ButtonToStreaming } from '../Register/ButtonToStreaming';
import { WatchCatalog } from '../Register/WatchCatalog';
import { convertToDate } from '../Utilities/General';
import { getIdStreaming, getExecution } from '../../helpers/getConstants';

export const AuctionsCarousel = (props) => {
  const [state, setState] = useState({
    name: '',
    idAuction: '',
    auctionDate: new Date(),
    pallet: 0,
    isStreaming: false,
    idPallet: '',
    idType: '',
    idStatus: '',
    title: '',
  });

  const { userStatusLastCatalog } = props;
  const {
    validate: { registeredAuctions },
  } = useContext(DataContext);

  const idStreaming = getIdStreaming();
  const execution = getExecution();

  const getIsStreaming = (idAuction) => {
    const auctionValue = registeredAuctions.find((auction) => auction.idAuction === idAuction);
    if (auctionValue) {
      setState((prevState) => ({
        ...prevState,
        pallet: parseInt(auctionValue.pallet, 10),
        isStreaming: auctionValue.streaming,
        idPallet: auctionValue.idPallet,
      }));
    }
  };

  useEffect(() => {
    const data = props.data;
    if (data) {
      getIsStreaming(data.id);
      setState((prevState) => ({
        ...prevState,
        name: data.id ? data.name : '',
        idAuction: data.id,
        auctionDate: convertToDate(data.auctionDate),
        idType: data.idType,
        idStatus: data.idStatus,
      }));
    }
  }, [props]);

  if (state.idAuction) {
    return (
      <div className="container-fluid container-auctions mb-4 pb-4 container__auctions--types">
        <div className="row">
          <div className="col-12">
            <h2>
              <span className="container-auctions--title">Próxima</span> subasta
            </h2>
          </div>
        </div>

        <div className="row container-auctions-row">
          <div className="col-12 text-center-mobile">
            <span className="container-auctions--subtitle">{state.name}</span>
          </div>
        </div>

        <div className="row container-auctions-row">
          <div className="col-12 text-center-mobile">
            <span className="container-auctions--subtitle2">{state.auctionDate}</span>
          </div>
        </div>

        <div className="row container-auctions-row">
          <div className="col-12 mt-2 mb-2 text-center-mobile">
            <span className="container-auctions--description">
              Contaremos con una gran variedad de piezas únicas en distintas categorías:
            </span>
          </div>
        </div>

        <div className="row container-auctions-row">
          <div className="col-12 text-center-mobile">
            {state.pallet > 0 && state.idType === idStreaming && state.idStatus === execution && (
              <ButtonToStreaming streaming={state.isStreaming} idAuction={state.idAuction} userStatusLastCatalog={userStatusLastCatalog} />
            )}
            {state.pallet <= 0 && <ButtonRegister idAuction={state.idAuction} nameAuction={state.name} dateAuction={state.auctionDate} />}
          </div>
        </div>

        <div className="row container-auctions-row">
          <div className="col-12 mb-5 text-center-mobile">
            <WatchCatalog idAuction={state.idAuction} modal={false} clasStyle={'watch-catalog'} />
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div id="carouselAuction" className="carousel carousel-dark slide" data-bs-ride="carousel">
              <div className="carousel-indicators auctionsCarousel-indicators">
                <button
                  type="button"
                  data-bs-target="#carouselAuction"
                  data-bs-slide-to="0"
                  className="rounded-circle active"
                  aria-current="true"
                ></button>

                <button
                  type="button"
                  data-bs-target="#carouselAuction"
                  data-bs-slide-to="1"
                  className="rounded-circle"
                  aria-current="false"
                ></button>

                <button
                  type="button"
                  data-bs-target="#carouselAuction"
                  data-bs-slide-to="2"
                  className="rounded-circle"
                  aria-current="false"
                ></button>
              </div>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <div className="row justify-content-center">
                    <div className="col-8">
                      <Link to={`/catalogo?id=${state.idAuction}&categoria=02`}>
                        <div className="card">
                          <div className="container-auctions-items--img">
                            <img src="resources/SV-343.jpg" className="card-img-top" alt="..." />
                          </div>
                          <div className="card-body">
                            <h5 className="card-title container-auctions-items--title">Relojes</h5>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="carousel-item">
                  <div className="row justify-content-center">
                    <div className="col-8">
                      <Link to={`/catalogo?id=${state.idAuction}&categoria=01`}>
                        <div className="card">
                          <div className="container-auctions-items--img">
                            <img src="resources/SV-010.jpg" className="card-img-top" alt="..." />
                          </div>
                          <div className="card-body">
                            <h5 className="card-title container-auctions-items--title">Alhajas</h5>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="carousel-item">
                  <div className="row justify-content-center">
                    <div className="col-8">
                      <Link to={`/catalogo?id=${state.idAuction}&categoria=03`}>
                        <div className="card">
                          <div className="container-auctions-items--img">
                            <img src="resources/SV-231.jpg" className="card-img-top" alt="..." />
                          </div>
                          <div className="card-body">
                            <h5 className="card-title container-auctions-items--title">Artículos personales</h5>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <button className="carousel-control-prev" type="button" data-bs-target="#carouselAuction" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#carouselAuction" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="container-fluid container-auctions mb-4 pb-4 container__auctions--types">
      <div className="row">
        <div className="col-12 text-center">
          <h2>
            <span className="container-auctions--title"> Espera nuestras próximas Subastas.</span>
          </h2>
        </div>
      </div>
    </div>
  );
};
