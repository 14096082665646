import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { SummaryOrder } from './SummaryOrder';
import { OrderDetail } from './OrderDetail';

import { GET } from '../../../helpers/APIServices';
import { ServiceGetOrderById, ServiceInfoAddressShippingByCustomer, ServiceInfoAddressBillingByCustomer } from '../../../helpers/getURL';
import { getGuidEmpty } from '../../../helpers/getConstants';

export const OrderDetailGrid = (props) => {
  const [data, setData] = useState();
  const [delivery, setDelivery] = useState('');
  const [billing, setBilling] = useState('');
  const [billingTitle, setBillingTitle] = useState('');
  const [address, setAddress] = useState('Entrega en oficina');
  const [payment, setPayment] = useState('');
  const [lots, setLots] = useState([]);
  const [cardClass, setCardClass] = useState('hide');
  const [consiga, setConsiga] = useState(false);
  const { search } = useLocation();
  const id = new URLSearchParams(search).get('id');
  const idOrder = props.location.state ? props.location.state.idOrder : id;

  useEffect(() => {
    if (id) {
      setCardClass('');
    }
    if (idOrder) {
      const getData = () => {
        const URL = ServiceGetOrderById(idOrder);
        GET(URL).then((data) => {
          if (data === '401') {
            getData();
            return '';
          }
          if (data) {
            setData(data);
            setDelivery(data.deliveryServiceName);
            setConsiga(data.consignation);
            setPayment(data.typePayment);

            const GuidEmpty = getGuidEmpty();
            if (data.idDeliveryAddress && data.idDeliveryAddress !== GuidEmpty) {
              const URLAddressShipping = ServiceInfoAddressShippingByCustomer(data.idDeliveryAddress);
              GET(URLAddressShipping).then((addressShipping) => {
                if (addressShipping === '401') {
                  getData();
                  return '';
                }
                if (addressShipping && addressShipping.length > 0) {
                  const address = addressShipping[0];
                  const value = `${address.calle} No. Ext. ${address.numero} ${
                    address.numeroInt !== '' ? `No. Int. ${address.numeroInt}` : ''
                  }, ${address.colonia}, C.P. ${address.scp}, ${address.municipio}, ${address.estado}, ${address.idPais}`;
                  setAddress(value);
                }
              });
            }
            if (data.idBillingClient !== GuidEmpty) {
              const URLAddressBilling = ServiceInfoAddressBillingByCustomer(data.idBillingClient);
              GET(URLAddressBilling).then((addressBilling) => {
                if (addressBilling === '401') {
                  getData();
                  return '';
                }
                if (addressBilling && addressBilling.length > 0) {
                  const address = addressBilling[0];
                  const title = `${address.rfc}, ${address.razonSocial}`;
                  const value = `${address.calle} No. Ext. ${address.numero} ${
                    address.numeroInt !== '' ? `No. Int. ${address.numeroInt}` : ''
                  }, ${address.colonia}, C.P. ${address.scp}, ${address.municipio}, ${address.estado}, ${address.idPais}`;
                  setBilling(value);
                  setBillingTitle(title);
                }
              });
            }
            if (data.idLotList && data.idLotList.length > 0) {
              setLots(data.idLotList);
            }
          }
        });
      };
      getData();
    }
  }, [idOrder, id]);

  return (
    <div className="container-fluid container__shoppingCart">
      <div className={'card mb-5 ' + cardClass}>
        <div className="card-body">
          <div className="row justify-content-center">
            <div className="col-2 text-end">
              <i className="fas fa-cart-arrow-down"></i>
            </div>
            <div className="col-10">
              <div className="row">
                <div className="col-12">
                  <label className="title__thanks">Gracias ha realizado su pedido</label>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <label className="subtile__thanks">En breve recibirá un correo electrónico con la información de su pedido</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-12 col-md-9 mb-4">
          <OrderDetail
            delivery={delivery}
            address={address}
            addressBilling={billing}
            addressBillingTitle={billingTitle}
            lots={lots}
            consiga={consiga}
            payment={payment}
          />
        </div>

        <div className="col-7 col-sm-6 col-md-3">
          <SummaryOrder data={data} />
        </div>
      </div>
    </div>
  );
};
