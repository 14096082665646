import React, { useEffect, useState } from 'react';
import { getConfig } from '../../helpers/getConfig';
import '../../css/Menu/Services.css';
import { Link } from 'react-router-dom';

export const Services = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    getConfig('services.json').then((data) => setData(data));
  }, []);

  let title1 = '',
    title2 = '',
    title3 = '',
    paragraph1 = '',
    paragraph2 = '',
    paragraph3 = '',
    paragraph4 = '',
    phone1 = '',
    phone2 = '',
    phone3 = '',
    phone4 = '',
    desc1 = '',
    desc2 = '',
    desc3 = '',
    desc4 = '',
    email = '';

  if (data) {
    title1 = data.title1;
    title2 = data.title2;
    title3 = data.title3;
    paragraph1 = data.paragraph1;
    paragraph2 = data.paragraph2;
    paragraph3 = data.paragraph3;
    paragraph4 = data.paragraph4;
    phone1 = data.phone1;
    phone2 = data.phone2;
    phone3 = data.phone3;
    phone4 = data.phone4;
    desc1 = data.desc1;
    desc2 = data.desc2;
    desc3 = data.desc3;
    desc4 = data.desc4;
    email = data.email;
  }

  return (
    <div className="container-fluid container-services slidein">
      <div className="row">
        <div className="col-12 col-sm-6">
          <div className="row">
            <div className="col-12">
              <h2 className="title-services">
                <div dangerouslySetInnerHTML={{ __html: title1 }} />
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12 mb-5 text-center-mobile">
              <div dangerouslySetInnerHTML={{ __html: title2 }} />
            </div>
          </div>
          <div className="row">
            <div className="col-12 consig-section mb-5">
              <div dangerouslySetInnerHTML={{ __html: paragraph1 }} />
              <div dangerouslySetInnerHTML={{ __html: paragraph2 }} />
            </div>
          </div>
          <div className="row">
            <div className="col-12 mb-5 text-center-mobile">
              <div dangerouslySetInnerHTML={{ __html: title3 }} />
            </div>
          </div>
          <div className="row">
            <div className="col-12 consig-section mb-4">
              <div dangerouslySetInnerHTML={{ __html: paragraph3 }} />
              <div dangerouslySetInnerHTML={{ __html: paragraph4 }} />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-12 container-services__align">
              <h2>
                <a href={'tel:' + phone1} className="phone-number" rel="noreferrer">
                  <b>{phone1}</b>
                </a>
              </h2>
            </div>
            <div className="col-lg-8 col-12 container-services__alignDesc">
              <h2 className='desc'>&nbsp;&nbsp;{desc1}</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-12 container-services__align">
              <h2>
                <a href={'tel:' + phone2} className="phone-number" rel="noreferrer">
                  <b>{phone2}</b>
                </a>
              </h2>
            </div>
            <div className="col-lg-8 col-12 container-services__alignDesc">
              <h2 className='desc'>&nbsp;&nbsp;{desc2}</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-12 container-services__align">
              <h2>
                <a href={'tel:' + phone3} className="phone-number" rel="noreferrer">
                  <b>{phone3}</b>
                </a>
              </h2>
            </div>
            <div className="col-lg-8 col-12 container-services__alignDesc">
              <h2 className='desc'>&nbsp;&nbsp;{desc3}</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-12 container-services__align">
              <h2>
                <a href={'tel:' + phone4} className="phone-number" rel="noreferrer">
                  <b>{phone4}</b>
                </a>
              </h2>
            </div>
            <div className="col-lg-8 col-12 container-services__alignDesc">
              <h2 className='desc'>&nbsp;&nbsp;{desc4}</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12 text-center">
              <p className="email-contact">
                <Link to="/contacto">
                  <u>{email}</u>
                </Link>
              </p>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6 text-center content-vertical-align">
          <img src="./resources/servicios.jpg" alt="..." className="img-fluid"></img>
        </div>
      </div>
    </div>
  );
};
