import React, { useCallback, useEffect, useState, useRef } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import '../../css/Menu/PriceFilter.css';
import CurrencyInput from 'react-currency-input-field';

export const PriceFilter = ({ min, max, getByPrice, cleanPrice }) => {
  const [displayed, setDisplayed] = useState(true);
  const [minVal, setMinVal] = useState(min);
  const [maxVal, setMaxVal] = useState(max);

  const minValRef = useRef(null);
  const maxValRef = useRef(null);
  const range = useRef(null);

  // Convertir a porcentaje
  const getPercent = useCallback(
    (value) => Math.round(((value - min) / (max - min)) * 100),
    [min, max]
  );
  useEffect(() => {
    cleanPrice.current = clean;
  }, []);

  const clean = () => {
    setMinVal(min);
    setMaxVal(max);
  };

  // Establecer el ancho del rango para disminuir desde el lado izquierdo
  useEffect(() => {
    if (maxValRef.current) {
      const minPercent = getPercent(minVal);
      const maxPercent = getPercent(+maxValRef.current.value); // Precediendo con '+' Convierte el valor de tipo string a numero

      if (range.current) {
        range.current.style.left = `${minPercent}%`;
        range.current.style.width = `${maxPercent - minPercent}%`;
      }
    }
  }, [minVal, getPercent]);

  //Establecer el ancho del rango para disminuir del lado derecho
  useEffect(() => {
    if (minValRef.current) {
      const minPercent = getPercent(+minValRef.current.value);
      const maxPercent = getPercent(maxVal);

      if (range.current) {
        range.current.style.width = `${maxPercent - minPercent}%`;
      }
    }
  }, [maxVal, getPercent]);

  return (
    <div className='mt-5'>
      <div
        className='row d-flex justify-content-center pt-5 pb-3 title-cat-section mb-5'
        onClick={() => setDisplayed(!displayed)}
      >
        <div className='col'>
          <h3 className='title-cats'>Precio</h3>
        </div>
        <div className='col text-end'>
          <img
            src={`./resources/arrow_drop_${displayed ? 'down' : 'up'}.png`}
            alt='arrow'
            className='arrows'
          />
        </div>
      </div>
      {displayed && (
        <div>
          <input
            type='range'
            min={min}
            max={max}
            value={minVal}
            ref={minValRef}
            onChange={(event) => {
              const value = Math.min(+event.target.value, maxVal - 1);
              setMinVal(value);
              event.target.value = value.toString();
            }}
            onMouseUp={(e) => getByPrice(e.target.value, maxVal)}
            className={classnames('thumb thumb--zindex-3', {
              'thumb--zindex-5': minVal > max - 100,
            })}
          />
          <input
            type='range'
            min={min}
            max={max}
            value={maxVal}
            ref={maxValRef}
            onChange={(event) => {
              const value = Math.max(+event.target.value, minVal + 1);
              setMaxVal(value);
              event.target.value = value.toString();
            }}
            onMouseUp={(e) => getByPrice(minVal, e.target.value)}
            className='thumb thumb--zindex-4'
          />
          <div className='slider'>
            <div className='slider__track' />
            <div ref={range} className='slider__range' />
          </div>
          <div className='row pt-5 text-center fw-bold price-container'>
            <CurrencyInput
              id='start-currency-filter'
              name='input-currency'
              className='price-box price-item'
              prefix='$'
              placeholder='Precio mínimo'
              defaultValue={minVal}
              value={minVal}
              decimalsLimit={2}
              maxLength={9}
              onValueChange={(value) =>
                value === '0' ? setMinVal(1) : setMinVal(value)
              }
              allowNegativeValue={false}
            />
            <div className='fs-4 pt-3 price-item'>A</div>{' '}
            <CurrencyInput
              id='end-currency-filter'
              name='input-currency'
              className='price-box price-item'
              prefix='$'
              placeholder='Precio máximo'
              defaultValue={maxVal}
              value={maxVal}
              decimalsLimit={2}
              maxLength={9}
              onValueChange={(value) =>
                value === '0' ? setMaxVal(1) : setMaxVal(value)
              }
              allowNegativeValue={false}
            />
          </div>
          <div className='row mt-3'>
            <button
              className='btn btn-outline-gold fs-5'
              onClick={(e) => getByPrice(minVal, maxVal)}
            >
              <i className={'fas fa-check-circle'}></i>
              <strong> Aplicar</strong>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

PriceFilter.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
};
