/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';
import { DataContext } from '../../context/DataContext';
//CSS
import '../../css/Menu/Catalog.css';

import { GET, POST } from '../../helpers/APIServices';
import {
  ServiceAuctionById,
  ServiceAuctionCustomerStatus,
  ServiceFavoritesGetById,
  ServiceLastCatalog,
  ServiceClientData,
  ServiceGenericParameters,
  ServiceLotCatalogByAuctionFilter,
} from '../../helpers/getURL';
import { ButtonRegister } from '../Register/ButtonRegister';
import { ButtonToStreaming } from '../Register/ButtonToStreaming';
import {
  convertToDate,
  OpenOfferSuccess,
  validSession,
  getCart,
  OpenMessage,
} from '../Utilities/General';
import { CountDownCatalog } from './CountDownCatalog';
import {
  getIdStreaming,
  getExecution,
  getIdGoldenMinutes,
  getScheduled,
  getDirectSale,
  getPartialClosure,
  getPartialTotal,
  getPriceFilterID,
} from '../../helpers/getConstants';

//Componentes
import { OrderCatalog } from './OrderCatalog';
import { PaginationCatalog } from './PaginationCatalog';
import { ProductGrid } from './ProductGrid';
import { SearchDescription } from './SearchDescription';
import { Cart } from './Cart';
import { ModalCustomerData } from '../Profile/customerData/ModalCustomerData';
import { FiltersCatalog } from './FiltersCatalog';
import { PriceFilter } from './PriceFilter';

const Catalog = (props) => {
  const [visibleModal, setVisibleModal] = useState('hide');
  const [clientData, setClientData] = useState({});
  const [state, setState] = useState({
    articles: [],
    auctionName: '',
    auctionDate: new Date(),
    searchTerm: '',
    searchCategory: 'Categoría',
    searchOrder: 'Ordenar por', //por default
    selectedCategory: '',
    selectedOrder: '00',
    totalResults: 0,
    filterItems: 0,
    numberPages: 0,
    currentPage: 1,
    itemsPerPage: 50,
    catalog: {},
    filsers: {},
    perPageOptions: [
      { value: 50 },
      { value: 100 },
      { value: 200 },
      { value: 500 },
    ],
    format: 1,
    pallet: 0,
    isStreaming: false,
    idPallet: '',
    exibitionDate: new Date(),
    eventEndDate: new Date(),
    textStatusAuction: 'COMIENZA EN:',
    classAuction: 'timer-course',
    titleColor: 'title-auction-start',
    idType: '',
    idStatus: '',
    idAuction: '',
    shopingCart: [],
    minPrice: 0,
    maxPrice: 0,
    filterSections: [],
    priceSection: {},
    atualLot: '',
  });
  const [favorities, setFavorities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [statusCustomer, setStatusCustomer] = useState('');
  const [enableInvoice, setEnableInvoice] = useState(true);

  const IdCustomer = localStorage.getItem('id');
  const {
    validate: { registeredAuctions, idPallet },
  } = useContext(DataContext);
  const auctionTypes = {
    idStreaming: getIdStreaming(),
    idGoldenMinutes: getIdGoldenMinutes(),
  };

  const auctionStatus = {
    scheduled: getScheduled(),
    execution: getExecution(),
    directSale: getDirectSale(),
    partialClosure: getPartialClosure(),
    partialTotal: getPartialTotal(),
  };

  const priceFilterID = getPriceFilterID();
  const cleanPrice = useRef(null);

  const getIsStreaming = (idAuction) => {
    const auctionValue = registeredAuctions.find(
      (auction) => auction.idAuction === idAuction
    );
    if (auctionValue) {
      setState((prevState) => ({
        ...prevState,
        pallet: auctionValue.pallet,
        isStreaming: auctionValue.streaming,
        idPallet: auctionValue.idPallet,
      }));
    }
  };

  useEffect(() => {
    getClientData();
    if (idPallet) {
      setState((prevState) => ({
        ...prevState,
        idStatus: '',
      }));
      getCatalog();
    }
  }, [idPallet]);

  useEffect(() => {
    if (IdCustomer) {
      setState((prevState) => ({
        ...prevState,
        idStatus: '',
      }));
      getCatalog();
    }
  }, [IdCustomer]);

  useEffect(() => {
    const constants = JSON.parse(sessionStorage.getItem('constants'));
    if (constants && constants.enableInvoice) {
      const URL = ServiceGenericParameters(constants.enableInvoice);
      GET(URL).then((data) => {
        if (data && data.value) {
          const isTrue = data.value.toLowerCase() === 'true';
          setEnableInvoice(isTrue);
        }
      });
    }
  }, []);

  const checkStatus = (auctions, id) => {
    let value = '';
    if (auctions.length > 0) {
      const users = auctions.find((auction) => auction.idAuction === id);
      if (users) {
        value = users.idAuctionCustomerStatus;
      }
    }
    return value;
  };

  const getBySP = (auctions = []) => {
    let textStatusAuction = 'COMIENZA EN:';
    let classAuction = 'timer-course';
    let titleColor = 'title-auction-start';
    if (props.location.search === '') {
      const URLLastCatalog = ServiceLastCatalog();
      GET(URLLastCatalog).then(
        ({
          id,
          name,
          auctionDate,
          idType,
          idStatus,
          exibitionDate,
          eventEndDate,
          saleEndDate,
          saleStartDate,
        }) => {
          const status = checkStatus(auctions, id);
          if (status) {
            setStatusCustomer(status);
          }
          getIsStreaming(id);
          if (
            idType === auctionTypes.idGoldenMinutes &&
            idStatus === auctionStatus.directSale
          ) {
            exibitionDate = saleEndDate;
            eventEndDate = saleStartDate;
            textStatusAuction = 'TERMINA EN:';
            classAuction = 'timer-start';
            titleColor = 'title-auction-course';
          }
          setState((prevState) => ({
            ...prevState,
            name: id ? name : '',
            idAuction: id,
            auctionDate,
            idType,
            idStatus,
            textStatusAuction: textStatusAuction,
            classAuction: classAuction,
            titleColor: titleColor,
            exibitionDate: new Date(exibitionDate),
            eventEndDate: new Date(eventEndDate),
          }));
          getFavorities(id);
          itemsCartAuction(id);
        }
      );
    } else {
      const { search } = props.location;
      const idAuction = search.substr(4, 36);
      let category = search.includes('categoria');
      category ? (category = search.substr(-2)) : (category = '');
      const URLAuctionById = ServiceAuctionById(idAuction);
      GET(URLAuctionById).then(
        ({
          id,
          name,
          eventDate,
          idTypeAuction,
          idStatus,
          eventEndDate,
          saleEndDate,
          saleStartDate,
        }) => {
          const status = checkStatus(auctions, id);
          if (status) {
            setStatusCustomer(status);
          }
          getIsStreaming(id);
          const auctionDate = eventDate;
          if (
            idTypeAuction === auctionTypes.idGoldenMinutes &&
            idStatus === auctionStatus.directSale
          ) {
            eventDate = saleEndDate;
            eventEndDate = saleStartDate;
            textStatusAuction = 'TERMINA EN:';
            classAuction = 'timer-start';
            titleColor = 'title-auction-course';
          }
          setState((prevState) => ({
            ...prevState,
            name: id ? name : '',
            idAuction: id,
            auctionDate: auctionDate,
            idType: idTypeAuction,
            idStatus,
            textStatusAuction: textStatusAuction,
            classAuction: classAuction,
            titleColor: titleColor,
            exibitionDate: new Date(eventDate),
            eventEndDate: new Date(eventEndDate),
          }));
          getFavorities(idAuction, category);
          itemsCartAuction(id);
        }
      );
    }
  };

  const itemsCartAuction = (idAuction) => {
    let sessionCart = getCart();
    if (sessionCart === null || sessionCart === undefined) {
      sessionCart = [];
    }
    sessionCart = sessionCart.filter((item) => item.IdAuction === idAuction);
    setState((prevState) => ({
      ...prevState,
      shopingCart: sessionCart,
    }));
  };

  const getCatalog = () => {
    const { id } = localStorage;
    if (id) {
      const URLAuctionCustomerStatus = ServiceAuctionCustomerStatus(id);
      GET(URLAuctionCustomerStatus).then((response) => {
        if (response) {
          getBySP(response);
        }
      });
    } else {
      getBySP();
    }
  };

  useEffect(() => {
    getCatalog();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setState((prevState) => ({ ...prevState, currentPage: 1 }));
    getLotCatalogFilter(
      state.idAuction,
      1,
      state.itemsPerPage,
      state.filterSections,
      state.priceSection,
      state.selectedOrder,
      state.searchTerm,
      false,
      true
    );
  };

  const handleChange = (e) => {
    setState((prevState) => ({ ...prevState, searchTerm: e.target.value }));
  };

  const handleSelectOrder = (e) => {
    const { innerText } = e.target;
    let value = '';
    switch (innerText) {
      case 'PRECIO: DE MENOR A MAYOR':
        value = '01';
        break;
      case 'PRECIO: DE MAYOR A MENOR':
        value = '02';
        break;
      case 'LOTE: ASCENDENTE':
        value = '03';
        break;
      case 'LOTE: DESCENDENTE':
        value = '04';
        break;
      case 'CATEGORÝA: ASCENDENTE':
        value = '05';
        break;
      case 'CATEGORÝA: DESCENDENTE':
        value = '06';
        break;
      default:
        value = '';
        break;
    }
    setState((prevState) => ({
      ...prevState,
      searchOrder: innerText,
      selectedOrder: value,
      currentPage: 1,
    }));
    getLotCatalogFilter(
      state.idAuction,
      1,
      state.itemsPerPage,
      state.filterSections,
      state.priceSection,
      value,
      state.searchTerm,
      true,
      false
    );
  };
  const nextPage = (pageNumber) => {
    setState((prevState) => ({ ...prevState, currentPage: pageNumber }));
    getLotCatalogFilter(
      state.idAuction,
      pageNumber,
      state.itemsPerPage,
      state.filterSections,
      state.priceSection,
      state.selectedOrder,
      state.searchTerm,
      true,
      false
    );
  };
  const onItemsPerPage = (value) => {
    getLotCatalogFilter(
      state.idAuction,
      1,
      value,
      state.filterSections,
      state.priceSection,
      state.selectedOrder,
      state.searchTerm,
      true,
      false
    );
  };

  const getFavorities = (idAuction, category = '') => {
    if (!idAuction) {
      return;
    }
    let value = '';
    switch (category) {
      case '01':
        value = 'ALHAJAS';
        break;
      case '02':
        value = 'RELOJES';
        break;
      case '03':
        value = 'ARTÝCULOS PERSONALES';
        break;
      default:
        value = 'CATEGORÝA';
        break;
    }
    setState((prevState) => ({
      ...prevState,
      searchCategory: value,
      selectedCategory: category,
    }));
    const idCustomer = localStorage.getItem('id');
    if (validSession()) {
      const URLFavoritesGet = ServiceFavoritesGetById(idAuction);
      let url = new URL(URLFavoritesGet);
      let params = {
        idCustomer,
      };
      Object.keys(params).forEach((key) => {
        if (!params[key]) {
          delete params[key];
        }
      });
      Object.keys(params).forEach((key) => {
        url.searchParams.append(key, params[key]);
      });
      GET(url)
        .then((data) => {
          if (data) setFavorities(data);
          getLotCatalogFilter(idAuction, state.currentPage, state.itemsPerPage);
        })
        .catch((err) => {
          getLotCatalogFilter(idAuction, state.currentPage, state.itemsPerPage);
        });
    } else {
      getLotCatalogFilter(idAuction, state.currentPage, state.itemsPerPage);
    }
  };
  const getByChecks = (checks, idSection) => {
    const arrayFilters = state.filterSections.map((filter) => {
      if (idSection === filter.id) {
        filter.filters = checks;
      }
      return filter;
    });

    setState((prevState) => ({
      ...prevState,
      filterSections: arrayFilters,
      currentPage: 1,
    }));
    getLotCatalogFilter(
      state.idAuction,
      1,
      state.itemsPerPage,
      arrayFilters,
      state.priceSection,
      state.selectedOrder,
      state.searchTerm
    );
  };
  const getLotCatalogFilter = (
    idAuction,
    pageNumber,
    itemsPerPage,
    filterSections = undefined,
    priceSection,
    order = 0,
    search,
    byPrice = false,
    bySearch = false
  ) => {
    let priceJson = priceSection;
    const auxFilters = filterSections;
    if (!byPrice && priceJson) {
      priceJson.maxPrice = null;
      priceJson.minprice = null;
      setState((prevState) => ({
        ...prevState,
        filterSections: [],
      }));
    }
    if (bySearch) {
      priceJson = undefined;
      filterSections = undefined;
    }
    const jsonLots = {
      idAuction,
      pageNumber,
      itemsPerPage,
      filterSections: filterSections,
      priceSection: priceJson,
      order: order === '' ? '00' : order,
      search: search === '' ? null : search,
    };
    const URLLOTCATALOG = ServiceLotCatalogByAuctionFilter();
    POST(URLLOTCATALOG, jsonLots)
      .then(({ lots = [], filter, lotActual = {} }) => {
        setState((prevState) => ({
          ...prevState,
          articles: [],
          totalResults: 0,
          numberPages: 0,
          filterItems: 0,
          actualLot: '',
        }));
        setLoading(false);
        const numberPages = Math.ceil(filter.filterItems / filter.itemsPerPage);

        setState((prevState) => ({
          ...prevState,
          articles: [...lots],
          totalResults: filter.maxItems,
          filterItems: filter.filterItems,
          numberPages,
          minPrice: filter.priceSection.minprice,
          maxPrice: filter.priceSection.maxPrice,
          filterSections: filter.filterSections,
          priceSection: byPrice ? state.priceSection : filter.priceSection,
          actualLot: lotActual?.idLotActual,
        }));

        window.scrollTo(0, 0);
      })
      .catch((err) => {
        setLoading(false);
        if(auxFilters)OpenMessage('warning', 'No se encontraron registros con los filtros seleccionados.');
        setState((prevState) => ({
          ...prevState,
          articles: [],
          filterSections: auxFilters,
          totalResults: 0,
          filterItems: 0,
        }));
      });
  };
  const { auctionDate = new Date(), name = '', idAuction } = state;
  const date = convertToDate(auctionDate);
  const onCourseCountDown = () => {
    setState((prevState) => ({
      ...prevState,
      textStatusAuction: 'TERMINA EN:',
      classAuction: 'timer-start',
      titleColor: 'title-auction-course',
      idStatus: auctionStatus.execution,
    }));
  };
  const onEndCountDown = () => {
    setState((prevState) => ({
      ...prevState,
      idStatus: auctionStatus.partialTotal,
      textStatusAuction: '',
      classAuction: 'timer-start',
      titleColor: '',
    }));
    let message =
      "<h2>LA SUBASTA HA TERMINADO</h2><h5>PARA CONSULTAR EL ESTATUS DE LAS PIEZAS ADJUDICADAS REVISE SU CORREO ELECTRÓNICO O ACCEDA DIRECTAMENTE A <span>'MI CUENTA'</span>.</h5>";
    if (state.idStatus === auctionStatus.directSale) {
      message =
        "<h2>LA VENTA HA TERMINADO</h2><h5>EN CASO DE HABER REALIZADO COMPRAS, FAVOR DE REVISAR EN <span>'MI CUENTA'</span>.</h5>";
    }
    OpenOfferSuccess(message, true);
  };

  const handdleAddCartItem = (data) => {
    let shoppingCart = state.shopingCart;
    if (
      shoppingCart.filter((item) => {
        return item.IdLot === data.IdLot;
      }).length === 0
    ) {
      OpenMessage(
        'success',
        'El producto se ha agregado al carrito de compras'
      );
      shoppingCart.push(data);
      setState((prevState) => ({
        ...prevState,
        shopingCart: shoppingCart,
      }));
    }
  };

  const handdleDeleteCartItem = (data) => {
    let shoppingCart = state.shopingCart;
    if (
      shoppingCart.filter((item) => {
        return item.IdLot === data.IdLot;
      }).length > 0
    ) {
      shoppingCart = shoppingCart.filter((lot) => {
        return lot.IdLot !== data.IdLot;
      });
      setState((prevState) => ({
        ...prevState,
        shopingCart: shoppingCart,
      }));
    }
  };

  const getClientData = () => {
    const URLClientData = ServiceClientData();
    GET(URLClientData).then((data) => {
      if (data === '401') {
        getClientData();
        return '';
      }
      setClientData(data);
    });
  };

  const handleSetVisibleModal = (visible) => {
    setVisibleModal(visible);
  };

  const getByPrice = (minVal, maxVal) => {
    let priceSection = state.priceSection;
    priceSection.minprice = minVal;
    priceSection.maxPrice = maxVal;
    setState((prevState) => ({
      ...prevState,
      priceSection,
    }));
    getLotCatalogFilter(
      state.idAuction,
      1,
      state.itemsPerPage,
      state.filterSections,
      priceSection,
      state.selectedOrder,
      state.searchTerm,
      true
    );
  };

  const cleanFilters = () => {
    if(state.filterSections)cleanPrice.current();
    getLotCatalogFilter(idAuction, 1, 50);
    setState((prevState) => ({
      ...prevState,
      searchTerm: '',
      currentPage: 1,
      itemsPerPage: 50,
      searchCategory: 'Categoría',
      searchOrder: 'Ordenar por',
      selectedCategory: '',
      selectedOrder: '',
      filterSections: [],
      priceSection: {},
    }));
  };
  return (
    <Fragment>
      <div className='container-fluid container-catalog'>
        <div className='row '>
          <div className='col-12'>
            <h2 className='title-sections'>
              Catálogo <span className='gold-text'>{name}</span>
            </h2>
          </div>
        </div>
        <div className='row'>
          <h3 className='time-auction'>{date}</h3>
          <div className='col-md-4 col-sm-12 offset-md-8 offset-sm-0 pallet-section'>
            {state.idType &&
              state.idType === auctionTypes.idGoldenMinutes &&
              (state.idStatus === auctionStatus.scheduled ||
                state.idStatus === auctionStatus.execution ||
                state.idStatus === auctionStatus.directSale) && (
                <CountDownCatalog
                  startAuctionDate={state.exibitionDate}
                  endAuctionDate={state.eventEndDate}
                  classAuction={state.classAuction}
                  onCourseCountDown={onCourseCountDown}
                  onEndCountDown={onEndCountDown}
                  textStatusAuction={state.textStatusAuction}
                  titleColor={state.titleColor}
                  scheduled={auctionStatus.scheduled}
                  statusAuction={state.idStatus}
                />
              )}
          </div>
          {state.pallet <= 0 && !loading && (
            <div className='col pallet-section'>
              <ButtonRegister
                idAuction={idAuction}
                nameAuction={name}
                dateAuction={date}
                classStyle={'button-reg'}
              />
            </div>
          )}
        </div>
        <div className='row'>
          {' '}
          <div className='col-12 order-sm-2 pallet-section'>
            {state.pallet <= 0 ? null : (
              <h3>
                Paleta asignada:{' '}
                <span className='price-text'>{state.pallet}</span>
              </h3>
            )}
          </div>
          <div className='col-12 order-sm-1 pallet-section'>
            {state.pallet > 0 &&
              state.idType === auctionTypes.idStreaming &&
              state.idStatus === auctionStatus.execution && (
                <ButtonToStreaming
                  idAuction={idAuction}
                  streaming={state.isStreaming}
                  classStyle={'buttons-auctions'}
                  userStatusLastCatalog={statusCustomer}
                />
              )}
          </div>
        </div>
        <div className='row'>
          {''}
          <div className='col-12 order-sm-2 pallet-section'>
            {state.pallet > 0 &&
              state.idType === auctionTypes.idGoldenMinutes &&
              state.idStatus === auctionStatus.directSale && (
                <h1>
                  <a
                    data-bs-toggle='offcanvas'
                    href='#CartCanvas'
                    className='cart-icon'
                    role='button'
                    aria-controls='CartCanvas'
                  >
                    <i className='bi bi-cart-check-fill'></i>Carrito(
                    {state.shopingCart.length})
                  </a>
                </h1>
              )}
          </div>
        </div>
        <div className='row justify-content-end'>
          <div className='col-md-3 col-sm-12'>
            <OrderCatalog
              handleSelectOrder={handleSelectOrder}
              searchOrder={state.searchOrder}
            />
          </div>
        </div>

        <div className='row offset-xl-3 offset-md-1'>
          <div className='col-md-6 col-sm-12 '>
            <div className='row'>
              <div className='col-4 text-end'>
                <h5 className='show-perpag '>Mostrar</h5>
              </div>
              <div className='col-3 text-center'>
                <h5 className='dropdown shadow-0 drop-perPage'>
                  <a
                    href='#'
                    className='btn btn-link dropdown-toggle items-per-page'
                    role='button'
                    data-mdb-toggle='dropdown'
                    aria-expanded='false'
                  >
                    {state.itemsPerPage}
                  </a>
                  <ul
                    className='dropdown-menu'
                    aria-labelledby='dropdownMenuLink'
                  >
                    {state.perPageOptions.map((item) => {
                      return (
                        <li key={item.value}>
                          <a
                            href='#'
                            className='dropdown-item drop-items'
                            onClick={(e) => {
                              setState((prevState) => ({
                                ...prevState,
                                itemsPerPage: item.value,
                                currentPage: 1,
                              }));
                              onItemsPerPage(item.value);
                            }}
                          >
                            {item.value}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </h5>{' '}
              </div>
              <div className='col-5'>
                <h5 className='show-perpag'>resultados por página</h5>
              </div>
            </div>
          </div>
          <div className='col pagination-section'>
            <PaginationCatalog
              pages={state.numberPages}
              nextPage={nextPage}
              currentPage={state.currentPage}
            />
          </div>
          <div className='col mt-0'>
            <div className='card-body d-flex justify-content-end p-1 show-options'>
              <button
                className={`${state.format === 1 ? 'box-items' : 'box-items2'}`}
                onClick={(e) =>
                  setState((prevState) => ({ ...prevState, format: 1 }))
                }
              >
                <i className='fas fa-th-large'></i>
              </button>
              <button
                className={`${state.format === 1 ? 'box-items2' : 'box-items'}`}
                onClick={(e) =>
                  setState((prevState) => ({ ...prevState, format: 2 }))
                }
              >
                <i className='fas fa-bars'></i>
              </button>
            </div>
          </div>
        </div>

        <div className='row justify-content-md-center grid-items'>
          <div className='col-md-3 text-uppercase filters'>
            <h5>Mostrando</h5>
            <h4>
              {state.filterItems} de {state.totalResults} <span>lotes</span>
            </h4>
            <SearchDescription
              handleSubmit={handleSubmit}
              handleChange={handleChange}
              searchTerm={state.searchTerm}
            />
            {state.filterSections && state.filterSections.map((item) => {
              if (item.id === priceFilterID) {
                return (
                  <PriceFilter
                    key={item.id}
                    min={state.minPrice}
                    max={state.maxPrice}
                    getByPrice={getByPrice}
                    cleanPrice={cleanPrice}
                  />
                );
              }
              return (
                <FiltersCatalog
                  key={item.id}
                  idSection={item.id}
                  title={item.description}
                  filters={item.filters}
                  getByChecks={getByChecks}
                />
              );
            })}
            <div className='row mt-3'>
              <button
                className='btn btn-outline-secondary fs-5'
                onClick={(e) => cleanFilters(e)}
              >
                <img src='./resources/Frame.png' alt='Frame' />
                <strong> Limpiar filtros</strong>
              </button>
            </div>
          </div>

          <div className='col-md-9'>
            {!loading && (
              <ProductGrid
                articles={state.articles}
                format={state.format}
                idAuction={state.idAuction}
                favorities={favorities}
                registered={state.pallet > 0 ? true : false}
                idPallet={state.idPallet}
                idType={state.idType}
                idStatus={state.idStatus}
                auctionTypes={auctionTypes}
                auctionStatus={auctionStatus}
                addCartItem={handdleAddCartItem}
                statusCustomer={statusCustomer}
                actualLotID={state.actualLot}
              />
            )}
          </div>
        </div>
        <div className='row justify-content-center pagination-section mt-5'>
          <div className='col-auto'>
            <PaginationCatalog
              pages={state.numberPages}
              nextPage={nextPage}
              currentPage={state.currentPage}
            />
          </div>
        </div>
      </div>
      <div
        className='offcanvas offcanvas-end cart-canvas'
        tabIndex='-1'
        id='CartCanvas'
      >
        <Cart
          clientData={clientData}
          visible={handleSetVisibleModal}
          idAuction={state.idAuction}
          ShoppingCart={state.shopingCart}
          delCartItem={handdleDeleteCartItem}
          enableInvoice={enableInvoice}
        />
      </div>
      <button
        id='btnDataComplementary'
        className='hide'
        data-bs-toggle='modal'
        data-bs-target='#modalCustomerData'
      />
      <div className='form-profile'>
        <ModalCustomerData updateData={getClientData} visible={visibleModal} />
      </div>
    </Fragment>
  );
};

export default Catalog;
