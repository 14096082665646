import React, { useState, useEffect } from "react";

import { POST } from "../../../helpers/APIServices";
import { ServiceQuotationMail } from "../../../helpers/getURL";
import {
  formatCurrency,
  resetRadios,
  OpenMessage,
} from "../../Utilities/General";
import {
  getIdGoldenMinutes,
  getIdPresential,
  getIdStreaming,
  getIdSystem,
  getReferenceObject,
  getZipCodeOrigenMO,
  getZipCodeOrigenPresential,
  getZipCodeOrigenStreaming,
} from "../../../helpers/getConstants";

export const Mail = (props) => {
  let [step, setStep] = useState(props.step + 1);
  const URL = ServiceQuotationMail();
  const idGoldenMinutes = getIdGoldenMinutes();
  const idStreaming = getIdStreaming();
  const idPresential = getIdPresential();
  const [data, setData] = useState([]);
  const [listMail, setListMail] = useState([]);
  const [disabledBtn, setDisabledBtn] = useState(true);

  useEffect(() => {
    if (props.lastStep && props.visible === "show") {
      setStep(8);
      resetRadios("MailRadios");
      setDisabledBtn(true);
      setListMail([]);
    }
  }, [props.lastStep, props.visible]);

  useEffect(() => {
    if (
      props.visible === "show" &&
      props.data &&
      props.data.addressMail &&
      props.data.addressMail.zipCode
    ) {
      const params = {
        idSystem: getIdSystem(),
        referenceObject: getReferenceObject(),
        packageList: {
          description: "Subastas streaming",
          contentValue: props.subTotal ? props.subTotal : 1,
          weight: 1,
          length: 1,
          height: 1,
          width: 1,
        },
        originAddress: {
          zipCode: getZipCodeOrigen(),
        },
        destinationAddress: {
          zipCode: props.data.addressMail.zipCode,
        },
      };

      POST(URL, params).then((response) => {
        if (response && response.length > 0 && response[0].shippingOptions) {
          setListMail(response[0].shippingOptions);
        } else {
          OpenMessage("warning", response);
          setListMail([]);
          props.eventButton(true, 2, data);
        }
      });
    }
  }, [URL, props.visible, props.data, props.subTotal, step, data]);

  const getZipCodeOrigen = () => {
    let zipCode = "";

    switch (props.idTypeAuction) {
      case idGoldenMinutes:
        zipCode = getZipCodeOrigenMO();
        break;
      case idStreaming:
        zipCode = getZipCodeOrigenStreaming();
        break;
      case idPresential:
        zipCode = getZipCodeOrigenPresential();
        break;
      default:
        zipCode = "";
        break;
    }

    return zipCode;
  };

  const clickRadio = (value, data, rate, carrier, idRate, AmountInsurance) => {
    const selectItem = {
      mail: {
        id: value,
        name: data,
        rate: rate,
        carrier: carrier,
        idRateDelivery: idRate,
        amountInsurance: AmountInsurance,
      },
    };
    console.log("SELECT ITEM : ", selectItem);
    setData(selectItem);
    setDisabledBtn(false);
  };

  return (
    <div className={"card " + props.visible}>
      <div className='card-header text-center'>
        <h3>Método de envío</h3>
      </div>
      <div className='card-body'>
        <div className='row mb-6'>
          <div className='col-12'>
            <h5 className='card-text card-title'>
              Seleccione un método de envío de las opciones disponibles:
            </h5>
          </div>
        </div>
        {listMail.map((item, i) => {
          console.log("currency ->", item);
          const totalRate = item.rate + item.amountInsurance;
          const value = `${item.carrier} - ${item.description} ${formatCurrency(
            totalRate
          )} ${item.currency}`;
          return (
            <div className='row mb-2' key={i}>
              <div className='col-12'>
                <div className='form-check'>
                  <input
                    className='form-check-input'
                    type='radio'
                    name='MailRadios'
                    id={item.rateId}
                    value={item.rateId}
                    onClick={(e) =>
                      clickRadio(
                        e.target.value,
                        value,
                        totalRate,
                        item.carrier,
                        item.idRate,
                        item.amountInsurance
                      )
                    }
                  />
                  <label className='form-check-label' htmlFor={item.rateId}>
                    <p>{value}</p>
                  </label>
                </div>
              </div>
            </div>
          );
        })}
        <div className='row mb-2'>
          <div className='col-6 text-end'>
            <button
              type='button'
              className='btn btn-lg btn-outline-danger'
              onClick={(e) => [
                props.eventButton(true, step, data),
                props.setOrigen(4),
                props.setOrigen2(4),
              ]}
              disabled={disabledBtn}
            >
              Continuar
            </button>
          </div>
          <div className='col-6 text-start'>
            <button
              type='button'
              className='btn btn-lg btn-outline-secondary'
              onClick={(e) => props.eventButton(true, step - 2, data)}
            >
              Regresar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
