import React, { useState, useEffect, useContext } from 'react';

//Componentes
import CarouselItem from './CarouselItem';
import CarouselIndicators from './CarouselIndicators';

//Servicios
import { ServiceAnnouncement, ServiceCarousel, ServiceGenericParameters } from '../../helpers/getURL';
import { GET } from '../../helpers/APIServices';
import { getImgDefault, getCarouselTime } from '../../helpers/getConstants';

//Utiles
import { convertToDate } from '../Utilities/General';
import { DataContext } from '../../context/DataContext';

export const Carousel = ({ auctions }) => {
  const data = [];
  const [dataCarousel, setDataCarousel] = useState([]);
  const [dataAnnouncement, setDataAnnouncement] = useState([]);
  const [interval, setInterval] = useState(0);
  let classIndicator = 'active';
  let classItem = 'true';
  let slide = 0;
  const {
    validate: { valid, registeredAuctions, idPallet },
  } = useContext(DataContext);

  const getIsStreaming = (idAuction) => {
    let auctionRegistered = {
      pallet: 0,
      streaming: false,
      idPallet: '',
    };
    const auctionValue = registeredAuctions.find((auction) => auction.idAuction === idAuction);
    if (auctionValue) {
      auctionRegistered.pallet = auctionValue.pallet;
      auctionRegistered.streaming = auctionValue.streaming;
      auctionRegistered.idPallet = auctionValue.idPallet;
    }
    return auctionRegistered;
  };

  const getCarousel = () => {
    const URLCarousel = ServiceCarousel();
    const URLAnnouncement = ServiceAnnouncement();
    const IdInterval = getCarouselTime();
    const URLTimeInterval = ServiceGenericParameters(IdInterval);

    GET(URLTimeInterval).then((data) => {
      if (data) {
        const value = data.value ? data.value * 1000 : 5000;
        setInterval(value);
      }
      GET(URLCarousel).then((response) => {
        if (response && response.length > 0) {
          const items = response.filter(
            (carousel) =>
              carousel.bannerIni === null ||
              carousel.bannerFin === null ||
              (new Date(carousel.bannerIni) < new Date() && new Date() < new Date(carousel.bannerFin))
          );
          setDataCarousel(items);
        }
        GET(URLAnnouncement).then((resp) => {
          if (resp && resp.length > 0) {
            setDataAnnouncement(resp);
          }
        });
      });
    });
  };

  const checkStatus = (item) => {
    let value = '';
    const users = auctions.find((auction) => auction.idAuction === item.id);
    if (users) {
      value = users.idAuctionCustomerStatus;
    }
    return value;
  };

  dataCarousel.forEach((item) => {
    item.auctionCustomerStatus = checkStatus(item);
    item.auctionType = item.idType;
    item.auctionStatus = item.idStatus;
    item.auctionRegistered = getIsStreaming(item.id);
    item.classIndicator = classIndicator;
    item.classItem = classItem;
    item.slide = slide;
    item.date = convertToDate(item.auctionDate);
    item.announcement = false;
    item.link = '';
    slide++;
    classIndicator = '';
    classItem = '';
    if (!item.url[0]) {
      item.url[0] = getImgDefault();
    }
    data.push(item);
  });
  slide = dataCarousel.length;
  dataAnnouncement.forEach((item) => {
    item.announcement = true;
    item.slide = slide;
    item.url = [];
    item.url[0] = item.imageUrl;
    item.classIndicator = classIndicator;
    item.classItem = classItem;
    item.name = '';
    item.place = '';
    classIndicator = '';
    classItem = '';
    item.date = convertToDate(new Date());
    slide++;
    data.push(item);
  });

  useEffect(() => {
    if (idPallet) {
      getCarousel();
    }
  }, [valid]);

  useEffect(() => {
    getCarousel();
  }, []);
  return (
    <div className="container-carousel">
      {data.length > 0 ? (
        <div className="row">
          <div className="col">
            <div id="carouselMLS" className="carousel carousel-dark slide" data-bs-ride="carousel" data-bs-interval={interval}>
              <div className="carousel-indicators">
                {data.map((item) => {
                  return <CarouselIndicators idCarousel="carouselMLS" key={item.id} slide={item.slide} nameClass={item.classIndicator} />;
                })}
              </div>
              <div className="carousel-inner">
                {data.map((item) => {
                  return (
                    <CarouselItem
                      idAuction={item.id}
                      key={item.id}
                      active={item.classItem}
                      title={item.name}
                      description1={item.description ? item.description.substr(0, 40) : ''}
                      description2={item.description ? item.description.substr(40, 40) : ''}
                      description3={item.description ? item.description.substr(80) : ''}
                      place={item.place}
                      img={item.url[0]}
                      date={item.date}
                      nuevaPestania={item.nuevaPestania}
                      announcement={item.announcement}
                      link={item.link}
                      auctionRegistered={item.auctionRegistered}
                      auctionType={item.auctionType}
                      auctionStatus={item.auctionStatus}
                      statusCustomer={item.auctionCustomerStatus}
                    />
                  );
                })}
              </div>
              <button className="carousel-control-prev" type="button" data-bs-target="#carouselMLS" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#carouselMLS" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};
