import React from 'react';

import '../../css/Login/TokenNotValid.css';

export const TokenNotValid = () => {
  return (
    <div className="row container-fluid container__expire justify-content-center">
      <div className="col-8 col-sm-6 col-lg-5 col-xxl-4 text-center container__expire-content">
        <i className="fas fa-times"></i>
        <label>Enlace no válido</label>
        <i className="fas fa-times"></i>
      </div>
    </div>
  );
};
