import React, { useState, useEffect, useContext } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';

//Componentes
import Login from '../Login/Login';
import CheckIn from '../CheckIn/CheckIn';

//CSS
import '../../css/menu.css';

//helpers
import { getConfig } from '../../helpers/getConfig';
import { setSession, validSession, closeMenuMobile, OpenCloseStreaming } from '../Utilities/General';
import { DataContext } from '../../context/DataContext';
import { getBillingUrl } from '../../helpers/getConstants';
import { getDiamondUrl } from '../../helpers/getConstants';

const Header = () => {
  const [data, setData] = useState([]);
  const history = useHistory();
  const { search } = useLocation();
  const { setValidate } = useContext(DataContext);
  const isHome = new URLSearchParams(search).get('home');
  const { pathname } = history.location;
  const IdAuction = new URLSearchParams(search).get('id');
  const billingUrl = getBillingUrl();
  const diamondUrl = getDiamondUrl();

  if (isHome) {
    window.location.href = '/';
  }

  useEffect(() => {
    getConfig('header.json').then((data) => {
      setData(data);
    });

    getConfig('constants.json').then((response) => {
      if (response) {
        const constants = JSON.stringify(response);
        sessionStorage.setItem('constants', constants);
        const PROTOCOL = window.location.protocol;
        if (response.redirectSSL && PROTOCOL === 'http:') {
          const URL = 'https://' + window.location.host + window.location.pathname;
          window.location.href = URL;
        }
      }
    });

    const urlServices = sessionStorage.getItem('URLServices');
    if (!urlServices || urlServices.length >= 2) {
      getConfig('urlServices.json').then((response) => {
        const url = JSON.stringify(response);
        sessionStorage.setItem('URLServices', url);
      });
    }
  }, []);

  const LOCATION = useLocation();
  let aboutActive = '',
    auctionActive = '',
    catalogActive = '',
    servicesActive = '',
    contactActive = '',
    hideMenu = '',
    btnExitStreaming = 'hide';

  const menuActive = () => {
    switch (LOCATION.pathname.toLowerCase()) {
      case '/quienes-somos':
        aboutActive = 'active';
        break;
      case '/subastas':
        auctionActive = 'active';
        break;
      case '/catalogo':
        catalogActive = 'active';
        break;
      case '/servicios':
        servicesActive = 'active';
        break;
      case '/contacto':
        contactActive = 'active';
        break;
      case '/streaming':
        hideMenu = 'hide';
        btnExitStreaming = 'show menu__streaming';
        break;
      default:
        break;
    }
  };

  const closeSession = () => {
    setValidate({
      valid: false,
      registeredAuctions: [],
      idAuction: '',
      nameAuction: '',
      dateAuction: '',
      palletAuction: '',
      idPallet: '',
      onPaymentValidate: false,
    });
    setSession(false, '');
    history.push('/?home=true');
  };

  const exitStreaming = (path) => {
    if (pathname === '/streaming') {
      if (path === 'perfil') {
        history.push({
          search: `?id=${IdAuction}&profile=true`,
        });
      }
      OpenCloseStreaming();
    } else {
      history.push(`/${path}`);
    }
  };
  const exitStreamingLogOut = (path) => {
    if (pathname === '/streaming') {
      if (path === 'logout') {
        history.push({
          search: `?id=${IdAuction}&logout=true`,
        });
      }
      OpenCloseStreaming();
    } else {
      closeSession();
    }
  };

  /*useLayoutEffect(() => {
    const validToken = () => {
      const id = localStorage.getItem('id');
      const element = document.getElementById('refreshToken');
      const dateExpire = validDateExpire();

      if (dateExpire === -1 && element.value === '0' && id) {
        //actualizar TOKEN
        element.value = '1'; //valor cambia en el login y en APIServices hay validacion
        refreshToken().then((token) => {
          if (token === 2) {
            setSession(false, '');
            OpenMessage('warning', 'Su sesión ha expirado, favor de ingresar nuevamente sus credenciales');
            setTimeout(function () {
              window.location = './?home=true';
            }, 5000);
          }
        });
      }
    };

    validToken();
  }, []);*/

  validSession();

  menuActive();
  const { logo = '' } = data;

  return (
    <div className="container-header">
      <nav className="navbar-expand-sm navbar-light container-menu-primary">
        <div className="collapse navbar-collapse justify-content-end" id="menuPrimary">
          <ul className="navbar-nav mr-auto container__users">
            <li className="nav-item container__users--item hide">
              <a
                className="nav-link text-uppercase container-menu-primary--login"
                href="!#"
                data-bs-toggle="modal"
                data-bs-target="#modalLogin"
              >
                Iniciar sesión
              </a>
            </li>
            <li className="nav-item container__users--item hide">
              <a className="nav-link" href="!#" data-bs-toggle="modal" data-bs-target="#modalCheckIn">
                <button className="btn btn-outline-danger" type="button">
                  Registrarse
                </button>
              </a>
            </li>
            <li className="nav-item container__users--login hide">
              <div className="dropdown dropdown-account">
                <a
                  className="btn btn-user dropdown-toggle"
                  href="!#"
                  role="button"
                  id="dropdownMenuLogin"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="fas fa-user-circle"></i>
                  <span id="userName">Usuario</span>
                </a>

                <ul className="dropdown-menu" aria-labelledby="dropdownMenuLogin">
                  <li>
                    <a
                      href="!#"
                      className="dropdown-item"
                      onClick={(e) => {
                        exitStreaming('perfil');
                        e.preventDefault();
                      }}
                    >
                      Mi cuenta
                    </a>
                  </li>
                  <li>
                    <a
                      href="!#"
                      className="dropdown-item"
                      onClick={(e) => {
                        exitStreamingLogOut('logout');
                        e.preventDefault();
                      }}
                    >
                      Cerrar Sesión
                    </a>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </nav>

      <nav className="navbar-expand-sm navbar-light">
        <div className="navbar-brand">
          <img src={logo} alt="logo MLS" className="img-fluid" onClick={() => exitStreaming('?home=true')} />
        </div>
        <button id="navbarMenu" className="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#menu">
          <i className="fas fa-bars"></i>
        </button>
        <div className="navbar-collapse collapse justify-content-end container-menu" id="menu">
          {/*MENU MOBILE*/}
          <div className="row mt-4 mb-5 container__menu--secondLogin">
            <div className="col-6 text-end container__menu--secondLogin-login loginMobile">
              <a
                className="nav-link text-uppercase container-menu-primary--login"
                href="!#"
                data-bs-toggle="modal"
                data-bs-target="#modalLogin"
              >
                Iniciar sesión
              </a>
            </div>
            <div className="col-6 text-start container__menu--secondLogin-register loginMobile">
              <a className="nav-link" href="!#" data-bs-toggle="modal" data-bs-target="#modalCheckIn">
                <button className="btn btn-outline-danger" type="button">
                  Registrarse
                </button>
              </a>
            </div>
          </div>
          <div className="row container__users--secondUser">
            <div className="col-12 text-center btn-user loginMobile--items hide">
              <i className="fas fa-user-circle"></i>
              <span id="userName2">Usuario</span>
            </div>
          </div>
          <div className="row container__users--secondUser">
            <div className="col-6 loginMobile--items hide">
              <a
                href="!#"
                className="nav-link text-uppercase container-menu-primary--login"
                onClick={(e) => {
                  exitStreaming('perfil');
                  e.preventDefault();
                }}
              >
                Mi perfil
              </a>
            </div>
            <div className="col-6 text-start loginMobile--items hide">
              <a
                href="!#"
                className="nav-link text-uppercase container-menu-primary--login"
                onClick={(e) => {
                  exitStreamingLogOut('logout');
                  e.preventDefault();
                }}
              >
                Cerrar Sesión
              </a>
            </div>
          </div>
          {/*FIN MENU MOBILE*/}
          <ul className="navbar-nav">
            <li className={'nav-item ' + hideMenu}>
              <Link className={'nav-link text-uppercase fw-bold ' + aboutActive} to="/quienes-somos" onClick={() => closeMenuMobile()}>
                Quiénes somos
              </Link>
            </li>
            <li className={'nav-item ' + hideMenu}>
              <Link className={'nav-link text-uppercase fw-bold ' + auctionActive} to="/subastas" onClick={() => closeMenuMobile()}>
                Subastas
              </Link>
            </li>
            <li className={'nav-item ' + hideMenu}>
              <Link className={'nav-link text-uppercase fw-bold ' + catalogActive} to="/catalogo" onClick={() => closeMenuMobile()}>
                Catálogo
              </Link>
            </li>
            <li className={'nav-item ' + hideMenu}>
              <a className='nav-link text-uppercase fw-bold' href={diamondUrl} target="_blank" rel='noreferrer'>
                Consignación
              </a>
            </li>
            <li className={'nav-item ' + hideMenu}>
              <Link className={'nav-link text-uppercase fw-bold ' + servicesActive} to="/servicios" onClick={() => closeMenuMobile()}>
                Servicios
              </Link>
            </li>
            <li className={'nav-item ' + hideMenu}>
              <a className='nav-link text-uppercase fw-bold' href={billingUrl} target="_blank" rel='noreferrer'>
                Facturación
              </a>
            </li>
            <li className={'nav-item ' + hideMenu}>
              <Link className={'nav-link text-uppercase fw-bold ' + contactActive} to="/contacto" onClick={() => closeMenuMobile()}>
                Contacto
              </Link>
            </li>
            <li className={'nav-item ' + btnExitStreaming}>
              <button className="btn btn-outline-danger btn-lg btn-out-streaming" onClick={() => exitStreaming('?home=true')}>
                <i className="fas fa-sign-out-alt"></i>Salir del streaming
              </button>
            </li>
          </ul>
        </div>
      </nav>
      <Login />
      <CheckIn />
      <input type="hidden" id="refreshToken" value="0" />
    </div>
  );
};

export default Header;
