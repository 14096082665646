import React from 'react';
import { Offer } from '../Register/Offer';
import { formatCurrency } from '../Utilities/General';

function AuctionsHighlightItem(props) {
  return (
    <div className={'col-3 col-xxl-2 mb-5 ' + props.nameClass}>
      <div className="card h-100">
        <div className="row h-100">
          <div className="container-products-items--img">
            <img src={props.url} className="card-img-top " alt="..." />
          </div>

          <div className="card-body text-center">
            <p className="container-products-items--title">{props.title}</p>
            <p className="product-text">
              Lote <span>{props.reference}</span>
            </p>
            <span className="product-text">
              PRECIO DE SALIDA: <span className="price-text">{formatCurrency(props.price)}</span>
            </span>
          </div>
          {props.registered && (
            <div className="card-footer text-center article-footer">
              <Offer offerLot={props.offerLot} format={props.format} />
            </div>
          )}
          <div className="card-footer text-center">
            <button className="btn btn-outline-secondary" data-mdb-toggle="modal" data-mdb-target={`#modal${props.id}`}>
              <i className="fas fa-eye"></i>
              <strong> Detalles</strong>
            </button>
          </div>
        </div>
      </div>
      <div className="modal fade" id={`modal${props.id}`} aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <img src={props.url} alt="" className="img-fluid" />
            </div>
            <h4 className="text-desc text-center">{props.description}</h4>
            <div className="modal-footer">
              <button type="button" className="btn btn-outline-danger" data-mdb-dismiss="modal">
                Cerrar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AuctionsHighlightItem;
