import React, { Fragment } from 'react';
import { DELETE, GET } from '../../helpers/APIServices';
import { ServiceInfoAddressBillingById } from '../../helpers/getURL';
import { verifyFieldsRequerid } from '../Utilities/General';
import { AdressConfirmDelete } from './paymentProcess/AdressConfirmDelete';

export const AddressBilling = (props) => {
  const deleteAddress = (id) => {
    const URL = ServiceInfoAddressBillingById(id);
    DELETE(URL, props.address).then((data) => {
      props.AddressRefresh(true);
    });
  };

  const getAddress = (e, id) => {
    e.preventDefault();
    const URL = ServiceInfoAddressBillingById(id);
    props.setTitleModal('Editar');
    GET(URL, props.address).then((data) => {
      if (data === '401') {
        getAddress();
        return '';
      }
      props.setAddress(data[0]);

      const inputs = [
        'input-Calle-i',
        'input-Numero-i',
        'input-NumeroInt-i',
        'input-Rfc-i',
        'input-RazonSocial-i',
        'input-emailBilling',
        'input-Scp-i',
      ];

      inputs.forEach((item) => {
        const element = document.getElementById(item);
        element.classList.remove('is-invalid');
        element.focus();
      });

      const inputsTwo = ['input-Scp-i', 'input-Calle-i', 'input-Numero-i', 'input-Rfc-i', 'input-RazonSocial-i'];

      verifyFieldsRequerid(inputsTwo);

      const esFiscoMoral = document.getElementsByName('esfiscoMoral-i');
      esFiscoMoral.forEach((x) => {
        if (data[0].esFisicoMoral && x.id === 'moral') {
          x.checked = true;
        }
      });
    });
  };

  let list = '';
  if (props.listAddress.length > 0) {
    list = props.listAddress.map((item) => {
      const value = `${item.rfc}, ${item.razonSocial}, ${item.calle} No. Ext. ${item.numero}${
        item.numeroInt !== '' ? ` No. Int ${item.numeroInt}` : ''
      }, ${item.colonia}, C.P. ${item.scp}, ${item.municipio}, ${item.estado}, ${item.idPais}`;
      return (
        <div className="mb-3 row inputs-form" key={item.id}>
          <div className="col-10 col-sm-10">
            <p>{value}</p>
          </div>
          <div className="col-6 col-sm-1 text-end">
            <a href="!#" onClick={(e) => getAddress(e, item.id)} data-bs-toggle="modal" data-bs-target={`#${props.modal}`}>
              Editar
            </a>
          </div>
          <div className="col-6 col-sm-1 text-start">
            <a href="!#" data-bs-toggle="modal" data-bs-target={'#modalDeleteAddressBilling_' + item.id}>
              <i className="far fa-trash-alt"></i>
            </a>
          </div>
          <AdressConfirmDelete id={item.id} deleteAddress={deleteAddress} modalType={'Billing_' + item.id} />
        </div>
      );
    });
  } else {
    list = (
      <div className="mb-3 row inputs-form">
        <div className="col-12 text-center">No existen registros</div>
      </div>
    );
  }

  return <Fragment>{list}</Fragment>;
};
