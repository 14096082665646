import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { GET, POST } from '../../helpers/APIServices';
import {
  getnoteCancelled,
  getOrderPendingStatus,
  getDisableCheckoutMessageID,
  getOrderPendingPaymentStatus, getOrderCompletedStatus
} from '../../helpers/getConstants'
import { ServicePaymentRetry, ServiceGetOrdersByUser, ServiceGenericParameters } from '../../helpers/getURL';

import { formatCurrency, convertToDate, OpenMessage } from '../Utilities/General';

import { PaymentGateways } from '../Profile/paymentProcess/PaymentGateways';
import { getConfig } from '../../helpers/getConfig';

export const Orders = (props) => {
  const [data, setData] = useState([]);
  const [idOrder, setIdOrder] = useState('');
  const [hideBillPocket, setHideBillPocket] = useState(false);
  const [hideActnet, setHideActnet] = useState(false);
  const [hideMercadoPago, setHideMercadoPago] = useState(false);
  const history = useHistory();
  const pending = getOrderPendingStatus();
  const pendingPayment = getOrderPendingPaymentStatus();
  const completedtStatus = getOrderCompletedStatus();
  const noteCancelled = getnoteCancelled();
  const [disabelCheckoutMessage, setMessage] = useState('');
  const openOrderDetail = (idOrder) => {
    history.push({
      pathname: '/detalle-orden',
      state: { idOrder: idOrder },
    });
  };

  useEffect(() => {
    const deliveryLimitID = getDisableCheckoutMessageID();
    const URLDeliveryLimit = ServiceGenericParameters(deliveryLimitID);
    const getData = () => {
      GET(URLDeliveryLimit).then((data) => {
        if (data === '401') {
          getData();
          return '';
        }
        if(data.value){
          setMessage(data.value);
        }
      });
    };
    getData();
  }, [])

  useEffect(() => {
    getOrders()
  }, [props.visible]);

  const getOrders = () => {
    return new Promise((resolve, reject) => {
      if (props.visible === 'show') {
        const getData = () => {
          const URL = ServiceGetOrdersByUser();
          GET(URL).then((data) => {
            if (data === '401') {
              getData();
              return;
            }
            const result = data || [];
            setData(result);
            resolve(result);
          }).catch((error) => {
            setData([]);
            reject([]);
          });
        };
        getData();
      } else {
        resolve([]);
      }
    });
  };

  useEffect(() => {
    const constants = JSON.parse(sessionStorage.getItem('constants'));
    if (!constants || !constants.hideBillPocket) {
      getConfig('constants.json').then((response) => {
        if (response && response.hideBillPocket) {
          setHideBillPocket(response.hideBillPocket);
        }
        if (response && response['hideActnet']) {
          setHideActnet(response['hideActnet']);
        }
        if (response && response['hideMercadoPago']) {
          setHideMercadoPago(response['hideMercadoPago']);
        }
      });
    } else {
      setHideBillPocket(constants.hideBillPocket);
      setHideActnet(constants['hideActnet']);
      setHideMercadoPago(constants['hideMercadoPago']);
    }
  }, []);

  const shoppingCartError = () => {
    OpenMessage(
      'warning',
      disabelCheckoutMessage
    );
  };

  const retryPAyment = ({ payment }) => {
    const URL = ServicePaymentRetry();
    const params = {
      idNota: idOrder,
      idTpv: payment.id,
    };
    POST(URL, params).then((data) => {
      if (data === '401') {
        retryPAyment(payment);
        return '';
      }
      if (data.success && data.urlRedirect) {
        window.open(data.urlRedirect, '_self');
      } else {
        OpenMessage('error', 'Ha ocurrido un error al reintentar el pago. Por favor intente nuevamente');
      }
    });
  };

  function  getPendingOrder (order) {
    getOrders().then((data) => {
      const updatedOrders = data;
      console.log('updatedOrders', updatedOrders);
      const updatedOrder = updatedOrders.find((item) => item.idOrder === order.idOrder);
      if (!updatedOrder) {
        return;
      }
      if (updatedOrder.idOrderStatus === pendingPayment) {
        OpenMessage('warning', 'Pago en proceso de validación por su banco, intente más tarde.');
        return;
      }
      if (updatedOrder.idOrderStatus === pending) {
        OpenMessage('warning', 'Hubo un problema con su pago. por favor reintente el pago');
        return;
      }
      if (updatedOrder.idOrderStatus === completedtStatus) {
        OpenMessage('success', 'Pago validado con éxito.');
      }
    }).catch((error) => {
      console.log('error', error);
    });
  }

    if (!data || data.length === 0) {
    return (
      <div className={props.visible}>
        <div className="border-title">
          <div>Mis Órdenes</div>
        </div>
        <div className="mb-3 row inputs-form">
          <div className="col-12 text-center">No existen registros</div>
        </div>
      </div>
    );
  } else {
    return (
      <div className={'container__orders ' + props.visible}>
        <div className="border-title">
          <div>Mis Órdenes</div>
        </div>
        {data.map((item, i) => {
          return (
            <div className="mt-5 row" key={i}>
              <div className="col-12">
                <div className="card">
                  <div className="row">
                    <div className="col-12">
                      <div className="card-body">
                        <h5 className="card-title">No. de orden: {item.folio}</h5>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-6 col-sm-4">
                      <label className="container__orders-title"> Estado de la orden: </label>
                    </div>
                    <div className="col-6 col-sm-4">
                      <label className="container__orders-value">{item.status}</label>
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-6 col-sm-4">
                      <label className="container__orders-title"> Fecha de compra: </label>
                    </div>
                    <div className="col-6 col-sm-4">
                      <label className="container__orders-value"> {convertToDate(item.created)}</label>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-6 col-sm-4">
                      <label className="container__orders-title"> Total: </label>
                    </div>
                    <div className="col-6 col-sm-4">
                      <label className="container__orders-value">{formatCurrency(item.amount)}</label>
                    </div>
                    <div className="col-12 col-sm-4 container-fluid text-center">
                      <div className="row">
                        <div className="col">
                          <button className="btn btn-outline-secondary" onClick={(e) => openOrderDetail(item.idOrder)}>
                            Ver detalle
                          </button>
                        </div>
                        {item.idOrderStatus === pending && (
                          <div className="col">
                            <button
                              className="btn btn-outline-danger retry-payment"
                              data-bs-target="#modalPaymentGateways"
                              data-bs-toggle="modal"
                              /* 
                              ToDo: Se debe cambiar el metodo shoppingCartError() por  setIdOrder(item.idOrder)
                                Cuando se habilite de nuevo actnet. 
                                Se deben descomentar data-bs-target, data-bs-toggle
                              */
                              onClick={(e) => setIdOrder(item.idOrder) }
                              disabled={item.amount > 0 ? false : true}
                            >
                              Reintentar pago
                            </button>
                          </div>
                        )}
                        {item.idOrderStatus === pendingPayment && (
                          <div className="col">
                            <button
                              className="btn btn-outline-danger retry-payment"
                              onClick={(e) => getPendingOrder(item) }>
                              Verificar pago
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <PaymentGateways retryPAyment={retryPAyment} hideBillPocket={hideBillPocket} hideActnet={hideActnet} hideMercadoPago={hideMercadoPago} />
            </div>
          );
        })}
      </div>
    );
  }
};
