import React, { useEffect, useState } from 'react';
import { GET } from '../../helpers/APIServices';
import { AddressShipping } from './AddressShipping';
import ModalAddressShipping from './ModalAddressShipping';
//import { getAddresShipping } from '../../helpers/getConstants';
import { validSession, CloseModal, OpenMessage } from '../Utilities/General';
import { ServiceGetAddressShippingByIdCustomer, ServiceGetGeneralParameterById, ServiceGenericParameters } from '../../helpers/getURL';
import { getLimitShipping } from '../../helpers/getConstants';

export const AddressShippingAccount = (props) => {
  const [titleModal, setTitleModal] = useState('');
  const [listAddress, setListAddress] = useState([]);
  const [listCities, setListCities] = useState([]);
  const [maxAddress, setMaxAddress] = useState(false);
  const [address, setAddress] = useState({
    id: '00000000-0000-0000-0000-000000000000',
    idCustomer: localStorage.getItem('id'),
    idPais: 'MX',
    idEdo: '',
    idMun: '',
    idCol: '',
    scp: '',
    numero: '',
    numeroInt: '',
    razonSocial: '',
    colonia: '',
    calle: '',
    rfc: '',
    alias: '',
    referencia: '',
    esFisicoMoral: false,
    predeterminado: false,
  });

  const AddressRefresh = (flagDelete) => {
    const idCustomer = localStorage.getItem('id');
    if (!validSession()) {
      return;
    }
    const URL = ServiceGetAddressShippingByIdCustomer(idCustomer);
    GET(URL).then((data) => {
      if (data === '401') {
        AddressRefresh();
        return '';
      }
      if (flagDelete) {
        CloseModal('modalDeleteAddressShipping');
      }
      if (data) {
        setListAddress(data);
        const id = getLimitShipping();
        const URLParameter = ServiceGetGeneralParameterById(id);

        GET(URLParameter).then((response) => {
          if (response === '401') {
            AddressRefresh();
            return '';
          }
          if (response && response.valor !== undefined && !isNaN(response.valor)) {
            setMaxAddress(parseInt(data.length) >= parseInt(response.valor));
          } else {
            setMaxAddress(false);
          }
        });
      } else {
        setListAddress([]);
      }
    });
  };

  useEffect(() => {
    if (props.visible === 'show') {
      const Address = () => {
        const idCustomer = localStorage.getItem('id');
        if (!validSession()) {
          return;
        }

        const URL = ServiceGetAddressShippingByIdCustomer(idCustomer);
        GET(URL).then((data) => {
          if (data === '401') {
            Address();
            return '';
          }
          if (data) {
            setListAddress(data);

            const id = getLimitShipping();
            const URLParameter = ServiceGetGeneralParameterById(id);

            GET(URLParameter).then((response) => {
              if (response === '401') {
                Address();
                return '';
              }
              if (response && response.valor !== undefined && !isNaN(response.valor)) {
                setMaxAddress(parseInt(data.length) >= parseInt(response.valor));
              } else {
                setMaxAddress(false);
              }
            });
          }
        });
      };

      Address();
    }
  }, [props.visible]);

  const NDirectionsValidation = () => {
    const URL = ServiceGenericParameters(getLimitShipping());
    setTitleModal('Agregar');
    const inputs = ['input-Calle', 'input-Numero', 'input-referenceAddress', 'input-Alias', 'input-NumeroInt', 'input-Scp'];

    inputs.forEach((item) => {
      document.getElementById(item).classList.remove('active');
    });
    GET(URL).then((data) => {
      if (data === '401') {
        NDirectionsValidation();
        return '';
      }
      if (data) {
        if (listAddress.length >= data.value) {
          CloseModal('modalAddress');
          OpenMessage('warning', 'No se pueden registrar más de ' + data.value + ' direcciones de envío');
        }
      } else {
        OpenMessage('warning', 'No es posible registrar una dirección de envío');
      }
    });
  };

  return (
    <div className={'container__addressAccount ' + props.visible}>
      <div className="border-title">
        <div>Direcciones de envío</div>
      </div>
      <AddressShipping
        listAddress={listAddress}
        setAddress={setAddress}
        address={address}
        AddressRefresh={AddressRefresh}
        modal={'modalAddress'}
        setTitleModal={setTitleModal}
      />
      <div className="mb-3 row inputs-form justify-content-center">
        <div className="col-12 text-center">
          <button
            disabled={maxAddress}
            type="button"
            className="btn btn-outline-secondary btn-profile"
            data-bs-toggle="modal"
            data-bs-target="#modalAddress"
            onClick={() => NDirectionsValidation()}
          >
            Agregar dirección
          </button>
        </div>
      </div>
      <ModalAddressShipping
        setAddress={setAddress}
        address={address}
        AddressRefresh={AddressRefresh}
        setListCities={setListCities}
        listCities={listCities}
        titleModal={titleModal}
      />
    </div>
  );
};
