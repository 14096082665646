import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

//import { ConfigurationAccount } from './ConfigurationAccount';
import { ActionAccount } from './AuctionAccount';
import { AddressBillingAccount } from './AddressBillingAccount';
import { AddressShippingAccount } from './AddressShippingAccount';
import { AwardActive } from './AwardActive';
import { AwardHistory } from './AwardHistory';
import { Orders } from './Orders';
import { CustomerData } from './customerData/CustomerData';
import { Bids } from './Bids';
import { validSession, setSession } from '../Utilities/General';
import { GET } from '../../helpers/APIServices';
import { getConfig } from '../../helpers/getConfig';
import { ServiceGenericParameters } from '../../helpers/getURL';

import '../../css/Profile/ProfileGrid.css';

export const ProfileGrid = () => {
  const history = useHistory();

  if (!validSession()) {
    history.push('/?home=true');
  }

  const [dataGeneral, setDataGeneral] = useState('show');
  const [dataAuction, setDataAuction] = useState('hide');
  const [dataAwardActive, setDataAwardActive] = useState('hide');
  const [dataAwardHistory, setDataAwardHistory] = useState('hide');
  const [dataBid, setBid] = useState('hide');
  const [dataOrders, setDataOrders] = useState('hide');
  const [dataAddress, setDataAdress] = useState('hide');
  const [dataAddressInvoice, setDataAdressInvoice] = useState('hide');
  const [enableInvoice, setEnableInvoice] = useState(true);

  const { search } = useLocation();
  const option = new URLSearchParams(search).get('opcion');
  const isProfile = new URLSearchParams(search).get('profile');

  if (isProfile) {
    window.location.href = '/perfil';
  }

  const removeMenuActive = () => {
    let elements = document.getElementsByClassName('list-group-item-action');
    elements.forEach((element) => {
      element.classList.remove('active');
    });
  };

  useEffect(() => {
    if (option) {
      removeMenuActive();
      setDataGeneral('hide');
      switch (option) {
        case '3':
          setDataAwardActive('show');
          document.getElementById('liAwardActive').classList.add('active');
          break;
        case '5':
          setDataOrders('show');
          document.getElementById('liOrders').classList.add('active');
          break;
        default:
          break;
      }
    }
  }, [option]);

  useEffect(() => {
    const constants = JSON.parse(sessionStorage.getItem('constants'));
    if (constants && constants.enableInvoice) {
      const URL = ServiceGenericParameters(constants.enableInvoice);
      GET(URL).then((data) => {
        if (data && data.value) {
          const isTrue = data.value.toLowerCase() === 'true';
          setEnableInvoice(isTrue);
        }
      });
    }
  }, []);

  const closeSession = () => {
    setSession(false, '');
    history.push('/?home=true');
  };

  const openTab = (form, option) => {
    removeMenuActive();

    setDataGeneral('hide');
    setDataAuction('hide');
    setDataAwardActive('hide');
    setDataAwardHistory('hide');
    setBid('hide');
    setDataOrders('hide');
    setDataAdress('hide');
    setDataAdressInvoice('hide');

    option.classList.add('active');

    switch (form) {
      case 'GENERAL':
        setDataGeneral('show');
        break;
      case 'AUCTION':
        setDataAuction('show');
        break;
      case 'AWARDACTIVE':
        setDataAwardActive('show');
        break;
      case 'BID':
        setBid('show');
        break;
      case 'AWARDHISTORY':
        setDataAwardHistory('show');
        break;
      case 'ORDERS':
        setDataOrders('show');
        break;
      case 'ADDRESS':
        setDataAdress('show');
        break;
      case 'ADDRESSINVOICE':
        setDataAdressInvoice('show');
        break;
      default:
        break;
    }
  };

  return (
    <div className="container container__profile">
      <div className="row">
        <div className="col-12 col-sm-8 text-center-mobile">
          <h3 className="profile-title">Mi cuenta</h3>
        </div>
        <div className="col-12 col-sm-4 text-end text-center-mobile">
          <button className="btn btn-outline-danger btn-logout" onClick={closeSession}>
            <strong>
              <i className="fas fa-sign-out-alt"></i> Cerrar sesión
            </strong>
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-4">
          <ul className="list-group menu-profile">
            <li className="list-group-item menu-profile__title">
              <div>Configuración</div>
            </li>
            <li id="liGeneral" className="list-group-item list-group-item-action active" onClick={(e) => openTab('GENERAL', e.target)}>
              General
            </li>
            <li className="list-group-item list-group-item-action" onClick={(e) => openTab('AUCTION', e.target)}>
              Subastas
            </li>
            <li className="list-group-item list-group-item-action" onClick={(e) => openTab('BID', e.target)}>
              Mis Pujas
            </li>
            <li id="liAwardActive" className="list-group-item list-group-item-action" onClick={(e) => openTab('AWARDACTIVE', e.target)}>
              Mis Adjudicaciones Activas
            </li>
            <li className="list-group-item list-group-item-action" onClick={(e) => openTab('AWARDHISTORY', e.target)}>
              Historial de Adjudicaciones
            </li>
            <li id="liOrders" className="list-group-item list-group-item-action" onClick={(e) => openTab('ORDERS', e.target)}>
              Mis Órdenes
            </li>
            <li className="list-group-item list-group-item-action" onClick={(e) => openTab('ADDRESS', e.target)}>
              Direcciones de envío
            </li>
            {enableInvoice && (
              <li className="list-group-item list-group-item-action" onClick={(e) => openTab('ADDRESSINVOICE', e.target)}>
                Datos fiscales
              </li>
            )}
          </ul>
        </div>
        <div className="col-12 col-md-8 form-profile">
          {/* <ConfigurationAccount visible={dataGeneral} /> */}
          {dataGeneral === 'show' ? <CustomerData visible={dataGeneral} parent={'ProfileGrid'} close={null} /> : null}

          <ActionAccount visible={dataAuction} />
          <AddressShippingAccount visible={dataAddress} />
          <AddressBillingAccount visible={dataAddressInvoice} />
          <AwardActive visible={dataAwardActive} enableInvoice={enableInvoice} />
          <AwardHistory visible={dataAwardHistory} />
          <Bids visible={dataBid} />
          <Orders visible={dataOrders} />
        </div>
      </div>
    </div>
  );
};
