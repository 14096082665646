import React from 'react';

export const MenuStreaming = ({
  displayVideo,
  displayActualLot,
  displayAwards,
  setDisplayActualLot,
  setDisplayAwards,
  position,
}) => {
  return (
    <div
      className={`row mb-5 menu-stream-movil ${
        position === 'bottom' ? 'menu-bottom' : ''
      }`}
    >
      <div className='col-4 text-center'>
        <label
          className={`text-uppercase text_icons ${
            displayVideo ? 'displayed' : ''
          }`}
        >
          <img
            src='/resources/streaming2.png'
            alt='streaming'
            className='icons_streaming icons_streaming--border'
          />{' '}
          streaming
        </label>
      </div>
      <div
        className='col-4  text-center'
        onClick={(e) => [setDisplayActualLot(true), setDisplayAwards(false)]}
      >
        <label
          className={`text-uppercase text_icons ${
            displayActualLot ? 'displayed' : ''
          }`}
        >
          <img
            src={`/resources/actualLot${displayActualLot ? '2' : ''}.png`}
            alt='Actual lot'
            className={`icons_streaming ${
              displayActualLot ? 'icons_streaming--border' : ''
            }`}
          />{' '}
          lote actual
        </label>
      </div>
      <div
        className='col-4  text-center'
        onClick={(e) => [setDisplayActualLot(false), setDisplayAwards(true)]}
      >
        <label
          className={`text-uppercase text_icons ${
            displayAwards ? 'displayed' : ''
          }`}
        >
          <img
            src={`/resources/awards${displayAwards ? '2' : ''}.png`}
            alt='Awards'
            className={`icons_streaming ${
              displayAwards ? 'icons_streaming--border' : ''
            }`}
          />{' '}
          adjudicaciones
        </label>
      </div>
    </div>
  );
};
