import React, { useState, useEffect } from 'react';

import { getImgDefault } from '../../../helpers/getConstants';
import { formatCurrency } from '../../Utilities/General';

export const OrderDetail = (props) => {
  const [addressBilling, setAddressBilling] = useState('');
  const [addressBillingTitle, setAddressBillingTitle] = useState('');
  const [address, setAddress] = useState('');
  const [delivery, setDelivery] = useState('');
  const [lots, setLots] = useState([]);
  const [payment, setPayment] = useState('Bill Pocket');
  const [hideInvoice, setHideInvoice] = useState('hide');
  const [hideDelivery, setHideDelivery] = useState('hide');
  const [cart, setCart] = useState([]);

  useEffect(() => {
    const shoppingCart = JSON.parse(localStorage.getItem('cart'));
    if (shoppingCart && shoppingCart.length > 0) {
      setCart(shoppingCart);
    }
    if (props.addressBilling && props.addressBillingTitle && !props.consiga) {
      setAddressBillingTitle(props.addressBillingTitle);
      setAddressBilling(props.addressBilling);
      setHideInvoice('');
    }
    if (!props.addressBilling) {
      setHideInvoice('hide');
    }
    if (props.address) {
      setAddress(props.address);
    }
    if (props.delivery) {
      setDelivery(props.delivery);
      setHideDelivery('');
    }
    if (props.lots) {
      setLots(props.lots);
    }
    if (props.payment) {
      setPayment(props.payment);
    }
  }, [props.addressBilling, props.address, props.lots, props.delivery, props.consiga, props.addressBillingTitle, props.payment]);

  const deleteCart = (idLote) => {
    let index = -1;
    cart.filter((item, i) => {
      if (item && item.IdLot === idLote) {
        index = i;
      }
      return i;
    });
    if (index !== -1) {
      let item = [];
      delete cart[index];
      localStorage.removeItem('cart');
      for (let i = 0; i < cart.length; i++) {
        if (cart[i]) {
          item.push(cart[i]);
        }
      }
      if (item.length > 0) {
        localStorage.setItem('cart', JSON.stringify(item));
      }
    }
  };

  return (
    <div className="card">
      <div className="card-header text-center">
        <h3>Número de pedido</h3>
      </div>
      <div className="card-body">
        <div className={'row mb-2 ' + hideDelivery}>
          <div className="col-12">
            <h5 className="card-text card-title">Método de envío</h5>
          </div>
        </div>
        <div className={'row mb-5 ' + hideDelivery}>
          <div className="col-8">
            <p>{delivery}</p>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-12">
            <h5 className="card-text card-title">Dirección de envío</h5>
          </div>
        </div>
        <div className="row mb-5">
          <div className="col-8">
            <p>{address}</p>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-12">
            <h5 className="card-text card-title">Método de pago</h5>
          </div>
        </div>
        <div className="row mb-5">
          <div className="col-8">
            <p>{payment}</p>
          </div>
        </div>
        <div className={'row mb-2 ' + hideInvoice}>
          <div className="col-12">
            <h5 className="card-text card-title">Dirección de facturación</h5>
          </div>
        </div>
        <div className={'row mb-5 ' + hideInvoice}>
          <div className="col-8">
            <p>{addressBillingTitle}</p>
            <p>{addressBilling}</p>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-12">
            <h5 className="card-text card-title">Materiales:</h5>
          </div>
        </div>

        {lots.map((lot, i) => {
          const img = lot.firstImageLote ? lot.firstImageLote : getImgDefault();
          deleteCart(lot.idLote);
          return (
            <div key={i}>
              <div className="mb-3 row inputs-form">
                <div className="col-12">
                  <div className="mb-3">
                    <div className="row g-0 align-items-center">
                      <div className="col-2">
                        <img src={img} className="img-fluid rounded-start" alt={''} />
                      </div>
                      <div className="col-md-10">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-3">
                              <p className="card-text">{lot.nameLote}</p>
                            </div>
                            <div className="col-6">
                              <p className="card-text">{lot.descriptionLote}</p>
                            </div>
                            <div className="col-3">
                              <small className="text-muted">{formatCurrency(lot.priceLote)}</small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
