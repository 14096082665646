import React, { useState, useEffect } from 'react';

export const FiltersCatalog = ({
  idSection = '',
  title = '',
  filters = [],
  getByChecks,
}) => {
  const [displayed, setDisplayed] = useState(true);
  const [arrayFilters, setArrayFilters] = useState(filters);

  const onCheckFilter = (idFilter) => {
    const filterSections = arrayFilters.map((filter) => {
      if (idFilter === filter.id) {
        filter.value = filter.value ? null : true;
      }
      return filter;
    });
    setArrayFilters(filterSections);
    getByChecks(filterSections, idSection, arrayFilters);
  };

  useEffect(() => {
    setArrayFilters(filters);
  }, [arrayFilters]);

  if (filters.length > 0) {
    return (
      <div className='mt-5'>
        <div
          className='row d-flex justify-content-center pt-5 pb-3 title-cat-section'
          onClick={(e) => setDisplayed(!displayed)}
        >
          <div className='col'>
            <h3 className='title-cats'>{title}</h3>
          </div>
          <div className='col text-end'>
            <img
              src={`./resources/arrow_drop_${displayed ? 'down' : 'up'}.png`}
              alt='arrow'
              className='arrows'
            />
          </div>
        </div>
        {displayed && (
          <div className='row mt-4 px-3 scroll-cats'>
            {arrayFilters.map((filter) => (
              <div className='form-check d-flex' key={filter.id}>
                <input
                  id={filter.id}
                  className='form-check-input input-check-cat'
                  type='checkbox'
                  onClick={(e) => onCheckFilter(filter.id)}
                  checked={filter.value ? true : false}
                  onChange={(e) => {}}
                />
                <label
                  className='form-check-label filter-label'
                  htmlFor='flexCheckDefault'
                >
                  {filter.description}{' '}
                  <span className='font-weight-normal'>({filter.items})</span>
                </label>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
  return null;
};
