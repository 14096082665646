export const getConfig = async (file) => {
  const url = '/resources/Config/' + file;
  const data = await fetch(url, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  })
    .then((response) => {
      return response.json();
    })
    .then((resp) => {
      return resp;
    });
  return data;
};
