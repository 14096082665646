import React, { useEffect, useState, Fragment, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { POST } from '../../helpers/APIServices';
import { validSession, OpenMessage } from '../Utilities/General';
import { ServiceCheckOnToJoinStreming } from '../../helpers/getURL';
import { DataContext } from '../../context/DataContext';
import { getUserRejectedStatus } from '../../helpers/getConstants';

export const ButtonToStreaming = (props) => {
  const history = useHistory();
  const statusRejected = getUserRejectedStatus();
  const { userStatusLastCatalog = '' } = props;
  const [button, setButton] = useState(
    <button
      id={'btnToStreaming_' + props.idAuction}
      className={`btn btn-outline-danger btn-lg button-streaming ${props.classStyle}`}
    >
      Unirse a streaming
    </button>
  );
  const [messagge, setMessagge] = useState('');
  const IdClient = localStorage.getItem('id');
  const {
    validate: { valid, idPallet },
  } = useContext(DataContext);
  const modalError = () => {
    if (userStatusLastCatalog === statusRejected) {
      OpenMessage(
        'warning',
        'Su cuenta se encuentra bloqueada, favor de contactarnos.'
      );
    } else {
      OpenMessage('warning', messagge);
    }
  };

  useEffect(() => {
    checkOnToJoinStreming();
  }, []);

  useEffect(() => {
    if (idPallet) {
      checkOnToJoinStreming();
    }
  }, [valid]);

  useEffect(() => {
    if (IdClient) {
      checkOnToJoinStreming();
    }
  }, [IdClient]);

  const checkOnToJoinStreming = () => {
    if (!validSession()) {
      return;
    }
    const URL = ServiceCheckOnToJoinStreming();

    const auctionClient = {
      IdClient,
      IdAuction: props.idAuction,
    };

    const onValidate = () => {
      if (userStatusLastCatalog === statusRejected) {
        OpenMessage(
          'warning',
          'Su cuenta se encuentra bloqueada, favor de contactarnos.'
        );
      } else {
        history.push(`/streaming?id=${props.idAuction}`);
      }
    };
    POST(URL, auctionClient).then((data) => {
      if (data && data === true) {
        setButton(
          <button
            id={'btnToStreaming_' + props.idAuction}
            className={`btn btn-outline-danger btn-lg button-streaming ${props.classStyle}`}
            onClick={() => onValidate()}
          >
            Unirse a streaming
          </button>
        );
      }
      if (data && data !== true) {
        setButton(
          <button
            id={'btnToStreaming_' + props.idAuction}
            type='button'
            onClick={() => modalError()}
            className={`btn btn-outline-danger btn-lg button-streaming ${props.classStyle}`}
          >
            Unirse a streaming
          </button>
        );
        setMessagge(data);
      }
    });
  };
  return <Fragment>{button}</Fragment>;
};
