import React, { useEffect, useState } from 'react';
import { renderToString } from 'react-dom/server';

import AuctionsHighlightItem from './AuctionsHighlightItem';
import AuctionsHighlight from './AuctionsHighlight';
import { ButtonRegister } from '../Register/ButtonRegister';
import { getImgDefault } from '../../helpers/getConstants';
import { convertToDate } from '../Utilities/General';

export const AuctionsHighlightSection = (props) => {
  const [state, setState] = useState({
    articles: [],
    auctionName: '',
    auctionDate: new Date(),
    idAuction: '',
    rowsItems: '',
  });
  const itemsArray = [];

  const setAuctionsHigh = () => {
    let index = 0;
    state.articles.map((article, i) => {
      if (i % 3 === 0) {
        itemsArray.push([]);
        index++;
      }

      let item = renderToString(
        <AuctionsHighlightItem
          id={article.idLot}
          idAuction={props.idAuction}
          title={article.name}
          description={article.description}
          reference={article.order}
          url={
            article.lstImageLote && article.lstImageLote.length > 0 && article.lstImageLote[0] ? article.lstImageLote[0] : getImgDefault()
          }
          price={article.startingPrice}
          key={article.idLot}
          offerLot={article.lstOffertsLot}
          nameClass={i === 0 || i % 3 === 0 ? '' : 'col-xxl-2 container-auctionsHighlight-items--ml offset-xxl-2'}
        />
      );
      itemsArray[index - 1].push(item);
      return ''; //asi esta bien que regrese vacio
    });

    let divRow = '';
    for (let i = 0; i < itemsArray.length; i++) {
      divRow += '<div class="row justify-content-center">';
      for (let j = 0; j < itemsArray[i].length; j++) {
        divRow += itemsArray[i][j];
      }
      //se acomodan a 3 columnas siempre, va de acuerdo a la seccion de "Proxima Subasta" del home
      //los estilos van de acuerdo a los que se encuentran en el componente AuctionsHighlightItem
      for (let k = itemsArray[i].length; k < 3; k++) {
        divRow += '<div class="col-3 col-xxl-2 mb-5 container-auctionsHighlight-items--ml offset-xxl-2" ></div>';
      }
      divRow += '</div>';
    }
    return divRow;
  };

  useEffect(() => {
    const data = props.data;
    if (data) {
      setState((prevState) => ({
        ...prevState,
        name: data.id ? data.name : '',
        idAuction: data.id,
        auctionDate: convertToDate(data.auctionDate),
      }));
    }
    setState((prevState) => ({
      ...prevState,
      articles: [],
    }));
    if (props.articles) {
      setState((prevState) => ({
        ...prevState,
        articles: props.articles,
      }));
      const div = document.getElementById('AuctionsHighlightDiv');
      if (div && props.articles.length > 0) {
        div.classList.remove('visually-hidden');
      }
    }
  }, [props]);

  if (state.idAuction) {
    return (
      <div id="AuctionsHighlightDiv" className="visually-hidden">
        <AuctionsHighlight title="Destacados" title2="de subasta">
          <div dangerouslySetInnerHTML={{ __html: setAuctionsHigh() }}></div>
          <div className="row">
            <div className="col text-center container-auctions-button">
              <ButtonRegister
                idAuction={state.idAuction}
                nameAuction={state.name}
                dateAuction={state.auctionDate}
                classStyle={'btn-carousel-secondary'}
              />
            </div>
          </div>
        </AuctionsHighlight>
      </div>
    );
  }
  return null;
};
