import React, { useState, useEffect } from 'react';
import {
  formatCurrency,
  OpenMessage,
  OpenOfferSuccess,
  setCart,
  validSession,
} from '../Utilities/General';
import { POST, DELETE, GET } from '../../helpers/APIServices';
import {
  ServiceFavoritesDeletetById,
  ServiceFavoritesPost,
  ServiceGetNextLotOffer,
  ServicePostMaxOfert,
} from '../../helpers/getURL';
import { Offer } from '../Register/Offer';
import {
  getIdGoldenMinutes,
  getUserRejectedStatus,
  getAwardLot,
  getAvailableLot,
  getRetiredLot,
} from '../../helpers/getConstants';

export const ProductGridItem = (props) => {
  const [loading, setLoading] = useState(false);
  const [offer, setOffer] = useState(false);
  const [offerLot, setOfferLot] = useState(props.offerLot);
  const [offerSelected, setOfferSelected] = useState(
    props.offerLot?.length > 0 ? props.offerLot[0].precio : props.price
  );
  const [confirm, setConfirm] = useState(false);
  const [statusDescription, setStatusDescription] = useState('');
  const [cardColor, setCardColor] = useState('');
  const [headerCard, setheaderCard] = useState('');
  const [iconLot, setIconLot] = useState('');
  const statusRejected = getUserRejectedStatus();
  const awardID = getAwardLot().toLowerCase();
  const availableID = getAvailableLot();
  const retiredID = getRetiredLot();
  const IdCustomer = localStorage.getItem('id');
  const actual = 'ACTUAL';
  const {
    id,
    price,
    url,
    title,
    reference,
    description,
    idAuction,
    registered,
    favorities,
    idPaleta,
    idType,
    idStatus,
    auctionTypes,
    auctionStatus,
    format,
    idLoteStatus,
    enablePostSale,
    statusCustomer,
    clicked,
    setClicked,
    consigna,
    consignaSAP,
    finalPrice,
    actualLotID,
    idClient,
  } = props;

  useEffect(() => {
    const statusLot =
      id === actualLotID &&
      idType === auctionTypes.idStreaming &&
      idStatus === auctionStatus.execution
        ? actual
        : idLoteStatus;
    switch (statusLot) {
      case actual:
        setStatusDescription('Estatus: Ahora');
        setCardColor('lot-now');
        setheaderCard('now-bg');
        setIconLot('info2');
        break;
      case awardID:
        if (idClient === IdCustomer) {
          setStatusDescription('Estatus: Ganado');
          setCardColor('lot-won');
          setheaderCard('won-bg');
          setIconLot('done2');
        } else {
          setStatusDescription('Estatus: Vendido');
          setCardColor('lot-sold');
          setheaderCard('sold-bg');
          setIconLot('Group 18329');
        }
        break;
      case availableID:
        setStatusDescription('Lote ' + reference);
        setCardColor('lot-next');
        setheaderCard('next-bg');
        setIconLot('minus');
        break;
      case retiredID:
        setStatusDescription('Estatus: Retirado');
        setCardColor('lot-retired');
        setheaderCard('retired-bg');
        setIconLot('cross2');
        break;
      default:
        break;
    }
  }, []);

  const addFavorite = (e) => {
    const item = document.getElementById(`favoriteItem-${id}`);
    let isFavorite = item.getAttribute('isfavorite');
    const idCustomer = localStorage.getItem('id');
    if (isFavorite === 'true' ? true : false) {
      item.src = './resources/Star6.svg';
      item.setAttribute('isfavorite', 'false');
      const URLFavoritesDelete = ServiceFavoritesDeletetById(idCustomer);
      let url = new URL(URLFavoritesDelete);
      let params = {
        idLot: id,
      };
      Object.keys(params).forEach((key) => {
        if (!params[key]) {
          delete params[key];
        }
      });
      Object.keys(params).forEach((key) => {
        url.searchParams.append(key, params[key]);
      });
      DELETE(url)
        .then((data) => {
          if (data && typeof data !== 'boolean') {
            item.src = './resources/Star4.svg';
            item.setAttribute('isfavorite', 'true');
            OpenMessage('error', 'Por favor intente nuevamente');
          }
        })
        .catch((err) => {
          item.src = './resources/Star4.svg';
          item.setAttribute('isfavorite', 'true');
          OpenMessage('error', 'Por favor intente nuevamente');
        });
    } else {
      item.src = './resources/Star4.svg';
      item.setAttribute('isFavorite', 'true');
      const URLFavoritesPost = ServiceFavoritesPost(id);
      const params = {
        idAuction: idAuction,
        idCustomer: idCustomer,
        idLot: id,
      };

      POST(URLFavoritesPost, params)
        .then((data) => {
          if (data && typeof data !== 'object') {
            item.src = './resources/Star6.svg';
            item.setAttribute('isfavorite', 'false');
            OpenMessage('error', 'Por favor intente nuevamente');
          }
        })
        .catch((err) => {
          item.src = './resources/Star6.svg';
          item.setAttribute('isfavorite', 'false');
          OpenMessage('error', 'Por favor intente nuevamente');
        });
    }
  };
  const handleOffer = (e) => {
    e.stopPropagation();
    if (statusCustomer.toLowerCase() === statusRejected) {
      OpenMessage(
        'warning',
        'Su cuenta se encuentra bloqueada, favor de contactarnos.'
      );
    } else {
      if (offerLot.length <= 0) {
        nextLotOffer(price);
      } else {
        setOfferSelected(offerLot[0].precio);
        setOffer(true);
      }
    }
  };
  const handleConfirm = () => {
    const URLMaxOfert = ServicePostMaxOfert();
    const idCliente = localStorage.getItem('id');
    const json = {
      idAuction,
      idLote: id,
      idCliente,
      idPaleta,
      monto: offerSelected,
      validado: true,
    };
    setLoading(true);
    POST(URLMaxOfert, json).then((resp) => {
      if (resp && typeof resp === 'boolean') {
        const message =
          '<h2>SU OFERTA HA SIDO</h2><h2>REALIZADA CON ÉXITO.</h2>';
        OpenOfferSuccess(message, false);
        setConfirm(true);
        setLoading(false);
      } else if (resp && typeof resp === 'object') {
        OpenMessage('error', 'Por favor intente nuevamente.');
        setLoading(false);
      } else {
        OpenMessage('error', resp);
        setLoading(false);
      }
    });
  };
  const nextLotOffer = (price) => {
    setLoading(true);
    const lastElement = price ? price : offerLot[offerLot.length - 1].precio;
    const URLGetNextOffert = ServiceGetNextLotOffer(
      idAuction,
      id,
      lastElement,
      30
    );
    GET(URLGetNextOffert).then((data) => {
      if (data) {
        const nextOfferLot = data.map((price) => {
          price.idLote = id;
          price.idSubasta = idAuction;
          price.incremento = price.increment;
          price.precio = price.newPrice;
          return price;
        });
        setOfferLot((prevState) => [...prevState, ...nextOfferLot]);
        setOfferSelected(nextOfferLot[0].precio);
        setOffer(true);
        setLoading(false);
      } else {
        OpenMessage('error', data);
      }
    });
  };
  let img = './resources/Star6.svg';
  if (favorities.length > 0)
    img = favorities.includes(id)
      ? './resources/Star4.svg'
      : './resources/Star6.svg';

  const favorite =
    validSession() && props.idType !== getIdGoldenMinutes() ? (
      <img
        src={img}
        onClick={() => addFavorite(id)}
        isfavorite={favorities.includes(id).toString()}
        id={`favoriteItem-${id}`}
        alt='favorite'
        className='img-fluid'
        width='22px'
      />
    ) : (
      ''
    );

  const handleScroll = (event) => {
    var node = event.target;
    const value = node.scrollHeight - node.scrollTop;
    const bottom =
      Math.floor(value) === node.clientHeight ||
      Math.floor(value) === node.clientHeight - 1;
    if (bottom && !loading) {
      nextLotOffer();
    }
  };

  const addCartItem = () => {
    if (statusCustomer === statusRejected) {
      OpenMessage(
        'warning',
        'Su cuenta se encuentra bloqueada, favor de contactarnos.'
      );
    } else {
      let cart = {
        IdLot: id,
        Order: reference,
        Name: title,
        Description: description,
        Price: price,
        Image: url,
        consigna: consigna,
        consignaSAP: consignaSAP,
        IdAuction: idAuction,
      };
      console.log('cartItem', cart);
      props.addCartItem(cart);
      setCart(cart);
    }
  };
  if (props.format === 1) {
    return (
      <div className='col w-md-90 mt-5 slidein'>
        <div className={`card h-100 card-products-items ${cardColor}`}>
          <div
            className={`text-white ${headerCard} d-flex justify-content-center head-lots`}
          >
            <img
              className='py-3'
              src={`./resources/${iconLot}.png`}
              alt='won'
            />
            <h4 className='pt-4 px-5 text-uppercase'>{statusDescription}</h4>
          </div>
          <div className='d-flex justify-content-end p-4'>{favorite}</div>
          <div className='container-products-items--img p-0 '>
            <img src={url} className='card-img-top img-lot' alt='...' />
          </div>
          <div className='card-body text-center'>
            <p className='container-products-items--title'>{title}</p>
            <p className='product-text'>
              Lote <span className='article-reference'>{reference}</span>
            </p>
            {idLoteStatus === awardID && finalPrice >= price ? (
              <span className='product-text'>
                PRECIO DE VENTA:{' '}
                <span className='price-text article-reference'>
                  {formatCurrency(finalPrice)}
                </span>
              </span>
            ) : (
              <span className='product-text'>
                PRECIO DE SALIDA:{' '}
                <span className='price-text article-reference'>
                  {formatCurrency(price)}
                </span>
              </span>
            )}
          </div>
          {registered && (
            <div className='card-footer text-center article-footer'>
              <Offer
                offerLot={offerLot}
                format={format}
                offer={offer}
                setOffer={setOffer}
                offerSelected={offerSelected}
                setOfferSelected={setOfferSelected}
                confirm={confirm}
                setConfirm={setConfirm}
                handleScroll={handleScroll}
                handleOffer={handleOffer}
                handleConfirm={handleConfirm}
                loading={loading}
                idType={idType}
                idStatus={idStatus}
                auctionTypes={auctionTypes}
                auctionStatus={auctionStatus}
                idLoteStatus={idLoteStatus}
                addCartItem={addCartItem}
                enablePostSale={enablePostSale}
                clicked={clicked}
                setClicked={setClicked}
                idLot={id}
              />
            </div>
          )}
          <div className='card-footer text-center article-footer'>
            <button
              className='btn btn-outline-secondary button-eye'
              data-mdb-toggle='modal'
              data-mdb-target={`#modal${id}`}
              onClick={(e) => e.stopPropagation()}
            >
              <i className='fas fa-eye'></i>
              <strong> Detalles</strong>
            </button>
          </div>
        </div>
        <div
          className='modal fade modal-top modal-lot'
          id={`modal${id}`}
          aria-labelledby='exampleModalLabel'
          aria-hidden='true'
        >
          <div className='modal-dialog modal-dialog-centered'>
            <div className='modal-content'>
              <div className='modal-body text-center'>
                <img src={url} alt='' className='img-fluid h-100' />
              </div>
              <h4 className='text-desc'>{description}</h4>
              <div className='modal-footer'>
                <button
                  type='button'
                  className='btn btn-outline-danger'
                  data-mdb-dismiss='modal'
                  onClick={(e) => e.stopPropagation()}
                >
                  Cerrar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className='col w-md-90 mt-5 slidein'>
      <div className={`card mb-3 ${cardColor}`}>
        <div
          className={`text-white ${headerCard} d-flex justify-content-center head-lots`}
        >
          <img className='py-3' src={`./resources/${iconLot}.png`} alt='won' />
          <h4 className='pt-4 px-5 text-uppercase'>{statusDescription}</h4>
        </div>
        <div className='row g-0 card-dimension h-100'>
          <div className='col-md-4 image-list'>
            <img src={url} alt='...' className='img-lot-2' />
          </div>
          <div className='col-md-8 card-dimension'>
            <div className='card-body card-left'>
              <div className='d-flex justify-content-end p-4'>{favorite}</div>
              <h5 className='text-bottom'>{title}</h5>
              <p className='product-text'>
                Lote <span className='article-reference'>{reference}</span>
              </p>
              {idLoteStatus === awardID && finalPrice >= price ? (
                <span className='product-text'>
                  PRECIO DE VENTA:{' '}
                  <span className='price-text article-reference'>
                    {formatCurrency(finalPrice)}
                  </span>
                </span>
              ) : (
                <span className='product-text'>
                  PRECIO DE SALIDA:{' '}
                  <span className='price-text article-reference'>
                    {formatCurrency(price)}
                  </span>
                </span>
              )}
              <div className='card-body d-flex justify-content-end align-items-center'>
                {registered && (
                  <Offer
                    offerLot={offerLot}
                    format={format}
                    offer={offer}
                    setOffer={setOffer}
                    offerSelected={offerSelected}
                    setOfferSelected={setOfferSelected}
                    confirm={confirm}
                    setConfirm={setConfirm}
                    handleScroll={handleScroll}
                    handleOffer={handleOffer}
                    handleConfirm={handleConfirm}
                    loading={loading}
                    idType={idType}
                    idStatus={idStatus}
                    auctionTypes={auctionTypes}
                    auctionStatus={auctionStatus}
                    idLoteStatus={idLoteStatus}
                    addCartItem={addCartItem}
                    enablePostSale={enablePostSale}
                    clicked={clicked}
                    setClicked={setClicked}
                    idLot={id}
                  />
                )}
                <button
                  className='btn btn-outline-secondary button-eye float-right'
                  data-mdb-toggle='modal'
                  data-mdb-target={`#modal${id}`}
                  onClick={(e) => e.stopPropagation()}
                >
                  <i className='fas fa-eye'></i>
                  <strong> Detalles</strong>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className='modal fade modal-top modal-lot'
        id={`modal${id}`}
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-body text-center'>
              <img src={url} alt='' className='img-fluid h-100' />
            </div>
            <h4 className='text-desc'>{description}</h4>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-outline-danger'
                data-mdb-dismiss='modal'
                onClick={(e) => e.stopPropagation()}
              >
                Cerrar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
