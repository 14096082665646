import React, { useState, useEffect } from 'react';
import { getImgDefault } from '../../helpers/getConstants';
import { ProductGridItem } from './ProductGridItem';

export const ProductGrid = (props) => {
  const handdleAddCartItem = (data) => {
    props.addCartItem(data);
  };

  const [clicked, setClicked] = useState('');
  const [numCards, setNumCards] = useState('row-cols-md-3 g-3');

  const url = getImgDefault();
  useEffect(() => {
    resizing();
  }, []);

  const resizing = () => {
    const intFrameWidth = window.innerWidth;

    if (intFrameWidth < 1261) {
      setNumCards('row-cols-md-2 g-4');
    } else {
      setNumCards('row-cols-md-3 g-3');
    }
  };

  window.onresize = resizing;

  if (props.articles.length > 0) {
    return (
      <div
        className={`row row-cols-1 ${
          props.format === 1 ? numCards : 'row-cols-md-12'
        }`}
      >
        {props.articles.map((article) => (
          <ProductGridItem
            id={article.idLot}
            idAuction={props.idAuction}
            title={article.name}
            description={article.description}
            reference={article.order}
            consigna={article.consignacion}
            consignaSAP={article.consignacionSAP}
            url={
              article?.imageLote && article?.imageLote
                ? article.imageLote
                : url
            }
            price={article.startingPrice}
            finalPrice={article.finalPrice}
            key={article.idLot}
            offerLot={article.lstOffertsLot ? article.lstOffertsLot?.sort(
              (a, b) => a.precio - b.precio
            ) : []}
            format={props.format}
            favorities={props.favorities}
            registered={props.registered}
            idPaleta={props.idPallet}
            idType={props.idType}
            idStatus={props.idStatus}
            auctionTypes={props.auctionTypes}
            auctionStatus={props.auctionStatus}
            idLoteStatus={article.idLoteStatus}
            addCartItem={handdleAddCartItem}
            enablePostSale={article.enablePostSale}
            statusCustomer={props.statusCustomer}
            clicked={clicked}
            setClicked={setClicked}
            actualLotID={props.actualLotID}
            idClient={article.idClient}
          />
        ))}
      </div>
    );
  } else {
    return <h5>No hay registros...</h5>;
  }
};
