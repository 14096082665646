/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { useState } from 'react';

export const OrderCatalog = (props) => {
  const [orders] = useState([
    { label: 'Ordenar por', value: '' },
    { label: 'Precio: de menor a mayor', value: '01' },
    { label: 'Precio: de mayor a menor', value: '02' },
    { label: 'Lote: ascendente', value: '03' },
    { label: 'Lote: descendente', value: '04' },
    { label: 'Categoría: ascendente', value: '05' },
    { label: 'Categoría: descendente', value: '06' },
  ]);

  return (
    <form>
      <div className='dropdown shadow-0'>
        <button
          className='btn dropdown-toggle drop-element-2 shadow-0'
          role='button'
          data-mdb-toggle='dropdown'
          id='dropdownMenuButton'
          aria-expanded='false'
        >
          {props.searchOrder}
        </button>
        <ul className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
          {orders.map((item, i) => {
            return (
              <li key={i}>
                <a
                  href='#'
                  className='dropdown-item drop-items'
                  onClick={props.handleSelectOrder}
                >
                  {item.label}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </form>
  );
};
