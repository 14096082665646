import React, { useContext } from 'react'
import { DataContext } from '../../context/DataContext';
import { ServiceValidationRegisterToken } from '../../helpers/getURL'
import { GET } from '../../helpers/APIServices'
import { OpenMessage, OpenModal, OpenStreamingRegisterMessage } from '../Utilities/General'
import { TokenStatusContext } from '../../context/TokenStatusContext'
import { useHistory } from 'react-router-dom'

export const ButtonRegister = ({ idAuction, nameAuction, dateAuction, idPallet, classStyle }) => {
  const {
    validate,
    setValidate,
  } = useContext(DataContext);

  const {setRenderMpPaymentForm, setTokenStatus} = useContext(TokenStatusContext);

  const history = useHistory();

  const { token = '' } = localStorage;

  const clearState = () => {
    setValidate({
      valid: false,
      registeredAuctions: [...validate.registeredAuctions],
      idAuction: '',
      nameAuction: '',
      dateAuction: '',
      palletAuction: '',
      idPallet: '',
    });
  };

  const saveToken = (data) => {
    OpenStreamingRegisterMessage();
    setValidate({
      valid: false,
      registeredAuctions: [
        ...validate.registeredAuctions,
        {
          idAuction,
          pallet: data.description,
          streaming: data.streaming,
          idPallet: data.idPalette,
        },
      ],
      idAuction,
      nameAuction,
      dateAuction,
      palletAuction: data.description,
      idPallet: data.idPalette,
    });
  };

  function openRegisterModal (idAuction, nameAuction, dateAuction, idPallet) {
    const { id = ''} = localStorage;
    const URLServiceValidationRegisterToken = ServiceValidationRegisterToken(
      id,
      idAuction
    );
    GET(URLServiceValidationRegisterToken).then((data) => {
      if (data.success) {
        const response = data.data;
        setTokenStatus(response.statusToken)
        if (response.redirectToValidation === true){
          history.push(response.urlValidation)
          setValidate({
            valid: true,
            registeredAuctions: [...validate.registeredAuctions],
            idAuction,
            nameAuction,
            dateAuction,
            idPallet,
          })
        }
        else {
          if (response.statusToken === 1) {
            saveToken(response)
          }
          else if (response.statusToken === 3) {
            OpenMessage('warning', 'Si pago esta pendiente, intente nuevamente en unos minutos.')
            clearState()
          }
          else if (response.statusToken === 0 || response.statusToken === 2) {
            setRenderMpPaymentForm(true)
            OpenModal('modalRegisterToOffer')
            setValidate({
              valid: true,
              registeredAuctions: [...validate.registeredAuctions],
              idAuction,
              nameAuction,
              dateAuction,
              idPallet,
            })
          } else {
            OpenMessage('warning', 'Ocurrió un error al validar su tóken.')
            clearState()
          }
        }
      } else {
        OpenMessage('warning', 'Ocurrió un error al validar su tóken.')
        clearState()
      }
    }).catch((error) => {
    })
  }

  function openLoginModal () {
    OpenModal('modalLogin');
  }

  return (
    <button
      id={'btnRegister_' + idAuction}
      className={`btn btn-outline-secondary btn-lg ${classStyle}`}
      onClick={(e) =>
        token !== ''
          ? openRegisterModal(idAuction, nameAuction, dateAuction, idPallet)
          : openLoginModal()
      }
    >
      Registrarse para ofertar
    </button>
  );
};
