import React, { useState, useEffect } from 'react';
import { POST, PUT, GET } from '../../helpers/APIServices';
import { ServiceCustomerInfoAddressBilling } from '../../helpers/getURL';
import { SelectCountry } from './SelectCountry';
import { SelectState } from './SelectState';
import { SelectTown } from './SelectTown';
import { SelectCity } from './SelectCity';
import {
  CloseModal,
  verifyFieldsRequerid,
  OpenMessage,
  onlyNumber,
  onlyTextNumber,
  onlyTextNumber2,
  validRFC,
  onlyMail,
  validEmail,
} from '../Utilities/General';
import { ServiceGetByZipCode } from '../../helpers/getURL';

import '../../css/Login/RecoveryPassword.css';
import { CloseModalIcon } from '../Home/CloseModalIcon';

const ModalAddressBilling = (props) => {
  const [errors, setErrors] = useState({});
  const clearForm = () => {
    props.setAddress({
      id: '00000000-0000-0000-0000-000000000000',
      idCustomer: localStorage.getItem('id'),
      nombreCompleto: '',
      idPais: 'MX',
      idEdo: '',
      idMun: '',
      idCol: '',
      scp: '',
      numero: '',
      numeroInt: '',
      razonSocial: '',
      colonia: '',
      calle: '',
      rfc: '',
      referencia: '',
      esFisicoMoral: false,
      correo: '',
    });

    const esFiscoMoral = document.getElementsByName('esfiscoMoral-i');
    esFiscoMoral.forEach((x) => {
      if (x.id === 'fisica') {
        x.checked = true;
      }
    });

    setErrors({
      country: false,
      state: false,
      town: false,
      city: false,
    });

    CloseModal('modalAddressInvoice');
  };

  const verifyFields = () => {
    const inputs = ['input-Scp-i', 'input-Calle-i', 'input-Numero-i', 'input-Rfc-i', 'input-RazonSocial-i', 'input-emailBilling'];

    let flag = true;
    let validFields = verifyFieldsRequerid(inputs);
    if (!validFields) {
      OpenMessage('warning', 'Por favor ingrese todos los campos obligatorios.');
      flag = false;
    }

    let errorSet = {
      country: false,
      state: false,
      town: false,
      city: false,
    };

    if (props.address.idPais === '') {
      errorSet.country = true;
      flag = false;
    }
    if (props.address.idEdo === '') {
      errorSet.state = true;
      flag = false;
    }
    if (props.address.idMun === '') {
      errorSet.town = true;
      flag = false;
    }
    if (props.address.idCol === '') {
      errorSet.city = true;
      flag = false;
    }

    setErrors(errorSet);
    return flag;
  };

  const getZipCode = () => {
    if (props.address.scp === '') {
      return;
    }
    const URL = ServiceGetByZipCode(props.address.scp);
    GET(URL, props.address).then((data) => {
      if (data === '401') {
        getZipCode();
        return '';
      }
      if (data) {
        props.setAddress({
          ...props.address,
          idPais: data.state.idCountry,
          idMun: data.town.idTown,
          idEdo: data.state.idState,
          idCol: '',
        });

        props.setListCities(data.cities);
      } else {
        OpenMessage('error', 'Código postal no encontrado');
      }
    });
  };

  useEffect(() => {
    const { idPais, idEdo, idMun } = props.address;
    if (idPais === '' || idEdo === '' || idMun === '') {
      props.setListCities([]);
    }
  }, []);

  const Send = (e) => {
    e.preventDefault();
    if (!verifyFields()) {
      return;
    }

    const rfc = document.getElementById('input-Rfc-i');
    const esFiscoMoral = document.getElementsByName('esfiscoMoral-i');
    let bandera = false;
    esFiscoMoral.forEach((x) => {
      bandera = x.checked && x.id === 'moral';
    });

    if (!validRFC(rfc, bandera)) {
      return;
    }
    const email = document.getElementById('input-emailBilling');
    email.classList.remove('is-invalid');
    if (props.address.correo && !validEmail(props.address.correo)) {
      email.classList.add('is-invalid');
      OpenMessage('warning', 'El formato del correo eléctronico no es válido');
      return;
    }

    const URL = ServiceCustomerInfoAddressBilling();

    if (props.address.id === '00000000-0000-0000-0000-000000000000') {
      POST(URL, props.address).then((data) => {
        if (data.success === true) {
          clearForm();
          props.AddressRefresh();
        } else {
          OpenMessage('error', data.message);
        }
      });
      return;
    }

    PUT(URL, props.address).then((data) => {
      if (data.success === true) {
        clearForm();
        props.AddressRefresh();
      } else {
        OpenMessage('error', data.message);
      }
    });
  };

  return (
    <div
      className="modal container__modaladress"
      id="modalAddressInvoice"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-sm modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header modal-header-login justify-content-end">
            <CloseModalIcon onHandleClick={(e) => clearForm(e)} />
          </div>
          <div className="modal-body">
            <div className="text-center mt-2">
              <img src="./resources/logo.svg" alt="logo MLS" className="img-fluid" width="65%" />
            </div>
            <div className="text-center mb-4 mt-4">
              <h4 className="modal-title fw-bold text-uppercase">{props.titleModal} datos fiscales</h4>
            </div>
            <div className="row justify-content-center">
              <div className="col-12">
                <div className="mb-3 form-outline">
                  <input
                    type="text"
                    id="input-Rfc-i"
                    className="form-control text-uppercase"
                    maxLength="15"
                    value={props.address.rfc}
                    onInput={(e) => onlyTextNumber2(e)}
                    onChange={(e) =>
                      props.setAddress({
                        ...props.address,
                        rfc: e.target.value,
                      })
                    }
                  />
                  <label htmlFor="input-Rfc-i" className="form-label">
                    RFC:
                  </label>
                  <div className="form-notch">
                    <div className="form-notch-leading"></div>
                    <div className="form-notch-middle"></div>
                    <div className="form-notch-trailing"></div>
                  </div>
                </div>
              </div>

              <div className="col-12">
                <div className="mb-3 form-outline">
                  <input
                    type="text"
                    id="input-RazonSocial-i"
                    className="form-control"
                    value={props.address.razonSocial}
                    onInput={(e) => onlyTextNumber(e)}
                    onChange={(e) =>
                      props.setAddress({
                        ...props.address,
                        razonSocial: e.target.value,
                      })
                    }
                  />
                  <label htmlFor="input-RazonSocial-i" className="form-label">
                    Razón social:
                  </label>
                  <div className="form-notch">
                    <div className="form-notch-leading"></div>
                    <div className="form-notch-middle"></div>
                    <div className="form-notch-trailing"></div>
                  </div>
                </div>
              </div>

              <div className="col-9">
                <div className="mb-3 form-outline">
                  <input
                    type="text"
                    id="input-Scp-i"
                    className="form-control"
                    maxLength='5'
                    value={props.address.scp}
                    onInput={(e) => onlyNumber(e)}
                    onChange={(e) =>
                      props.setAddress({
                        ...props.address,
                        scp: e.target.value,
                      })
                    }
                  />
                  <label htmlFor="input-Scp-i" className="form-label">
                    Código Postal:
                  </label>
                  <div className="form-notch">
                    <div className="form-notch-leading"></div>
                    <div className="form-notch-middle"></div>
                    <div className="form-notch-trailing"></div>
                  </div>
                </div>
              </div>

              <div className="col-3">
                <div className="mb-3 form-outline">
                  <button className="btn btn-outline-secondary btn-lg" type="button" onClick={() => getZipCode()}>
                    <i className="fas fa-search"></i>
                  </button>
                </div>
              </div>

              <div className="mb-3 col-12">
                <SelectCountry setAddress={props.setAddress} address={props.address} id={'select-country-i'} errors={errors} />
              </div>

              <div className="mb-3 col-12">
                <SelectState setAddress={props.setAddress} address={props.address} id={'select-state-i'} errors={errors} />
              </div>

              <div className="mb-3 col-12">
                <SelectTown
                  setAddress={props.setAddress}
                  address={props.address}
                  id={'select-town-i'}
                  setListCities={props.setListCities}
                  errors={errors}
                />
              </div>

              <div className="mb-3 col-12">
                <SelectCity
                  setAddress={props.setAddress}
                  address={props.address}
                  id={'select-city-i'}
                  listCities={props.listCities}
                  errors={errors}
                />
              </div>

              <div className="col-12">
                <div className="mb-3 form-outline">
                  <input
                    type="text"
                    id="input-Calle-i"
                    className="form-control"
                    value={props.address.calle}
                    onInput={(e) => onlyTextNumber(e)}
                    onChange={(e) =>
                      props.setAddress({
                        ...props.address,
                        calle: e.target.value,
                      })
                    }
                  />
                  <label htmlFor="input-Calle-i" className="form-label">
                    Calle:
                  </label>
                  <div className="form-notch">
                    <div className="form-notch-leading"></div>
                    <div className="form-notch-middle"></div>
                    <div className="form-notch-trailing"></div>
                  </div>
                </div>
              </div>

              <div className="col-6">
                <div className="mb-3 form-outline">
                  <input
                    type="text"
                    id="input-Numero-i"
                    className="form-control"
                    value={props.address.numero}
                    onInput={(e) => onlyTextNumber(e)}
                    onChange={(e) =>
                      props.setAddress({
                        ...props.address,
                        numero: e.target.value,
                      })
                    }
                  />
                  <label htmlFor="input-Numero-i" className="form-label">
                    N° Exterior:
                  </label>
                  <div className="form-notch">
                    <div className="form-notch-leading"></div>
                    <div className="form-notch-middle"></div>
                    <div className="form-notch-trailing"></div>
                  </div>
                </div>
              </div>

              <div className="col-6">
                <div className="mb-3 form-outline">
                  <input
                    type="text"
                    id="input-NumeroInt-i"
                    className="form-control"
                    value={props.address.numeroInt}
                    onInput={(e) => onlyTextNumber(e)}
                    onChange={(e) =>
                      props.setAddress({
                        ...props.address,
                        numeroInt: e.target.value,
                      })
                    }
                  />
                  <label htmlFor="input-NumeroInt-i" className="form-label">
                    N° Interior:
                  </label>
                  <div className="form-notch">
                    <div className="form-notch-leading"></div>
                    <div className="form-notch-middle"></div>
                    <div className="form-notch-trailing"></div>
                  </div>
                </div>
              </div>

              <div className="col-12">
                <div className="mb-3 form-outline">
                  <input
                    type="text"
                    id="input-emailBilling"
                    className="form-control"
                    value={props.address.correo}
                    onInput={(e) => onlyMail(e)}
                    onChange={(e) =>
                      props.setAddress({
                        ...props.address,
                        correo: e.target.value,
                      })
                    }
                  />
                  <label htmlFor="input-emailBilling" className="form-label">
                    Correo:
                  </label>
                  <div className="form-notch">
                    <div className="form-notch-leading"></div>
                    <div className="form-notch-middle"></div>
                    <div className="form-notch-trailing"></div>
                  </div>
                </div>
              </div>

              <div className="col-6">
                <div className="form-check align-terms ">
                  <label className="form-check-label" htmlFor="fisica">
                    Física
                  </label>
                  <input
                    className="form-check-input"
                    type="radio"
                    id="fisica"
                    name="esfiscoMoral-i"
                    value="false"
                    onClick={(e) => {
                      props.setAddress({
                        ...props.address,
                        esFisicoMoral: true,
                      });
                    }}
                  />
                </div>
              </div>

              <div className="col-6">
                <div className="form-check align-terms ">
                  <label className="form-check-label" htmlFor="moral">
                    Moral
                  </label>
                  <input
                    className="form-check-input"
                    type="radio"
                    id="moral"
                    name="esfiscoMoral-i"
                    value="true"
                    onClick={(e) => {
                      props.setAddress({
                        ...props.address,
                        esFisicoMoral: false,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <div className="container-fluid">
              <div className="mb-3 row inputs-form">
                <div className="col-7 text-end">
                  <button type="button" className="btn btn-outline-danger" onClick={() => clearForm()}>
                    <i className="fas fa-times"></i>
                    Cancelar
                  </button>
                </div>
                <div className="col-5">
                  <button className="btn btn-outline-secondary" onClick={(e) => Send(e)}>
                    Guardar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalAddressBilling;
