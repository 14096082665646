import React, { useState, useEffect } from 'react';
import { POST, PUT, GET } from '../../helpers/APIServices';
import { ServiceCustomerInfoAddressShipping } from '../../helpers/getURL';
import { SelectCountry } from './SelectCountry';
import { SelectState } from './SelectState';
import { SelectTown } from './SelectTown';
import { SelectCity } from './SelectCity';
import { CloseModal, verifyFieldsRequerid, OpenMessage, onlyNumber, onlyTextNumber } from '../Utilities/General';
import { ServiceGetByZipCode } from '../../helpers/getURL';
import { CloseModalIcon } from '../Home/CloseModalIcon';
import '../../css/Login/RecoveryPassword.css';

const ModalAddressShipping = (props) => {
  const [errors, setErrors] = useState({});
  const clearForm = () => {
    props.setAddress({
      id: '00000000-0000-0000-0000-000000000000',
      idCustomer: localStorage.getItem('id'),
      nombreCompleto: '',
      idPais: 'MX',
      idEdo: '',
      idMun: '',
      idCol: '',
      scp: '',
      numero: '',
      numeroInt: '',
      razonSocial: '',
      colonia: '',
      calle: '',
      rfc: '',
      alias: '',
      referencia: '',
      esFisicoMoral: true,
      predeterminado: false,
    });

    setErrors({
      country: false,
      state: false,
      town: false,
      city: false,
    });

    CloseModal('modalAddress');
  };

  const verifyFields = () => {
    const inputs = ['input-Scp', 'input-Calle', 'input-Alias', 'input-Numero'];

    let flag = true;
    let validFields = verifyFieldsRequerid(inputs);
    if (!validFields) {
      OpenMessage('warning', 'Por favor ingrese todos los campos obligatorios.');
      flag = false;
    }

    let errorSet = {
      country: false,
      state: false,
      town: false,
      city: false,
    };

    if (props.address.idPais === '') {
      errorSet.country = true;
      flag = false;
    }
    if (props.address.idEdo === '') {
      errorSet.state = true;
      flag = false;
    }
    if (props.address.idMun === '') {
      errorSet.town = true;
      flag = false;
    }
    if (props.address.idCol === '') {
      errorSet.city = true;
      flag = false;
    }

    setErrors(errorSet);
    return flag;
  };

  const getZipCode = () => {
    if (props.address.scp === '') {
      return;
    }
    const URL = ServiceGetByZipCode(props.address.scp);
    GET(URL, props.address).then((data) => {
      if (data === '401') {
        getZipCode();
        return '';
      }
      if (data) {
        props.setAddress({
          ...props.address,
          idPais: data.state.idCountry,
          idMun: data.town.idTown,
          idEdo: data.state.idState,
          idCol: '',
          colonia: '',
        });
        props.setListCities(data.cities);
      } else {
        OpenMessage('warning', 'Código postal no encontrado');
      }
    });
  };

  useEffect(() => {
    const { idPais, idEdo, idMun } = props.address;
    if (idPais === '' || idEdo === '' || idMun === '') {
      props.setListCities([]);
    }
  }, []);

  const Send = (e) => {
    e.preventDefault();

    if (!verifyFields()) {
      return;
    }

    const URL = ServiceCustomerInfoAddressShipping();

    if (props.address.id === '00000000-0000-0000-0000-000000000000') {
      POST(URL, props.address).then((data) => {
        if (data.success) {
          clearForm();
          props.AddressRefresh();
        } else {
          OpenMessage('error', data.message);
        }
      });
      return;
    }
    PUT(URL, props.address).then((data) => {
      if (data.success) {
        clearForm();
        props.AddressRefresh();
      } else {
        OpenMessage('error', data.message);
      }
    });
  };

  return (
    <div className="modal container__modaladress" id="modalAddress" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div className="modal-dialog modal-sm modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header modal-header-login justify-content-end">
            <CloseModalIcon onHandleClick={(e) => clearForm(e)}/>
          </div>
          <div className="modal-body">
            <div className="text-center mt-2">
              <img src="./resources/logo.svg" alt="logo MLS" className="img-fluid" width="65%" />
            </div>
            <div className="text-center mb-4 mt-4">
              <h4 className="modal-title fw-bold text-uppercase mb-3">{props.titleModal} dirección</h4>
            </div>
            <div className="row justify-content-center">
              <div className="col-9">
                <div className="mb-3 form-outline">
                  <input
                    type="text"
                    id="input-Scp"
                    className="form-control"
                    value={props.address.scp}
                    maxLength='5'
                    onInput={(e) => onlyNumber(e)}
                    onChange={(e) =>
                      props.setAddress({
                        ...props.address,
                        scp: e.target.value,
                      })
                    }
                  />
                  <label htmlFor="input-Scp" className="form-label">
                    Código Postal:
                  </label>
                  <div className="form-notch">
                    <div className="form-notch-leading"></div>
                    <div className="form-notch-middle"></div>
                    <div className="form-notch-trailing"></div>
                  </div>
                </div>
              </div>

              <div className="col-3">
                <div className="mb-3 form-outline">
                  <button className="btn btn-outline-secondary btn-lg" type="button" onClick={() => getZipCode()}>
                    <i className="fas fa-search"></i>
                  </button>
                </div>
              </div>

              <div className="mb-3 col-12">
                <SelectCountry setAddress={props.setAddress} address={props.address} id={'select-country'} errors={errors} />
              </div>

              <div className="mb-3 col-12">
                <SelectState setAddress={props.setAddress} address={props.address} id={'select-state'} errors={errors} />
              </div>

              <div className="mb-3 col-12">
                <SelectTown
                  setAddress={props.setAddress}
                  address={props.address}
                  id={'select-town'}
                  setListCities={props.setListCities}
                  errors={errors}
                />
              </div>

              <div className="mb-3 col-12">
                <SelectCity
                  setAddress={props.setAddress}
                  address={props.address}
                  id={'select-city'}
                  listCities={props.listCities}
                  errors={errors}
                />
              </div>

              <div className="col-12">
                <div className="mb-3 form-outline">
                  <input
                    type="text"
                    id="input-Calle"
                    className="form-control"
                    value={props.address.calle}
                    onInput={(e) => onlyTextNumber(e)}
                    onChange={(e) =>
                      props.setAddress({
                        ...props.address,
                        calle: e.target.value,
                      })
                    }
                  />
                  <label htmlFor="input-Calle" className="form-label">
                    Calle:
                  </label>
                  <div className="form-notch">
                    <div className="form-notch-leading"></div>
                    <div className="form-notch-middle"></div>
                    <div className="form-notch-trailing"></div>
                  </div>
                </div>
              </div>

              <div className="col-6">
                <div className="mb-3 form-outline">
                  <input
                    type="text"
                    id="input-Numero"
                    className="form-control"
                    value={props.address.numero}
                    onInput={(e) => onlyTextNumber(e)}
                    onChange={(e) =>
                      props.setAddress({
                        ...props.address,
                        numero: e.target.value,
                      })
                    }
                  />
                  <label htmlFor="input-Numero" className="form-label">
                    N° Exterior:
                  </label>
                  <div className="form-notch">
                    <div className="form-notch-leading"></div>
                    <div className="form-notch-middle"></div>
                    <div className="form-notch-trailing"></div>
                  </div>
                </div>
              </div>

              <div className="col-6">
                <div className="mb-3 form-outline">
                  <input
                    type="text"
                    id="input-NumeroInt"
                    className="form-control"
                    value={props.address.numeroInt}
                    onInput={(e) => onlyTextNumber(e)}
                    onChange={(e) =>
                      props.setAddress({
                        ...props.address,
                        numeroInt: e.target.value,
                      })
                    }
                  />
                  <label htmlFor="input-NumeroInt" className="form-label">
                    N° interior:
                  </label>
                  <div className="form-notch">
                    <div className="form-notch-leading"></div>
                    <div className="form-notch-middle"></div>
                    <div className="form-notch-trailing"></div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="mb-3 form-outline">
                  <input
                    type="text"
                    id="input-referenceAddress"
                    className="form-control"
                    value={props.address.referencia}
                    onInput={(e) => onlyTextNumber(e)}
                    onChange={(e) =>
                      props.setAddress({
                        ...props.address,
                        referencia: e.target.value,
                      })
                    }
                  />
                  <label htmlFor="input-referenceAddress" className="form-label">
                    Referencia:
                  </label>
                  <div className="form-notch">
                    <div className="form-notch-leading"></div>
                    <div className="form-notch-middle"></div>
                    <div className="form-notch-trailing"></div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="mb-3 form-outline">
                  <input
                    type="text"
                    id="input-Alias"
                    className="form-control"
                    value={props.address.alias}
                    onInput={(e) => onlyTextNumber(e)}
                    onChange={(e) =>
                      props.setAddress({
                        ...props.address,
                        alias: e.target.value,
                      })
                    }
                  />
                  <label htmlFor="input-Alias" className="form-label">
                    Alias:
                  </label>
                  <div className="form-notch">
                    <div className="form-notch-leading"></div>
                    <div className="form-notch-middle"></div>
                    <div className="form-notch-trailing"></div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <label htmlFor="checkBoxDefault" className="text-capitalize">
                  Predeterminada:{' '}
                </label>{' '}
                <input
                  className="form-check-input mt-2"
                  type="checkbox"
                  id="checkBoxDefault"
                  checked={props.address.predeterminado}
                  onChange={(e) => {}}
                  onClick={(e) =>
                    props.setAddress({
                      ...props.address,
                      predeterminado: e.target.checked,
                    })
                  }
                />
                <br />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <div className="container-fluid">
              <div className="mb-3 row inputs-form">
                <div className="col-7 text-end">
                  <button type="button" className="btn btn-outline-danger" onClick={() => clearForm()}>
                    <i className="fas fa-times"></i>
                    Cancelar
                  </button>
                </div>
                <div className="col-5">
                  <button className="btn btn-outline-secondary" onClick={(e) => Send(e)}>
                    Guardar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalAddressShipping;
