import React, { Fragment } from 'react';
import { DELETE, GET } from '../../helpers/APIServices';
import { ServiceInfoAddressShippingById } from '../../helpers/getURL';
import { verifyFieldsRequerid } from '../Utilities/General';
import { AdressConfirmDelete } from './paymentProcess/AdressConfirmDelete';

export const AddressShipping = (props) => {
  const deleteAddress = (id) => {
    const URL = ServiceInfoAddressShippingById(id);
    DELETE(URL, props.address).then((data) => {
      props.AddressRefresh(true);
    });
  };

  const getAddress = (e, id) => {
    e.preventDefault();
    props.setTitleModal('Editar');
    const URL = ServiceInfoAddressShippingById(id);
    GET(URL, props.address).then((data) => {
      if (data === '401') {
        getAddress();
        return '';
      }
      props.setAddress(data[0]);

      const inputs = ['input-Calle', 'input-Numero', 'input-Alias', 'input-NumeroInt', 'input-referenceAddress', 'input-Scp'];

      inputs.forEach((item) => {
        document.getElementById(item).focus();
      });

      const inputsTwo = ['input-Scp', 'input-Calle', 'input-Numero', 'input-Alias'];

      verifyFieldsRequerid(inputsTwo);
    });
  };

  let list = '';

  if (props.listAddress.length > 0) {
    list = props.listAddress.map((item, i) => {
      let alias = '';
      if (item.alias !== undefined) {
        alias = (
          <div className="col-2">
            <label className="col-form-label configuration-profile-labels">
              {item.alias.length > 10 ? item.alias.substring(0, 11) + '...' : item.alias}
            </label>
            <br />
            {item.predeterminado && (
              <i className="far fa-check-square address-default" data-toggle="tooltip" title="Dirección predeterminada"></i>
            )}
          </div>
        );
      }

      const value = `${item.calle} No. Ext. ${item.numero}${item.numeroInt !== '' ? ` No. Int ${item.numeroInt}` : ''}, ${
        item.colonia
      }, C.P. ${item.scp}, ${item.municipio}, ${item.estado}, ${item.idPais}`;

      return (
        <div className="mb-3 row inputs-form" key={i}>
          {alias}
          <div className="col-10 col-sm-8">
            <p>{value}</p>
          </div>
          <div className="col-6 col-sm-1 text-end">
            <a href="!#" onClick={(e) => getAddress(e, item.id)} data-bs-toggle="modal" data-bs-target={`#${props.modal}`}>
              Editar
            </a>
          </div>
          <div className="col-6 col-sm-1 text-start">
            <a href="!#" data-bs-toggle="modal" data-bs-target={'#modalDeleteAddressShipping_' + item.id}>
              <i className="far fa-trash-alt"></i>
            </a>
          </div>
          <AdressConfirmDelete id={item.id} deleteAddress={deleteAddress} modalType={'Shipping_' + item.id} />
        </div>
      );
    });
  } else {
    list = (
      <div className="mb-3 row inputs-form">
        <div className="col-12 text-center">No existen registros</div>
      </div>
    );
  }

  return <Fragment>{list}</Fragment>;
};
