import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import ReCAPTCHA from 'react-google-recaptcha';

import { getConfig } from '../../helpers/getConfig';
import { POST } from '../../helpers/APIServices';
import { ServiceContact } from '../../helpers/getURL';
import { OpenMessage, verifyFieldsRequerid, validEmail, restoreInputs, onlyText, onlyMail } from '../Utilities/General';
//CSS
import '../../css/Menu/Contact.css';

export const Contact = () => {
  const [fullName, setFullName] = useState('');
  const [postEmail, setPostEmail] = useState('');
  const [tel, setTel] = useState('');
  const [message, setMessage] = useState('');
  const [termsConditions, setTermsConditions] = useState(false);
  const [captcha, setCaptcha] = useState(false);
  const [disabledBtn, setDisabledBtn] = useState(true);
  const [data, setData] = useState([]);
  const URL = ServiceContact();
  let history = useHistory();
  const inputs = ['input-nameContact', 'input-emailContact', 'input-telContact', 'input-messageContact'];
  const captchaContact = useRef(null);
  const checkContact = useRef(null);

  const enableBtn = () => {
    setDisabledBtn(true);
    const chk = document.getElementById('checkBoxContact').checked;
    const chkCaptcha = captchaContact.current.getValue();
    if (fullName && postEmail && tel && message && chk && chkCaptcha) {
      setDisabledBtn(false);
    }
  };

  const onChange = () => {
    if (captchaContact.current.getValue()) {
      setCaptcha(true);
      enableBtn();
    }
  };

  const verifyFields = () => {
    const validFields = verifyFieldsRequerid(inputs);
    if (!validFields) {
      OpenMessage('error', 'Faltan campos por llenar');
      return false;
    } else {
      if (!validEmail(postEmail)) {
        document.getElementById('input-emailContact').classList.add('is-invalid');
        OpenMessage('error', 'El formato del correo eléctronico no es válido');
        return false;
      }
      return true;
    }
  };
  useEffect(() => {
    getConfig('contact.json').then((data) => {
      setData(data);
    });
  }, []);

  const {
    title1 = '',
    text1 = '',
    text2 = '',
    text3 = '',
    text4 = '',
    text5 = '',
    phone1 = '',
    phone2 = '',
    phone3 = '',
    phone4 = '',
    desc1 = '',
    desc2 = '',
    desc3 = '',
    desc4 = '',
    email = '',
    facebook = '#',
    twitter = '#',
  } = data;

  const onSendContact = () => {
    const validFields = verifyFields();
    if (!validFields) {
      return false;
    }
    if (!termsConditions) {
      OpenMessage('error', 'Debe aceptar los términos y condiciones');
      return false;
    }
    if (!captcha) {
      OpenMessage('error', 'Debe aceptar el captcha');
      return false;
    }
    setDisabledBtn(true);
    const json = {
      name: fullName,
      email: postEmail,
      phone: tel,
      message: message,
    };
    POST(URL, json)
      .then((data) => {
        if (data === true) {
          OpenMessage('success', 'Agradecemos su comentario, nuestro equipo se pondrá en contacto para resolver sus dudas.');
          captchaContact.current.props.grecaptcha.reset();
          setDisabledBtn(false);
          setFullName('');
          setPostEmail('');
          setTel('');
          setMessage('');
          setTermsConditions(false);
          setCaptcha(false);
          restoreInputs(inputs);
          history.push('/');
        } else {
          OpenMessage('error', data);
          setDisabledBtn(false);
          captchaContact.current.props.grecaptcha.reset();
        }
      })
      .catch((error) => {
        OpenMessage('error', 'Intentar nuevamente');
        setDisabledBtn(false);
        captchaContact.current.props.grecaptcha.reset();
      });
  };

  const openPDF = () => {
    window.open('/resources/terminos-y-condiciones-subasta-2021.pdf');
  };
  return (
    <div className="container-fluid container-contact">
      <div className="row">
        <div className="col-12 text-center-mobile">
          <div dangerouslySetInnerHTML={{ __html: title1 }} />
        </div>
      </div>
      <div className="row form-section justify-content-around">
        <div className="col-12 col-md-6">
          <div className="card">
            <div className="card-body">
              <div dangerouslySetInnerHTML={{ __html: text1 }} />

              <div className="row mb-3">
                <div className="col-10 form-outline container-input-checkin form-inputs-space">
                  <input
                    type="text"
                    className="form-control form-contact"
                    id="input-nameContact"
                    onChange={(e) => setFullName(e.target.value)}
                    onInput={(e) => onlyText(e)}
                    maxLength="150"
                    onKeyUp={enableBtn}
                    value={fullName}
                    autoComplete="off"
                  />
                  <label htmlFor="input-name" className="col-form-label form-label holder-border">
                    Nombre completo*
                  </label>
                  <div className="form-notch">
                    <div className="form-notch-leading"></div>
                    <div className="form-notch-middle"></div>
                    <div className="form-notch-trailing"></div>
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-10 form-outline container-input-checkin form-inputs-space">
                  <input
                    type="email"
                    className="form-control form-contact"
                    id="input-emailContact"
                    value={postEmail}
                    onChange={(e) => setPostEmail(e.target.value)}
                    onKeyUp={enableBtn}
                    maxLength="80"
                    onInput={(e) => onlyMail(e)}
                    autoComplete="off"
                  />
                  <label htmlFor="input-email" className="col-form-label form-label holder-border">
                    Correo*
                  </label>
                  <div className="form-notch">
                    <div className="form-notch-leading"></div>
                    <div className="form-notch-middle"></div>
                    <div className="form-notch-trailing"></div>
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-10 form-outline container-input-checkin form-inputs-space">
                  <NumberFormat
                    format="(###) ###-####"
                    mask=""
                    name="phoneNumberInput"
                    className="form-control form-contact"
                    id="input-telContact"
                    onValueChange={(e) => setTel(e.value)}
                    onKeyUp={enableBtn}
                    value={tel}
                    autoComplete="off"
                  />
                  <label htmlFor="input-tel" className="col-form-label form-label holder-border">
                    Teléfono*
                  </label>
                  <div className="form-notch">
                    <div className="form-notch-leading"></div>
                    <div className="form-notch-middle"></div>
                    <div className="form-notch-trailing"></div>
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-10 form-outline container-input-checkin form-inputs-space">
                  <textarea
                    className="form-control form-contact"
                    id="input-messageContact"
                    rows="6"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    onKeyUp={enableBtn}
                    maxLength="500"
                  ></textarea>
                  <label htmlFor="input-message" className="col-form-label form-label holder-border message-contact">
                    Mensaje*
                  </label>
                  <div className="form-notch">
                    <div className="form-notch-leading"></div>
                    <div className="form-notch-middle"></div>
                    <div className="form-notch-trailing"></div>
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="container-fluid">
                  <div className="row form-inputs-space">
                    <div className="col-10 container-input-checkin">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="checkBoxContact"
                          ref={checkContact}
                          checked={termsConditions}
                          onClick={(e) => {
                            setTermsConditions(e.target.checked);
                            enableBtn();
                          }}
                          onChange={(e) => {}}
                        />
                        <label className="form-check-label" htmlFor="checkBoxContact">
                          Acepto todos los &nbsp;
                          <span className="terms-conditions" onClick={(e) => openPDF(e)}>
                            Términos y condiciones
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3 mt-4 justify-content-center">
                    <div className="col-12 col-sm-8 col-md-12 col-lg-8 col-xl-6 container-captcha">
                      <ReCAPTCHA ref={captchaContact} sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI" onChange={onChange} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col text-center mb-3 mt-4">
                      <button
                        type="button"
                        className="btn btn-outline-secondary btn-lg btn-send"
                        onClick={(e) => onSendContact(e)}
                        disabled={disabledBtn}
                      >
                        ENVIAR
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="row">
            <div className="col-12">
              <div dangerouslySetInnerHTML={{ __html: text2 }} />
            </div>
          </div>
          <div className="row">
            <div className="col-12 details-contact">
              <div className="icons-contact">
                <i className="fas fa-phone-alt icons-align"></i>
                <a href={'tel:' + phone1} rel="noreferrer">
                  <h4 className="phone-contact">
                    <b>{phone1}</b> <label>{desc1}</label>
                  </h4>
                </a>
              </div>
              <div className="icons-contact">
                <i className="fas fa-phone-alt icons-align"></i>
                <a href={'tel:' + phone2} rel="noreferrer">
                  <h4 className="phone-contact">
                    <b>{phone2}</b> <label>{desc2}</label>
                  </h4>
                </a>
              </div>
              <div className="icons-contact">
                <i className="fas fa-phone-alt icons-align"></i>
                <a href={'tel:' + phone3} rel="noreferrer">
                  <h4 className="phone-contact">
                    <b>{phone3}</b> <label>{desc3}</label>
                  </h4>
                </a>
              </div>
              <div className="icons-contact">
                <i className="fas fa-phone-alt icons-align"></i>
                <a href={'tel:' + phone4} rel="noreferrer">
                  <h4 className="phone-contact">
                    <b>{phone4}</b> <label>{desc4}</label>
                  </h4>
                </a>
              </div>
              <div className="icons-contact">
                <i className="fas fa-envelope icons-align"></i>
                <a href={`mailto:${email}`} rel="noreferrer">
                  <h4 className="email-contact">{email}</h4>
                </a>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div dangerouslySetInnerHTML={{ __html: text3 }} />
            </div>
          </div>
          <div className="row">
            <div className="col-12 mt-3">
              <div dangerouslySetInnerHTML={{ __html: text4 }} />
            </div>
          </div>
          <div className="row">
            <div className="col-12 mt-3 container-icon-social">
              <div className="icons-contact">
                <a href={facebook} target="_blank" rel="noreferrer">
                  <i className="fab fa-facebook-square facebook-icon"></i>
                </a>
                <a href={facebook} target="_blank" rel="noreferrer" className="acution-social">
                  <div dangerouslySetInnerHTML={{ __html: text5 }} />
                </a>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 container-icon-social">
              <div className="icons-contact">
                <a href={twitter} target="_blank" rel="noreferrer">
                  <i className="fab fa-twitter-square twitter-icon"></i>
                </a>
                <a href={twitter} target="_blank" rel="noreferrer" className="acution-social">
                  <div dangerouslySetInnerHTML={{ __html: text5 }} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
