import React, { useContext, useEffect, useState } from 'react';
import { DataContext } from '../../context/DataContext';
import {
  CloseModal,
  formatCurrency
} from '../Utilities/General'
import {
  getWarrantyID,
  getPaymentBillPocketID,
} from '../../helpers/getConstants';
import { CardFormToken } from './MePago/CardFormToken';
import '../../css/Register/RegisterToOffer.css';
import {
  ServiceGenericParameters,
} from '../../helpers/getURL';
import { GET } from '../../helpers/APIServices';
import { CloseModalIcon } from '../Home/CloseModalIcon';
import { TokenStatusContext } from '../../context/TokenStatusContext'

export const RegisterToOffer = () => {
  const {
    validate: {
      registeredAuctions,
      idAuction,
      nameAuction,
    },
    setValidate,
  } = useContext(DataContext);

  const {
    mercadoPagoInitialized,
    paymentFormError,
    renderMpPaymentForm,
    setRenderMpPaymentForm,
    setTokenAmount,
  } = useContext(TokenStatusContext)

  const [message, setMessage] = useState(
    'Para participar en la subasta, se requiere validar la cuenta con sus datos bancarios, favor de ingresar los datos de su tarjeta.'
  );
  const [warrantyMessage, setWarrantyMessage] = useState('');

  const [disabledActions, setDisabledActions] = useState(false);

  const clearState = () => {
    if (disabledActions) {
      return;
    }
    CloseModal('modalRegisterToOffer');
    setTimeout(() => {
      setValidate({
        valid: false,
        registeredAuctions: [...registeredAuctions],
        idAuction: '',
        nameAuction: '',
        dateAuction: '',
        palletAuction: '',
        idPallet: '',
      });
      setRenderMpPaymentForm(false);
    }, 200);
  };

  useEffect(() => {
    if (!renderMpPaymentForm && mercadoPagoInitialized && !paymentFormError) {
      setTimeout(() => {
        setRenderMpPaymentForm(true);
      }, 200);
    }
  }, [renderMpPaymentForm]);

  useEffect(() => {
    getWarrantyMesage();
  }, []);

  const getWarrantyMesage = () => {
    const warrantyID = getWarrantyID();
    const URLWarrantyMessage = ServiceGenericParameters(warrantyID);
    const getDataWarranty = () => {
      GET(URLWarrantyMessage).then((data) => {
        if (data === '401') {
          getDataWarranty();
          return '';
        }
        if (data.value) {
          getBillPocketPayment(data.value);
        }
      });
    };
    getDataWarranty();
  };

  const getBillPocketPayment = (value) => {
    const paymentBillPocketID = getPaymentBillPocketID();
    const URLWarrantyMessage = ServiceGenericParameters(paymentBillPocketID);
    const getDataBillPocketPayment = () => {
      GET(URLWarrantyMessage).then((data) => {
        if (data === '401') {
          getDataBillPocketPayment();
          return '';
        }
        if (data.value) {
          setTokenAmount(parseFloat(data.value));
          let text = value;
          text = text.replace('${0}', formatCurrency(data.value));
          setWarrantyMessage(text);
        }
      });
    };
    getDataBillPocketPayment();
  };

  return (
    <div
      className={`modal fade`}
      id='modalRegisterToOffer'
      aria-hidden='true'
      data-bs-backdrop='static'
      data-bs-keyboard='false'
    >
      <div className='modal-dialog modal-dialog-centered'>
        <div className='modal-content'>
          <div className='modal-header modal-header-login justify-content-end'>
            <CloseModalIcon onHandleClick={() => clearState()} />
          </div>
          <div className='modal-body'>
            <div className='text-center mt-2'>
              <img
                src='./resources/logo.svg'
                alt='logo MLS'
                className='img-fluid'
                width='50%'
              />
            </div>
            <div className='text-center mb-4 mt-4'>
              <h4 className='modal-title fw-bold text-uppercase'>
                Registro a subasta
              </h4>
            </div>
          </div>
          <CardFormToken
            idAuction={idAuction}
            nameAuction={nameAuction}
            message={message}
            warrantyMessage={warrantyMessage}
            clearState={clearState}
            disabledActions={disabledActions}
            setDisabledActions={setDisabledActions}
          />
        </div>
      </div>
    </div>
  );
};
