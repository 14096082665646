import React, { useState, useEffect } from 'react';

//Servicios
import { ServiceAwardsStreaming } from '../../helpers/getURL';
import { GET } from '../../helpers/APIServices';
import { getImgDefault } from '../../helpers/getConstants';
import { PaginationCatalog } from '../Menu/PaginationCatalog';

export const Information = (props) => {
  const [dataAwards, setDataAwards] = useState([]);
  const [pages, setPages] = useState(0);
  const [maxReg, setMaxReg] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPageOptions] = useState([
    { value: 1 },
    { value: 10 },
    { value: 20 },
    { value: 30 },
    { value: 50 },
  ]);
  const {
    CurrentLot,
    idCurrentLot,
    watchLot,
    displayActualLot,
    displayAwards,
    carouselMovil,
    announcement,
  } = props;
  const imgLot =
    CurrentLot.imagesList && CurrentLot.imagesList.length > 0
      ? CurrentLot.imagesList[0]
      : getImgDefault();
  const viewfavorite = CurrentLot.favorite === true ? '' : 'hide';
  const URLAwards = ServiceAwardsStreaming();

  useEffect(() => {
    getAwards(1, 10);
  }, []);

  useEffect(() => {
    if (props.update) {
      getAwards(1, 10);
    }
  }, [props.update]);
  const getAwards = (Pagina, TamanioPagina) => {
    let url = new URL(URLAwards);
    let params = {
      IdAuction: props.IdAuction,
      IdCustomer: props.IdCustomer,
      Pagina,
      TamanioPagina,
    };

    Object.keys(params).forEach((key) => {
      if (!params[key]) {
        delete params[key];
      }
    });
    Object.keys(params).forEach((key) => {
      url.searchParams.append(key, params[key]);
    });
    GET(url).then((data) => {
      if (data) {
        const numberPages = Math.ceil(data[0].totalRecords / TamanioPagina);
        setDataAwards(data);
        setPages(numberPages);
        props.setUpdate(false);
      } else {
        setDataAwards([]);
        setPages(0);
        props.setUpdate(false);
      }
    });
  };
  const nextPage = (pageNumber) => {
    setCurrentPage(pageNumber);
    getAwards(pageNumber, maxReg);
  };
  const onItemsPerPage = (value) => {
    getAwards(1, value);
  };
  const tabActive = (event) => {
    const elements = document.querySelectorAll('#tabs_information > li');
    const id = 'li_' + event.id;
    for (let element of elements) {
      if (id === element.id) {
        element.classList.add('active');
      } else {
        element.classList.remove('active');
      }
    }
  };

  return (
    <div
      className={`container__information ${
        !displayActualLot && !displayAwards ? 'no-border' : ''
      }`}
    >
      {!carouselMovil && (
        <div className='row'>
          <div className='col-12'>
            <ul
              id='tabs_information'
              className='nav nav-tabs nav-fill'
              role='tablist'
            >
              <li
                id='li_CLot'
                className='nav-item active'
                role='presentation'
                onClick={(e) => tabActive(e.target)}
              >
                <a
                  id='CLot'
                  className='nav-link active fw-bold'
                  aria-current='page'
                  href='#CurrentLot'
                  data-mdb-toggle='tab'
                  role='tab'
                  aria-controls='CurrentLot'
                  aria-selected='true'
                >
                  {CurrentLot.anuncio ? 'Anuncio' : 'Lote Actual'}
                </a>
              </li>
              <li
                id='li_CAwards'
                className='nav-item'
                role='presentation'
                onClick={(e) => tabActive(e.target)}
              >
                <a
                  id='CAwards'
                  className='nav-link fw-bold'
                  href='#CurrentAwards'
                  data-mdb-toggle='tab'
                  role='tab'
                  aria-controls='CurrentAwards'
                  aria-selected='false'
                >
                  Adjudicaciones
                </a>
              </li>
            </ul>
          </div>
        </div>
      )}

      {/* CONTENIDO */}

      <div className='tab-content'>
        {CurrentLot && (
          <div
            className={`tab-pane fade ${displayActualLot ? 'show active' : ''}`}
            id='CurrentLot'
            role='tabpanel'
            aria-labelledby='CLot'
          >
            <div className='container-fluid container__CurrentLot'>
              {!CurrentLot.anuncio && (
                <div className='row'>
                  <div className='col-1'>
                    <i
                      className={
                        'fas fa-star bookmark-CurrentLot ' + viewfavorite
                      }
                    ></i>
                  </div>
                  <div className='col-11'>
                    <label>
                      Lote &nbsp;{' '}
                      <span className='fw-bold'>{CurrentLot.order}</span>
                    </label>
                  </div>
                </div>
              )}
              <div className='row'>
                <div className='col-12'>
                  <label className='fw-bold'>{CurrentLot.name}</label>
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <div className='card'>
                    <img
                      src={imgLot}
                      className='card-img-top img-fluid w-100'
                      alt={CurrentLot.name}
                    />
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <label className='fw-bold'>Descripción:</label>
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <p>{CurrentLot.description}</p>
                </div>
              </div>
              {CurrentLot.idLot !== idCurrentLot && (
                <div className='row mt-5'>
                  <div className='col-auto'>
                    <button
                      className='btn btn-outline-danger fs-4'
                      onClick={(e) => watchLot(idCurrentLot)}
                    >
                      <i className='fas fa-eye'></i>
                      <span>
                        {' '}
                        Ver {announcement ? 'anuncio' : 'lote actual'}
                      </span>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        <div
          className={`tab-pane fade ${displayAwards ? 'show active' : ''}`}
          id='CurrentAwards'
          role='tabpanel'
          aria-labelledby='CAwards'
        >
          <div className='container-fluid container__awards'>
            <div className='row'>
              <div className='col-md-12 '>
                <div className='row'>
                  <div className='col-4 text-end'>
                    <h5 className='show-perpag '>Mostrar</h5>
                  </div>
                  <div className='col-3 text-center'>
                    <h5 className='dropdown shadow-0 drop-perPage'>
                      <a
                        href='#'
                        className='btn btn-link dropdown-toggle items-per-page'
                        role='button'
                        data-mdb-toggle='dropdown'
                        aria-expanded='false'
                      >
                        {maxReg}
                      </a>
                      <ul
                        className='dropdown-menu'
                        aria-labelledby='dropdownMenuLink'
                      >
                        {perPageOptions.map((item) => {
                          return (
                            <li key={item.value}>
                              <a
                                href='#'
                                className='dropdown-item drop-items'
                                onClick={(e) => {
                                  setMaxReg(item.value);
                                  setCurrentPage(1);
                                  onItemsPerPage(item.value);
                                }}
                              >
                                {item.value}
                              </a>
                            </li>
                          );
                        })}
                      </ul>
                    </h5>{' '}
                  </div>
                  <div className='col-5'>
                    <h5 className='show-perpag'>resultados por página</h5>
                  </div>
                </div>
              </div>
              <div className='col-12 tableScroll'>
                <table className='table table-striped'>
                  <thead>
                    <tr>
                      <th>No. Lote</th>
                      <th>Descripción</th>
                      <th>Estatus</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataAwards.map((item, i) => {
                      let hidden = item.favorite === false ? 'hidden' : '';
                      let colorStatus;
                      switch (item.statusSubasta.toUpperCase()) {
                        case 'GANADO':
                          colorStatus = 'statusAwardsWin';
                          break;
                        case 'PERDIDO':
                          colorStatus = 'statusAwardsLost';
                          break;
                        default:
                          colorStatus = '';
                          break;
                      }
                      return (
                        <tr key={i}>
                          <td>
                            <i
                              className={
                                'fas fa-star bookmark-CurrentAwards ' + hidden
                              }
                            ></i>{' '}
                            {item.order}
                          </td>
                          <td>{item.name}</td>
                          <td className={colorStatus}>{item.statusSubasta}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className='row justify-content-center pagination-section mt-5'>
                <div className='col-auto'>
                  <PaginationCatalog
                    pages={pages}
                    nextPage={nextPage}
                    currentPage={currentPage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
