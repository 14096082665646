import React, { useEffect, useState, useContext } from 'react';
import { ServiceMePagoPaymentValidate } from '../../helpers/getURL'
import { POST } from '../../helpers/APIServices';
import { useHistory, useLocation } from 'react-router-dom'
import { DataContext } from '../../context/DataContext';
import { OpenStreamingRegisterMessage, CloseModal, CloseBackdrops, convertToDate } from '../Utilities/General'
import { Home } from '../Home/Home';


export const ReceivePayToken = (props) => {

  const { href = '' } = window.location;
  const external_transaction_id = new URL(href).searchParams.get(
    'external_transaction_id'
  );
  const transaction_id = new URL(href).searchParams.get('transaction_id');
  const status_code = new URL(href).searchParams.get('status_code');
  const error_code = new URL(href).searchParams.get('error_code');
  const error_message = new URL(href).searchParams.get('error_message');
  const [message, setMessage] = useState('Validando Tóken...');
  const [loading, setLoading] = useState('show');
  const [redirect, setRedirect] = useState(false);
  const [validToken, setValidToken] = useState(false);
  const [error, setError] = useState(false);
  const { pathname = '', state } = useLocation();
  const history  = useHistory();
  const location = useLocation();
  const {
    validate: { registeredAuctions },
    setValidate,
  } = useContext(DataContext);

  useEffect(() => {
    const URLValidate = ServiceMePagoPaymentValidate();
    const json = {
      external_transaction_id,
      transaction_id,
      status_code,
      error_code,
      error_message,
    };
    const validateData = () => {
      if (!transaction_id || transaction_id === "null") {
        history.push('/perfil?opcion=5')
        return;
      }
      POST(URLValidate, json).then((data) => {
        if (data === '401') {
          validateData();
          return '';
        }
        setLoading('hide');
        if (data.success) {
          if (data.data.pendingChallenge === true) {
            history.push('/confirmarTokenPago', {
              creq: state.creq,
              externalResourceUrl: state.externalResourceUrl,
              status: "pending",
              statusDetail: "pending_challenge",
              id: transaction_id,
              redirectUrl: `${location.pathname}${location.search}`,
            });
          }
          else {
            const response = data.data;
            const existRegisteredAuction = registeredAuctions.some((element) => {
              return element.idAuction === response.auctionId;
            });
            const auctions = [...registeredAuctions];
            if (!existRegisteredAuction) {
              auctions.push({
                idAuction: response.auctionId,
                pallet: response.description,
                streaming: response.streaming,
                idPallet: response.idPalette,
              });
            }
            setMessage('Tóken Validado.');
            setValidate({
              valid: false,
              registeredAuctions: [
                ...auctions
              ],
              idAuction: data.data.auctionId,
              nameAuction: data.data.auctionName,
              dateAuction: convertToDate(data.data.auctionDate),
              palletAuction: data.data.description,
              idPallet: data.data.idPalette,
            });
            OpenStreamingRegisterMessage();
            setValidToken(true);
            setTimeout(() => setRedirect(true), 2000);
          }
        } else {
          if (data.message) {
            setMessage(data.message);
          }
          else {
            setMessage('Ha ocurrido un error al validar tu pago. Por favor reintenta recargando la pagina, si el problema persiste contacta a soporte.');
          }
          setError(true);
          CloseModal('modalStreamingReisterMesage');
          CloseBackdrops();
          setTimeout(() => history.push('/subastas'), 5000);
        }
      }).catch(err => {
          setError(true);
          setMessage('Ha ocurrido un error al validar tu pago. Por favor reintenta recargando la pagina, si el problema persiste contacta a soporte.');
          CloseModal('modalStreamingReisterMesage');
          CloseBackdrops();
      })
    };
    validateData();
  }, []);

  useEffect(() => {
    if (validToken && redirect){
      if (pathname === '/validarTokenPago') {
        history.push('/subastas');
      }
      return <Home />;
    }
  }, [redirect, validToken])

  return (
    <div className='row container-fluid container__expire justify-content-center'>
      {!error && (
        <div id='loading' className={'row container-back container-loading ' + loading}>
          <div className='col-12 text-center'>
            <img src='/resources/loading.gif' alt='loading...' width='15%'/>
          </div>
        </div>
      )}
      <div className='col-8 col-sm-6 col-lg-5 col-xxl-4 text-center'>
        <label>{message}</label>
        {error && (
          <button
            id='errorGoHome'
            className='btn btn-outline-danger btn-lg mt-4'
            onClick={() => history.push('/subastas')}
          >
            Ir a Subastas
          </button>
        )}
      </div>
    </div>
  );
};
