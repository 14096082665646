import React from 'react';
import { Link } from 'react-router-dom';

function AuctionsItem(props) {
  return (
    <div className={'col-3 col-sm-3 col-xxl-2 container-auctions-items ' + props.class}>
      <Link to={`/catalogo?id=${props.idAuction}&categoria=${props.category}`}>
        <div className="card h-100">
          <div className="container-auctions-items--img">
            <img src={props.img} className="card-img-top" alt="..." />
          </div>
          <div className="card-body">
            <h5 className="card-title container-auctions-items--title">{props.productName}</h5>
            <p className="card-text">{props.description}</p>
          </div>
        </div>
      </Link>
    </div>
  );
}

export default AuctionsItem;
