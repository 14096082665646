import React, { useContext, useEffect, useState, useLayoutEffect } from 'react';

import { ServiceAuctionCustomerStatus, ServiceCarousel } from '../../helpers/getURL';
import { GET } from '../../helpers/APIServices';
import { getImgDefault, getIdStreaming, getExecution } from '../../helpers/getConstants';

import { ButtonRegister } from '../Register/ButtonRegister';
import { WatchCatalog } from '../Register/WatchCatalog';
import { ButtonToStreaming } from '../Register/ButtonToStreaming';

import { convertToDate } from '../Utilities/General';
import { DataContext } from '../../context/DataContext';

//Animaciones
import { setSlideIn } from '../Utilities/Animation';

//CSS
import '../../css/Menu/Auctions.css';

export const AuctionsMenu = (props) => {
  const [auctions, setAuctions] = useState([]);
  const [loading, setLoading] = useState(true);
  const idStreaming = getIdStreaming();
  const execution = getExecution();
  const { id } = localStorage;
  const URLAuctionCustomerStatus = ServiceAuctionCustomerStatus(id);
  const {
    validate: { valid, registeredAuctions, idPallet },
  } = useContext(DataContext);

  useEffect(() => {
    if (id) {
      GET(URLAuctionCustomerStatus).then((response) => {
        getAuctions(response);
      });
    } else {
      getAuctions();
    }
  }, [URLAuctionCustomerStatus, id]);

  useEffect(() => {
    if (idPallet) {
      GET(URLAuctionCustomerStatus).then((response) => {
        getAuctions(response);
      });
    }
  }, [valid, URLAuctionCustomerStatus, idPallet]);

  const checkStatus = (item, auctions) => {
    let value = '';
    if (auctions.length > 0) {
      const users = auctions.find((auction) => auction.idAuction === item.id);
      if (users) {
        value = users.idAuctionCustomerStatus;
      }
    }
    return value;
  };
  const getAuctions = (auctionsList = []) => {
    const URLCarousel = ServiceCarousel();
    GET(URLCarousel).then((response) => {
      if (response && response.length > 0) {
        const auctions = response.map((auction) => {
          auction.auctionCustomerStatus = checkStatus(auction, auctionsList);
          auction.auctionRegistered = getIsStreaming(auction.id);
          if (!auction.url[0]) {
            auction.url[0] = getImgDefault();
          }
          return auction;
        });
        setAuctions(auctions);
      }
      setLoading(false);
    });
  };
  const getIsStreaming = (idAuction) => {
    let auctionRegistered = {
      pallet: 0,
      streaming: false,
      idPallet: '',
    };
    const auctionValue = registeredAuctions.find((auction) => auction.idAuction === idAuction);
    if (auctionValue) {
      auctionRegistered.pallet = auctionValue.pallet;
      auctionRegistered.streaming = auctionValue.streaming;
      auctionRegistered.idPallet = auctionValue.idPallet;
    }

    return auctionRegistered;
  };

  useLayoutEffect(() => {
    const handleScroll = () => {
      auctions.forEach((item) => {
        setSlideIn('.container__auctionMenu--' + item.id);
      });
    }
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [auctions]);

  return (
    <div className="container-fluid container-auctions-menu">
      <div className="row">
        <div className="col-12">
          <h2 className="title-section-auc">
            <span className="auctions2">Nuestras</span> subastas
          </h2>
        </div>
      </div>
      {!loading && (
        <div>
          {auctions.length > 0 ? (
            <div>
              {auctions.map(
                (
                  {
                    id,
                    name = '',
                    auctionDate = new Date(),
                    url,
                    auctionRegistered: { pallet, streaming },
                    idType,
                    idStatus,
                    description,
                    place,
                    auctionCustomerStatus,
                  },
                  index
                ) => (
                  <div className={index === 0 ? 'card mb-8 slidein' : 'card mb-8 container__auctionMenu--' + id} key={index}>
                    <div className="row g-0">
                      <div className="col-12 col-sm-6">
                        <div className="card-body ">
                          <h2 className="auction-text1 text-center-mobile">Subasta</h2>
                          <h2 className="auction-text2 text-center-mobile">{name}</h2>
                          <h4 className="auction-text3 text-center-mobile">{convertToDate(auctionDate)}</h4>
                          <div className="row">
                            <div className="col-12 text-center-mobile">
                              <WatchCatalog idAuction={id} modal={false} clasStyle={'buttons-auctions btnAuction'} />
                            </div>
                          </div>
                          <br />
                          {pallet > 0 && idType === idStreaming && idStatus === execution && (
                            <div className="row">
                              <div className="col-12 text-center-mobile">
                                <ButtonToStreaming
                                  streaming={streaming}
                                  idAuction={id}
                                  classStyle={'buttons-auctions btnAuction'}
                                  userStatusLastCatalog={auctionCustomerStatus}
                                />
                              </div>
                            </div>
                          )}
                          {pallet <= 0 && (
                            <div className="row">
                              <div className="col-12 text-center-mobile">
                                <ButtonRegister
                                  idAuction={id}
                                  nameAuction={name}
                                  dateAuction={convertToDate(auctionDate)}
                                  classStyle={'buttons-auctions btnAuction'}
                                />
                              </div>
                            </div>
                          )}
                          <p className="note-auction text-center-mobile">{description}</p>
                          <p className="note-auction text-center-mobile">{place}</p>
                        </div>
                      </div>
                      <div className="col-12 col-sm-6 image-col text-center">
                        <img src={url} className="auction-image rounded-start" alt="..." />
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
          ) : (
            <h4>No hay registros...</h4>
          )}
        </div>
      )}
      <div className="row card-bottom">
        <div className="col-12">
          <h2 className="title-section-auc">
            <span className="auctions2">métodos de</span> pago
          </h2>
        </div>
      </div>
      <div className="row">
        <div className="col-12 text-methods">
          <span>Conoce nuestros distintos métodos con los que podrás realizar tus pagos:</span>
        </div>
      </div>
      <div className="row justify-content-center text-center container_payment_methods">
        <div className="col-2 container_payment_methods--box">
          <img className="transfer" src="./resources/svg/Transferencia.svg" alt="Transferencia" />
          <p>Transferencia</p>
        </div>
        <div className="col-2 container_payment_methods--box">
          <img className="cash" src="./resources/svg/Efectivo.svg" alt="Efectivo" />
          <p>Efectivo</p>
        </div>
        <div className="col-2 container_payment_methods--box">
          <img className="amex" src="./resources/svg/AmericanExpress.svg" alt="American Express" />
        </div>
        <div className="col-2 container_payment_methods--box">
          <img className="visa" src="./resources/svg/Visa.svg" alt="Visa" />
        </div>
        <div className="col-2 container_payment_methods--box">
          <img className="mastercard" src="./resources/svg/Mastercard.svg" alt="Mastercard" />
        </div>
      </div>
    </div>
  );
};
