import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { TokenStatusProvider } from './context/TokenStatusContext'
import { CaptchaProvider } from './context/CaptchaContext'

ReactDOM.render(
  <React.StrictMode>
    <TokenStatusProvider>
      <CaptchaProvider>
        <App />
      </CaptchaProvider>
    </TokenStatusProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
reportWebVitals();
