import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { formatCurrency, OpenMessage } from "../../Utilities/General";
import {
  ServiceAddOrder,
  ServiceGenericParameters,
  ServiceAddDirectSale,
} from "../../../helpers/getURL";
import { POST, GET } from "../../../helpers/APIServices";
import {
  getDeliveryMail,
  getDeliveryOffice,
  getGuidEmpty,
  getLotPaymentLimit,
  getLotPaymentLimitDefault,
} from "../../../helpers/getConstants";

export const OrderPanel = (props) => {
  const [disabledBtn, setDisabledBtn] = useState(true);
  const count = props.count ? props.count : 0;
  const subTotal = props.subTotal ? props.subTotal : 0;
  const send = props.send ? props.send : 0;
  const total = subTotal + send;
  const step = props.step ? props.step : 2; //siempre es el siguiente paso
  const history = useHistory();
  const data = props.data ? props.data : null;
  const [lotPaymentLimit, setLotPaymentLimit] = useState(
    getLotPaymentLimitDefault()
  );
  const id = getLotPaymentLimit();
  const URLParameter = ServiceGenericParameters(id);
  const [cart, setCart] = useState([]);

  useEffect(() => {
    const shoppingCart = JSON.parse(localStorage.getItem("cart"));
    if (shoppingCart && shoppingCart.length > 0) {
      setCart(shoppingCart);
    }

    if (count > lotPaymentLimit) {
      setDisabledBtn(true);
    } else {
      setDisabledBtn(props.disabledBtn);
    }
  }, [props.disabledBtn, count, lotPaymentLimit]);

  useEffect(() => {
    const NLotPaymentLimitValidation = () => {
      GET(URLParameter).then((response) => {
        if (response === "401") {
          NLotPaymentLimitValidation();
          return "";
        }
        if (
          response &&
          response.value !== undefined &&
          !isNaN(response.value)
        ) {
          setLotPaymentLimit(response.value);
        }
      });
    };

    NLotPaymentLimitValidation();
  }, [URLParameter]);

  const getParams = () => {
    if (props.lastStep === true) {
      let orderDetails = [];

      let isDeliveryRequired = false;
      let idDeliveryType = getDeliveryOffice();
      let rateDelivery = "0";
      let idRateDelivery = getGuidEmpty();
      let nombreMensajeria = "";
      let montoMensajeria = 0;
      let idDireccionEnvio = getGuidEmpty();

      let isBillingRequired = false;
      let idBillingAdress = getGuidEmpty();
      let rfc = "";

      let montoSeguro = 0;

      if (data) {
        for (let i = 0; i < data.selectItem.length; i++) {
          let arr = {
            idAuction: data.selectItem[i].idSubasta,
            idLot: data.selectItem[i].idLote,
            precioVenta: data.selectItem[i].precioSalida,
            lotName: data.selectItem[i].nombreLote,
          };
          orderDetails.push(arr);
        }

        if (data.send && data.send.type === "2") {
          isDeliveryRequired = true;
          idDeliveryType = getDeliveryMail();
        }

        if (data.mail) {
          rateDelivery = data.mail.id;
          nombreMensajeria = data.mail.carrier;
          montoMensajeria = data.mail.rate;
          idRateDelivery = data.mail.idRateDelivery;
          montoSeguro = data.mail.amountInsurance;
        }

        if (data.addressMail) {
          idDireccionEnvio = data.addressMail.id;
        }

        if (
          props.enableInvoice === true &&
          data.invoice.name.toLowerCase() === "si"
        ) {
          isBillingRequired = true;
        }

        if (data.addressInvoice) {
          idBillingAdress = data.addressInvoice.id;
          rfc = data.addressInvoice.rfc;
        }
      }

      const id = localStorage.getItem("id");
      const params = {
        idClient: id,
        tipoPasarelaTPV: data.payment?.paymentGateway,
        orderDetails: orderDetails,
        isDeliveryRequired: isDeliveryRequired,
        idDeliveryType: idDeliveryType,
        deliveryService: {
          nombreMensajeria: nombreMensajeria,
          rateDelivery: rateDelivery,
          idRateDelivery: idRateDelivery,
          montoMensajeria: montoMensajeria,
          idDireccionEnvio: idDireccionEnvio,
          montoSeguro: montoSeguro,
        },
        isBillingRequired: isBillingRequired,
        billing: {
          idBillingUser: idBillingAdress,
          rfc: rfc,
        },
      };
      return params;
    }
    return "";
  };

  const deleteCart = (idLote) => {
    let index = -1;
    cart.filter((item, i) => {
      if (item && item.IdLot === idLote) {
        index = i;
      }
      return i;
    });
    if (index !== -1) {
      let item = [];
      delete cart[index];
      localStorage.removeItem("cart");
      for (let i = 0; i < cart.length; i++) {
        if (cart[i]) {
          item.push(cart[i]);
        }
      }
      if (item.length > 0) {
        localStorage.setItem("cart", JSON.stringify(item));
      }
    }
  };

  const saveOrder = () => {
    const directSale =
      data && data.selectItem
        ? data.selectItem.filter((x) => x.origin === "directSale")
        : [];
    const params = getParams();
    console.log("params", params);
    if (params) {
      const URL =
        directSale.length > 0 ? ServiceAddDirectSale() : ServiceAddOrder();
      POST(URL, params).then((data) => {
        if (data.errors) {
          OpenMessage("error", data.title);
        } else if (!data.success) {
          //solo en venta directa existe el carrito de compras
          if (directSale.length > 0 && params && params.orderDetails) {
            params.orderDetails.forEach((element) => {
              deleteCart(element.idLot);
            });
          }
          OpenMessage("error", data.description);
        } else if (data.urlRedirect) {
          window.open(data.urlRedirect, "_self");
        }
      });
    }
  };

  let limitPayment = "";
  if (count > lotPaymentLimit) {
    limitPayment = (
      <div className='col-12'>
        <p className='text-notice fw-bolder'>
          Superaste el límite de lotes a pagar. El límite de lotes por pagar es
          de: {lotPaymentLimit}.
        </p>
      </div>
    );
  }

  const backTo = () => {
    const isCatalog = new URLSearchParams(history.location.search).get(
      "modulo"
    );
    if (isCatalog === "catalogo") {
      const idAuction = new URLSearchParams(history.location.search).get("id");
      history.push(`/catalogo?id=${idAuction}`);
    } else {
      history.push("/perfil?opcion=3");
    }
  };
  return (
    <div className='card'>
      <div className='card-header text-center'>
        <h3>Tu pedido</h3>
      </div>
      <div className='card-body'>
        <div className='row mb-4'>
          <div className='col-5'>
            <h5 className='card-text text-end text-uppercase'>Cantidad</h5>
          </div>
          <div className='col-7'>
            <h5 className='card-text text-end'>{count}</h5>
          </div>
        </div>

        <div className='row mb-4'>
          <div className='col-5'>
            <h5 className='card-text text-end text-uppercase'>SubTotal</h5>
          </div>
          <div className='col-7'>
            <h5 className='card-text text-end'>{formatCurrency(subTotal)}</h5>
          </div>
        </div>
        <div className='row mb-4'>
          <div className='col-5'>
            <h5 className='card-text text-end text-uppercase'>Envío</h5>
          </div>
          <div className='col-7'>
            <h5 className='card-text text-end'>{formatCurrency(send)}</h5>
          </div>
        </div>
        <div className='row mb-4'>
          <div className='col-5'>
            <h5 className='card-text text-end text-uppercase'>Total</h5>
          </div>
          <div className='col-7'>
            <h5 className='card-text text-end'>{formatCurrency(total)}</h5>
          </div>
        </div>
        <div className='row mb-2 mt-5'>
          <div className='col-6 text-end'>
            <button
              type='button'
              className='btn btn-lg btn-outline-secondary'
              onClick={(e) =>
                props.lastStep === false
                  ? props.eventButton(!disabledBtn, step)
                  : saveOrder()
              }
              disabled={disabledBtn}
            >
              Continuar
            </button>
          </div>
          <div className='col-6 text-start'>
            <button
              type='button'
              className='btn btn-lg btn-outline-danger'
              onClick={() => backTo()}
            >
              Cancelar
            </button>
          </div>
        </div>
        <div className='row mt-5'>
          <div className='col-12'>
            <p className='text-notice'>
              El costo del envío se calcula durante el proceso
            </p>
          </div>
          {limitPayment}
        </div>
      </div>
    </div>
  );
};
