import { HubConnectionBuilder } from '@microsoft/signalr';
import { setSession, OpenMessage, CloseAllModal } from '../components/Utilities/General';
import { ServiceRefreshToken } from './getURL';

export const showLoading = () => {
  let loading = document.getElementById('loading');
  if (loading) {
    loading.classList.remove('hide');
    loading.classList.add('show');
  }
};

export const hideLoading = () => {
  let loading = document.getElementById('loading');
  if (loading) {
    loading.classList.remove('show');
    loading.classList.add('hide');
  }
};

export const refreshToken = async () => {
  const Token = localStorage.getItem('refreshToken');
  let url = ServiceRefreshToken(Token);
  if (!Token) {
    return 0;
  }

  const data = await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((resp) => {
      return resp;
    });

  if (data && data !== 'Refresh Token inválido') {
    //TODO: decirle al menso del back que regrese false
    const id = localStorage.getItem('id');
    if (id) {
      const expirationRefresh = localStorage.getItem('expirationRefresh');
      localStorage.setItem('token', data.token);
      localStorage.setItem('refreshToken', data.refreshToken);
      localStorage.setItem('expiration', expirationRefresh);
    }
    return 1;
  }
  return 2;
};

export const POST = async (url, params) => {
  showLoading();
  const token = localStorage.getItem('token');
  params = JSON.stringify(params);
  const data = await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
    body: params,
  })
    .then((response) => {
      return response.json();
    })
    .catch((resp) => {
      return resp;
    });
  hideLoading();
  return data;
};

export const GET = async (url) => {
  if (!url) {
    return '';
  }
  const token = localStorage.getItem('token');

  showLoading();
  const data = await fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  })
    .then((response) => {
      if (response.status === 401) {
        return refreshToken().then((token) => {
          if (token === 1) {
            //window.location.reload();
            return '401';
          } else if (token === 2) {
            setSession(false, '');
            CloseAllModal();
            OpenMessage('warning', 'Su sesión ha expirado, favor de ingresar nuevamente sus credenciales');
            setTimeout(function () {
              window.location.href = './?home=true';
            }, 4000);
            return '';
          }
        });
      } else if (response.ok) {
        return response.json();
      }
      return '';
    })
    .catch((resp) => {
      return resp;
    });
  hideLoading();
  return data;
};

export const PUT = async (url, params) => {
  const token = localStorage.getItem('token');
  showLoading();
  params = JSON.stringify(params);
  const data = await fetch(url, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
    body: params,
  })
    .then((response) => {
      return response.json();
    })
    .catch((resp) => {
      return resp;
    });
  hideLoading();
  return data;
};

export const DELETE = async (url, params) => {
  const token = localStorage.getItem('token');
  showLoading();
  params = JSON.stringify(params);
  const data = await fetch(url, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
    body: params,
  })
    .then((response) => {
      return response.json();
    })
    .catch((resp) => {
      return resp;
    });
  hideLoading();
  return data;
};

export const ConnectionSignalr = (url) => {
  const newConnection = new HubConnectionBuilder().withUrl(url).withAutomaticReconnect().build();
  return newConnection;
};
