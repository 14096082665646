import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { formatCurrency } from '../../Utilities/General';

export const SummaryOrder = (props) => {
  const [quantity, setQuantity] = useState(0);
  const [subTotal, setSubtotal] = useState(0);
  const [delivery, setDelivery] = useState(0);
  const [total, setTotal] = useState(0);

  const history = useHistory();

  const openOrder = () => {
    history.push('./perfil?opcion=5');
  };

  useEffect(() => {
    const data = props.data;
    if (data) {
      setQuantity(data.quantity);
      setSubtotal(data.subtotal);
      setDelivery(data.deliveryPrice);
      setTotal(data.total);
    }
  }, [props]);

  return (
    <div className="card">
      <div className="card-header text-center">
        <h3>Resumen de su pedido</h3>
      </div>
      <div className="card-body">
        <div className="row mb-4">
          <div className="col-5">
            <h5 className="card-text text-end text-uppercase">Cantidad</h5>
          </div>
          <div className="col-7">
            <h5 className="card-text text-end">{quantity}</h5>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-5">
            <h5 className="card-text text-end text-uppercase">SubTotal</h5>
          </div>
          <div className="col-7">
            <h5 className="card-text text-end">{formatCurrency(subTotal)}</h5>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-5">
            <h5 className="card-text text-end text-uppercase">Envío</h5>
          </div>
          <div className="col-7">
            <h5 className="card-text text-end">{formatCurrency(delivery)}</h5>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-5">
            <h5 className="card-text text-end text-uppercase">Total</h5>
          </div>
          <div className="col-7">
            <h5 className="card-text text-end">{formatCurrency(total)}</h5>
          </div>
        </div>
        <div className="row mb-2 mt-5">
          <div className="col-12 text-center">
            <button type="button" className="btn btn-lg btn-outline-secondary" onClick={(e) => openOrder()}>
              Mis órdenes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
