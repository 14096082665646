import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { POST } from '../../helpers/APIServices';
import { ServiceRequestPassword } from '../../helpers/getURL';
import { OpenMessage, validEmail, onlyMail } from '../Utilities/General';

import '../../css/Login/RecoveryPassword.css';

export const RecoveryPassword = () => {
  const [disabledBtn, setdisabledBtn] = useState(true);
  const [inputMail, setinputMail] = useState('');
  const URL = ServiceRequestPassword();
  const history = useHistory();

  const changeMail = (value) => {
    setinputMail(value);
    setdisabledBtn(false);
    if (value.trim() === '') {
      setdisabledBtn(true);
    }
  };

  const recovery = () => {
    if (!validEmail(inputMail)) {
      document.getElementById('input-mail').classList.add('is-invalid');
      OpenMessage('error', 'El formato del correo eléctronico no es válido');
      return false;
    }
    setdisabledBtn(true);
    const params = {
      email: inputMail,
    };
    POST(URL, params).then((data) => {
      setdisabledBtn(false);
      if (data === true) {
        OpenMessage('success', 'En breve recibira un correo para restaurar su contraseña');
        setinputMail('');
        setTimeout(() => history.push('/'), 3000);
      } else {
        OpenMessage('error', data);
      }
    });
  };

  return (
    <div className="container-fluid container__recoverypwd">
      <div className="row justify-content-center">
        <div className="col-8 col-sm-6 col-lg-4">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-12 mb-5">
                  <h5 className="card-title">
                    <i className="far fa-gem"></i>Recuperar contraseña
                  </h5>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-md-11 col-lg-10 col-xxl-8">
                  <div className="mb-3 form-outline">
                    <input
                      type="text"
                      id="input-mailRecovery"
                      className="form-control"
                      value={inputMail}
                      onChange={(e) => changeMail(e.target.value)}
                      maxLength="80"
                      onInput={(e) => onlyMail(e)}
                    />
                    <label htmlFor="input-mailRecovery" className="form-label">
                      Correo electrónico:
                    </label>
                    <div className="form-notch">
                      <div className="form-notch-leading"></div>
                      <div className="form-notch-middle"></div>
                      <div className="form-notch-trailing"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-8 text-center mt-3">
                  <button type="button" className="btn btn-outline-secondary btn-lg" onClick={recovery} disabled={disabledBtn}>
                    Enviar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
