import React, { useState, useEffect } from 'react';

import { ServiceGetCustomerAuctions } from '../../helpers/getURL';
import { GET } from '../../helpers/APIServices';
import { WatchCatalog } from '../Register/WatchCatalog';
import { convertToDate } from '../Utilities/General';

export const ActionAccount = (props) => {
  const [dataAuction, setDataAuction] = useState([]);

  useEffect(() => {
    if (props.visible === 'show') {
      const getData = () => {
        const URL = ServiceGetCustomerAuctions();
        GET(URL).then((data) => {
          if (data === '401') {
            getData();
            return '';
          }
          if (data) {
            setDataAuction(data);
          }
        });
      };
      getData();
    }
  }, [props.visible]);

  if (dataAuction.length === 0) {
    return (
      <div className={props.visible}>
        <div className="border-title">
          <div>Subastas</div>
        </div>
        <div className="mb-3 row inputs-form">
          <div className="col-12 text-center">No existen registros</div>
        </div>
      </div>
    );
  } else {
    return (
      <div className={props.visible}>
        <div className="border-title">
          <div>Subastas</div>
        </div>
        {dataAuction.map((item) => {
          return (
            <div className="mb-3 row inputs-form border-title" key={item.idAuction}>
              <div className="col-8">
                <h4>
                  {item.name} | {convertToDate(item.date)}
                </h4>
              </div>
              <div className="col-4 auctions-account">
                <WatchCatalog idAuction={item.idAuction} modal={false} />
              </div>
            </div>
          );
        })}
      </div>
    );
  }
};
