import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { GET, POST } from '../../helpers/APIServices';
import { ServiceValidateTokenPasswordReset, ServiceResetPasswordReset } from '../../helpers/getURL';
import { OpenMessage, validPassword, changePasswordText } from '../Utilities/General';
import '../../css/Login/RecoveryPassword.css';

let id = '';
let token = '';
export const ResetPassword = () => {
  const [inputPasswordReset, setinputPasswordReset] = useState('');
  const [inputVerifyPasswordReset, setinputVerifyPasswordReset] = useState('');
  const [disabledBtnReset, setdisabledBtnReset] = useState(true);

  const history = useHistory();
  const { search } = useLocation();

  let URLReset = ServiceResetPasswordReset();

  useEffect(() => {
    let URL = ServiceValidateTokenPasswordReset();
    if (!search) {
      history.push('/');
    }
    if (URL) {
      URL += search;
      GET(URL).then((data) => {
        if (!data) {
          history.push('/expire');
        }
        id = data.id;
        token = data.token;
        const element = document.getElementsByClassName('container__recoverypwd');
        if (element && element.length === 1) {
          element[0].classList.remove('hidden');
        }
      });
    }
  }, [search, history]);

  const verifyPassword = () => {
    const msg = validPassword(inputPasswordReset);
    if (msg) {
      OpenMessage('error', msg);
      document.getElementById('input-passwordReset').classList.add('is-invalid');
      return false;
    }
    if (inputPasswordReset !== inputVerifyPasswordReset) {
      const password = ['input-passwordReset', 'input-passwordVerifyReset'];
      password.forEach((id) => {
        let element = document.getElementById(id);
        element.classList.add('is-invalid');
      });
      OpenMessage('error', 'Las contraseñas no coinciden');
      return false;
    }

    return true;
  };

  const enableBtnReset = () => {
    setdisabledBtnReset(true);
    if (inputPasswordReset && inputVerifyPasswordReset) {
      setdisabledBtnReset(false);
    }
  };

  const resetPassword = () => {
    if (verifyPassword()) {
      setdisabledBtnReset(true);
      const params = {
        id: id,
        token: token,
        newpassword: inputPasswordReset,
      };
      POST(URLReset, params).then((data) => {
        OpenMessage('success', data);
        setinputPasswordReset('');
        setinputVerifyPasswordReset('');

        setTimeout(() => history.push('/'), 3000);
      });
    }
  };

  return (
    <div className="container-fluid container__recoverypwd hidden">
      <div className="row justify-content-center">
        <div className="col-8 col-sm-6 col-lg-4">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-12 mb-5">
                  <h5 className="card-title">
                    <i className="far fa-gem"></i>Restaurar contraseña
                  </h5>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-md-11 col-lg-10 col-xxl-8">
                  <div className="mb-3 form-outline input-group input-password container-input-login">
                    <input
                      type="password"
                      className="form-control"
                      id="input-passwordReset"
                      value={inputPasswordReset}
                      onKeyUp={enableBtnReset}
                      onChange={(e) => setinputPasswordReset(e.target.value)}
                      maxLength="50"
                      aria-describedby="icon-eyes-login"
                    />
                    <i className="fas fa-eye-slash" id="icon-eyes-login" onClick={(e) => changePasswordText('input-passwordReset', e)}></i>

                    <label htmlFor="input-passwordReset" className="form-label">
                      Nueva contraseña:
                    </label>
                    <div className="form-notch">
                      <div className="form-notch-leading"></div>
                      <div className="form-notch-middle"></div>
                      <div className="form-notch-trailing"></div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row justify-content-center">
                <div className="col-md-11 col-lg-10 col-xxl-8">
                  <div className="mb-3 form-outline input-group input-password container-input-login">
                    <input
                      type="password"
                      className="form-control"
                      id="input-passwordVerifyReset"
                      onKeyUp={enableBtnReset}
                      value={inputVerifyPasswordReset}
                      onChange={(e) => setinputVerifyPasswordReset(e.target.value)}
                      maxLength="50"
                      aria-describedby="icon-eyes-login"
                    />
                    <i
                      className="fas fa-eye-slash"
                      id="icon-eyes-login"
                      onClick={(e) => changePasswordText('input-passwordVerifyReset', e)}
                    ></i>

                    <label htmlFor="input-passwordReset" className="form-label">
                      Confirmar contraseña:
                    </label>
                    <div className="form-notch">
                      <div className="form-notch-leading"></div>
                      <div className="form-notch-middle"></div>
                      <div className="form-notch-trailing"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-8 text-center mt-3">
                  <button type="button" className="btn btn-outline-secondary btn-lg" disabled={disabledBtnReset} onClick={resetPassword}>
                    Cambiar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
