import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { validSession } from '../../Utilities/General';

import { ShoppingCart } from './ShoppingCart';
import { Send } from './Send';
import { OrderPanel } from './OrderPanel';
import { Mail } from './Mail';
import { Invoice } from './Invoice';
import { AddressBilling } from './AddressBilling';
import { AddressShipping } from './AddressShipping';
import { Payment } from './Payment';
import { ConfirmOrder } from './ConfirmOrder';
import { getConfig } from '../../../helpers/getConfig';
import { GET } from '../../../helpers/APIServices';
import { ServiceAuctionById } from '../../../helpers/getURL';

export const PaymentProcessGrid = (props) => {
  //Variables Generales
  const [visibleShopping, setVisibleShopping] = useState('hide');
  const [visibleSend, setVisibleSend] = useState('hide');
  const [visibleAddressBilling, setVisibleAddressBilling] = useState('hide');
  const [visibleMail, setVisibleMail] = useState('hide');
  const [visibleInvoice, setVisibleInvoice] = useState('hide');
  const [visibleAddressShipping, setVisibleAddressShipping] = useState('hide');
  const [visiblePayment, setVisiblePayment] = useState('hide');
  const [visibleConfirmOrder, setVisibleConfirmOrder] = useState('hide');
  const [lastStep, setLastStep] = useState(false);
  const [rate, setRate] = useState(0);
  const [originStep, setOrigenStep] = useState(0); //pantalla origen envio
  const [originStep2, setOrigenStep2] = useState(0); //pantalla origen factura
  const [hideBillPocket, setHideBillPocket] = useState(false);
  const [hideActnet, setHideActnet] = useState(false);
  const [hideMercadoPago, setHideMercadoPago] = useState(false);
  const [idTypeAuction, setIdTypeAuction] = useState('');

  //ShoppingCart
  const [dataSubTotal, setDataSubTotal] = useState(0);
  const [dataCount, setDataCount] = useState(0);
  const [dataConsigna, setDataConsigna] = useState(false); //true = lotes plus, false = lotes gama (SAP)
  let [dataInformation, setDataInformation] = useState([]);

  const history = useHistory();
  const detail = props.location.state ? props.location.state.detail : null;
  const idAuction = props.location.state && props.location.state.detail.length > 0 ? props.location.state.detail[0].idSubasta : '';
  const enableInvoice = props.location.state ? props.location.state.enableInvoice : null;

  //OrderPanel
  const [disabledBtnOrderPanel, setDisabledBtnOrderPanel] = useState(true);

  
  useEffect(() => {
    if (!validSession()) {
      history.push('/?home=true');
    }
    if (!detail) {
      history.push('/perfil');
    }
    setVisibleShopping('show');
  }, [history, detail]);

  useEffect(() => {
    const constants = JSON.parse(sessionStorage.getItem('constants'));
    if (!constants || !constants.hideBillPocket) {
      getConfig('constants.json').then((response) => {
        if (response && response.hideBillPocket) {
          setHideBillPocket(response.hideBillPocket);
        }
        if (response && response['hideActnet']) {
          setHideActnet(response['hideActnet']);
        }
        if (response && response['hideMercadoPago']) {
          setHideMercadoPago(response['hideMercadoPago']);
        }
      });
    } else {
      setHideBillPocket(constants.hideBillPocket);
      setHideActnet(constants['hideActnet']);
      setHideMercadoPago(constants['hideMercadoPago']);
    }
  }, [hideBillPocket]);

  useEffect(() => {
    const URL = ServiceAuctionById(idAuction);
    const getData = () => {
      GET(URL).then((data) => {
        if (data === '401') {
          getData();
          return '';
        }
        if(data.idTypeAuction){
          setIdTypeAuction(data.idTypeAuction);
        }
      });
    };
    getData();
  }, []);

  const addAllCart = (check, consigna, consignaSAP, idElement) => {
    console.log('addAllCart-onChange', check, consigna, consignaSAP, idElement);
    const checkAll = document.getElementsByClassName('checkAll');
    document.getElementById(idElement).checked = check;
    setDataSubTotal(0);
    setDataCount(0);
    setDisabledBtnOrderPanel(false);
    setDataConsigna(consigna);
    let total = 0;
    let count = 0;

    for (let i = 0; i < checkAll.length; i++) {
      checkAll[i].disabled = check;
      if (checkAll[i].id !== idElement) {
        checkAll[i].checked = false;
      } else {
        checkAll[i].disabled = false;
      }
    }

    detail.forEach((element) => {
      if (element.consigna === consigna && element.consignaSAP === consignaSAP) {
        document.getElementById(element.idLote).checked = check;
        document.getElementById(element.idLote).disabled = false;
        if (check) {
          total += element.precioSalida;
          count++;
        } else {
          total -= element.precioSalida;
          count--;
        }
      } else {
        document.getElementById(element.idLote).checked = false;
        document.getElementById(element.idLote).disabled = check;
      }
    });

    if (total <= 0) {
      total = 0;
      count = 0;
      setDisabledBtnOrderPanel(true);
    }
    setDataSubTotal(total);
    setDataCount(count);
  };

  const addCart = (idLot, check, price, consigna, consignaSAP, idElement) => {
    console.log('check individual', idLot, consigna, consignaSAP, idElement);

    const checkAll = document.getElementsByClassName('checkAll');
    const totalItems = detail.filter((x) => x.consigna === consigna && x.consignaSAP === consignaSAP).length;
    let item = 0;
    let total = dataSubTotal;
    let count = dataCount;
    setDisabledBtnOrderPanel(false);
    setDataConsigna(consigna);
    if (check) {
      total += price;
      count++;
    } else {
      total -= price;
      count--;
    }
    if (count <= 0) {
      setDisabledBtnOrderPanel(true);
    }
    setDataSubTotal(total);
    setDataCount(count);

    //Controla disabled de cada checkbox
    detail.forEach((element) => {
      const el = document.getElementById(element.idLote);
      if (element.consigna === consigna && element.consignaSAP === consignaSAP) {
        el.disabled = false;
        if (el.checked) {
          item++;
        } else {
          item = item > 0 ? item-- : 0;
        }
      } else {
        el.disabled = true;
      }
      //ToDo: Validar si esta variable se controla con un useState o similar
      if(count == 0){
        el.disabled = false;
      }
    });

    //Controla check / unchek de checkbox select all
    for (let i = 0; i < checkAll.length; i++) {
      //disabled
      if (checkAll[i].id === idElement) {
        checkAll[i].disabled = false;
      } else {
        checkAll[i].disabled = true;
      }
      //ToDo: Validar si esta variable se controla con un useState o similar
      if(count == 0){
        checkAll[i].disabled = false;
      }

      // checkall
      if (item === totalItems && checkAll[i].id === idElement) {
        checkAll[i].checked = true;
      } else {
        checkAll[i].checked = false;
      }
    }
  };

  const continueOrderPanel = (disabledBtn, step, data) => {
    setVisibleShopping('hide');
    setVisibleSend('hide');
    setVisibleAddressBilling('hide');
    setVisibleMail('hide');
    setVisibleInvoice('hide');
    setVisibleAddressShipping('hide');
    setVisiblePayment('hide');
    setVisibleConfirmOrder('hide');
    setDisabledBtnOrderPanel(disabledBtn);

    if (dataConsigna && (step === 5 || step === 6)) {
      //lotes gama no tiene derecho a factura
      data = {
        ...data,
        invoice: {
          id: 2,
          name: 'no',
        },
      };
      step = 7;
    }
    //inhabilita la facturacion desde la tabla de paremetros del SPMLS
    if (!enableInvoice && (step === 5 || step === 6)) {
      step = 7;
    }

    if (step === 8 && data.payment && data.payment.name !== ''){
      step = 8;
    }else if(step === 8 && !dataInformation.payment){
      setLastStep(false);
      step = 7;
    }

    switch (step) {
      case 1:
        setVisibleShopping('show');
        setLastStep(false)
        break;
      case 2:
        setVisibleSend('show');
        let selectItem = [];
        detail.forEach((element) => {
          let check = document.getElementById(element.idLote).checked;
          if (check) {
            selectItem.push(element);
          }
        });
        const select = {
          selectItem: selectItem,
          allItem: detail,
        };
        deletePropertie('selectItem');
        deletePropertie('allItem');
        deletePropertie('AddressBilling');
        deletePropertie('mail');
        deletePropertie('addressMail');
        assignInformation(select, 'send');
        break;
      case 3:
        setVisibleAddressShipping('show');
        assignInformation(data, 'addressShipping');
        break;
      case 4:
        setVisibleMail('show');
        assignInformation(data, 'mail');
        break;
      case 5:
        setVisibleInvoice('show');
        deletePropertie('addressShipping');
        assignInformation(data, 'invoice');
        break;
      case 6:
        setVisibleAddressBilling('show');
        assignInformation(data, 'addressBilling');
        break;
      case 7:
        setVisiblePayment('show');
        assignInformation(data, 'payment');
        break;
      case 8:
        setVisibleConfirmOrder('show');
        assignInformation(data);
        setLastStep(true);
        setDisabledBtnOrderPanel(false);
        break;
      default:
        break;
    }
  };

  const assignInformation = (data, propertie) => {
    if (propertie) {
      deletePropertie(propertie);
    }
    data = {
      ...data,
      ...dataInformation,
    };
    setDataInformation(data);
    if (data && data.mail) {
      setRate(data.mail.rate);
    } else {
      setRate(0);
    }
  };

  const deletePropertie = (name) => {
    if (dataInformation && dataInformation[name]) {
      delete dataInformation[name];
    }
  };

  const setOrigen = (step) => {
    setOrigenStep(step);
  };

  const setOrigen2 = (step) => {
    setOrigenStep2(step);
  };

  return (
    <div className="container-fluid container__shoppingCart">
      <div className="row justify-content-center">
        <div className="col-12 col-md-9 mb-4">
          <ShoppingCart
            data={detail}
            addCart={addCart}
            addAllCart={addAllCart}
            visible={visibleShopping}
            eventButton={continueOrderPanel}
            step={1}
          />
          <Send
            visible={visibleSend}
            eventButton={continueOrderPanel}
            step={2}
            lastStep={lastStep}
            setOrigen={setOrigen}
            setOrigen2={setOrigen2}
            idTypeAuction={idTypeAuction}
          />
          <AddressShipping
            visible={visibleAddressShipping}
            eventButton={continueOrderPanel}
            step={3}
            lastStep={lastStep}
            data={dataInformation}
          />
          <Mail
            visible={visibleMail}
            eventButton={continueOrderPanel}
            step={4}
            lastStep={lastStep}
            data={dataInformation}
            setOrigen={setOrigen}
            setOrigen2={setOrigen2}
            subTotal={dataSubTotal}
            idTypeAuction={idTypeAuction}
          />
          <Invoice
            visible={visibleInvoice}
            eventButton={continueOrderPanel}
            step={5}
            lastStep={lastStep}
            originStep={originStep}
            setOrigen2={setOrigen2}
          />
          <AddressBilling 
            visible={visibleAddressBilling} 
            eventButton={continueOrderPanel} 
            step={6} 
            lastStep={lastStep} 
          />
          <Payment
            visible={visiblePayment}
            eventButton={continueOrderPanel}
            externStep={7}
            lastStep={lastStep}
            originStep2={originStep2}
            hideBillPocket={hideBillPocket}
            hideActnet={hideActnet}
            hideMercadoPago={hideMercadoPago}
          />
          <ConfirmOrder
            visible={visibleConfirmOrder}
            eventButton={continueOrderPanel}
            step={8}
            data={dataInformation}
            dataConsigna={dataConsigna}
            enableInvoice={enableInvoice}
          />
        </div>
        <div className="col-7 col-sm-6 col-md-3">
          <OrderPanel
            count={dataCount}
            subTotal={dataSubTotal}
            eventButton={continueOrderPanel}
            disabledBtn={disabledBtnOrderPanel}
            send={rate}
            data={dataInformation}
            lastStep={lastStep}
            enableInvoice={enableInvoice}
          />
        </div>
      </div>
    </div>
  );
};
