import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { OpenMessage } from '../../Utilities/General';
import { GET } from '../../../helpers/APIServices';
import { ServiceCompleteOrder } from '../../../helpers/getURL';

export const ReceivePay = () => {
  const { search } = useLocation();
  const [loading, setLoading] = useState('show');

  useEffect(() => {
    let userName = localStorage.getItem('userName');
    userName = userName ? userName : '';

    const URL = ServiceCompleteOrder();
    const PARAMS = search + '&NameCustomer=' + userName;

    const getData = () => {
      GET(URL + PARAMS).then((data) => {
        if (data === '401') {
          getData();
          return '';
        }
        if (data.successOrder) {
          setTimeout(() => {
            setLoading('hide');
            const idOrder = new URLSearchParams(search).get('externalId');
            window.open('/detalle-orden?id=' + idOrder, '_self');
          }, 2000);
          
        } else {
          setLoading('hide');
          OpenMessage('error', data.orderMessage);
        }
      });
    };
    getData();
  }, [search]);

  return (
    <div className="row container-fluid container__expire justify-content-center">
      <div id='loading2' className={'row container-back container-loading ' + loading }>
          <div className='col-12 text-center'>
            <img src='./resources/loading.gif' alt='loading...' width='15%' />
          </div>
        </div>
      <div className="col-8 col-sm-6 col-lg-5 col-xxl-4 text-center">
        <label>Validando pago</label>
      </div>
    </div>
  );
};
