import React from 'react';
import { Link } from 'react-router-dom';

function AuctionsHighlight(props) {
  return (
    <div className="container-fluid container-auctionsHighlight">
      <div className="row">
        <div className="col-11 offset-1 text-uppercase">
          <h2>
            {' '}
            <span className="container-auctionsHighlight--title">{props.title}</span> {props.title2}
          </h2>
        </div>
      </div>
      <div className="row container-auctionsHighlight-row">
        <div className="col-7 offset-1">
          <span className="container-auctionsHighlight--description">
            Contaremos con una gran variedad de piezas únicas en distintas categorías:{' '}
          </span>
        </div>
      </div>
      <div className="row container-auctionsHighlight-row">
        <div className="col-11 container-auctionsHighlight-col container-auctionsHighlight-btn2 text-end">
          <Link to="/catalogo">
            <button className="btn btn-outline-danger btn-lg">Catálogo completo</button>
          </Link>
        </div>
      </div>
      <div className="container-auctionsHighlight-row container__auctionsHighlight--types">{props.children}</div>
    </div>
  );
}

export default AuctionsHighlight;
