/* eslint-disable no-useless-escape */
/* eslint-disable no-undef */
import { getConfig } from '../../helpers/getConfig';
import axios from 'axios';
import { getMercadoPagoPublicKey } from '../../helpers/getConstants'
import { ServiceGenericParameters } from '../../helpers/getURL'
import { GET } from '../../helpers/APIServices'
import { initMercadoPago } from '@mercadopago/sdk-react'

export const CloseModal = (elementID) => {
  const modal = document.getElementById(elementID);
  const modalBootstrap = bootstrap.Modal.getInstance(modal);
  if (modalBootstrap) {
    modalBootstrap.hide();
  }
  const backdrops = document.getElementsByClassName('modal-backdrop fade').length - 1;
  for (let i = 0; i < backdrops; i++) {
    document.getElementsByClassName('modal-backdrop fade')[i].remove();
  }
};

export const OpenModal = (elementID) => {
  const modal = document.getElementById(elementID);
  const modalBootstrap = new bootstrap.Modal(modal);
  modalBootstrap.toggle();
};

export const CloseAllModal = () => {
  const modals = document.getElementsByClassName('modal show');
  for (let i = 0; i < modals.length; i++) {
    let modalBootstrap = bootstrap.Modal.getInstance(modals[i]);
    if (modalBootstrap) {
      modalBootstrap.hide();
    }
  }
  const backdrops = document.getElementsByClassName('modal-backdrop fade').length - 1;
  for (let i = 0; i < backdrops; i++) {
    document.getElementsByClassName('modal-backdrop fade')[i].remove();
  }
};

export const OpenMessage = (type, messageService, contact = false, messageSubText = null) => {
  let dataMessage = getConfig('messages.json').then((data) => {
    switch (type) {
      case 'success':
        return data.success;
      case 'warning':
        return data.warning;
      case 'error':
        return data.error;
      case 'confirm':
        return data.confirm;
      default:
        return null;
    }
  });

  dataMessage.then((data) => {
    const { message, icon, text, text2, button } = data;
    let messageType = 'message-' + type + ' ' + icon;
    const arrIcon = messageType.split(' ');
    document.getElementById('headerTitle').innerHTML = message;
    switch (type) {
      case 'success':
        document
          .getElementById('messageType')
          .classList.remove('message-error', 'fas', 'fa-times', 'message-warning', 'fa-exclamation-circle', 'message-confirm');
        document.getElementById('buttonClose').classList.remove('btn-outline-warning', 'btn-outline-danger', 'btn-outline-dark');
        break;
      case 'error':
        document
          .getElementById('messageType')
          .classList.remove('message-success', 'fas', 'fa-check-circle', 'message-warning', 'fa-exclamation-circle', 'message-confirm');
        document.getElementById('buttonClose').classList.remove('btn-outline-dark', 'btn-outline-warning', 'btn-outline-success');
        break;
      case 'warning':
        document
          .getElementById('messageType')
          .classList.remove('message-success', 'fas', 'fa-check-circle', 'message-warning', 'message-error', 'fa-times', 'message-confirm');
        document.getElementById('buttonClose').classList.add('btn-outline-dark', 'btn-outline-success', 'btn-outline-error');
        break;
      case 'confirm':
        document
          .getElementById('messageType')
          .classList.remove('message-success', 'fas', 'fa-check-circle', 'message-warning', 'message-error', 'fa-times');
        document.getElementById('buttonClose').classList.add('btn-outline-warning', 'btn-outline-success', 'btn-outline-error');
        break;
      default:
        break;
    }

    arrIcon.forEach((element) => {
      document.getElementById('messageType').classList.add(element);
    });

    document.getElementById('messageText').innerHTML = messageService ? messageService : text;
    if (messageSubText) {
      document.getElementById('messageSubText').innerHTML = messageSubText;
    }
    document.getElementById('messageContact').innerHTML = contact ? 'subastas@montepio.org.mx' : '';

    const messageText2 = document.getElementById('messageText2');
    text2 ? (messageText2.innerHTML = text2) : (messageText2.style = 'display:none');

    document.getElementById('buttonClose').classList.add(button);

    const modal = new bootstrap.Modal(document.getElementById('modalMessage'), {});
    modal.toggle();
  });
};

export const OpenStreamingRegisterMessage = () => {
  const modal = new bootstrap.Modal(document.getElementById('modalStreamingReisterMesage'), {});
  modal.toggle();
};

export const OpenOfferSuccess = (message, redirectHome = true) => {
  document.getElementById('textMessageSection').innerHTML = message;
  document.getElementById('redirectHome').value = redirectHome;
  const modal = new bootstrap.Modal(document.getElementById('modalOfferSuccess'), {});
  modal.toggle();
};
export const OpenCloseStreaming = () => {
  const modal = new bootstrap.Modal(document.getElementById('modalCloseStreaming'), {});
  modal.toggle();
};
export const OpenWaitNextAuction = () => {
  const modal = new bootstrap.Modal(document.getElementById('modalWaitNextAuction'), {});
  modal.toggle();
};
export const OpenDeclineClient = () => {
  const modal = new bootstrap.Modal(document.getElementById('modalDeclineClient'), {});
  modal.toggle();
};
export const verifyFieldsRequerid = (elements) => {
  let error = false;
  elements.forEach((id) => {
    let element = document.getElementById(id);
    if (!element.value.trim()) {
      error = true;
      element.classList.add('is-invalid');
    } else {
      element.classList.remove('is-invalid');
    }
  });
  if (error) {
    return false;
  }
  return true;
};

export const validEmail = (email) => {
  const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  const valid = regex.test(email);
  if (!valid) {
    return false;
  }
  return true;
};

//FORMATO: dd/mm/aaaa
export const validDate = (date) => {
  const regex =
    /^(?:(?:(?:0?[1-9]|1\d|2[0-8])[/](?:0?[1-9]|1[0-2])|(?:29|30)[/](?:0?[13-9]|1[0-2])|31[/](?:0?[13578]|1[02]))[/](?:0{2,3}[1-9]|0{1,2}[1-9]\d|0?[1-9]\d{2}|[1-9]\d{3})|29[/]0?2[/](?:\d{1,2}(?:0[48]|[2468][048]|[13579][26])|(?:0?[48]|[13579][26]|[2468][048])00))$/;
  const valid = regex.test(date);
  if (!valid) {
    return false;
  }
  return true;
};

export const restoreInputs = (inputs) => {
  inputs.forEach((id) => {
    let element = document.getElementById(id);
    element.classList.remove('active');
    element.classList.remove('is-invalid');
  });
};

export const validRFC = (input, esFiscoMoral) => {
  let rfc = input.value;
  if (rfc === '') {
    OpenMessage('warning', 'El RFC no tiene un formato válido');
    return false;
  }

  input.classList.remove('is-invalid');
  rfc = rfc.replace(/\-/g, '');

  if (esFiscoMoral && rfc.match(/^([A-Z]{3}[0-9]{6}[A-Z0-9]{3})$/i)) {
    return true;
  } else if (!esFiscoMoral && rfc.match(/^([A-Z]{4}[0-9]{6}[A-Z0-9]{3})$/i)) {
    return true;
  }

  input.classList.add('is-invalid');

  OpenMessage('warning', 'El RFC no tiene un formato válido');
  return false;
};

export const validPassword = (value) => {
  let msg = '';
  if (value.length < 8) {
    msg = 'La contraseña debe contar con 8 caracteres como mínimo';
  } else if (value.search(/[A-Z]/) < 0) {
    msg = 'La contraseña debe de tener al menos una mayúscula';
  } else if (value.search(/[a-z]/) < 0) {
    msg = 'La contraseña debe de tener al menos una minúscula';
  } else if (value.search(/[0-9]/) < 0) {
    msg = 'La contraseña debe de tener al menos un dígito del 0 al 9';
  } else if (value.search(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?¿~]/) < 0) {
    msg = 'La contraseña debe de tener al menos un caracter especial';
  }
  return msg;
};

export const validSession = () => {
  const token = localStorage.getItem('token');
  if (token) {
    setSession(true, token);
    return true;
  }
  setSession(false, '');
  return false;
};

export const setSession = (open, data) => {
  let items = document.getElementsByClassName('container__users--item');
  let login = document.getElementsByClassName('container__users--login');
  let loginMobile = document.getElementsByClassName('loginMobile');
  let itemsMobile = document.getElementsByClassName('loginMobile--items');
  let userName = document.getElementById('userName');
  let userNameMobile = document.getElementById('userName2');
  let add = '';
  let remove = '';

  if (open) {
    if (data && data.token) {
      localStorage.setItem('id', data.id);
      localStorage.setItem('token', data.token); //Se hace una actualizacion en APIServices
      localStorage.setItem('userName', data.name);
      localStorage.setItem('auctions', data.auctions);
      localStorage.setItem('refreshToken', data.refreshToken); //Se hace una actualizacion en APIServices
      localStorage.setItem('expiration', data.expiration); //Se hace una actualizacion en APIServices
      localStorage.setItem('expirationRefresh', data.expirationRefresh);
      //tambien se asigna en ReceivePayToken y RegisterToOffer
      localStorage.setItem('IdPalleteVIP', (data.idPalleteVIP !== null && data.idPalleteVIP !== undefined) ? data.idPalleteVIP: '' );
    }
    if (userName) {
      userName.innerHTML = localStorage.getItem('userName');
      userNameMobile.innerHTML = localStorage.getItem('userName');
    }
    add = 'hide';
    remove = 'show';

    const mercadoPagoPublicKey = localStorage.getItem('MercadoPagoPublicKey')
    if (!mercadoPagoPublicKey) {
      const idMercadoPagoPublicKey = getMercadoPagoPublicKey();
      const endpointMercadoPagoPublicKey = ServiceGenericParameters(idMercadoPagoPublicKey);
      GET(endpointMercadoPagoPublicKey).then((data) => {
        if (data && data.value) {
          localStorage.setItem('MercadoPagoPublicKey', data.value);
          initMercadoPago(data.value, {locale: "es-MX"});
        }
      });
    }
    else {
      initMercadoPago(mercadoPagoPublicKey, {locale: "es-MX"});
    }

  } else {
    localStorage.clear();
    if (userName) {
      userName.innerHTML = '';
      userNameMobile.innerHTML = '';
    }
    add = 'show';
    remove = 'hide';
  }
  items.forEach((element) => {
    element.classList.add(add);
    element.classList.remove(remove);
  });

  login.forEach((element) => {
    element.classList.add(add === 'show' ? 'hide' : 'show');
    element.classList.remove(remove === 'hide' ? 'show' : 'hide');
  });

  loginMobile.forEach((element) => {
    element.classList.add(add);
    element.classList.remove(remove);
  });

  itemsMobile.forEach((element) => {
    element.classList.add(add === 'show' ? 'hide' : 'show');
    element.classList.remove(remove === 'hide' ? 'show' : 'hide');
  });
};

//Establece el minimo, maximo de un campo de tipo fecha
export const setMinMaxDate = (id) => {
  let element = document.getElementById(id);
  if (element) {
    const currentDate = new Date();
    let currentMonth = currentDate.getMonth();
    if (currentMonth < 10) {
      currentMonth = '0' + currentDate.getMonth();
    }
    let currentDay = currentDate.getDate();
    if (currentDay < 10) {
      currentDay = '0' + currentDate.getDate();
    }
    const dateMax = currentDate.getFullYear() - 18 + '-' + currentMonth + '-' + currentDay;
    element.setAttribute('min', currentDate.getFullYear() - 100 + '-01-01');
    element.setAttribute('max', dateMax);
    return dateMax;
  }
  return '';
};

export const validMinMaxDate = (id) => {
  let element = document.getElementById(id);
  if (element) {
    let limitDate = new Date();
    limitDate.setFullYear(limitDate.getFullYear() - 18);
    const date = new Date(element.value);
    const minDate = new Date(element.min);
    const maxDate = new Date(limitDate);
    if (date >= minDate && date <= maxDate) {
      return true;
    }
  }
  return false;
};

export const onlyText = (event) => {
  let element = document.getElementById(event.target.id);
  if (element) {
    element.value = element.value.replace(/[^a-zA-Z\s\u00f1\u00d1]/g, '').replace(/(\..*)\./g, '$1');
  }
};

export const onlyMail = (event) => {
  let element = document.getElementById(event.target.id);
  if (element) {
    element.value = element.value.replace(/[^a-zA-Z0-9@_.-]/g, '');
  }
};

export const onlyNumber = (event) => {
  let element = document.getElementById(event.target.id);
  if (element) {
    element.value = element.value.replace(/[^0-9]/g, '');
  }
};
//texto, numeros, espacio en blanco y guion medio
export const onlyTextNumber = (event) => {
  let element = document.getElementById(event.target.id);
  if (element) {
    element.value = element.value.replace(/[^a-zA-Z0-9\u00f1\u00d1\s.-]/g, '');
  }
};

//texto y numeros
export const onlyTextNumber2 = (event) => {
  let element = document.getElementById(event.target.id);
  if (element) {
    element.value = element.value.replace(/[^a-zA-Z0-9\u00f1\u00d1]/g, '');
  }
};

//Entrada: 2021-06-01T03:51:00
//Salida: 01 junio 2021 | 03:51 AM
export const convertToDate = (value) => {
  const monthNames = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ];
  const d = new Date(value);
  let hours = d.getHours();
  let minutes = d.getMinutes();
  let meridiem = 'A.M.';
  if (hours >= 12) {
    meridiem = 'P.M.';
  }
  if (hours > 12) {
    hours -= 12;
  }
  if (hours === 0) {
    hours = 1;
  }
  if (hours <= 9) {
    hours = '0' + hours;
  }
  if (minutes <= 9) {
    minutes = '0' + minutes;
  }
  return d.getDate() + ' ' + monthNames[d.getMonth()] + ' ' + d.getFullYear() + ' | ' + hours + ':' + minutes + ' ' + meridiem;
};

//Entrada: 2021-06-01T03:51:00
//Salida: 01 junio 2021 | 03:51 AM
export const convertToDatePenaltyDay = (value, days) => {
  const monthNames = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ];
  let d = new Date(value);
  d.setDate(d.getDate() + days);
  let hours = d.getHours();
  let minutes = d.getMinutes();
  let meridiem = 'A.M.';
  if (hours >= 12) {
    meridiem = 'P.M.';
  }
  if (hours > 12) {
    hours -= 12;
  }
  if (hours === 0) {
    hours = 1;
  }
  if (hours <= 9) {
    hours = '0' + hours;
  }
  if (minutes <= 9) {
    minutes = '0' + minutes;
  }
  return d.getDate() + ' ' + monthNames[d.getMonth()] + ' ' + d.getFullYear() + ' | ' + hours + ':' + minutes + ' ' + meridiem;
};

//Entrada: 2021-06-01T03:51:00
//Salida: 01/06/2021
export const convertToDateWithTime = (value) => {
  const d = new Date(value);
  let month = d.getMonth() + 1;
  if (month < 10) {
    month = '0' + month;
  }
  return d.getDate() + '/' + month + '/' + d.getFullYear();
};

//Entrada: 2021-06-01T03:51:00
//Salida: 2021-06-01
export const convertToDateFormatEnglish = (value) => {
  const d = new Date(value);
  let month = d.getMonth() + 1;
  let day = d.getDate();
  if (month < 10) {
    month = '0' + month;
  }
  if (day < 10) {
    day = '0' + day;
  }
  return d.getFullYear() + '-' + month + '-' + day;
};

//Entrada: 2021-06-01T03:51:00
//Salida: 03:51:00 AM
export const convertToTime = (value) => {
  const d = new Date(value);
  let hours = d.getHours();
  let minutes = d.getMinutes();
  let seconds = d.getSeconds();
  let meridiem = 'A.M.';
  if (hours >= 12) {
    meridiem = 'P.M.';
  }
  if (hours <= 9) {
    hours = '0' + hours;
  }
  if (minutes <= 9) {
    minutes = '0' + minutes;
  }
  if (seconds <= 9) {
    seconds = '0' + seconds;
  }
  return hours + ':' + minutes + ':' + seconds + ' ' + meridiem;
};

export const cancelHref = (event) => {
  event.preventDefault();
  return false;
};

export const changePasswordText = (id, event) => {
  let element = document.getElementById(id);
  if (element) {
    const type = element.getAttribute('type') === 'password' ? 'text' : 'password';
    event.target.attributes.class.nodeValue = type === 'password' ? 'fas fa-eye-slash' : 'fas fa-eye';
    element.setAttribute('type', type);
  }
};

export const setAttributePassword = (id) => {
  let element = document.getElementById(id);
  if (element) {
    element.setAttribute('type', 'password');
  }
};

export const formatCurrency = (value) => {
  const options = { style: 'currency', currency: 'USD' };
  const numberFormat = new Intl.NumberFormat('en-US', options);
  if (isNaN(value)) {
    value = 0;
  }
  return numberFormat.format(value);
};

export const capitalize = (value) => {
  if (!value) {
    return '';
  }
  let string = '';
  let letter = value.split(' ');
  for (let i = 0; i < letter.length; i++) {
    string += letter[i].charAt(0).toUpperCase() + letter[i].slice(1).toLowerCase() + ' ';
  }
  return string.trim();
};

export const setCart = (lote) => {
  let cart = JSON.parse(localStorage.getItem('cart'));
  if (!cart) {
    cart = [];
  }
  if (
    cart.filter((item) => {
      return item.IdLot === lote.IdLot;
    }).length === 0
  )
    localStorage.setItem('cart', JSON.stringify([...cart, lote]));
};
export const pullCart = (lote) => {
  let cart = JSON.parse(localStorage.getItem('cart'));
  if (!cart) {
    cart = [];
  }
  cart = cart.filter((item) => {
    return item.IdLot !== lote.IdLot;
  });
  localStorage.setItem('cart', JSON.stringify(cart));
};

export const getCart = () => {
  let cart = JSON.parse(localStorage.getItem('cart'));
  if (!cart) {
    cart = [];
  }
  return cart;
};

export const resetRadios = (name) => {
  let element = document.getElementsByName(name);
  if (element && element.length > 0) {
    for (let i = 0; i < element.length; i++) {
      element[i].checked = false;
    }
  }
};

export const closeMenuMobile = () => {
  const menu = document.getElementById('menu');
  const open = menu.classList.contains('show');
  if (open) {
    const element = document.getElementById('navbarMenu');
    if (element) {
      element.click();
    }
  }
};

//Solo valida un formato general, sin digito verificador
export const validCURP = (inputCURP) => {
  let curp = inputCURP.value;
  if (curp === '') {
    OpenMessage('warning', 'El CURP no tiene un formato válido');
    return false;
  }

  inputCURP.classList.remove('is-invalid');
  curp = curp.replace(/\-/g, '');

  const expReg =
    /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/;
  const valid = curp.match(expReg);

  if (!valid) {
    inputCURP.classList.add('is-invalid');
    OpenMessage('warning', 'El CURP no tiene un formato válido');
    return false;
  }
  return true;
};

//Verifica la fecha de expiracion del token
//se comenta la funcion, se esta viendo otra forma de validar el TOKEN
/*export const validDateExpire = () => {
  const expiration = localStorage.getItem('expiration');
  if (!expiration) {
    return -1;
  }

  const dateExp = new Date(expiration);
  const currentDate = new Date();

  const date1utc = Date.UTC(dateExp.getFullYear(), dateExp.getMonth(), dateExp.getDate());
  const date2utc = Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
  const day = 86400000; //milisegundos
  const differenceDays = (date2utc - date1utc) / day;
  if (differenceDays !== 0) {
    //0 = mismo dia
    return -1;
  }

  const hours = dateExp.getHours() - currentDate.getHours();
  if (hours < 0) {
    //excede la hora
    return -1;
  }
  const minutes = dateExp.getMinutes() - currentDate.getMinutes();
  if (hours === 0 && minutes <= 0) {
    //excede los minutos
    return -1;
  }
  return 0;
};*/

export const CloseBackdrops = () => {
  const backdrops = document.getElementsByClassName('modal-backdrop fade show').length;
  for (let i = 0; i < backdrops; i++) {
    document.getElementsByClassName('modal-backdrop fade')[i].remove();
  }
};

export const ResetStyleBody = () => {
  document.getElementsByTagName('body')[0].style = '';
};

export const getIP = async () => {
  const response = await axios.get('https://api.ipify.org?format=json');
  if (response && response.data && response.data.ip) {
    return response.data.ip;
  }
  return '';
};

export const addFieldsRequerid = (id) => {
  let element = document.getElementById(id);
  element.classList.add('is-invalid');
};

//obliga a tener el label arriba del input
export const verifyInputField = (container) => {
  const element = document.getElementById(container);
  if (element) {
    new mdb.Input(element).update();
  }
};

const removeArt = (word) => {
  return word.replace('DEL ', '').replace('LAS ', '').replace('DE ', '').replace('LA ', '').replace('Y ', '').replace('A ', '');
};
const isVowel = (letter) => {
  if (letter === 'A' || letter === 'E' || letter === 'I' || letter === 'O' || letter === 'U') return true;
  else return false;
};

//Calcula el RFC/CURP sin homoclave
//birthDay YYYY-mm-dd
export const calcRFC = (name, lastName, secondLastName, birthDay) => {
  name = name.toUpperCase();
  lastName = lastName.toUpperCase();
  secondLastName = secondLastName.toUpperCase();
  birthDay = birthDay.toUpperCase();

  let rfc = '';
  lastName = removeArt(lastName);
  secondLastName = removeArt(secondLastName);

  if (lastName.length === 0) {
    return '';
  }

  rfc = lastName.substr(0, 1);
  let c;
  for (let i = 0; i < lastName.length; i++) {
    c = lastName.charAt(i);
    if (isVowel(c)) {
      rfc += c;
      break;
    }
  }

  if (secondLastName.length === 0) {
    return rfc;
  }
  rfc += secondLastName.substr(0, 1);

  if (name.length === 0) {
    return rfc;
  }
  rfc += name.substr(0, 1);

  if (birthDay.length === 0) {
    return rfc;
  }
  rfc += birthDay.substr(2, 2); //YY
  rfc += birthDay.substr(5, 2); //mm
  rfc += birthDay.substr(8, 5); //dd
  return rfc;
};

export const validDataComplementary = (clientData) => {
  let GeneralData = [
    'customerName',
    'customerLastname',
    'customerSecondLastname',
    'customerBirthday',
    'customerCURP',
    'customerRFC',
    'customerNationalityId',
  ];
  let ContactData = ['country', 'idState', 'idTown', 'idCity', 'street', 'zipCode', 'number', 'phoneNumber', 'email'];
  let EconomicActivityData = ['idActEconomica', 'idPais'];
  let contador = 0;
  for (let key in clientData) {
    if (key === 'customerGeneralData') {
      for (let customerGeneralData in clientData[key]) {
        let campo = GeneralData.find((item) => {
          return item === customerGeneralData;
        });
        if (campo) {
          if (!clientData[key][customerGeneralData]) {
            contador++;
          }
        }
      }
    }
    if (key === 'customerContactData') {
      for (let customerContactData in clientData[key]) {
        let campo = ContactData.find((item) => {
          return item === customerContactData;
        });
        if (campo) {
          if (!clientData[key][customerContactData] && !clientData[key][customerContactData] !== '') {
            contador++;
          }
        }
      }
    }

    if (key === 'customerEconomicActivity') {
      for (let customerEconomicActivity in clientData[key]) {
        let campo = EconomicActivityData.find((item) => {
          return item === customerEconomicActivity;
        });
        if (campo) {
          if (!clientData[key][customerEconomicActivity]) {
            contador++;
          }
        }
      }
    }
  }
  return contador;
};
