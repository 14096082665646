import React, { useEffect, useState } from 'react';
import { GET } from '../../helpers/APIServices';
import { ServiceGetListBids, ServiceGetListBidsDetails } from '../../helpers/getURL';
import { OpenModal, CloseAllModal, formatCurrency, convertToDate } from '../Utilities/General';

export const Bids = (props) => {
  const [clientDataBids, setDataBids] = useState([]);
  const [clientDataDetailsBids, setDataDetailsBids] = useState([]);
  const [auctionName, setAuctionName] = useState('');
  const URL = ServiceGetListBids();

  const openDetailBids = (idAuction, nameAuction) => {
    const URLDetail = ServiceGetListBidsDetails(idAuction);
    GET(URLDetail).then((data) => {
      setDataDetailsBids([]);
      if (data && data.length > 0) {
        setDataDetailsBids(data);
      }
      setAuctionName(nameAuction);
      OpenModal('modalBids');
    });
  };

  useEffect(() => {
    if (props.visible === 'show') {
      const getData = () => {
        GET(URL).then((data) => {
          if (data === '401') {
            getData();
            return '';
          }
          if (data && data.length > 0) {
            setDataBids(data);
          }
        });
      };
      getData();
    }
  }, [props.visible, URL]);

  const formatDate = (date, time) => {
    if(date && time){
      let converDate = date.split(/\//);
      converDate = [ converDate[1], converDate[0], converDate[2] ].join('/')
      let dateTime = new Date(converDate + ' ' + time);
      dateTime = convertToDate(dateTime)
      return dateTime;
    }

    return '';
  }

  if (!clientDataBids || clientDataBids.length === 0) {
    return (
      <div className={'container__orders ' + props.visible}>
        <div className="border-title">
          <div>Mis pujas</div>
        </div>
        <div className="mb-3 row inputs-form">
          <div className="col-12 text-center">No existen registros</div>
        </div>
      </div>
    );
  } else {
    return (
      <div className={'container__orders ' + props.visible}>
        <div className="border-title">
          <div>Mis pujas</div>
        </div>
        {/* Datos */}
        {clientDataBids.map((item, x) => {
          return (
            <div className="mt-5 row" key={x}>
              <div className="col-12">
                <div className="card">
                  <div className="row">
                    <div className="col-12">
                      <div className="card-body">
                        <h5 className="card-title">{item.nameAuction}</h5>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-6 col-sm-4">
                      <label className="container__orders-title"> Nombre: </label>
                    </div>
                    <div className="col-6 col-sm-4">
                      <label className="container__orders-value">{item.nameAuction}</label>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-6 col-sm-4">
                      <label className="container__orders-title"> Descripción: </label>
                    </div>
                    <div className="col-6 col-sm-7">
                      <label className="container__orders-value label-value">{item.description}</label>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-6 col-sm-4">
                      <label className="container__orders-title"> Fecha: </label>
                    </div>
                    <div className="col-6 col-sm-4">
                      <label className="container__orders-value">{formatDate(item.fecha, item.hora)}</label>
                    </div>
                    <div className="col-6 col-sm-4 container-fluid text-center">
                      <button className="btn btn-outline-secondary" onClick={(e) => openDetailBids(item.idAuction, item.nameAuction)}>
                        Ver detalle
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}

        {/* MODAL */}
        <div className="modal fade modal-top" id="modalBids" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-title">
                <div className="row">
                  <div className="col-12 text-center mt-3 mb-3">
                    <b>Mis pujas - {auctionName}</b>
                  </div>
                </div>
                <h4 className="text-desc"> </h4>
              </div>
              <div className="modal-body modal-body__bids">
                {(!clientDataDetailsBids || clientDataDetailsBids.length === 0) && (
                  <div className="card mt-4">
                    <div className="card-body">
                      <h6 className="card-title text-center"> No hay registros </h6>
                    </div>
                  </div>
                )}

                {clientDataDetailsBids.map((item, x) => {
                  return (
                    <div className="card mt-4" key={x}>
                      <div className="card-body">
                        <h6 className="card-title">Lote: {item.numberLot}</h6>
                        <div className="row mb-3">
                          <div className="col-4">
                            <label className="container__orders-title"> Descripción: </label>
                          </div>
                          <div className="col-8">
                            <label className="container__orders-value">{item.description}</label>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-4">
                            <label className="container__orders-title"> Precio salida: </label>
                          </div>
                          <div className="col-8">
                            <label className="container__orders-value">{formatCurrency(item.price)}</label>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-4">
                            <label className="container__orders-title"> Oferta cliente: </label>
                          </div>
                          <div className="col-8">
                            <label className="container__orders-value">{formatCurrency(item.clientBid)}</label>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-4">
                            <label className="container__orders-title"> Fecha: </label>
                          </div>
                          <div className="col-8">
                            <label className="container__orders-value">{formatDate(item.fecha, item.hora)}</label>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-4">
                            <label className="container__orders-title"> Tipo oferta: </label>
                          </div>
                          <div className="col-8">
                            <label className="container__orders-value">{item.typeOffert}</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <h4 className="text-desc"> </h4>
              <div className="modal-footer">
                <button type="button" className="btn btn-outline-danger" onClick={() => CloseAllModal()}>
                  Cerrar
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* Datos */}
      </div>
    );
  }
};
