import React, { useEffect, useState } from 'react';
import { GET } from '../../helpers/APIServices';
import { ServiceGetAwardsHistory } from '../../helpers/getURL';
import { convertToDate, formatCurrency } from '../Utilities/General';
import { getImgDefault } from '../../helpers/getConstants';

export const AwardHistory = (props) => {
  const [awardHistory, setAwardHistory] = useState([]);

  useEffect(() => {
    if (props.visible === 'show') {
      const getData = () => {
        const idCustomer = localStorage.getItem('id');
        const URL = ServiceGetAwardsHistory(idCustomer);
        GET(URL).then((data) => {
          if (data === '401') {
            getData();
            return '';
          }
          if (data) {
            let arrayGrouped;
            let index = 0;

            const groupBy = (array, key = 'idSubasta', kay = 'nombreSubasta', koy = 'fechaSubasta') => {
              const grouped = {};

              array.forEach((el) => {
                grouped[el[key]] = {
                  idSubasta: el[key],
                  nombreSubasta: el[kay],
                  fechaSubasta: el[koy],
                  index,
                  awards: [],
                };
                index++;
              });

              array.forEach((el) => {
                if (grouped[el[key]]) {
                  grouped[el[key]].awards.push(el);
                }
              });
              arrayGrouped = Object.values(grouped);
            };
            groupBy(data);
            setAwardHistory(arrayGrouped);
          }
        });
      };
      getData();
    }
  }, [props.visible]);

  if (awardHistory.length === 0) {
    return (
      <div className={props.visible}>
        <div className="border-title">
          <div>Historial de adjudicaciones</div>
        </div>
        <div className="mb-3 row inputs-form">
          <div className="col-12 text-center">No existen registros</div>
        </div>
      </div>
    );
  } else {
    return (
      <div className={props.visible}>
        <div className="border-title">
          <div>Historial de adjudicaciones</div>
        </div>
        {awardHistory.map((item, x) => {
          return (
            <div key={x}>
              {' '}
              <div className="mb-3 row inputs-form ">
                <label className="col-12 col-form-label configuration-profile-labels">{item.awards.length} adjudicaciones</label>
              </div>
              <div className="mb-3 row inputs-form border-bottom">
                <div className="col-9">
                  <h5>
                    {item.nombreSubasta} {convertToDate(item.fechaSubasta)}
                  </h5>
                </div>
                <br />
                <br />
              </div>
              {item.awards.map((award, i) => {
                return (
                  <div className="mb-3 row inputs-form" key={i}>
                    <div className="col-12">
                      <div className="card mb-3">
                        <div className="row g-0 align-items-center">
                          <div className="col-4">
                            <img src={award.images[0] ? award.images[0] : getImgDefault()} className="img-fluid rounded-start" alt="..." />
                          </div>
                          <div className="col-8">
                            <div className="card-body">
                              <h5 className="card-title">Lote: {award.lot}</h5>
                              <p className="card-text">
                                {award.nombreLote} <br /> {award.descripcionLote}
                              </p>

                              <div className="row">
                                <div className="col-8">
                                  <small className="text-muted">Precio final {formatCurrency(award.precioSalida)}</small>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  }
};
