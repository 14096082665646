import React, { Fragment } from 'react';
import { pullCart } from '../Utilities/General';
import { formatCurrency } from '../Utilities/General';

export const CartGridItem = (props) => {
  const { item } = props;

  const dellCartItem = () => {
    props.delCartItem(item);
    pullCart(item);
  };

  return (
    <Fragment>
      <div className="card mb-3">
        <div className="row">
          <div className="col-5 col-full text-center-mobile">
            <span className="cart-img-center"></span>
            <img src={item.Image} alt="..." className="img-fluid cart-img" />
          </div>
          <div className="col-7 col-full">
            <div className="card-body card-left text-center-mobile">
              <h5 className="text-bottom">{item.Name}</h5>
              <p className="product-text">
                Lote <span className="article-reference">{item.Order}</span>
              </p>
              <p className="product-text">PRECIO DE SALIDA:</p>
              <p className="price-text article-reference">{formatCurrency(item.Price)}</p>
              <div className="card-footer text-end">
                <i className="far fa-trash-alt icon-danger" onClick={() => dellCartItem()}></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
