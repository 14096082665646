import React, { useContext, useEffect, useMemo, useState } from 'react'
import { POST, PUT } from '../../../helpers/APIServices'
import { ServicePaymentTokenMercadoPago } from '../../../helpers/getURL'
import {
  OpenMessage,
  getIP,
} from '../../Utilities/General';

import { getGuidEmpty } from '../../../helpers/getConstants';

import { CardPayment } from '@mercadopago/sdk-react/bricks';
import { TokenStatusContext } from '../../../context/TokenStatusContext'
import { useHistory } from 'react-router-dom'

export const CardFormToken = (props) => {
  const idAuction = props.idAuction ? props.idAuction : getGuidEmpty();
  const [IPAddress, setIPAddress] = useState('');
  const [browser, setBrowser] = useState('');

  const history = useHistory();

  const {
    tokenStatus,
    mercadoPagoInitialized,
    paymentFormError,
    renderMpPaymentForm,
    tokenAmount,
  } = useContext(TokenStatusContext)

  const { id = '' } = localStorage;

  useEffect(() => {
    getIP().then((resp) => {
      setIPAddress(resp);
    });
  }, []);

  useEffect(() => {
    let sBrowser = 'Unknown',
      sUsrAg = navigator.userAgent;
    if (sUsrAg.indexOf('Chrome') > -1) {
      sBrowser = 'Google Chrome';
    } else if (sUsrAg.indexOf('Safari') > -1) {
      sBrowser = 'Apple Safari';
    } else if (sUsrAg.indexOf('Opera') > -1) {
      sBrowser = 'Opera';
    } else if (sUsrAg.indexOf('Firefox') > -1) {
      sBrowser = 'Mozilla Firefox';
    } else if (sUsrAg.indexOf('MSIE') > -1) {
      sBrowser = 'Microsoft Internet Explorer';
    }
    setBrowser(sBrowser);
  }, []);

  const responsePayment = (data, success, message) => {
    const msg =
      'Ocurrio un error al registrar su tarjeta, por favor intente más tarde o con una tarjeta diferente.';
    if (success) {
      if (data.pendingChallenge) {
        history.push(data.redirectUrl, {
          pendingChallenge: data.pendingChallenge,
          creq: data.creq,
          externalResourceUrl: data.externalResourceUrl
        })
      }
      else {
        window.open(data.redirectUrl, '_self');
      }
      props.clearState();
    } else if (!success && message && message.includes('400')) {

      const regex = /Bad Request\.\s*(.*?)\s*JSON:/;
      const match = message.match(regex);
      if (match && match[1]) {
        const extractedText = match[1];
        OpenMessage('warning', msg, false, extractedText);
      } else {
        OpenMessage('warning', msg, true);
      }
    } else {
      OpenMessage('warning', message ? message : msg);
    }
    props.setDisabledActions(false);
  };
  
  const createPayment = () => {
    if (props.disabledActions) {
      return;
    }
    props.setDisabledActions(true);
    window
      .cardPaymentBrickController
      .getFormData()
      .then((cardFormData) => {
        if (cardFormData === null) {
          props.setDisabledActions(false);
          OpenMessage('warning', 'Favor de verificar sus datos.');
          return;
        }
        const json = {
          idCustomer: id,
          idAuction: idAuction,
          customer_username: 'na',
          first_name: 'na',
          last_name: 'na',
          email: 'na',
          phone: 'na',
          country: 'na',
          state: 'na',
          city: 'na',
          address: 'na',
          zip_code: 'na',
          name_on_card: 'na',
          card_number: 'na',
          expiration_year: 'na',
          expiration_month: 'na',
          cvv: 'na',
          browserUserAgent: browser,
          ipAddress: IPAddress,
          cardFormData: cardFormData,
        };
        const URLTokenPayment = ServicePaymentTokenMercadoPago();
        if (tokenStatus === 2) {
          PUT(URLTokenPayment, json).then(({ data, success, message }) => {
            responsePayment(data, success, message);
          });
        } else {
          POST(URLTokenPayment, json).then(({ data, success, message }) => {
            responsePayment(data, success, message);
          });
        }
      })
      .catch((error) => {console.error('Error getting card form data', error); props.setDisabledActions(false);});
  }

const renderPaymentForm = useMemo(function renderPaymentForm () {
  if (mercadoPagoInitialized && !paymentFormError && renderMpPaymentForm && tokenAmount > 0) {
    return (
      <CardPayment
        initialization={{ amount: tokenAmount }}
        customization={{ paymentMethods: { maxInstallments: 1 }, visual: { hidePaymentButton: true } }}
        onSubmit={event => {}}
        onReady={() => {console.log('MercadoPago Ready'); }}
        onError={(erro) => {console.log('BrickError', erro)}}>
      </CardPayment>
    )
  }
  return null;

}, [mercadoPagoInitialized, paymentFormError, renderMpPaymentForm, tokenAmount])

  return (
    <div className='container__cardForm'>
      <h5 className='validation-section'>{props.message}</h5>
      <h5 className='validation-section'>{props.warrantyMessage}</h5>
      <div>
        {renderPaymentForm}
        <div className='modal-footer'>
          <button
            id="btnSaveFormToken"
            type="button"
            className="btn btn-outline-secondary button-validation"
            onClick={(e) => createPayment()}
            disabled={props.disabledActions}>
            Validar
          </button>

          <button
            id="btnCancelFormToken"
            type="button"
            className="btn btn-outline-danger button-validation"
            data-mdb-dismiss="modalRegisterToOffer"
            onClick={(e) => {
              props.clearState()
            }}
            disabled={props.disabledActions}>
            Cancelar
          </button>
        </div>
      </div>
    </div>
  );
};
