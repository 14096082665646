import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';

import { CartGrid } from './CartGrid';

import '../../css/Menu/Cart.css';
import { formatCurrency, validDataComplementary } from '../Utilities/General';

export const Cart = (props) => {
  let history = useHistory();
  let cart = props.ShoppingCart;
  let idAuction = props.idAuction;

  let total = 0;

  const calculaTotal = () => {
    cart.forEach((item) => {
      total = total + item.Price;
    });
    return total;
  };

  const handdleDeleteCartItem = (data) => {
    props.delCartItem(data);
  };

  const shoppingCart = () => {
    const valid = validDataComplementary(props.clientData);
    props.visible('hide');
    if (valid > 0) {
      document.getElementById('btnCloseCanvas').click();
      const collapseOne = document.getElementById('collapseOne');
      const collapseTwo = document.getElementById('collapseTwo');
      const headingOne = document.querySelector('#headingOne > button');
      const headingTwo = document.querySelector('#headingTwo > button');

      setTimeout(() => {
        collapseOne.classList.remove('show');
        headingOne.classList.add('collapsed');
        collapseTwo.classList.remove('show');
        headingTwo.classList.add('collapsed');
      }, 500);

      props.visible('show');
      let btnDataComplementary = document.getElementById('btnDataComplementary');
      btnDataComplementary.click();
    } else {
      let params = [];
      //Los parametros van de acuerdo a los datos que regresa el servicio: /Auctions/Awards/:idAuction
      let data = {
        descripcionLote: '',
        //fechaSubasta: '',
        lot: 0,
        idLote: '',
        idSubasta: idAuction,
        images: [],
        nombreLote: '',
        //nombreSubasta: '',
        precioSalida: 0,
        consigna: true,
        consignaSAP: true,
        origin: 'directSale', //campo para diferenciar el origen, si es por catalogo o por adjudicaciones activas
        //statusLote: ''
      };
      for (let i = 0; i < cart.length; i++) {
        data.descripcionLote = cart[i].Description;
        data.idLote = cart[i].IdLot;
        data.images.push(cart[i].Image);
        data.nombreLote = cart[i].Name;
        data.precioSalida = cart[i].Price;
        data.lot = cart[i].Order;
        data.consigna = cart[i].consigna;
        data.consignaSAP = cart[i].consignaSAP;
        params.push(data);
        data = {
          descripcionLote: '',
          lot: 0,
          idLote: '',
          idSubasta: idAuction,
          images: [],
          nombreLote: '',
          precioSalida: 0,
          consigna: true,
          consignaSAP: true,
          origin: 'directSale',
        };
      }

      history.push({
        pathname: '/carrito-compras',
        search: `?modulo=catalogo&id=${idAuction}`,
        state: { detail: params, enableInvoice: props.enableInvoice },
      });
    }
  };

  return (
    <Fragment>
      <div className="container container__cart">
        <div className="row">
          <div className="col-12 text-end">
            <button
              id="btnCloseCanvas"
              type="button"
              className="btn-close text-reset cart-close"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <h2 className="title-sections title-cart text-center">
              &nbsp;
              <i className="fas fa-cart-arrow-down cart-icon"></i> Agregado al carrito
            </h2>
          </div>
        </div>

        <hr />
        <div>
          <div className="container-fluid overflow-auto cart-container">
            <CartGrid items={cart} delCartItem={handdleDeleteCartItem} />
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-6">
            <p className="title-sections cart-text text-end"> CANTIDAD </p>
          </div>
          <div className="col-6">
            <p className="title-sections cart-text text-start">
              <span className="text-destkop">{cart.length} ARTÍCULOS</span> <span className="text-mobile">{cart.length} ART.</span>
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-6">
            <p className="title-sections cart-text title-subtotal text-end"> SUBTOTAL </p>
          </div>
          <div className="col-6">
            <p className="price-text title-sections cart-text text-start"> {formatCurrency(calculaTotal())} </p>
          </div>
        </div>
        <br />
        <div className="row text-center mb-5">
          <div className="col-12">
            <button
              type="button"
              disabled={cart.length <= 0 ? true : false}
              className="btn btn-outline-danger"
              onClick={(e) => shoppingCart()}
            >
              <i className="fas fa-dollar-sign"></i>
              &nbsp; Proceder al pago
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
