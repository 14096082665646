let URLServices = null;

const getUrls = () => {
  // Se asigna al sessionStorage desde App.js
  URLServices = sessionStorage.getItem('URLServices');
  if (URLServices) {
    return JSON.parse(URLServices);
  }
  return null;
};

export const ServiceLogin = () => {
  const URLs = getUrls();
  const PATH = '/Clients/login';
  if (URLs && URLs.clients) {
    let url = URLs.clients + PATH;
    return url;
  }
  return '';
};

export const ServiceClients = () => {
  const URLs = getUrls();
  const PATH = '/Clients/';
  if (URLs && URLs.clients) {
    return URLs.clients + PATH;
  }
  return '';
};

export const ServiceChangePassword = () => {
  const URLs = getUrls();
  const PATH = '/Clients/ChangePassword';
  if (URLs && URLs.clients) {
    return URLs.clients + PATH;
  }
  return '';
};

export const ServiceContact = () => {
  const URLs = getUrls();
  const PATH = '/Contact';
  if (URLs && URLs.customers) {
    let url = URLs.customers + PATH;
    return url;
  }
  return '';
};

export const ServiceCarousel = () => {
  const URLs = getUrls();
  const PATH = '/Auction/WebCatalog';
  if (URLs && URLs.catalog) {
    return URLs.catalog + PATH;
  }
  return '';
};
export const ServiceAuctionCustomerStatus = (id) => {
  const URLs = getUrls();
  const PATH = `/Auction/AuctionCustomerStatus/${id}`;
  if (URLs && URLs.catalog) {
    return URLs.catalog + PATH;
  }
  return '';
};

export const ServiceAnnouncement = () => {
  const URLs = getUrls();
  const PATH = '/Announcement';
  if (URLs && URLs.announcement) {
    return URLs.announcement + PATH;
  }
  return '';
};

export const ServiceRequestPassword = () => {
  const URLs = getUrls();
  const PATH = '/Clients/RequestPasswordReset';
  if (URLs && URLs.clients) {
    return URLs.clients + PATH;
  }
  return '';
};

export const ServiceValidateTokenPasswordReset = () => {
  const URLs = getUrls();
  const PATH = '/Clients/ValidateTokenPasswordReset';
  if (URLs && URLs.clients) {
    return URLs.clients + PATH;
  }
  return '';
};

export const ServiceResetPasswordReset = () => {
  const URLs = getUrls();
  const PATH = '/Clients/ResetPassword';
  if (URLs && URLs.clients) {
    return URLs.clients + PATH;
  }
  return '';
};

export const ServiceConfirmEmailNewUser = () => {
  const URLs = getUrls();
  const PATH = '/Clients/ConfirmEmailNewUser';
  if (URLs && URLs.clients) {
    return URLs.clients + PATH;
  }
  return '';
};

export const ServiceGetCustomerInfo = () => {
  const id = localStorage.getItem('id');
  const URLs = getUrls();
  const PATH = '/Clients/' + id;
  if (URLs && URLs.clients) {
    return URLs.clients + PATH;
  }
  return '';
};

export const ServiceCatalog = () => {
  const URLs = getUrls();
  const PATH = '/Lot/GetbyFilters';
  if (URLs && URLs.catalog) {
    return URLs.catalog + PATH;
  }
  return '';
};

export const ServiceLastCatalog = () => {
  const URLs = getUrls();
  const PATH = '/Auction/LastCatalog';
  if (URLs && URLs.catalog) {
    return URLs.catalog + PATH;
  }
  return '';
};

export const ServiceGetCustomerAuctions = () => {
  const id = localStorage.getItem('id');
  const URLs = getUrls();
  const PATH = '/Customer/Auctions/' + id;
  if (URLs && URLs.catalog) {
    return URLs.catalog + PATH;
  }
  return '';
};

export const ServiceAuctionById = (id) => {
  const URLs = getUrls();
  const PATH = `/Auction/${id}`;
  if (URLs && URLs.catalog) {
    return URLs.catalog + PATH;
  }
  return '';
};

export const ServiceFavoritesGetById = (idAuction) => {
  const URLs = getUrls();
  const PATH = `Favorites/Auction/${idAuction}`;
  if (URLs && URLs.auctionsClients) {
    return URLs.auctionsClients + PATH;
  }
  return '';
};

export const ServiceFavoritesDeletetById = (id) => {
  const URLs = getUrls();
  const PATH = `Favorites/Customer/${id}`;
  if (URLs && URLs.auctionsClients) {
    return URLs.auctionsClients + PATH;
  }
  return '';
};

export const ServiceFavoritesPost = () => {
  const URLs = getUrls();
  const PATH = `Favorites`;
  if (URLs && URLs.auctionsClients) {
    return URLs.auctionsClients + PATH;
  }
  return '';
};

export const ServiceValidationRegisterToken = (token, idAuction) => {
  const URLs = getUrls();
  const PATH = `/ActnetCustomerToken/${token}/${idAuction}`;
  if (URLs && URLs.clients) {
    return URLs.clients + PATH;
  }
  return '';
};

export const ServicePaymentToken = () => {
  const URLs = getUrls();
  const PATH = '/ActnetPayment';
  if (URLs && URLs.customers) {
    return URLs.customers + PATH;
  }
  return '';
};

export const ServicePaymentTokenMercadoPago = () => {
  const URLs = getUrls();
  const PATH = '/MePagoPayment';
  if (URLs && URLs.customers) {
    return URLs.customers + PATH;
  }
  return '';
};

export const ServiceGetAddressShippingByIdCustomer = (idCustomer) => {
  const URLs = getUrls();
  const PATH = `/CustomerInfoAddress/Customer/${idCustomer}`;
  if (URLs && URLs.customers) {
    return URLs.customers + PATH;
  }
  return '';
};

export const ServiceGetGeneralParameterById = (id) => {
  const URLs = getUrls();
  const PATH = `/GeneralParameters/${id}`;
  if (URLs && URLs.customers) {
    return URLs.customers + PATH;
  }
  return '';
};

export const ServiceGetAddressBillingByIdCustomer = (idCustomer) => {
  const URLs = getUrls();
  const PATH = `/CustomerInfoFactura/Customer/${idCustomer}`;
  if (URLs && URLs.customers) {
    return URLs.customers + PATH;
  }
  return '';
};

export const ServiceCustomerInfoAddressShipping = () => {
  const URLs = getUrls();
  const PATH = `/CustomerInfoAddress/`;
  if (URLs && URLs.customers) {
    return URLs.customers + PATH;
  }
  return '';
};

export const ServiceCustomerInfoAddressBilling = () => {
  const URLs = getUrls();
  const PATH = `/CustomerInfoFactura/`;
  if (URLs && URLs.customers) {
    return URLs.customers + PATH;
  }
  return '';
};

export const ServiceCarouselStreaming = () => {
  const URLs = getUrls();
  const PATH = '/Lot/GetCarouselbySp';
  if (URLs && URLs.streaming) {
    return URLs.streaming + PATH;
  }
  return '';
};

export const ServiceBidsStreaming = () => {
  const URLs = getUrls();
  const PATH = '/Lot/Bids';
  if (URLs && URLs.streaming) {
    return URLs.streaming + PATH;
  }
  return '';
};

export const ServiceGetAwardsActive = (idCustomer) => {
  const URLs = getUrls();
  const PATH = '/Auctions/Awards/' + idCustomer;
  if (URLs && URLs.auctionsClients) {
    return URLs.auctionsClients + PATH;
  }
  return '';
};

export const ServiceGetAwardsHistory = (idCustomer) => {
  const URLs = getUrls();
  const PATH = '/Auctions/Awards/History/' + idCustomer;
  if (URLs && URLs.auctionsClients) {
    return URLs.auctionsClients + PATH;
  }
  return '';
};

export const ServiceAwardsStreaming = () => {
  const URLs = getUrls();
  const PATH = '/Lot/Awards';
  if (URLs && URLs.streaming) {
    return URLs.streaming + PATH;
  }
  return '';
};

export const ServiceInfoAddressShippingById = (id) => {
  const URLs = getUrls();
  const PATH = `/CustomerInfoAddress/${id}`;
  if (URLs && URLs.customers) {
    return URLs.customers + PATH;
  }
  return '';
};

export const ServiceInfoAddressShippingByCustomer = (idAddress) => {
  const idCustomer = localStorage.getItem('id');
  const URLs = getUrls();
  const PATH = `/CustomerInfoAddress/Customer/${idCustomer}/${idAddress}`;
  if (URLs && URLs.customers) {
    return URLs.customers + PATH;
  }
  return '';
};

export const ServiceInfoAddressBillingById = (id) => {
  const URLs = getUrls();
  const PATH = `/CustomerInfoFactura/${id}`;
  if (URLs && URLs.customers) {
    return URLs.customers + PATH;
  }
  return '';
};

export const ServiceInfoAddressBillingByCustomer = (idAddress) => {
  const idCustomer = localStorage.getItem('id');
  const URLs = getUrls();
  const PATH = `/CustomerInfoFactura/Customer/${idCustomer}/${idAddress}`;
  if (URLs && URLs.customers) {
    return URLs.customers + PATH;
  }
  return '';
};

export const ServiceGetByZipCode = (zipCode) => {
  const URLs = getUrls();
  const PATH = `/ZipCode/GetByZipCode/${zipCode}`;
  if (URLs && URLs.announcement) {
    return URLs.announcement + PATH;
  }
  return '';
};

export const ServiceGetCountries = () => {
  const URLs = getUrls();
  const PATH = '/Country/';
  if (URLs && URLs.announcement) {
    return URLs.announcement + PATH;
  }
  return '';
};

export const ServiceGetCountriesISO = () => {
  const URLs = getUrls();
  const PATH = '/Country/GetByIso';
  if (URLs && URLs.announcement) {
    return URLs.announcement + PATH;
  }
  return '';
};

export const ServiceGetState = (idCountry) => {
  const URLs = getUrls();
  const PATH = `/State/Country/${idCountry}`;
  if (URLs && URLs.announcement) {
    return URLs.announcement + PATH;
  }
  return '';
};

export const ServiceGetTown = (id) => {
  const URLs = getUrls();
  const PATH = `/Town/State/${id}`;
  if (URLs && URLs.announcement) {
    return URLs.announcement + PATH;
  }
  return '';
};

export const ServiceGetCity = (idState, idTown) => {
  const URLs = getUrls();
  const PATH = `/City/Town?idState=${idState}&idTown=${idTown}`;
  if (URLs && URLs.announcement) {
    return URLs.announcement + PATH;
  }
  return '';
};

export const ServiceConnectionSignalr = () => {
  const URLs = getUrls();
  const PATH = '/hubs/bidder';
  if (URLs && URLs.bid) {
    return URLs.bid + PATH;
  }
  return '';
};

export const ServiceMakeBids = () => {
  const URLs = getUrls();
  const PATH = '/bid/makebid';
  if (URLs && URLs.bid) {
    return URLs.bid + PATH;
  }
  return '';
};
export const ServiceGetCurrentBid = () => {
  const URLs = getUrls();
  const PATH = '/Bid/GetCurrentBid';
  if (URLs && URLs.bid) {
    return URLs.bid + PATH;
  }
  return '';
};
export const ServiceGetNextLotOffer = (idAuction, idLot, mont, elements) => {
  const URLs = getUrls();
  const PATH = `/LotOffert/GetNextLotOffert/${idAuction}/${idLot}/${mont}/${elements}`;
  if (URLs && URLs.catalog) {
    return URLs.catalog + PATH;
  }
  return '';
};

export const ServicePostMaxOfert = () => {
  const URLs = getUrls();
  const PATH = `/MaxOfert`;
  if (URLs && URLs.catalog) {
    return URLs.catalog + PATH;
  }
  return '';
};

export const ServiceQuotationMail = () => {
  const URLs = getUrls();
  const PATH = 'Quotation/Quotation';
  if (URLs && URLs.notification) {
    return URLs.notification + PATH;
  }
  return '';
};

export const ServiceGenericParameters = (idParameter) => {
  const URLs = getUrls();
  const PATH = `/GenericParameter/${idParameter}`;
  if (URLs && URLs.announcement) {
    return URLs.announcement + PATH;
  }
  return '';
};

export const ServiceRefreshToken = (refreshToken) => {
  const URLs = getUrls();
  const PATH = '/Clients/RefreshToken/' + refreshToken;
  if (URLs && URLs.clients) {
    return URLs.clients + PATH;
  }
  return '';
};

export const ServiceCheckOnToJoinStreming = () => {
  const URLs = getUrls();
  const PATH = '/AuctionClient/CheckOnToJoinStreming/';
  if (URLs && URLs.catalog) {
    return URLs.catalog + PATH;
  }
  return '';
};

export const ServiceGetOrdersByUser = () => {
  const id = localStorage.getItem('id');
  const URLs = getUrls();
  const PATH = '/Checkout/GetOrdersByUser?idUser=' + id;
  if (URLs && URLs.checkout) {
    return URLs.checkout + PATH;
  }
  return '';
};

export const ServiceAddOrder = () => {
  const URLs = getUrls();
  const PATH = '/Checkout/AddOrder';
  if (URLs && URLs.checkout) {
    return URLs.checkout + PATH;
  }
  return '';
};

export const ServiceCompleteOrder = () => {
  const URLs = getUrls();
  const PATH = '/Checkout/CompleteOrder';
  if (URLs && URLs.checkout) {
    return URLs.checkout + PATH;
  }
  return '';
};

export const ServiceClientData = () => {
  const idCustomer = localStorage.getItem('id');
  const URLs = getUrls();
  const PATH = '/Clients/DataClient/' + idCustomer;
  if (URLs && URLs.clients) {
    return URLs.clients + PATH;
  }
  return '';
};

export const ServiceUpdateClientData = () => {
  const URLs = getUrls();
  const PATH = '/Clients/DataComplementaryClient';
  if (URLs && URLs.clients) {
    return URLs.clients + PATH;
  }
  return '';
};

export const ServiceUpdateClientGeneralData = () => {
  const URLs = getUrls();
  const PATH = '/Clients/DataGeneralClient';
  if (URLs && URLs.clients) {
    return URLs.clients + PATH;
  }
  return '';
};

export const ServiceStatusClient = () => {
  const URLs = getUrls();
  const PATH = '/ClientStatus/GetAllClientStatus';
  if (URLs && URLs.announcement) {
    return URLs.announcement + PATH;
  }
  return '';
};

export const ServiceNationality = () => {
  const URLs = getUrls();
  const PATH = '/Nationality';
  if (URLs && URLs.announcement) {
    return URLs.announcement + PATH;
  }
  return '';
};

export const ServiceEconomicActivity = () => {
  const URLs = getUrls();
  const PATH = '/EconomicActivity';
  if (URLs && URLs.announcement) {
    return URLs.announcement + PATH;
  }
  return '';
};

export const ServiceGetOrderById = (idOrder) => {
  const URLs = getUrls();
  const PATH = '/Checkout/GetOrderById?orderId=' + idOrder;
  if (URLs && URLs.checkout) {
    return URLs.checkout + PATH;
  }
  return '';
};

export const ServiceAddDirectSale = () => {
  const URLs = getUrls();
  const PATH = '/Checkout/AddDirectSale';
  if (URLs && URLs.checkout) {
    return URLs.checkout + PATH;
  }
  return '';
};

export const ServicePaymentRetry = () => {
  const URLs = getUrls();
  const PATH = '/Checkout/PaymentRetry';
  if (URLs && URLs.checkout) {
    return URLs.checkout + PATH;
  }
  return '';
};

export const ServiceActnetPaymentValidate = () => {
  const URLs = getUrls();
  const PATH = '/ActnetPayment/validate';
  if (URLs && URLs.customers) {
    return URLs.customers + PATH;
  }
  return '';
};

export const ServiceMePagoPaymentValidate = () => {
  const URLs = getUrls();
  const PATH = '/MePagoPayment/validate';
  if (URLs && URLs.customers) {
    return URLs.customers + PATH;
  }
  return '';
};

export const ServiceGetListBids = () => {
  const URLs = getUrls();
  const IdClient = localStorage.getItem('id');
  const PATH = '/MaxOfert/GetListBids/'+IdClient;
  if (URLs && URLs.catalog) {
    return URLs.catalog + PATH;
  }
  return '';
};

export const ServiceGetListBidsDetails = (IdAuction) => {
  const URLs = getUrls();
  const IdClient = localStorage.getItem('id');
  const PATH = '/MaxOfert/GetListBidsDetails/' + IdClient + '/' + IdAuction;
  if (URLs && URLs.catalog) {
    return URLs.catalog + PATH;
  }
  return '';
}

export const ServiceLotCatalogByAuctionFilter = () => {
  const URLs = getUrls();
  const PATH = '/Lot/LotCatalogByAuctionFilter';
  if (URLs && URLs.catalog) {
    return URLs.catalog + PATH;
  }
  return '';
};
