import React from 'react';
import { useHistory } from 'react-router-dom';
import { CloseModal } from '../Utilities/General';

export const WaitNextAuctions = () => {
  const history = useHistory();
  return (
    <div
      className="modal fade "
      id="modalWaitNextAuction"
      aria-labelledby="waitNextAuctionModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body text-center">
            <div className="text-center closing-accept mt-5">
              <img src="./resources/logo.svg" alt="logo MLS" className="img-fluid" width="50%" />
            </div>
            <div className="text-center closing-accept">
              <h2 className="text-uppercase" id="textMessage">Espera nuestra próxima subasta.</h2>
            </div>
            <button
              type="button"
              className="btn btn-outline-secondary btn-lg success-offer-items closin-accept"
              data-bs-dismiss="modalOfferSuccess"
              aria-label="Close"
              onClick={(e) => [history.push('/?home=true'), CloseModal('modalWaitNextAuction')]}
            >
              Aceptar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
