import './../../../css/Register/ChallengePayToken.css';

import {useLocation, useHistory} from "react-router-dom";
import {useEffect, useState} from "react";

export default function ChallengePayToken() {

  const location = useLocation();
  const history = useHistory();
  const {state} = location;

  const [id, setId] = useState(0);
  const [status, setStatus] = useState("");
  const [statusDetail, setStatusDetail] = useState("");
  const [creq, setCreq] = useState(null);
  const [externalResourceUrl, setExternalResourceUrl] = useState(null);
  const [redirectUrl, setRedirectUrl] = useState("");
  const [complete, setComplete] = useState(false);


  useEffect(() => {
    if(complete){
      setTimeout(() => {
        history.push(redirectUrl);
      }, 3000);
    }
  }, [complete]);

  useEffect(() => {
    const receiveMessage = (e) => {
      if (e.data.status === "COMPLETE") {
        console.log("COMPLETE", e);
        if (complete === false){
          setComplete(true);
        }
      }
    }

    window.addEventListener("message", receiveMessage);
    return () => {
      window.removeEventListener('message', receiveMessage);
    };
  }, []);

  useEffect(() => {
    if(id !== 0 && status !== "" && statusDetail !== "" && creq !== null && externalResourceUrl !== null && redirectUrl !== ""){
      doChallenge({status, statusDetail, creq, externalResourceUrl})
    }
  }, [creq, externalResourceUrl, id, redirectUrl, status, statusDetail]);

  useEffect(() => {
    if (state !== undefined) {
      const {
        creq: localCreq = null,
        externalResourceUrl: localExternalResourceUrl = null,
        id: localId = null,
        status: localStatus = null,
        statusDetail: localStatusDetail = null,
        redirectUrl: localRedirectUrl = ""
      } = state;
      if (localCreq !== null && localExternalResourceUrl !== null && localId !== null && localStatus !== null && localStatusDetail !== null && localRedirectUrl !== "") {
        if (id === 0) {
          setId(localId);
        }
        if (status === "") {
          setStatus(localStatus);
        }
        if (statusDetail === "") {
          setStatusDetail(localStatusDetail);
        }
        if (creq === null) {
          setCreq(localCreq);
        }
        if (externalResourceUrl === null) {
          setExternalResourceUrl(localExternalResourceUrl);
        }
        if (redirectUrl === "") {
          setRedirectUrl(localRedirectUrl);
        }
      }
    }
  }, [creq, externalResourceUrl, id, state, status, statusDetail]);

  function doChallenge({status, statusDetail, creq, externalResourceUrl}) {
    try {
      if (status === "pending" && statusDetail === "pending_challenge") {

        const iframeName = "myframe";
        const iframeRootName = "iframeValidation";
        const formName = "myform";

        const root = document.getElementById(iframeRootName);
        const existingIframe = root.querySelector(`#${iframeName}`);
        if (existingIframe) {
          root.removeChild(existingIframe);
        }
        var iframe = document.createElement("iframe");
        iframe.name = iframeName;
        iframe.id = iframeName;

        root.appendChild(iframe);

        var idocument = iframe.contentWindow.document;

        var myform = idocument.createElement("form");
        myform.name = formName;
        myform.setAttribute("target", iframeName);
        myform.setAttribute("method", "post");
        myform.setAttribute("action", externalResourceUrl);

        var hiddenField = idocument.createElement("input");
        hiddenField.setAttribute("type", "hidden");
        hiddenField.setAttribute("name", "creq");
        hiddenField.setAttribute("value", creq);
        myform.appendChild(hiddenField);
        iframe.appendChild(myform);

        myform.submit();
      }
    } catch (error) {
      console.log(error);
      alert("Error doing challenge, try again later.");
    }
  }

  return (
    <div className='row container-fluid container__expire justify-content-center'>
      <div id="iframeValidation" className='col-8 col-sm-6 col-lg-5 col-xxl-4 text-center'></div>
    </div>
  );
}
