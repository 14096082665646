import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { DataContext } from '../../context/DataContext';
import '../../css/Mesage.css';
import { CloseModalIcon } from '../Home/CloseModalIcon';
import { ClosingModal } from '../streaming/MiniPlayer/ClosingModal';
import { CloseModal } from '../Utilities/General';

export const Message = () => {
  const {
    validate: { registeredAuctions },
    setValidate,
  } = useContext(DataContext);

  const onClear = () => {
    setValidate({
      valid: false,
      registeredAuctions: [...registeredAuctions],
      idAuction: '',
      nameAuction: '',
      dateAuction: '',
      palletAuction: '',
      idPallet: '',
      onPaymentValidate: false,
    });
  };
  return (
    <div
      className='modal fade container__message__parent'
      id='modalMessage'
      aria-hidden='true'
    >
      <div className='modal-dialog modal-dialog-centered modal-sm'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h4 id='headerTitle'> </h4>
            <CloseModalIcon onHandleClick={() => CloseModal('modalMessage')} />
          </div>
          <div className='modal-body group-items'>
            <div className='col-2 col-md-3'>
              <span>
                <i id='messageType' className='icon-element'></i>
              </span>
            </div>
            <div className='col-10 col-md-9 text-items'>
              <p id='messageText'></p>
              <p id='messageSubText' style={{fontSize:'1rem'}}></p>
              <Link
                to={'/contacto'}
                id='messageContact'
                className={`button-contact-error`}
                onClick={(e) => {
                  onClear();
                  CloseModal('modalRegisterToOffer');
                  CloseModal('modalMessage');
                }}
              ></Link>
            </div>
          </div>
          <div className='modal-footer footer-buttons'>
            <button
              id='buttonClose'
              type='button'
              className='btn btn-lg'
              data-bs-dismiss='modal'
            >
              Cerrar
            </button>

            <button
              id='messageText2'
              type='button'
              className='btn btn-lg btn-outline-success'
            ></button>
          </div>
        </div>
      </div>
    </div>
  );
};
