import React from 'react';

const Loading = () => {
  return (
    <div id="loading" className="row container-loading hide">
      <div className="col-12 text-center">
        <img src="./resources/loading.gif" alt="loading..." width="15%" />
      </div>
    </div>
  );
};

export default Loading;
