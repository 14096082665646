import React, { useEffect, useState } from 'react';
import { ConfigurationAccount } from '../ConfigurationAccount';
import { CustomerComplementaryData } from './CustomerComplementaryData';

export const CustomerData = (props) => {
  const [load, setLoad] = useState(false);
  const cerrarModal = () => {
    props.close();
  };
  useEffect(() => {
    const collapseOne = document.getElementById('collapseOne');
    const collapseTwo = document.getElementById('collapseTwo');
    const headingOne = document.querySelector('#headingOne > button');
    const headingTwo = document.querySelector('#headingTwo > button');

    collapseOne.classList.add('show');
    headingOne.classList.remove('collapsed');
    collapseTwo.classList.remove('show');
    headingTwo.classList.add('collapsed');
  }, [props]);

  return (
    <div className={props.visible}>
      <div className="accordion" id={props.parent}>
        <div className="accordion-item">
          <h1 className="accordion-header" id="headingOne">
            <button
              className="accordion-button profile-title"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
              onClick={(e) => setLoad(false)}
            >
              Datos Generales
            </button>
          </h1>
          <div
            id="collapseOne"
            className="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent={'#' + props.parent}
          >
            <ConfigurationAccount parent={props.parent} close={cerrarModal} visible={props.visible} />
          </div>
        </div>
        <div className="accordion-item">
          <h1 className="accordion-header" id="headingTwo">
            <button
              className="accordion-button profile-title"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
              onClick={(e) => setLoad(!load)}
            >
              Datos Complementarios
            </button>
          </h1>
          <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent={'#' + props.parent}>
            <div className="accordion-body">
              <CustomerComplementaryData parent={props.parent} close={cerrarModal} visible={props.visible} load={load} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
