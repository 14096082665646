import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom'

import { GET } from '../../../helpers/APIServices';
import { ServiceCompleteOrder } from '../../../helpers/getURL';

export const ReceiveActnetPay = () => {
  const { search } = useLocation();
  const { href = '' } = window.location;
  const [message, setMessage] = useState('Validando pago...');
  const [error, setError] = useState(false);
  const [externalTransactionId, setExternalTransactionId] = useState(null);
  const [transactionId, setTransactionId] = useState(null);

  const history = useHistory();

  useEffect(() => {

    let external_transaction_id = new URL(href).searchParams.get('external_transaction_id');
    if (external_transaction_id === null) {
      external_transaction_id = new URL(href).searchParams.get('external_reference');
    }

    let transaction_id = new URL(href).searchParams.get('transaction_id');
    if (transaction_id === null) {
      transaction_id = new URL(href).searchParams.get('payment_id');
    }
    setExternalTransactionId(external_transaction_id);
    setTransactionId(transaction_id);

    let urlCompleteOrder = ServiceCompleteOrder();

    urlCompleteOrder = `${urlCompleteOrder}?externalId=${external_transaction_id}&checkout=${external_transaction_id}&authorization=${transaction_id}&maskedPAN=0000`;

    const getData = () => {
      if (!transaction_id || transaction_id === "null") {
        history.push('/perfil?opcion=5')
        return;
      }
      GET(urlCompleteOrder).then((data) => {
        if (data === '401') {
          getData();
          return '';
        }

        if (data.successOrder) {
          window.open('/detalle-orden?id=' + external_transaction_id, '_self');
        } else {
          setError(true);
          if (data.showOrderMessage === true){
            setMessage(data.orderMessage);
          }
          else {
            setMessage(
              'Ha ocurrido un error al procesar la solicitud. Por favor intente nuevamente.'
            );
          }

        }
      });
    };
    getData();
  }, []);

  return (
    <div className='row container-fluid container__expire justify-content-center'>
      {!error && (
        <div id='loading' className={'row container-back container-loading show' }>
          <div className='col-12 text-center'>
            <img src='./resources/loading.gif' alt='loading...' width='15%' />
          </div>
        </div>
      )}
      <div className='col-8 col-sm-6 col-lg-5 col-xxl-4 text-center'>
        <label>{message}</label>
      </div>
      {error && (
        <div className='col-12 text-center'>
          <button
            className='btn btn-outline-danger btn-lg'
            id='errorGoOrders'
            onClick={() => window.open('/perfil?opcion=5', '_self')}
          >
            Ir a mis órdenes
          </button>
        </div>
      )}
    </div>
  );
};
