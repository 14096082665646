import React from 'react';
import { ButtonRegister } from '../Register/ButtonRegister';
import { ButtonToStreaming } from '../Register/ButtonToStreaming';
import { WatchCatalog } from '../Register/WatchCatalog';
import { getIdStreaming, getExecution } from '../../helpers/getConstants';

//const widthA = '1280px';
const heightA = '700px';
const idStreaming = getIdStreaming();
const execution = getExecution();

function CarouselItem(props) {
  const { statusCustomer = '' } = props;
  if (props.announcement) {
    //con publicidad
    if (props.link && props.nuevaPestania) {
      //con link de publicidad
      return (
        <div className={'carousel-item ' + (props.active === 'true' ? 'active' : '')}>
          <div className="row justify-content-center">
            <div className="col-12 text-center">
              <a href={props.link} target="_blank" rel="noreferrer">
                <img className="announcement" src={props.img} alt="..." height={heightA} />
              </a>
            </div>
          </div>
        </div>
      );
    }else if(props.link && !props.nuevaPestania){
      return (
        <div className={'carousel-item ' + (props.active === 'true' ? 'active' : '')}>
            <div className="row justify-content-center">
              <div className="col-12 text-center">
                <a href={props.link}>
                  <img className="announcement" src={props.img} alt="..." height={heightA} />
                </a>
              </div>
            </div>
          </div>
      );
    }
    return (
      <div className={'carousel-item ' + (props.active === 'true' ? 'active' : '')}>
        <div className="row justify-content-center">
          <div className="col-12 text-center">
            <img className="announcement" src={props.img} alt="..." height={heightA} />
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className={'carousel-item ' + (props.active === 'true' ? 'active' : '')}>
      <div className="row justify-content-center">
        <div className="col-6">
          <div className="row panel__left justify-content-center">
            <div className="col-10 offset-md-2">
              <h1>SUBASTA</h1>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-10 offset-md-2">
              <h2>{props.title}</h2>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-10 offset-md-2">
              <h3>{props.date}</h3>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-12 col-sm-10 offset-1 offset-md-2">
              <p className="carousel-item__place">{props.place}</p>
            </div>
          </div>
          <div className="row mt-2 mb-3 mb-mobile">
            <div className="col-12 col-sm-10 offset-1 offset-md-2">
              <WatchCatalog idAuction={props.idAuction} modal={false} clasStyle={'btn-carousel-secondary'} />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-10 offset-1 offset-md-2">
              {props.auctionRegistered.pallet > 0 && props.auctionType === idStreaming && props.auctionStatus === execution && (
                <ButtonToStreaming
                  idAuction={props.idAuction}
                  userStatusLastCatalog={statusCustomer}
                  classStyle={''}
                />
              )}
              {props.auctionRegistered.pallet <= 0 && (
                <ButtonRegister idAuction={props.idAuction} nameAuction={props.title} dateAuction={props.date} classStyle={''} />
              )}
            </div>
          </div>
          <div className="row mt-3 mt-mobile">
            <div className="col-12 offset-1 offset-md-2">
              <p className="carousel-item__description">{props.description1}</p>
            </div>
            <div className="col-12 offset-1 offset-md-2">
              <p className="carousel-item__description">{props.description2}</p>
            </div>
            <div className="col-12 offset-1 offset-md-2">
              <p className="carousel-item__description">{props.description3}</p>
            </div>
          </div>
        </div>
        <div className="col-6 text-center">
          <img src={props.img} alt="..." />
        </div>
      </div>
    </div>
  );
}

export default CarouselItem;
