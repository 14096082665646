import { createContext, useState } from 'react'

export const CaptchaContext = createContext(null)

export const CaptchaProvider = ({ children }) => {
  const [captchaSiteKey, setCaptchaSiteKey] = useState('')
  return (
    <CaptchaContext.Provider value={{
      captchaSiteKey,
      setCaptchaSiteKey,
    }}>
      {children}
    </CaptchaContext.Provider>
  )
}
